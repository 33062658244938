import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import {
    RequestCommission,
    RequestCreateAuth,
    RequestCreateProvider,
    RequestCreateService,
    RequestUpdateAuth, RequestUpdateService,
    ResponseCreateAuth,
    ResponseCreateProvider,
    ResponseCreateService,
    ResponseProviderAuthList,
    ResponseProviderCommissions,
    ResponseProviderList,
    ResponseProviderServiceList,
    ResponseUpdateAuth, ResponseUpdateService
} from "../settings/interfaces/provider";
import { UtilsService } from "./utils.service";

const API_PROVIDER = environment.api.urlProvider;

@Injectable({
    providedIn: 'root'
})
export class ProviderService {

    constructor(private http: HttpClient, private utilsService: UtilsService) {
    }

    login(data: { email: string, password: string }) {
        const url = `${API_PROVIDER}provider/login`;
        return this.http.post(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getPayments(params: any = {type: 'PAYMENT'}): Observable<ResponseProviderList> {
        const url = `${API_PROVIDER}provider/list${this.utilsService.getParams(params)}`;
        return this.http.get<ResponseProviderList>(url).pipe(
            map((res) => {
                return res;
            })
        );
    }

    getProviderAuths(providerCode: number, params: any = {}): Observable<ResponseProviderAuthList> {
        const url = `${API_PROVIDER}provider/list_auth/${providerCode}${this.utilsService.getParams(params)}`;
        return this.http.get<ResponseProviderAuthList>(url).pipe(
            map((res) => {
                return res;
            })
        );
    }

    getProviderServices(params: any = {}): Observable<ResponseProviderServiceList> {
        const url = `${API_PROVIDER}provider/list_service${this.utilsService.getParams(params)}`;
        return this.http.get<ResponseProviderServiceList>(url).pipe(
            map((res) => {
                return res;
            })
        );
    }

    getProviderTypes(): Observable<string[]> {
        const url = `${API_PROVIDER}provider/types`;
        return this.http.get(url).pipe(
            map((res: any) => {
                return res.data as string[];
            })
        );
    }

    createProvider(data: RequestCreateProvider) {
        const url = `${API_PROVIDER}provider/create`;
        return this.http.post(url, data).pipe(
            map((res: ResponseCreateProvider) => {
                return res;
            }));
    }

    createProviderAuth(data: RequestCreateAuth) {
        const url = `${API_PROVIDER}provider/create_auth`;
        return this.http.post(url, data).pipe(
            map((res: ResponseCreateAuth) => {
                return res;
            }));
    }

    updateProviderAuth(providerAuthID: number | string, data: RequestUpdateAuth) {
        const url = `${API_PROVIDER}provider/update_auth/${providerAuthID}`;
        return this.http.post(url, data).pipe(
            map((res: ResponseUpdateAuth) => {
                return res;
            }));
    }

    createProviderService(data: RequestCreateService) {
        const url = `${API_PROVIDER}provider/create_service`;
        return this.http.post(url, data).pipe(
            map((res: ResponseCreateService) => {
                return res;
            }));
    }

    updateProviderService(providerServiceID: number | string, data: RequestUpdateService) {
        const url = `${API_PROVIDER}provider/update_service/${providerServiceID}`;
        return this.http.post(url, data).pipe(
            map((res: ResponseUpdateService) => {
                return res;
            }));
    }

    getProviderConfigurationById(id: string) {
        const url = `${API_PROVIDER}provider/configuration/provider/${id}`;
        return this.http.get<ResponseProviderCommissions>(url)
    }

    createCommission(commission: RequestCommission){
        const url = `${API_PROVIDER}provider/configuration/create`;
        return this.http.post(url, commission)
    }

    updateCommission(commission: any){
        const url = `${API_PROVIDER}provider/configuration/update`;
        return this.http.put(url, commission)
    }

    removeCommission(id: number){
        const url = `${API_PROVIDER}provider/configuration/delete/${id}`;
        return this.http.delete(url)
    }
}
