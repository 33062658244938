import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { BankAccount } from "../settings/interfaces/bank";
import {
    CreateCommission,
    IMerchant,
    MerchantType,
    UpdateCurrency,
    updateNotificationSettings, UpdateSettings
} from "../settings/interfaces/merchant";

const API_MERCHANT_URL = environment.api.urlMerchant;
const API_ADMIN_URL = environment.api.urlCore;

@Injectable({
    providedIn: 'root'
})
export class MerchantService {

    constructor(private http: HttpClient) {
    }

    get token(): string {
        return localStorage.getItem('ud') != null ? JSON.parse(localStorage.getItem('ud')).token : '';
    }

    login(data: { email: string, password: string }) {
        const url = `${API_MERCHANT_URL}login`;
        return this.http.post(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getChannels(merchantCode: number, params?: {countryCode?: string, channelId?: number}) {
        const url = `${API_MERCHANT_URL}setup/${merchantCode}${this.getParams(params)}`;
        return this.http.get(url).pipe(
            map((res: any) => {
                return res.data;
            }));
    }

    getMerchants(params?: any) {
        const url = `${API_MERCHANT_URL}${this.getParams(params)}`;
        return this.http.get(url).pipe(
            map((res ) => {
                return res;
            }));
    }

    getMerchant(id: string) {
        const url = `${API_MERCHANT_URL}detail/${id}`;
        return this.http.get(url).pipe(
            map((res: any) => {
                return res.data as IMerchant;
            }));
    }

    updateMerchant(id: string, data: any) {
        const url = `${API_MERCHANT_URL}${id}`;
        return this.http.put(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    createMerchant(data: any) {
        const url = `${API_MERCHANT_URL}create`;
        return this.http.post(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getMerchantType(): Observable<MerchantType[]> {
        const url = `${API_ADMIN_URL}merchant_type`;
        return this.http.get<MerchantType[]>(url).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getCommissions(id: number) {
        const url = `${API_ADMIN_URL}merchant/commisions?serviceID=${id}`;
        return this.http.get(url).pipe(
            map((res: any) => {
                return res.data;
            }));
    }

    updateNotification(id: number, data: updateNotificationSettings) {
        const url = `${API_MERCHANT_URL}update_notifications/${id}`;
        return this.http.put(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    updateCrossborder(id: number, state: boolean) {
        const url = `${API_MERCHANT_URL}crossborder/${id}`;
        return this.http.post(url, {crossborder: state }).pipe(
            map((res: any) => {
                return res;
            }));
    }

    createCommission(merchantCode: string | number, data: CreateCommission) {
        const url = `${API_MERCHANT_URL}commissions/${merchantCode}`;
        return this.http.post(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    updateCommission(id: number, data: CreateCommission) {
        const url = `${API_MERCHANT_URL}commissions/${id}`;
        return this.http.put(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }


    updateCurrency(id: number, data: UpdateCurrency) {
        const url = `${API_MERCHANT_URL}merchant_currency/${id}`;
        return this.http.put(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    updateSettings(id: number, data: UpdateSettings) {
        const url = `${API_MERCHANT_URL}update_setting/${id}`;
        return this.http.put(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    updateChannelStatus(merchantCode: number, channel: {active: boolean, paymentChannelId: number}) {
        const data = { active: channel.active, paymentChannelId: channel.paymentChannelId };
        const url = `${API_MERCHANT_URL}setup/channel/${merchantCode}`;
        return this.http.put(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    updateSubchannelStatus(id: string, active: boolean) {
        const url = `${API_MERCHANT_URL}setup/${id}`;
        return this.http.put(url, {active}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    uploadLogo(id: number | string, file: File) {
        const url = `${API_MERCHANT_URL}logo/${id}`;
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(url, formData).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getBankList(merchantCode: number) {
        const url = `${API_MERCHANT_URL}bank_account/${merchantCode}`;
        return this.http.get(url).pipe(
            map((res: any) => {
                return res.data;
            }));
    }

    createBankAccount(merchantCode: number, data: BankAccount) {
        const url = `${API_MERCHANT_URL}bank_account/${merchantCode}`;
        return this.http.post(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    updateBankAccount(idAccount: number | string, data: BankAccount) {
        const url = `${API_MERCHANT_URL}bank_account/${idAccount}`;
        return this.http.put(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    addChannel(merchantCode: number, data: any) {
        const url = `${API_MERCHANT_URL}setup/${merchantCode}`;
        return this.http.post(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    updateChannel(id: number | string, data: any) {
        const url = `${API_MERCHANT_URL}setup/${id}`;
        return this.http.put(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    createAppToken(labelVtexApi : string, merchantCode : string){
        const url = `${API_MERCHANT_URL}token/vtex`;
        return this.http.post(url, {labelVtexApi, merchantCode}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getParams(object){
        if (object) {
            const parameters = [];
            for (const property in object) {
                if (object.hasOwnProperty(property)) {
                    if(object[property] && property != 'period'){
                        parameters.push(encodeURI(property + '=' + (object[property] ? object[property] : '')));
                    }
                }
            }
            return '?'+parameters.join('&');
        }
        return ''
    }

    getSettlements(merchantCode) {
        const url = `${API_MERCHANT_URL}settlement/${merchantCode}`;
        return this.http.get(url)
    }

    updateSettlement(merchantSettlementId, settlement) {
        const url = `${API_MERCHANT_URL}settlement/${merchantSettlementId}`;
        return this.http.put(url, settlement)
    }

    createSettlement(merchantCode, settlement) {
        const url = `${API_MERCHANT_URL}settlement/${merchantCode}`;
        return this.http.post(url, settlement)
    }

    getTypeDocumentMerchant(countryCode){ 
        const url = `${API_ADMIN_URL}type_document/${countryCode}`;
        return this.http.get(url);
    }
}
