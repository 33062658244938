import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef
} from '@angular/core';
import { ProviderService, ResponseProviderServiceList } from "../../interfaces/provider";
import { TableModel } from "../../../models/table.model";
import { HeadersModel, IHeadersTable, TypeCell } from "../../../models/headersTable.model";
import { NzModalComponent } from "ng-zorro-antd";
import { FormGroup } from "@angular/forms";
import { InfoServiceForm } from "../../form-settings/info-service.form";
import { FormField } from "../../interfaces/form";
import { ProviderService as ProveedorService } from "../../../services/provider.service";

@Component({
  selector: 'app-services-providers',
  templateUrl: './services-providers.component.html',
  styleUrls: ['./services-providers.component.scss']
})
export class ServicesProvidersComponent implements OnInit, OnChanges {
  @Input() responseProviderServiceList: ResponseProviderServiceList;
  @Input() isLoading = false;
  @Output() pageIndexChange = new EventEmitter<any>()
  @ViewChild('colType', {static: false}) colType: TemplateRef<any>;
  @ViewChild('modalService', {static: false}) modalService: NzModalComponent;
  dataTable: TableModel<ProviderService> = new TableModel([], this.headersTable, { total: 0, currentPage: 1, pageSize: 10, lastPage: 1 });
  settingsForm: FormField[] = InfoServiceForm.getForm();
  form: FormGroup = new FormGroup({});
  providerServiceID: string | number;

  constructor(private providerService: ProveedorService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.responseProviderServiceList && changes.responseProviderServiceList.currentValue) {
      this.setOptionsType();
      this.dataTable = new TableModel(this.responseProviderServiceList.data, this.headersTable, {...this.responseProviderServiceList.metadata});
    }

  }

  setOptionsType() {
    this.settingsForm.map((item: FormField) => {
      if (item.key === 'type' && item.options) {
        item.options = [{name: 'CASH IN', value: 1}, {name: 'CASH OUT', value: 2}];
      }
    })
  }

  onClickNewService() {
    this.modalService.nzTitle = 'New service';
    this.modalService.open();
  }

  searchData(value) {
    this.pageIndexChange.emit(value);
  }

  eventTable(event) {
    if (event.function === 'edit') {
      this.providerServiceID = event.row.providerServiceID;
      this.modalService.nzTitle = 'Edit service';
      this.modalService.open();
      Object.keys(this.form.controls).forEach(key => {
        if (event.row[key]) {
          this.form.controls[key].setValue(event.row[key]);
        }
      });
    }
  }

  createService() {
    this.providerService.createProviderService(this.form.value).subscribe((res) => {
      this.modalService.close();
      this.pageIndexChange.emit(1);
    })
  }

  updateService() {
    this.providerService.updateProviderService(this.providerServiceID, this.form.value).subscribe((res) => {
      this.modalService.close();
      this.pageIndexChange.emit(this.dataTable.pagination.lastPage);
    })
  }

  onCancelModal() {
    this.form.reset();
    this.modalService.close();
  }

  onOkModal() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsDirty();
        this.form.controls[i].updateValueAndValidity();
      }
      return;
    }
    if (this.modalService.nzTitle === 'New service') {
      this.createService()
    }
    if (this.modalService.nzTitle === 'Edit service') {
      this.updateService();
    }

    this.form.reset();
    this.modalService.close();
  }

  get headersTable (): HeadersModel[] {
    const headers: IHeadersTable[] = [
      {
        label: 'Service ID',
        key: 'providerServiceID',
        width: '80px'
      },
      {
        label: 'ServiceCode',
        key: 'serviceCode',
      },
      {
        label: 'ProviderServiceCode',
        key: 'providerServiceCode',
      },
      {
        label: 'ServiceDescription',
        key: 'serviceDescription',
      },
      {
        label: 'Type',
        key: 'type',
        type: TypeCell.custom,
        template: this.colType
      },
      {
        label: 'Status',
        key: 'isActive',
        type: TypeCell.status,
      },
      {
        label: 'Action',
        key: 'edit',
        width: '100px',
        labelCol: 'Editar',
        type: TypeCell.button,
      }
    ]

    return headers.map((header) => new HeadersModel(header));
  }

}
