import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from "../shared/shared.module";
import { AuthenticationProvidersComponent } from './components/authentication-providers/authentication-providers.component';
import { BankAccountMerchantComponent } from './components/bank-account-merchant/bank-account-merchant.component';
import { ChannelsMerchantComponent } from './components/channels-merchant/channels-merchant.component';
import { CommissionsMerchantComponent } from './components/commissions-merchant/commissions-merchant.component';
import { CommissionsProvidersComponent } from './components/commissions-providers/commissions-providers.component';
import { DocumentsMerchantComponent } from './components/documents-merchant/documents-merchant.component';
import { FormBaseComponent } from './components/form-base/form-base.component';
import { FormDataBankComponent } from './components/form-data-bank/form-data-bank.component';
import { FormDataMerchantComponent } from './components/form-data-merchant/form-data-merchant.component';
import { FormDynamicSettingsComponent } from './components/form-dynamic-settings/form-dynamic-settings.component';
import { InputPercentageComponent } from './components/input-percentage/input-percentage.component';
import { CommisionsModalsComponent } from "./components/modals/commisions-modals/commisions-modals.component";
import { NotificationsMerchantComponent } from './components/notifications-merchant/notifications-merchant.component';
import { PaymentChannelsProvidersComponent } from './components/payment-channels-providers/payment-channels-providers.component';
import { ServicesProvidersComponent } from './components/services-providers/services-providers.component';
import { SettingsMerchantComponent } from './components/settings-merchant/settings-merchant.component';
import { SettlementsMerchantComponent } from './components/settlements-merchant/settlements-merchant.component';
import { TableSettingsComponent } from './components/table-settings/table-settings.component';
import { BankEditComponent } from './pages/bank-edit/bank-edit.component';
import { BankNewComponent } from './pages/bank-new/bank-new.component';
import { BanksComponent } from './pages/banks/banks.component';
import { MerchantEditComponent } from './pages/merchant-edit/merchant-edit.component';
import { MerchantNewComponent } from './pages/merchant-new/merchant-new.component';
import { MerchantsComponent } from './pages/merchants/merchants.component';
import { ProviderEditComponent } from './pages/provider-edit/provider-edit.component';
import { ProviderNewComponent } from './pages/provider-new/provider-new.component';
import { ProvidersComponent } from './pages/providers/providers.component';
import { SettingsRoutingModule } from './settings-routing.module';


@NgModule({
    declarations: [
        MerchantsComponent,
        MerchantEditComponent,
        FormDataMerchantComponent,
        SettingsMerchantComponent,
        NotificationsMerchantComponent,
        CommissionsMerchantComponent,
        BankAccountMerchantComponent,
        ChannelsMerchantComponent,
        MerchantNewComponent,
        CommisionsModalsComponent,
        BanksComponent,
        BankNewComponent,
        FormDataBankComponent,
        BankEditComponent,
        ProvidersComponent,
        TableSettingsComponent,
        ProviderNewComponent,
        FormDynamicSettingsComponent,
        ProviderEditComponent,
        AuthenticationProvidersComponent,
        ServicesProvidersComponent,
        PaymentChannelsProvidersComponent,
        FormBaseComponent,
        InputPercentageComponent,
        CommissionsProvidersComponent,
        SettlementsMerchantComponent,
        DocumentsMerchantComponent
    ],
    imports: [
        CommonModule,
        SettingsRoutingModule,
        SharedModule,
    ]
})
export class SettingsModule {
}
