import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd';
import { environment } from '../../../../../../environments/environment';
import { HeadersModel, IHeadersTable, TypeCell } from '../../../../../models/headersTable.model';
import { Pagination } from '../../../../../models/pagination';
import { Amortization, ResponseAmortizationConsulIt } from '../../../../../models/responseAmortizationConsult';
import { TableModel } from '../../../../../models/table.model';
import { ApiService } from '../../../../../services/api.service';

const API = environment.api;

@Component({
  selector: 'app-advances',
  templateUrl: './advances.component.html',
  styleUrls: ['./advances.component.scss']
})
export class AdvancesComponent implements OnInit {
  protected titlePage: string = 'Actividades > Cash In > Anticipos';
  protected filters: any;
  protected isLoading = false;
  protected advances: any;
  protected dataTable: TableModel<Amortization> = new TableModel([], this.headersTable, {
    total: 0,
    currentPage: 1,
    pageSize: 10,
    lastPage: 1
});
  protected pageSize = 10;
  protected monthSettlement = moment().month() + 1;
  protected yearSettlement = moment().year();
  protected status: number = 1;
  protected pagination: Pagination;

  constructor(private api: ApiService, private nzModalService: NzModalService) { }

  ngOnInit() {
    this.filters = this.getFilters()
    this.getAdvances();
  }

  async getFilters() {
    return [
      {
        'name': 'Mes',
        'key': 'month',
        'type': 'month',
        value: moment().toDate(),
      },
      {
        'name': 'Estado',
        'key': 'DevolutionStageID',
        'type': 'select',
        value: 1,
        data: [
            {
                name: 'Pendiente',
                value: 1,
            },
            {
                name: 'Aprobado',
                value: 2,
            },
            {
                name: 'Rechazado',
                value: 3,
            },
        ]
    },
    ]
  }

  changeDataTable(data) {
    this.monthSettlement = moment(data.month).month() + 1;
    this.yearSettlement = moment(data.month).year();
    this.status = data.DevolutionStageID ? data.DevolutionStageID : 1;
    this.getAdvances();
  }

  get headersTable(): HeadersModel[] {
    const headers: IHeadersTable[] = [
      {
        label: 'ID',
        key: 'transaction.PublicId',
        responsive: true,
      },
      {
        label: 'Nº de cuota',
        key: 'Installment',
      },
      {
        label: 'Monto',
        key: 'Amount',
      },
      {
        label: 'Fecha de liquidación',
        key: 'DateSettlement',
      },
      {
        label: 'Amount advance',
        key: 'SubTotalAdvance',
      },
      {
        label: 'Status',
        key: 'advances.Status',
        type: TypeCell.status,
      },
      {
        label: 'Action',
        key: 'advances.Status',
        labelCol: 'Aprobar',
        type: TypeCell.button,
      },
    ]

    return headers.map((header: IHeadersTable) => new HeadersModel(header));
  }

  getAdvances(page: number = 1) {
    this.isLoading = true;
    this.api.queryGet<ResponseAmortizationConsulIt>(
        'amortization/consult', 
        {page, per_page: this.pageSize, status: this.status, monthSettlement: this.monthSettlement, yearSettlement: this.yearSettlement }, 
        API.urlCmsMerchant
      ).subscribe(result => {
      this.isLoading = false;
      this.advances = result.data.data;
      this.pagination = new Pagination({total: result.data.total, currentPage: result.data.current_page, pageSize: result.data.per_page, lastPage: result.data.last_page});
      this.dataTable = new TableModel(result.data.data, this.headersTable, this.pagination);
    })
  }

  searchData(page) {
    this.getAdvances(page);
  }

  tableEvent(event) {
    if (event.function === 'Aprobar') {
      this.nzModalService.confirm({
        nzTitle: '¿Está seguro de Aprobar el anticipo?',
        nzContent: 'Al aprobar el anticipo se generará el pago de la cuota',
        nzOkText: 'Si',
        nzOkType: 'primary',
        nzOnOk: () => this.processAdvance(event.row, 2),
        nzCancelText: 'No',
        nzOnCancel: () => console.log('Cancel')
      });
    }

    if (event.function === 'Rechazar') {
      this.nzModalService.confirm({
        nzTitle: '¿Está seguro de Rechazar el anticipo?',
        nzContent: 'Al rechazar el anticipo no se generará el pago de la cuota',
        nzOkText: 'Si',
        nzOkType: 'primary',
        nzOnOk: () => this.processAdvance(event.row, 3),
        nzCancelText: 'No',
        nzOnCancel: () => console.log('Cancel')
      });
    }
  }

  getColorTag(status: number) {
    const statusColor = {
      1: 'orange',
      2: 'green',
      3: 'red',
    }
    return statusColor[status] ? statusColor[status] : 'default';
  }

  getLabelTag(status: number) {    
    const statusLabel = {
      1: 'Pendiente',
      2: 'Aprobado',
      3: 'Rechazado',
    }
    return statusLabel[status] ? statusLabel[status] : '-';
  }

  processAdvance(data, status) {
    this.api.queryPost('amortization/advance/update', { advanceAmortizationIds: [data.advances.AdvanceAmortizationID], status })
      .subscribe(res => {
        this.getAdvances();
      });
  }

}
