import { Validators } from "@angular/forms";
import { FormField } from "../interfaces/form";

export class InfoAuthForm {
    static getForm(values: any = {}): FormField[] {
        return [
            {
                label: 'ApiKey',
                key: 'apiKey',
                value: values && values.apiKey || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'apiKey',
                placeholder: 'ApiKey',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'SignatureKey',
                key: 'signatureKey',
                value: values && values.signatureKey || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'signatureKey',
                placeholder: 'SignatureKey',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'OtherKey1',
                key: 'otherKey1',
                value: values && values.otherKey1 || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'otherKey1',
                placeholder: 'OtherKey1',
                type: 'text',
                validations:  [],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'OtherKey2',
                key: 'otherKey2',
                value: values && values.otherKey2 || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'otherKey2',
                placeholder: 'OtherKey2',
                type: 'text',
                validations:  [],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'OtherKey3',
                key: 'otherKey3',
                value: values && values.otherKey3 || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'otherKey3',
                placeholder: 'OtherKey3',
                type: 'text',
                validations:  [],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'Endpoint',
                key: 'endpoint',
                value: values && values.endpoint || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'endpoint',
                placeholder: 'Endpoint',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'Metadata',
                key: 'metadata',
                value: values && values.metadata || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'metadata',
                placeholder: 'Metadata',
                type: 'textarea',
                validations:  [ ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 24,
            },
        ]
    }
}
