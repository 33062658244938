import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { TableModel } from "../../../models/table.model";
import { ProviderAuth, ResponseProviderAuthList } from "../../interfaces/provider";
import { HeadersModel, IHeadersTable, TypeCell } from "../../../models/headersTable.model";
import { FormGroup } from "@angular/forms";
import { NzModalComponent } from "ng-zorro-antd";
import { FormField } from "../../interfaces/form";
import { InfoAuthForm } from "../../form-settings/info-auth.form";
import { ProviderService } from "../../../services/provider.service";

@Component({
    selector: 'app-authentication-providers',
    templateUrl: './authentication-providers.component.html',
    styleUrls: ['./authentication-providers.component.scss']
})
export class AuthenticationProvidersComponent implements OnInit, OnChanges {
    @Input() responseProviderAuthList: ResponseProviderAuthList;
    @Input() isLoading = false;
    @Output() pageIndexChange = new EventEmitter<any>()
    @ViewChild('modalAuth', {static: false}) modalAuth: NzModalComponent;
    form: FormGroup = new FormGroup({});
    settingsForm: FormField[] = InfoAuthForm.getForm();
    providerAuthID: number | string;
    dataTable: TableModel<ProviderAuth> = new TableModel([], this.headersTable, {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        lastPage: 1
    });

    constructor(private providerService: ProviderService) {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.responseProviderAuthList && changes.responseProviderAuthList.currentValue) {
            this.dataTable = new TableModel(this.responseProviderAuthList.data, this.headersTable, {...this.responseProviderAuthList.metadata});
        }
    }

    onClickNewAuth() {
        this.modalAuth.nzTitle = 'New authentication';
        this.modalAuth.open();
    }

    searchData(value) {
        this.pageIndexChange.emit(value);
    }

    eventTable(event: { function: string, row: ProviderAuth }) {
        if (event.function === 'edit') {
            this.providerAuthID = event.row.providerAuthID;
            this.modalAuth.nzTitle = 'Edit authentication';
            this.modalAuth.open();
            Object.keys(this.form.controls).forEach(key => {
                if (event.row[key]) {
                    this.form.controls[key].setValue(event.row[key]);
                }
            });
        }
    }

    createAuth() {
        this.providerService.createProviderAuth(this.form.value).subscribe((res) => {
            this.modalAuth.close();
            this.pageIndexChange.emit(1);
        })
    }

    updateAuth() {
        this.providerService.updateProviderAuth(this.providerAuthID, this.form.value).subscribe((res) => {
            this.modalAuth.close();
            this.pageIndexChange.emit(this.dataTable.pagination.lastPage);
        })
    }

    onOkModal() {
        if (this.form.invalid) {
            for (const i in this.form.controls) {
                this.form.controls[i].markAsDirty();
                this.form.controls[i].updateValueAndValidity();
            }
            return;
        }
        if (this.modalAuth.nzTitle === 'New authentication') {
            this.createAuth()
        }
        if (this.modalAuth.nzTitle === 'Edit authentication') {
            this.updateAuth();
        }

        this.form.reset();
        this.modalAuth.close();
    }

    onCancelModal() {
        this.form.reset();
        this.modalAuth.close();
    }

    get headersTable(): HeadersModel[] {
        const headers: IHeadersTable[] = [
            {
                label: 'Auth ID',
                key: 'providerAuthID',
                width: '60px'
            },
            {
                label: 'ApiKey',
                key: 'apiKey',
                type: TypeCell.hideText,
                width: '100px'
            },
            {
                label: 'OtherKey1',
                key: 'otherKey1',
                type: TypeCell.hideText,
                width: '100px'
            },
            {
                label: 'OtherKey2',
                key: 'otherKey2',
                type: TypeCell.hideText,
                width: '100px'
            },
            {
                label: 'OtherKey3',
                key: 'otherKey3',
                type: TypeCell.hideText,
                width: '100px'
            },
            {
                label: 'Endpoint',
                key: 'endpoint',
                width: '200px',
                type: TypeCell.hideText,
            },
            {
                label: 'Status',
                key: 'status',
                width: '100px',
                type: TypeCell.status,
            },
            {
                label: 'Action',
                key: 'edit',
                width: '100px',
                labelCol: 'Editar',
                type: TypeCell.button,
            }
        ]

        return headers.map((header) => new HeadersModel(header));
    }

}
