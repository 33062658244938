import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataKeys'
})
export class DataKeysPipe implements PipeTransform {

  transform(value: any, path: string, separator = '.') {        
    return path.split(separator).reduce((obj, property) => obj[property], value);
  }

}
