import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { IPagination } from '../../../../models/pagination.interface';
import { FilterReportType, ReportType } from '../../../../models/response-report-types';
import { DataReports } from '../../../../models/response-reports';
import { ListService } from '../../../../services/list.service';
import { ReportsService } from '../../../../services/reports.service';

export interface Params {
	type: string;
	output: string;
}

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {
  protected filters: FilterReportType[] = [];
  protected maxDate = new Date();
  protected reportTypes: ReportType[] = [];
  protected reportTypes$: Observable<ReportType[]> = new Observable<ReportType[]>();
  protected listStatusReports$: Observable<any> = new Observable<any>();
  protected loadingStatusReports$ = new BehaviorSubject<boolean>(true);
  protected formReports = this.fb.group({
    type: [null, Validators.required],
    filters: this.fb.group({})
  });
  protected form = this.fb.group({
    status: [null],
    reportTypeId: [null],
    range: [[moment().subtract(1, 'months').format('YYYY-MM-DD'), new Date()], Validators.required],
  });
  protected loadingTypes$ = new BehaviorSubject<boolean>(true);
  private params: Params = { type: '', output: '' };
  protected isLoading = false;
  protected reports: DataReports[] = [];
  protected pagination: IPagination = {
    total: 0,
    currentPage: 1,
    pageSize: 10,
    lastPage: 0
  };
  protected pageSizeOptions = [10, 20, 50, 100];
  protected loadingReports$ = new BehaviorSubject<boolean>(true);
  protected isVisibleModal = false;

  constructor(
    private fb: FormBuilder, 
    private reportsService: ReportsService,
    private listService: ListService,
    private notification: NzNotificationService
  ) { }

  ngOnInit(): void {
    this.getListStatusReports();
    this.getReportTypes();
    this.updateForm();
    this.getReports();
  }

  updateForm() {
    this.formReports.controls.type.valueChanges.subscribe((value) => {
			this.resetFormFilters();
			if (!value) return;
			const type: ReportType = this.reportTypes.find((report: any) => report.reportTypeId === value);
			const filters: FilterReportType[] = type?.filters ?? [];
			this.params = type.metadata.params;

			filters.forEach((field) => {        
				if (field.fieldType === 'rangeDate') {
					this.formFilters.addControl('range', this.fb.control([], field.required ? Validators.required : null));
          this.formFilters.get('range')?.setValue([moment().subtract(1, 'months').format('YYYY-MM-DD'), new Date()]);
				} else if (typeof field.key === 'string') {
					this.formFilters.addControl(field.key, this.fb.control(null, field.required ? Validators.required : null));
				} else {
					console.error(`Invalid field key: ${field.key}`);
				}
			});

			this.filters = filters;
		});
  }

  getReportTypes(): void {
		this.reportTypes$ = this.reportsService.getReportTypes().pipe(
      map((response) => response.data),
      tap((reportTypes) => this.reportTypes = reportTypes),
      finalize(() => this.loadingTypes$.next(false))
    )
	}


  private resetFormFilters(): void {
		this.formFilters.reset();
		Object.keys(this.formFilters.controls).forEach((key) => {
      this.formFilters.removeControl(key);
		});
    this.filters = [];
	}

  resetFormReports(): void {
    this.formReports.reset();
    this.resetFormFilters();
  }

  protected generateReport(): void {
    this.isLoading = true;
    const multiselectFields = this.filters.filter((field) => field.multiple);
    
		if (this.formReports.valid) {
      const params = this.params
      const reportTypeId = this.formReports.value.type!
      const { range, ...filters } = this.formFilters.value;

      const _params = {
        ...filters,
        fromDate: range[0] ? moment(range[0]).format('YYYY-MM-DD') : null,
        toDate: range[1] ? moment(range[1]).format('YYYY-MM-DD') : null,
        reportTypeId
      };      

      this.reportsService.generateReport(params.type, params.output, _params).pipe(
        finalize(() => {
          this.isLoading = false
          this.isVisibleModal = false;
        })
        ).subscribe(() => {
          this.getReports();
          this.notification.success('Reporte', 'El reporte se está generando, por favor espere unos minutos y actualiza la tabla.');
        })
		}
	}

  getReports(): void {
    this.loadingReports$.next(true);
		const { range, ...rest } = this.form.getRawValue();
		const params = {
			statusId: rest.status,
			reportTypeId: rest.reportTypeId,
			page: this.pagination.currentPage,
			size: this.pagination.pageSize,
			fromDate: range[0] ? moment(range[0]).format('YYYY-MM-DD') : moment().subtract(2, 'months').format('YYYY-MM-DD'),
			toDate: range[1] ? moment(range[1]).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
		};
		this.reportsService.getReports(params).pipe(
      map((response) => ({
        data: response.data.map((report) => ({
          ...report,
          status: {
            ...report.status,
            label: this.getNameStatus(report.status.key),
            color: this.getColorStatus(report.status.key)
          }
        })),
        metadata: response.metadata
      })),
      finalize(() => this.loadingReports$.next(false))
    ).subscribe((response) => {
      this.reports = response.data;
      this.pagination = response.metadata;
		});
	}

  openModal(): void {
    this.isVisibleModal = true;
  }

  downloadReport(url: string): void {
		window.open(url, '_blank');
	}

  changePageSize(size: number): void {
    this.getReports();
  }

  changePage(page: number): void {
    this.pagination.currentPage = page;    
    this.getReports();
  }

  handleCancel(): void {
    this.isVisibleModal = false;
    this.resetFormReports();
  }

  handleOk(): void {
    this.generateReport();
    this.resetFormReports();
  }

  getListStatusReports(): void {
		this.listStatusReports$ = this.listService.getListStatusReports().pipe(
      map((response) => response.map((r) => ({ ...r, label: this.getNameStatus(r.key) }))),
      finalize(() => this.loadingStatusReports$.next(false))
    );
	}

  getNameStatus(key: string): string {
		const status: { [key: string]: string } = {
			GENERATED: 'Generado',
			ERROR: 'Error',
			PENDING: 'Pendiente'
		};
		return status[key] || key;
	}

  get formFilters(): FormGroup {
		return this.formReports.get('filters') as FormGroup;
	}

	getColorStatus(key: string): string {
		const status: { [key: string]: string } = {
			GENERATED: 'green',
			ERROR: 'red',
			PENDING: 'yellow'
		};
		return status[key] || 'gray'
	}

}
