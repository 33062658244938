import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { MerchantService } from '../../../services/merchant.service';
import { Channel } from '../../interfaces/channel';
import { Settlement } from '../../interfaces/merchant';
import { DAYS_OF_MONTH, InfoCardsMerchantSettlements, WEEKDAYS } from './settlements-settings';

@Component({
  selector: 'app-settlements-merchant',
  templateUrl: './settlements-merchant.component.html',
  styleUrls: ['./settlements-merchant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettlementsMerchantComponent implements OnChanges {
  @Input() settlements: Settlement[];
  @Input() channels: Channel[] = [];
  @Input() merchantCode: string;
  @Output() update = new EventEmitter<any>();

  protected formCards: FormGroup;
  protected baseInputsCard = InfoCardsMerchantSettlements.getInputs();
  protected configInputsCards: any[] = [];


  constructor(
    private fb: FormBuilder,
    private merchantService: MerchantService,
    private modalService: NzModalService,
    private message: NzMessageService
    ) {
      this.createForm();
    }


  ngOnChanges(changes: SimpleChanges): void {
    const hasChannels = changes.channels && changes.channels.currentValue.length > 0  
    if (hasChannels) {
      this.loadData('channel', this.baseInputsCard, this.channels);
    }

    if (this.channels.length && this.settlements.length) {
      this.settlements.forEach(element => {        
        const channelName = this.channels.find(channel => channel.PaymentChannelID === Number(element.paymentChannelId)).ChannelName;                 
          this.configInputsCards.push(InfoCardsMerchantSettlements.getFormCard(channelName, Number(element.merchantSettlementId), element)); 
      }); 
      this.createFormCards();
      this.configInputsCards.forEach((element) => {        
        this.loadData('channel', element.inputs, this.channels);
      });      
    }

  }

  createForm(){
    const formGroup = new FormGroup({});
    this.baseInputsCard.forEach(element => {      
      formGroup.addControl(element.formControlName, new FormControl({value: element.value, disabled: element.disabled}, element.validations));
    });
    this.formCards = this.fb.group({
      newSettlement: formGroup,
      settlements: this.fb.array([])
    });
  }

  createFormCard(card) {
    const formGroup = new FormGroup({});
    if (!card.inputs) return formGroup;
      card.inputs.forEach(element => {
        let value = element.value 
        if(Array.isArray(element.value)) {
          value = element.value 
        } else if (!isNaN(element.value)) {
            if (typeof element.value == "boolean") {
                value = element.value
            }else{
                value = Number(element.value)
            }    
        }         
        formGroup.addControl(element.formControlName, new FormControl({value, disabled: element.disabled}, element.validations));
    });    
    return formGroup;
  }

  createFormCards() {
    const channelArray = this.formSettlements;
    channelArray.clear();
    this.configInputsCards.forEach((data) => {      
      channelArray.push(this.createFormCard(data));
    });
    this.setDisabledControl();
  }

  changeSelectFormBase(key: string, form: FormGroup, nextInput: any) {
    if (key === 'type') {
      form.get('frequency').enable();
      form.get('frequency').setValue(null);
      this.setFrequency(key, form, nextInput);   
    }
  }

  setFrequency(key: string, form: FormGroup, nextInput: any) {    
    if (form.get('type').value === 1) {
      nextInput.type = 'text';
      nextInput.options = [];
      form.get('frequency').setValidators([Validators.required, Validators.pattern('^[0-9]+(\.[0-9]{1,3})?$')]);
      form.get('frequency').updateValueAndValidity();
      
    }
    if (form.get('type').value === 2) {
      nextInput.type = 'select';
      nextInput.options = WEEKDAYS;
      form.get('frequency').setValidators([Validators.required]);
      form.get('frequency').updateValueAndValidity();
      
    }
    if (form.get('type').value === 3) {
      nextInput.type = 'select';
      nextInput.options = DAYS_OF_MONTH;
      form.get('frequency').setValidators([Validators.required]);
      form.get('frequency').updateValueAndValidity();
    } 
  }

  loadData(key: string, inputs: any[], data: any[]) {    
    inputs.forEach(input => {
      if(input.formControlName === key) {
        input.options = data;          
      }
    });      
  }

  setDisabledControl() {
    this.configInputsCards.forEach((element, index) => {     
      element.inputs.forEach((input, j) => {
        if (input.formControlName === 'type') {
          this.setFrequency(input.formControlName, this.formSettlements.at(index) as FormGroup, element.inputs[j + 1]);
        }
        if (input.formControlName === 'channel') {
          this.formSettlements.at(index).get('channel').disable();
        }
        if (input.formControlName === 'isActive') {
          this.formSettlements.at(index).get('isActive').enable();
        }
      });
    });
  }

  updateSettlement(index: number, card) {
    const formSettlement = this.formSettlements.at(index) as FormGroup;
    const dataSettlement = formSettlement.getRawValue();

    const settlement = {
      type: dataSettlement.type,
      minimumSettlement: Number(dataSettlement.minimumSettlement),
      frequency: dataSettlement.frequency,
      totalTransfer: dataSettlement.totalTransfer,
      isActive: dataSettlement.isActive
    }

    this.modalService.confirm({
      nzTitle: '¿Estás seguro de actualizar esta liquidación?',
      nzContent: 'Se actualizará la liquidación del canal de pago',
      nzOkText: 'Si',
      nzOnOk: () => {
        this.merchantService.updateSettlement(card.id, settlement).subscribe((response) => {
          this.createMessage('success', 'Liquidación actualizada correctamente');
          this.configInputsCards = [];
          this.update.emit();
        });
      }
    })
  }

  createSettlement() {
    const formSettlement = this.formGroupNewSettlement as FormGroup;
    const dataSettlement = formSettlement.getRawValue();

    const settlement = {
      paymentChannelId: dataSettlement.channel,
      type: dataSettlement.type,
      minimumSettlement: Number(dataSettlement.minimumSettlement),
      frequency: dataSettlement.frequency,
      totalTransfer: dataSettlement.totalTransfer,
    }

    this.modalService.confirm({
      nzTitle: '¿Estás seguro de crear esta liquidación?',
      nzContent: 'Se creará la liquidación del canal de pago',
      nzOkText: 'Si',
      nzOnOk: () => {
        this.merchantService.createSettlement(this.merchantCode, settlement).subscribe((response) => {
          this.createMessage('success', 'Liquidación creada correctamente');
          this.formGroupNewSettlement.reset();
          this.configInputsCards = [];
          this.update.emit();
        });
      }
    })
  }

  disabledItem(item: any, input: any) {
    if (input.formControlName === 'channel') {
      return this.settlements.some(settlement => Number(settlement.paymentChannelId) === Number(item.PaymentChannelID));
    }
    return false;
  }

  createMessage(type: string, message: string): void {
    this.message.create(type, message);
  }

  get formSettlements(): FormArray {
    return this.formCards.get('settlements') as FormArray;
  }

  get formGroupNewSettlement(): FormGroup {
    return this.formCards.get('newSettlement') as FormGroup;
  }

}
