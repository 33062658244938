import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NzModalService } from 'ng-zorro-antd';
import { FormControl, FormGroup, Validators } from "@angular/forms";

const enum approvalType {
  approve = 2,
  reject = 0
}

@Component({
  selector: 'app-balance-detail',
  templateUrl: './balance-detail.component.html',
  styleUrls: ['./balance-detail.component.css']
})
export class BalanceDetailComponent implements OnInit {

  public details: any = this.processDetail();
  public historical: any = this.processHistorical();
  public titlePage: any;
  public selectedRecharge: any;
  public isVisible = false;
  public formReverseBalance: FormGroup;
  public isLoading = false;

  constructor(private aR: ActivatedRoute, private modalService: NzModalService, private api: ApiService, private router: Router) {
    this.formReverseBalance = this.createFormReverse();
  }

  ngOnInit() {
  }

  async processDetail() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];
    this.titlePage = 'Operation > Cash Out > Balance > ' + id;

    return {
      headers: this.getHeadersDetails(),
      data: this.getDetails(id, 'balance/detail')
    };
  }


  async getDetails(id, service) {
    const data = {
      service: service,
      data: id
    };
    return await this.api.api(data).toPromise();
  }


  getHeadersDetails() {
  return [
    {
      name: 'NumberAccount',
      key: 'Balance-NumberAccount'
    },
    {
      name: 'Total Amount',
      key: 'Balance-TotalAmount'
    },
    {
      name: 'Amount Available',
      key: 'Balance-AmountAvailable'
    },
    {
      name: 'Currency',
      key: 'Balance-CurrencyCode'
    },
    {
      name: 'Active',
      key: 'Balance-IsActive'
    },
    {
      name: 'Updated Time',
      key: 'Balance-updated_at'
    }
  ];
}


  // HISTORICAL

  getHeadersHistorical() {

    return [
      {
        name: 'Banco',
        key: 'ShortName',
      },
      {
        name: 'Reference',
        key: 'Reference'
      },
      {
        name: 'Amount',
        key: 'Amount'
      },
      {
        name: 'Currency',
        key: 'CurrencyCode'
      },
      {
        name: 'Date',
        key: 'created_at'
      },
      {
        name: 'Description',
        key: 'Description',
        responsive: true
      },
      {
        name: 'Status',
        key: 'LastStatus',
        responsive: true,
        type: 'status'
      },
      {
        name: 'Opciones',
        key: 'list',
        opc: false,
        type: 'actions',
        functions: ['Anular', 'Aprobar']
      }
    ];
  }

  functions(event) {
    switch (event.function) {
      case 'Anular':
        this.selectedRecharge = event;
        this.isVisible = true;
        this.formReverseBalance.get("Reference").setValue(event.data.Reference);
        this.formReverseBalance.get("BankCode").setValue(event.data.BankId);
        this.formReverseBalance.get("BankName").setValue(event.data.ShortName);
        this.formReverseBalance
            .get("MerchantCode")
            .setValue(event.data.Invoice.MerchantCode);
        this.formReverseBalance.get("LegalName").setValue(event.data.Invoice.LegalName);
        this.formReverseBalance.get("Amount").setValue(event.data.Amount);
        this.formReverseBalance.get("CurrencyId").setValue(event.data.CurrencyCode);
        break;
      case 'Aprobar':
        this.showWarning(event, '¿Está seguro de aprobar la operación?', approvalType.approve);
        break;
      case 'Rechazar':
        this.showWarning(event, '¿Está seguro de rechazar la operación?', approvalType.reject);
        break;
    }
  }

  showWarning(event: any, content: string, type: approvalType) {
    this.modalService.confirm({
      nzTitle: event.function,
      nzContent: content,
      nzOnOk: () => this.approveReject(event.data, type)
    });
  }

  approveReject(data: any, type: number) {
    const url = `transaction/approveTransferCashin/${data.Reference}`;
    const body = {
      Status: type,
      reason: !type ? this.Description.value : null
    };
    this.api.queryPost(url, body).subscribe(
      (res: any) => {
        this.isVisible = false;
        this.formReverseBalance.reset();
        this.modalService.success({
          nzTitle: 'Operación exitosa',
          nzContent: 'La operación se ha realizado con éxito'
        });
        this.historical = this.processHistorical();
      }
    );
  }

  cancelRecharge(info){
    this.approveReject(info.data, approvalType.reject)
  }

  handleCancel() {
    this.isVisible = false;
    this.selectedRecharge = null;
    this.formReverseBalance.reset();
  }

  handleOk() {
    if(this.formReverseBalance.invalid){
      this.formReverseBalance.markAllAsTouched()
      return;
    }
    this.cancelRecharge(this.selectedRecharge);
  }

  async processHistorical() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];

    return await this.getDetails(id, 'balance/detail').then((data: any) => {

      const activity = [];
      data.Historico.forEach(e => {
        // e.Status = this.api.getStatusByID(e.Status.toString());
        activity.push(e);
      });

      return {
          headers: this.getHeadersHistorical(),
          data: activity,
          title: 'Historico:',
          source: 'balance-detail-historical'

      };
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  createFormReverse() {
    return new FormGroup({
      MerchantCode: new FormControl(''),
      LegalName: new FormControl(''),
      BankCode: new FormControl(''),
      BankName: new FormControl(''),
      Reference: new FormControl('', [Validators.required]),
      Amount: new FormControl(''),
      CurrencyId: new FormControl('COP'),
      description: new FormControl('', [Validators.required])
    });
  }

  get Description() { return this.formReverseBalance.get('description'); }
  get DescriptionValid() { return this.Description.invalid && this.Description.touched; }
}
