import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  public path = '';

  constructor() { }

  ngOnInit() {
    this.path = window.location.pathname.trim();
  }

}
