import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzTableModule } from "ng-zorro-antd/table";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzIconModule } from "ng-zorro-antd/icon";
import { es_ES, NgZorroAntdModule, NZ_I18N } from "ng-zorro-antd";
import * as AllIcons from "@ant-design/icons-angular/icons";
import { IconDefinition } from "@ant-design/icons-angular";

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        NgZorroAntdModule,
        NzMenuModule,
        NzTableModule,
        NzFormModule,
        NzModalModule,
        NzDatePickerModule,
        NzIconModule,
    ],
    exports: [
        NgZorroAntdModule,
        NzMenuModule,
        NzTableModule,
        NzFormModule,
        NzModalModule,
        NzDatePickerModule,
        NzIconModule,
    ],
    providers: [
        {
            provide: NZ_I18N, useValue: es_ES
        },
        {
            provide: NzIconModule,
            useValue: icons
        }
    ],
})
export class AntDesignModule {
}
