import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';


@Component({
  selector: 'app-filter1',
  templateUrl: './filter1.component.html',
  styleUrls: ['./filter1.component.scss']
})
export class Filter1Component implements OnInit, OnChanges {

  @Input() data: any;
  @Input() labelButtonSecondary: any;
  @Output() dataOutput = new EventEmitter<any>();
  @Output() onEventSecondary = new EventEmitter<any>();

  form: FormGroup;
  items: FormArray;
  dataFields: any;
  isVisible = false;
  date = null;
  dateRange = [];
  titleModal: any = '';
  buttonDownload = false;

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {

    this.data.then((data: any) => {
      this.dataFields = data;
    });
  }

  ngOnInit() {

    this.data.then((data: any) => {
      this.dataFields = data;
      const item = {};

      data.forEach((e: any) => {

        if (e.key === 'download') {
          this.buttonDownload = true;
        }

        let fromDate = null;
        let toDate = null;



        if (Array.isArray(e.key)) {
          item['period'] = e.key[2] ? e.key[2] : '5';
          item[e.key[0]] = fromDate;
          item[e.key[1]] = toDate;
        } else {
          if (e.type === 'select') {
            item[e.key] = null;
          } else {
            item[e.key] = null;
          }
        }

      });

      this.form = this.fb.group({
        ...item
      });

    });
  }

  onChangePeriod(e){
    moment.locale('es', {
      week: {
        dow: 1,
        doy: 4
      }
    });

    let fromDate = '';
    let toDate = moment().format('YYYY-MM-DD');

    switch (String(e)) {
      case '1':
        fromDate = moment().subtract(1, "days").format('YYYY-MM-DD');
        break;
      case '2':
        fromDate = moment().weekday(0).format('YYYY-MM-DD');
        break;
      case '3':
        fromDate = moment().startOf('month').format('YYYY-MM-DD');
        break;
      case '4':
        fromDate = moment().subtract(3, "months").startOf('month').format('YYYY-MM-DD');
        break;
      case '5':
        fromDate = null
        toDate = null
        break;
      case '6':
        this.showModal();
        break;
      default:
        fromDate = null
        toDate = null
        break;
    }
      this.form.get('from_date').setValue(fromDate)
      this.form.get('to_date').setValue(toDate)
  }

  processAditionalButton(e){
    this.dataOutput.emit(e);
  }

  processAditionalButtonDownload(e:any) {
    this.dataOutput.emit({
      type: e,
      data: this.getData()
    });
  }

  process(){
    this.dataOutput.emit(this.getData());
  }

  getData()  {
    let dataValues = this.form.value;
    if (dataValues.CreatedAt) {
      return {...dataValues, CreatedAt: moment(dataValues.CreatedAt).format('YYYY-MM-DD')};
    }
    return dataValues
  }

  //MODAL

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  //DATE PICKER

  onChangeDate(result: Date): void {
    let fromDate = moment(result[0]).format('YYYY-MM-DD');
    let toDate = moment(result[1]).format('YYYY-MM-DD');
    this.form.get('from_date').setValue(fromDate)
    this.form.get('to_date').setValue(toDate)

  }


  onChangeType($event){
    this.onEventSecondary.emit($event);
  }

}
