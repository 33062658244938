import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ResponseReportTypes } from '../models/response-report-types';
import { ReportsResponse } from '../models/response-reports';
import { removeEmptyProperties } from '../shared/utils/remove-empty-properties';

@Injectable({
	providedIn: 'root'
})
export class ReportsService {
	private readonly API_URL = environment.api.urlReports;
	private httpClient = inject(HttpClient);
	private reportTypes$: Observable<ResponseReportTypes> | null = null;

	getReports(params: { [key: string]: string | number }): Observable<ReportsResponse> {
		const _params = removeEmptyProperties(params);
		return this.httpClient.get<ReportsResponse>(`${this.API_URL}/v1/reports`, { params: _params });
	}

	generateReport(type: string, output: string, params: any): Observable<any> {
		const _params = removeEmptyProperties(params);
		const serializedParams = this.serializeParams(_params);
		return this.httpClient.get<any>(`${this.API_URL}/v1/reports/build/${type}/${output}`, { params: serializedParams });
	}

	getReportTypes(): Observable<ResponseReportTypes> {
		if (!this.reportTypes$) {
			this.reportTypes$ = this.httpClient.get<ResponseReportTypes>(`${this.API_URL}/v1/reports/types`).pipe(shareReplay(1));
		}
		return this.reportTypes$;
	}

	private serializeParams(params: any): any {
    const serialized: any = {};
    Object.keys(params).forEach(key => {
        if (Array.isArray(params[key])) {
						serialized[key] = `["${params[key].join('","')}"]`;
        } else {
            serialized[key] = params[key];
        }
    });
    return serialized;
	}
}
