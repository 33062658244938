// export interface Pagination {
//     total:       number;
//     currentPage: number;
//     pageSize:    number;
//     lastPage:    number;
// }

export class Pagination {
    total: number;
    currentPage: number;
    pageSize: number;
    lastPage: number;

    constructor(pagination: Pagination) {
        this.total = pagination.total;
        this.currentPage = pagination.currentPage;
        this.pageSize = pagination.pageSize;
        this.lastPage = pagination.lastPage;
    }
}
