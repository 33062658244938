import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-bot-details',
  templateUrl: './bot-details.component.html',
  styleUrls: ['./bot-details.component.scss']
})
export class BotDetailsComponent implements OnInit {

  public historical: any = this.processHistorical();
  public titlePage: any;

  constructor(private aR: ActivatedRoute, private api:ApiService, private router: Router) { }

  ngOnInit() {
  }

  async getDetails(id, service) {
    const data = {
      service: service,
      data: id
    };
    return await this.api.api(data).toPromise();
  }

  

    // HISTORICAL

    getHeadersHistorical() {

      return [
        {
          name: 'Bot',
          key: 'Description'
        },
        {
          name: 'Monto',
          key: 'Amount'
        },
        {
          name: 'Moneda',
          key: 'CurrencyCode'
        },
        {
          name: 'Descripcion',
          key: 'OperationDetail',
          responsive: true
        },
        {
          name: 'N°Operacion',
          key: 'OperationNumber'
        },
        {
          name: 'TipoMovimiento',
          key: 'OperationType'
        },
        {
          name: 'Estado',
          key: 'AsociedTransactionID',
          responsive: true,
          type: 'status'
        },
        {
          name: 'Fecha Registro',
          key: 'created_at'
        }
      ];
    }
  
    async processHistorical() {

      const parameters = this.aR.snapshot.paramMap.get("id");
      const id = parameters.split('-')[0];
  
      return await this.getDetails(id, 'bots_detail').then((data: any) => {
        
        
        const activity = [];
        data.ContentBank.forEach(e => {
          // e.Status = this.api.getStatusByID(e.Status.toString());
          activity.push(e);
        });
        
        return {
            headers: this.getHeadersHistorical(),
            data: activity,
            title: 'Contenido del banco:'
        };

      }).catch(error => {
        if (error.status === 401) {
          sessionStorage.setItem('ud', '');
          this.router.navigate(['/']);
        }
      });
    }

    goBack() {
      this.router.navigate(['/monitor-bots']);
    }
  
  
    
    
}
