import { Component, OnDestroy, OnInit } from '@angular/core';
import { BankService } from "../../../services/bank.service";
import { Bank, ResponseBanks } from "../../interfaces/bank";
import { TableModel } from "../../../models/table.model";
import { HeadersModel, IHeadersTable, TypeCell } from "../../../models/headersTable.model";
import { Pagination } from "../../../models/pagination";
import { Subject, Subscription } from "rxjs";
import { CountriesService } from 'src/app/services/countries.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-banks',
    templateUrl: './banks.component.html',
    styleUrls: ['./banks.component.scss']
})
export class BanksComponent implements OnInit, OnDestroy {
    public banks: Bank[] = [];
    public isLoading = false;
    public dataTable: TableModel<Bank> = new TableModel([], this.headersTable, {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        lastPage: 1
    });
    public pagination: Pagination;
    public pageSizes = [10, 20, 50, 100];
    public pageSize = 10;
    public subBanks: Subscription
    public filters: any;
    public countries: any[] = [];
    private filterTable : {
        countryCode?: string,
        shortName?: string,
        bankCode?: string,
        publicId? : string
    };
    private destroy$ = new Subject();
    constructor(private bankService: BankService,
                private countriesService: CountriesService) {
        this.countriesService.countries$.pipe(
            takeUntil(this.destroy$)
            ).subscribe(countries => {
            if (countries.length === 0) {
                this.countriesService.getCountries();
            } else {
                this.countries = countries;
            }
            })
    
        this.filters = this.getFilters();
    }

    ngOnInit() {
        this.getBanks();
    }

    ngOnDestroy() {
        this.subBanks.unsubscribe();
    }

    getBanks(page = 1) {
        this.isLoading = true;
        this.subBanks = this.bankService.getBanks({page, size: this.pageSize , ...this.filterTable}).subscribe(
            (response: ResponseBanks) => {
                this.banks = response.data;
                this.isLoading = false;
                this.pagination = response.metadata;
                this.dataTable = new TableModel(this.banks, this.headersTable, {...response.metadata});
            },
            () => {
                this.isLoading = false;
            }
        );
    }

    searchData(index) {
        this.isLoading = true;
        this.getBanks(index);
    }

    get headersTable(): HeadersModel[] {
        const headers: IHeadersTable[] = [
            {
                label: 'ID',
                key: 'bankCode',
            },
            {
                label: 'Name',
                key: 'shortName',
                responsive: true
            },
            {
                label: 'Country Code',
                key: 'countryCode',
            },
            {
                label: 'Date',
                key: 'updatedAt',
                type: TypeCell.date,
            },
            {
                label: 'Action',
                key: 'bankCode',
                labelCol: 'Editar',
                type: TypeCell.linkInternal,
                path: '/settings/banks/edit/',
            }
        ]

        return headers.map((header) => new HeadersModel(header));
    }

    tableEvent(event) {
        console.log(event)
    }

    changeDataTable(data) {
        console.log(data)
        this.filterTable = {}
        if (data.countryCode != null){
          this.filterTable.countryCode = data.countryCode;
        }
        if (data.bankCode != null){
          this.filterTable.bankCode = data.bankCode;
        }
        if (data.publicID != null){
          this.filterTable.publicId = data.publicID;
        } 
        if (data.shortName != null){
          this.filterTable.shortName = data.shortName;
        }
    
        this.getBanks( 1) ;
    }

    async getFilters(){
    
        return [
          {
            'name': 'Country',
            'key': 'countryCode',
            'type': 'select',
            'data': this.countries
          },
          {
            'name': 'Short Name',
            'key': 'shortName',
            'type': 'text'
          },
          {
            'name': 'Bank Code',
            'key': 'bankCode',
            'type': 'text'
          },
          {
            'name': 'Public ID',
            'key': 'publicID ',
            'type': 'text'
          }
        ];
    
      }
}
