export function removeEmptyProperties(obj: {
	[param: string]: string | number | boolean | readonly (string | number | boolean)[];
}): any {
	return Object.entries(obj).reduce(
		(acc, [key, value]) => {
			if (value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0)) {
				acc[key] = value;
			}
			return acc;
		},
		{} as any
	);
}
