import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ResponseBanks } from "../settings/interfaces/bank";
import { UtilsService } from "./utils.service";

const API_BANK = environment.api.urlBank;

@Injectable({
    providedIn: 'root'
})
export class BankService {

    constructor(private http: HttpClient, private utilsService: UtilsService) {
    }

    login(data: { email: string, password: string }) {
        const url = `${API_BANK}auth/login`;
        return this.http.post(url, data).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getBanks(params?: any): Observable<ResponseBanks> {
        const url = `${API_BANK}banks/all${this.utilsService.getParams(params)}`;
        return this.http.get(url).pipe(
            map((res: ResponseBanks) => {
                return res;
            }));
    }

    getMonitorBanks(params: any = {}): Observable<any> {
        const url = `${API_BANK}banks/monitor-banks${this.utilsService.getParams(params)}`
        return this.http.get<any>(url)
    }

}
