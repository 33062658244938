import { Component, OnInit } from '@angular/core';
import { InfoProviderForm } from "../../form-settings/info-new-provider.form";
import { FormField } from "../../interfaces/form";
import { UtilsService } from "../../../services/utils.service";
import { ActivatedRoute } from "@angular/router";
import { ProviderService } from "../../../services/provider.service";
import { Provider, ResponseProviderAuthList, ResponseProviderServiceList } from "../../interfaces/provider";
import { LoaderService } from "../../../services/loader.service";
import { from } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: 'app-provider-edit',
    templateUrl: './provider-edit.component.html',
    styleUrls: ['./provider-edit.component.scss']
})
export class ProviderEditComponent implements OnInit {
    provider: Provider
    settingsForm: FormField[] = InfoProviderForm.getForm();
    pageSize: number = 10;
    isTableLoading = false;
    responseProviderAuthList: ResponseProviderAuthList
    responseProviderServiceList: ResponseProviderServiceList
    providerCode: string | number = this.aR.snapshot.paramMap.get('id') ? this.aR.snapshot.paramMap.get('id') : null;

    constructor(
        private utilsService: UtilsService,
        private aR: ActivatedRoute,
        private providerService: ProviderService,
        private loaderService: LoaderService
    ) {
        this.getProviderById();
    }

    ngOnInit() {

    }

    getProviderById() {
        this.loaderService.updateLoading(true);
        return this.providerService.getPayments({
            page: 1,
            size: 1,
            providerCode: Number(this.providerCode)
        }).subscribe(response => {
            this.loaderService.updateLoading(false);
            this.provider = response.data[0]
            this.settingsForm = InfoProviderForm.getForm(this.provider);
            this.setOptionsCountries();
            this.setOptionsProviderTypes();
            this.getProviderAuths();
            this.getProviderServices();
        }, () => {
            this.loaderService.updateLoading(false);
            }
        )
    }

    setOptionsCountries() {
        this.settingsForm.map((item: FormField) => {
            if (item.key === 'countryCode' && item.options) {
                item.options = from(this.utilsService.getDataCountry())
            }
        })
    }

    setOptionsProviderTypes() {
        this.settingsForm.map((item: FormField) => {
            if (item.key === 'providerType' && item.options) {
                item.options = this.providerService.getProviderTypes().pipe(map((resp) => resp.map((type) => ({value: type, name: type}))))
            }
        })
    }

    getProviderAuths({currentPage = 1} = {}) {
        const params = {
            currentPage,
            pageSize: this.pageSize,
        }
        this.isTableLoading = true;
        this.providerService.getProviderAuths(Number(this.providerCode), params).subscribe((response) => {
            this.responseProviderAuthList = response
            this.isTableLoading = false;
        })
    }

    getProviderServices({currentPage = 1} = {}) {
        const params = {
            currentPage,
            pageSize: this.pageSize,
        }
        this.isTableLoading = true;
        this.providerService.getProviderServices(params).subscribe((response) => {
            this.responseProviderServiceList = response
            this.isTableLoading = false;
        })
    }

    onPageIndexChangeProviderAuths(event) {
        this.getProviderAuths({currentPage: event});
    }

    onPageIndexChangeProviderServices(event) {
        this.getProviderServices({currentPage: event});
    }

    onSubmitForm(value) {
        console.log(value);
    }

    handleUploadFile(event) {
        console.log(event);
    }

}
