import { Component, OnDestroy, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-input-percentage',
  templateUrl: './input-percentage.component.html',
  styleUrls: ['./input-percentage.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPercentageComponent),
      multi: true
    }, 
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputPercentageComponent),
      multi: true
    }
  ]
})
export class InputPercentageComponent implements ControlValueAccessor, Validator, OnDestroy {
  patternDecimals = '^[0-9]+(\.[0-9]{1,3})?$';
  isDisabled = false;
  private sub?: Subscription;
  form = new FormGroup({
    value: new FormControl('', [Validators.required, Validators.pattern(this.patternDecimals)]),
    isPercentage: new FormControl(false)
  });
  
  ngOnDestroy(): void {
    this.sub && this.sub.unsubscribe();
  }
  
  onChange: (value: any) => void;
  onTouched: () => void;
  
  validate(control: AbstractControl): ValidationErrors {
    return this.form.valid ? null : { invalidForm: { valid: false, message: 'Input fields are invalid' } };
  }

  writeValue(value: any): void {    
    if (value) {
      this.form.patchValue(value, { emitEvent: false });
    }    
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
    this.sub = this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateValue() {
    this.onChange(this.form.value);
  }

  setDisabledState(isDisabled: boolean): void {
      isDisabled ? this.form.disable({ emitEvent: false }) : this.form.enable({ emitEvent: false });
  }

  formatNumber(control: AbstractControl,  value: boolean) {    
    if (value) {
        control.setValue(parseFloat((Number(control.value) * 100).toFixed(4) ));
    } else {
        control.setValue(parseFloat((Number(control.value) / 100).toFixed(4) ));
    }
  }

}
