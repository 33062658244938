import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  openMap = {
    dashboard: false,
    monitor: false,
    operations: false,
    activities: false,
    reports: false,
    settings: false
  };

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.setActiveSubMenu();
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(event => {
      this.setActiveSubMenu();
    });
  }

  setActiveSubMenu() {
    for (const key in this.openMap) {
      if (this.openMap.hasOwnProperty(key)) {
        this.openMap[key] = false;
      }
    }

    let currentRoute = this.route.snapshot.firstChild;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    const routeSegment = currentRoute.routeConfig.path.split('-')[0];

    if (this.openMap.hasOwnProperty(routeSegment)) {
      this.openMap[routeSegment] = true;
      if(window.innerWidth < 992){
        $('#nav').addClass('d-none');
        $('#nav').removeClass('position-absolute');
      }
    }
  }

  openHandler(value: string): void {
    for (const key in this.openMap) {
      if (this.openMap.hasOwnProperty(key)) {
        this.openMap[key] = false;
      }
    }
    this.openMap[value] = true;
  }

  marginNav(){
    let i = 0;
    let marginNav = setInterval(function () {
      if ($('.ng-star-inserted').length > 0) {
        clearInterval(marginNav);
      } else {
        if (i == 50) {
          clearInterval(marginNav);
        }
        i++
      }
    }, 200);
  }


  navigation(route){
    this.router.navigate(['/' + route]);
    if(window.innerWidth < 992){
      this.toogleMenuResponsive()
    }
  }

  toogleMenuResponsive(){
    $('#nav').toggleClass('d-none');
    $('#nav').toggleClass('position-absolute');
  }

}
