import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { IMerchant, Notifications, ServiceActive } from "../../interfaces/merchant";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MerchantService } from "../../../services/merchant.service";
import { LoaderService } from "../../../services/loader.service";
import { NzMessageService } from "ng-zorro-antd";

enum typeNotification {
    CASHIN = 'cashin',
    CASHOUT = 'cashout',
}

@Component({
    selector: 'app-notifications-merchant',
    templateUrl: './notifications-merchant.component.html',
    styleUrls: ['./notifications-merchant.component.scss']
})
export class NotificationsMerchantComponent implements OnInit, OnChanges {
    @Input() merchant: IMerchant;
    @Output() changeData = new EventEmitter<any>();
    serviceActive: ServiceActive;
    notifications: Notifications
    formNotification: FormGroup = new FormGroup({});

    constructor(private fb: FormBuilder, private merchantService: MerchantService, private loaderService: LoaderService, private message: NzMessageService) {

    }

    ngOnInit() {
        this.addFormGroup('cashout', this.formNotification, this.getFormNotification());
        this.addFormGroup('cashin', this.formNotification, this.getFormNotification());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.merchant && changes.merchant.currentValue) {
            this.serviceActive = changes.merchant.currentValue.serviceActive;
            this.notifications = changes.merchant.currentValue.notifications;
            this.formNotification.patchValue(this.notifications)
        }
    }

    addFormGroup(name: string, form: FormGroup, formChild: FormGroup) {
        form.addControl(name, formChild);
    }

    getFormNotification(): FormGroup {
        const whitespacePattern = /^\S*$/;

        return this.fb.group({
            activeNotificationWebhook: [false],
            notificationURL: ['', [Validators.pattern('^(https?:\\/\\/)?([\\da-z\\.-]+)\\.([a-z\\.]{2,6})([\\/\\w \\.-]*)*\\/?$')]],
            activeNotificationEmail: [false],
            listEmails: ['', [Validators.pattern(whitespacePattern)]],
            activeEmailShopper: [false],
            activeSmsShopper: [false],
        })
    }

    save(type: typeNotification) {
        this.loaderService.updateLoading(true);
        let data = this.formNotification.value[type];
        if (type === typeNotification.CASHIN) {
            this.merchantService.updateNotification(this.merchant.infoMerchant.merchantCode, {...data, type: 1})
                .subscribe(res => {
                    this.loaderService.updateLoading(false);
                    this.changeData.emit(res);
                    this.message.create('success', `Notification data saved successfully`);
                })
        } else if (type === typeNotification.CASHOUT) {
            this.merchantService.updateNotification(this.merchant.infoMerchant.merchantCode, {...data, type: 2})
                .subscribe(res => {
                    this.loaderService.updateLoading(false);
                    this.changeData.emit(res);
                    this.message.create('success', `Notification data saved successfully`);
                })
        }
    }

    get TypeNotification() {
        return typeNotification;
    }
}
