import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { NzModalService } from "ng-zorro-antd";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { InfoCardsCommissions } from "../../../components/content/settings/commerce-detail/settings/cards-commissions";
import { ApiService } from "../../../services/api.service";
import { LoaderService } from "../../../services/loader.service";
import { MerchantService } from "../../../services/merchant.service";
import { UtilsService } from "../../../services/utils.service";
import { Bank } from "../../interfaces/bank";
import { CreateCommission, IMerchant } from "../../interfaces/merchant";

@Component({
    selector: 'app-commissions-merchant',
    templateUrl: './commissions-merchant.component.html',
    styleUrls: ['./commissions-merchant.component.scss']
})
export class CommissionsMerchantComponent implements OnInit, OnChanges {
    @Output() updateMerchant = new EventEmitter<any>();
    @Input() merchant: IMerchant;
    @Input() banks: Bank[] = [];
    commissionType = '';
    selectedCommissionFilterType = '';
    selectedCommissionCode = '';
    selectedCommission: any = {};
    commissions: any[] = [];
    configInputsCards: any[] = [];
    titleModalStaggered: string;
    formCards: FormGroup = new FormGroup({});
    commissionCodesInListForm = ['CI_004', 'CI_005', 'CI_006', 'CO_002', 'CO_003'];
    isVisibleModalStaggered = false;
    configInputsModal: any[] = [];
    commissionId: number;
    isLoadingModalCommission = false

    constructor(private utilsService: UtilsService, private api: ApiService, private merchantService: MerchantService, private loaderService: LoaderService, private modalService: NzModalService,) {
    }

    ngOnInit() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.merchant && changes.merchant.currentValue) {
            this.createFormCards();
            this.selectedCommissionFilterType = this.getSelectedCommissionType(this.merchant.commissions);
            this.selectedCommissionCode = this.getCommissionCodeActive(this.merchant.commissions);
        }
    }

    get IsActiveCashOut() {
        return this.merchant ? this.merchant.serviceActive.activeCashout: false
    }

    get IsActiveCashIn() {
        return this.merchant ? this.merchant.serviceActive.activeCashin : false
    }

    getSelectedCommissionType(data) {
        let type = Object.keys(data).find(key => (data[key].data && data[key].data.find(item => item.isActive)));
        if (type) {
            this.updateCommissionFilterType(type);
            return type;
        }
       return this.IsActiveCashIn ? 'cashin' : 'cashout';
    }


    getCommissionCodeActive(data): string {
        if (data[this.selectedCommissionFilterType].data){
            const commission = data[this.selectedCommissionFilterType].data.find(item => item.isActive);
            this.updateCommissionFilterType(this.selectedCommissionFilterType);
            return commission ? commission.commissionCode : '';
        }
        return '';
    }

    updateCommissionFilterType(event) {
        const idCommission = event === 'cashin' ? 1 : 2;
        this.commissionType = event;
        this.getCommissions(idCommission);
    }

    getCommissions(serviceID = 1) {
        this.merchantService.getCommissions(serviceID).subscribe((commissions) => {
            this.commissions = commissions;
            this.selectedCommission = this.commissions.find(item => item.CommissionCode === this.selectedCommissionCode)
            if (!this.selectedCommission) {
                this.selectedCommission = commissions[0];
                this.selectedCommissionCode = this.selectedCommission.CommissionCode;
            }
            this.changeCommission(this.selectedCommissionCode);
        });
    }

    onCommissionCardButton(commissionCode, cardInfo) {
        Object.keys(this.formCards.get(commissionCode).get(cardInfo.title).value).forEach(key => {
            if (this.isNumberString(this.formCards.get(commissionCode).get(cardInfo.title).value[key])) {
                    this.formCards.get(commissionCode).get(cardInfo.title).value[key] = Number(this.formCards.get(commissionCode).get(cardInfo.title).value[key]);                
            }
        });

        const data: CreateCommission = {
            serviceType: this.selectedCommissionFilterType === 'cashin' ? 1 : 2,
            commissionCode: commissionCode,
            conditional: this.getConditionalCommission(commissionCode, cardInfo.title),
            ...this.formCards.get(commissionCode).get(cardInfo.title).value,
            isPercentageFee: this.formCards.get(commissionCode).get(cardInfo.title).value.fee.isPercentage,
            isPercentageOtherFee: this.formCards.get(commissionCode).get(cardInfo.title).value.otherFee.isPercentage,
            fee: Number(this.formCards.get(commissionCode).get(cardInfo.title).value.fee.value),
            otherFee: Number(this.formCards.get(commissionCode).get(cardInfo.title).value.otherFee.value),
        };
        
        data.commissionDefault = data.commissionDefault === 1 ? true : false 
        
        if (cardInfo.commissionId) this.updateCommission(data, cardInfo.commissionId);
        if (!cardInfo.commissionId) this.createCommission(data);
    }

    updateCommission(data, commissionId) {
        this.loaderService.updateLoading(true)
        this.merchantService.updateCommission(commissionId, data).subscribe(
            (res) => {
                this.nextResponseCommission(res)
                this.updateMerchant.emit();
            },
            () => this.errorResponseCommission(),
            () => {
                this.loaderService.updateLoading(false)
            })
    }

    createCommission(data: CreateCommission) {
        this.loaderService.updateLoading(true)
        this.merchantService.createCommission(this.merchant.infoMerchant.merchantCode, data).subscribe(
            (res: any) => {
                this.nextResponseCommission(res)
                this.updateMerchant.emit();
            },
            () => this.errorResponseCommission(),
            () => {
                this.loaderService.updateLoading(false)
            });
    }

    nextResponseCommission(res) {
        if (res.status) {
            this.modalService.success({
                nzTitle: 'Success',
                nzContent: 'Commission created successfully',
            });
            this.updateMerchant.emit();
        } else {
            this.modalService.error({
                nzTitle: 'Error',
                nzContent: res.message
            });
        }
    }

    errorResponseCommission() {
        this.loaderService.updateLoading(false)
    }

    getConditionalCommission(commissionCode, value) {
        const data = {
            CI_001: "Genérico",
            CI_002: value,
            CI_003: value,
            CI_004: value,
            CI_005: value,
            CI_006: value,
            CO_001: "Genérico",
            CO_002: value,
            CO_003: value,
        }

        return data[commissionCode];
    }

    changeCommission(event) {
        this.selectedCommission = this.commissions.find(commission => commission.CommissionCode === event);
        switch (event) {
            case 'CI_001':
                this.configInputsCards = InfoCardsCommissions.getFormCards(this.selectedCommission, null, this.merchant.commissions[this.commissionType]);                
                this.createFormCards();
                break;
            case 'CI_002':
                const channels = Object.keys(this.merchant.settings.cashin.channel || {})
                    .filter(key => this.merchant.settings.cashin.channel[key].active)
                    .map(key => ({channelName: key, ...this.merchant.settings.cashin.channel[key]}));
                this.configInputsCards = InfoCardsCommissions.getFormCards(this.selectedCommission, channels, this.merchant.commissions[this.commissionType]);
                this.createFormCards();
                break;
            case 'CI_003':
                this.loaderService.updateLoading(true)
                this.getCurrencies(this.merchant.infoMerchant.merchantCode).subscribe((currencies) => {
                    this.configInputsCards = InfoCardsCommissions.getFormCards(this.selectedCommission, currencies, this.merchant.commissions[this.commissionType]);
                    this.createFormCards();
                    this.loaderService.updateLoading(false)
                });
                break;
            case 'CI_004':
                this.titleModalStaggered = 'Save staggered by amount';
                this.configInputsCards = InfoCardsCommissions.getFormCards(this.selectedCommission, null, this.merchant.commissions[this.commissionType]);

                break;
            case 'CI_005':
                this.titleModalStaggered = 'Save staggered by number transactions';
                this.configInputsCards = InfoCardsCommissions.getFormCards(this.selectedCommission, null, this.merchant.commissions[this.commissionType]);
                break;
            case 'CI_006':
                this.titleModalStaggered = 'Save bank';
                this.configInputsCards = InfoCardsCommissions.getFormCards(this.selectedCommission, null, this.merchant.commissions[this.commissionType]);
                break;
            case 'CO_001':
                this.configInputsCards = InfoCardsCommissions.getFormCards(this.selectedCommission, null, this.merchant.commissions[this.commissionType]);
                this.createFormCards();
                break;
            case 'CO_002':
                this.titleModalStaggered = 'Save staggered by amount';
                this.configInputsCards = InfoCardsCommissions.getFormCards(this.selectedCommission, null, this.merchant.commissions[this.commissionType]);
                break;
            case 'CO_003':
                this.titleModalStaggered = 'Save staggered by number transactions';
                this.configInputsCards = InfoCardsCommissions.getFormCards(this.selectedCommission, null, this.merchant.commissions[this.commissionType]);
                break;
        }
    }

    createFormCards() {
        this.formCards.addControl(this.selectedCommissionCode, this.createFromCardsGroup());
    }

    createFromCardsGroup() {
        const formGroup = new FormGroup({});
        this.configInputsCards.forEach(element => {
            formGroup.addControl(element.title, this.createFormCard(element));
        });
        return formGroup;
    }

    createFormCard(card) {
        const formGroup = new FormGroup({});
        if (!card.inputs) return formGroup;
        card.inputs.forEach(element => {
            let value = element.value
            if (!isNaN(element.value)) {
                if (typeof element.value == "boolean") {
                    value = element.value
                }else{
                    value = Number(element.value)
                }    
            }

            formGroup.addControl(element.formControlName, new FormControl(value, element.validations));
        });
        return formGroup;
    }

    isNumberString(value) {
        return !isNaN(value);
    }

    getCurrencies(id: number | string): Observable<any> {
        return this.api.queryGet(`currencies?merchantCode=${id}`).pipe(
            map((res: any) => {
                return res;
            })
        )
    }

    getNameBankByID(id) {
        const bank = this.banks.find(item => Number(item.id) === Number(id));
        return bank ? bank.bussinesName : '';
    }

    async showCommissionModal(isUpdate = false, index?: number) {
        this.isVisibleModalStaggered = true;
        this.configInputsModal = []
        const staggered = ['CI_004', 'CI_005', 'CO_002', 'CO_003'];
        if (isUpdate && staggered.includes(this.selectedCommission.CommissionCode)) {
            this.titleModalStaggered = 'Update commission';
            this.commissionId = this.configInputsCards[index].merchantCommissionSetupID || this.configInputsCards[index].merchantCommissionSetupId;
            this.configInputsModal = InfoCardsCommissions.getInputsStaggered(this.configInputsCards[index]);
        }

        if (isUpdate && this.selectedCommission.CommissionCode === 'CI_006') {
            this.titleModalStaggered = 'Update commission';
            this.commissionId = this.configInputsCards[index].merchantCommissionSetupId;
            this.configInputsModal = InfoCardsCommissions.getInputsBank(this.banks, this.configInputsCards[index]);
        }

        if (!isUpdate && this.selectedCommission.CommissionCode === 'CI_006') {
            this.commissionId = null;
            this.configInputsModal = InfoCardsCommissions.getInputsBank(this.banks);
        }

        if (!isUpdate && this.selectedCommission.CommissionCode !== 'CI_006') {
            this.commissionId = null;
            this.titleModalStaggered = 'Save commission';
            this.configInputsModal = InfoCardsCommissions.getInputsStaggered();
        }

    }

    closeModalStaggered() {
        this.isVisibleModalStaggered = false;
    }

    getCommissionActive(commission) {
        if (this.merchant.commissions[this.commissionType].data){
            const commissionActive = this.merchant.commissions[this.commissionType].data.find(item => item.commissionCode === commission.CommissionCode);
            return commissionActive ? commissionActive.isActive : false;
        }
        return false;
    }

    eventModalStaggered(values) {
        this.isVisibleModalStaggered = false
       
        const {bank, ...dto} = values;
        const conditional = this.selectedCommissionCode === "CI_006" ? bank.id : `${values.from}-${values.until}`
        const data: any = {
            serviceType: this.selectedCommissionFilterType === 'cashin' ? 1 : 2,
            commissionCode: this.selectedCommissionCode,
            conditional: this.getConditionalCommission(this.selectedCommissionCode, conditional),
            metadata: this.selectedCommissionCode === "CI_006" ? {type: bank.type} : undefined,
            ...this.parseValuesToNumber(dto),
            otherFee: Number(dto.otherFee.value),
            isPercentageOtherFee: dto.otherFee.isPercentage,
            fee: Number(dto.fee.value),
            isPercentageFee: dto.fee.isPercentage,
        };
 
        data.commissionDefault = data.commissionDefault === 1 ? true : false         
        
        if (this.commissionId) this.updateCommission(data, this.commissionId);
        if (!this.commissionId) this.createCommission(data);
        
    }

    // Parse values of object to Number
    parseValuesToNumber(obj) {
        const newObj = {};
        Object.keys(obj).forEach(key => {
            if (!isNaN(obj[key])) {
                newObj[key] = Number(obj[key]);
            }
        });
        return newObj;
    }

    checkDefaultCard(event, selectedCommission){
        this.configInputsCards.forEach(key => {
          if (key.title != selectedCommission){
            const form1 =  this.formCards.get('CI_002').get(key.title).get('commissionDefault')
            form1.patchValue(false, {emitEvent: false} )
          }
         
        })
    }
}
