import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ItemListStatus, ResponseTransactionStatus } from '../models/transaction-status';


export enum ListType {
	COUNTRYDOCUMENTS = 'COUNTRYDOCUMENTS',
	DOCTYPE = 'DOCTYPE',
	ECONOMICACTIVITY = 'ECONOMICACTIVITY',
	ACCOUNTTYPE = 'ACCOUNTTYPE',
	POSITION = 'POSITION',
	TYPSESERVICES = 'TYPSESERVICES', // cashin cashout
	STATUSPAYROLL = 'STATUSPAYROLL',
	STATUSTRANSACTIONCASHIN = 'STATUSTRANSACTIONCASHIN',
	STATUSTRANSACTIONCASHOUT = 'STATUSTRANSACTIONCASHOUT',
	STATUSREPORTS = 'STATUSREPORTS'
}

@Injectable({
	providedIn: 'root'
})
export class ListService {
	private readonly API_CMS_ADMIN = environment.api.urlCore;
	private readonly API_CORE_ADMIN = environment.api.urlCoreAdmin;
	private httpClient = inject(HttpClient);

	private transactionStatusCashinList$!: Observable<ItemListStatus[]>;
	private transactionStatusCashoutList$!: Observable<ItemListStatus[]>;
	private statusPayrollList$!: Observable<any>;
	private typeServicesList$!: Observable<ItemListStatus[]>;
	private listStatusReports$: Observable<ItemListStatus[]> | null = null;


	getListByType<T>(type: ListType, country: string): Observable<T> {
		return this.httpClient.get<T>(`${this.API_CMS_ADMIN}/api/v1/consult/list`, {
			params: { list: type, countryCode: country }
		});
	}

	getTransactionStatusCashinList(): Observable<ItemListStatus[]> {
		if (!this.transactionStatusCashinList$) {
			this.transactionStatusCashinList$ = this.httpClient
				.get<ResponseTransactionStatus>(`${this.API_CORE_ADMIN}/v1/terms/list`, {
					params: { key: ListType.STATUSTRANSACTIONCASHIN }
				})
				.pipe(shareReplay(1), map((response) => response.data[0].childs));
		}
		return this.transactionStatusCashinList$;
	}

	getTransactionStatusCashoutList(): Observable<ItemListStatus[]> {
		if (!this.transactionStatusCashoutList$) {
			this.transactionStatusCashoutList$ = this.httpClient
				.get<ResponseTransactionStatus>(`${this.API_CORE_ADMIN}/v1/terms/list`, {
					params: { key: ListType.STATUSTRANSACTIONCASHOUT }
				})
				.pipe(shareReplay(1), map((response) => response.data[0].childs));
		}
		return this.transactionStatusCashoutList$;
	}

	getStatusPayrollList(): Observable<ItemListStatus[]> {
		if (!this.statusPayrollList$) {
			this.statusPayrollList$ = this.httpClient
				.get<ResponseTransactionStatus>(`${this.API_CORE_ADMIN}/v1/terms/list`, {
					params: { key: ListType.STATUSPAYROLL }
				})
				.pipe(shareReplay(1), map((response) => response.data[0].childs));
		}
		return this.statusPayrollList$;
	}

	getTypeServicesList(): Observable<ItemListStatus[]> {
		if (!this.typeServicesList$) {
			this.typeServicesList$ = this.httpClient
				.get<ResponseTransactionStatus>(`${this.API_CORE_ADMIN}/v1/terms/list`, {
					params: { key: ListType.TYPSESERVICES }
				})
				.pipe(shareReplay(1), map((response) => response.data[0].childs));
		}
		return this.typeServicesList$;
	}

	getListStatusReports(): Observable<ItemListStatus[]> {
		if (!this.listStatusReports$) {
			this.listStatusReports$ = this.httpClient
				.get<ResponseTransactionStatus>(`${this.API_CORE_ADMIN}/v1/terms/list`, {
					params: { key: ListType.STATUSREPORTS }
				})
				.pipe(shareReplay(1), map((response) => response.data[0].childs));
		}
		return this.listStatusReports$;
	}

}
