import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import * as moment from 'moment';
import { ApiService } from "../../../../services/api.service";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  public operationsInvoice: any = this.getOperationsInvoice();
  public filters: any = this.getFilters();
  public titlePage: string = 'Operaciones > Invoice';
  public countries: any[] = [];

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  async getOperationsInvoice(dataInfo: any = false) {
    const {from_date, to_date , ...filters} = dataInfo;
    const route = 'invoiceOperations';
    const queries = {
      doc_date_ini: from_date ? from_date : moment().startOf('month').format('YYYY-MM-DD'),
      doc_date_fin: to_date ? to_date : moment().format('YYYY-MM-DD'),
      ...filters
    }

    const headers = [
      {
        'name': 'Tipo de Documento',
        'key': 'typeDocumentCreated',
        'opc': true,
        responsive: true
      },
      {
        'name': 'Número de Documento',
        'key': 'serialNumber',
        'opc': true,
        responsive: true
      },
      {
        'name': 'Comercio',
        'key': 'BussinesName',
        'opc': true,
      },
      {
        'name': 'Cliente',
        'key': 'name',
        'opc': true,
      },
      {
        'name': 'Observaciones',
        'key': 'observation',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'currencyCode',
        'opc': true
      },
      {
        'name': 'Date',
        'key': 'created_at',
        'opc': true
      },
      {
        'name': 'Type',
        'key': 'type',
        'opc': true
      },
      {
        'name': 'Total',
        'key': 'total',
        'opc': true,
        responsive: true
      },
      {
        'name': 'Tax',
        'key': 'tax',
        'opc': true
      },
      {
        'name': 'Status',
        'key': 'status',
        'opc': true,
      },
      {
        'name': 'Factura',
        'key': 'url_document',
        'opc': true,
        type: 'external-link',
        label: 'Ver factura'
      },
    ];

    const response: any = await this.api.queryGet(route, queries).toPromise();


    return {
      headers: headers,
      data: response.data,
      source: 'operations-invoice',
    };

  }

  async getFilters(){

    const data = {
      service: 'merchants'
    };

    const currencies = []
    await this.api.queryGet('currencies').toPromise().then((response: any) => {
      response.forEach(element => {
        currencies.push({
          name: element.CurrencyCode,
          value: element.CurrencyCode,
        });
      });
    })

    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {
      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [
      {
        'name': 'Type',
        'key': 'type',
        'type': 'select',
        'data': [
          {
            name: 'FAC',
            value: 'FAC'
          },
          {
            name: 'NCR',
            value: 'NCR'
          },
          {
            name: 'NDB',
            value: 'NDB'
          }
        ]
      },
      {
        'name': 'Periodo',
        'key': ['from_date','to_date', '3'],
        'type': 'period'
      },
      {
        'name': 'Comercio',
        'key': 'Merchant',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': 'Moneda',
        'key': 'currencyCode',
        'type': 'select',
        'data': currencies
      }

    ];

  }

  changeDataTable(data: any) {
    if (data.period === '5') {
      data.from_date = '2020-01-01';
      data.to_date = moment().format('YYYY-MM-DD');
    }

    this.operationsInvoice = this.getOperationsInvoice(data);
  }

  functions(data: any) {
    if (data.function === 'Ver factura') {
      window.open(data.data.url_document, '_blank');
    }
  }

}
