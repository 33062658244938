import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/services/api.service';
import { CountriesService } from "../../../../../services/countries.service";

@Component({
  selector: 'app-force-payments',
  templateUrl: './force-payments.component.html',
  styleUrls: ['./force-payments.component.scss']
})
export class ForcePaymentsComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();
  public filters: any = this.getFilters();
  public titlePage: string = 'Actividades > Cash In > Forzar Pagos';

  titleModal:any = '';
  isVisible: boolean = false;
  contentModal: any = '';
  listBanksCashin: any;
  formForcePayment: FormGroup;
  messageModal: any = '';
  dataEventModal: any = '';
  dataForcePayment: any;
  okText: any = 'Pagar';
  isAlertVisible = false;
  countries: any[] = [];
  currentRow: any;
  isLoading = false;


  constructor(private api: ApiService, private router: Router, private countriesService: CountriesService, private modalService: NzModalService) {
    this.countriesService.countries$.subscribe(countries => {
      if (countries.length === 0) {
        this.countriesService.getCountries();
      } else {
        this.countries = countries;
      }
    })
    this.formForcePayment = this.createFormGroup();
  }

  createFormGroup() {
    return new FormGroup({
      bankCode: new FormControl(null, [Validators.required]),
      channel: new FormControl('1', [Validators.required]),
      bankOperationNumber: new FormControl('', [Validators.required]),
    });
  }
  ngOnInit() {
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      service: 'forcePayment/listOperation',
      data: []
    };

    let headers = [
      {
        'name': 'Operación',
        'key': 'PublicId',
        'opc': true,
        'type': 'link',
        responsive: true,
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'BussinesName',
        'opc': true
      },
      {
        'name': 'Código de pago',
        'key': 'PaymentCode',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'TxCreation',
        'opc': true
      },
      {
        'name': 'Expiración',
        'key': 'TxExpiration',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        type: 'status',
        'opc': true,
        responsive: true,
      },
      {
        'name': 'Opciones',
        'key': 'opc',
        'opc': false,
        'functions': ['Pagar'],
        type: 'actions',
      },
    ];


    if (dataInfo !== false) {
      data.service = 'forcePayment/listOperationWithParams';
      data.data = dataInfo;
    }


    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'activities-cashin-forcepayment',
      getUrl: (id) => `/operation-cashin/${id}`,
    }

  }



  async getFilters() {

    let data = {
      service: 'merchants'
    }
    let dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [

      {
        'name': 'N° Operación',
        'key': 'PublicId',
        'type': 'text'
      },
      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': 'País',
        'key': 'CountryCode',
        'type': 'select',
        'data': this.countries
      }
    ];

  }

  //MODAL

  showModal(): void {
    this.isVisible = true;
  }

  handleAlertNotification(): void {
    this.isAlertVisible = true;
    this.dataInfo = this.getDataInfo();
    this.formForcePayment = this.createFormGroup();
    var that = this;
    setTimeout(function() {
      that.isAlertVisible = false;
    }, 3000);
  }

  handleOk(): void {
    switch (this.dataEventModal) {
      case 'forcePayment':
        this.isLoading = true;
        this.forcePayment().then((result: any) => {
          if (result.message === 'Payment successful') {
            this.handleAlertNotification();
          } else {
            this.error(result.message);
          }
          this.isLoading = false;
          this.formForcePayment.reset();
          this.isVisible = false;
          this.okText = 'Aceptar';
        }).catch(error => {
          this.isLoading = false;
          this.formForcePayment.reset();
          this.isVisible = false;
          this.modalService.error({
            nzTitle: 'Mensaje de error',
            nzContent: error.error?.Message ?? error.message
          });
        });
        break;
      default:
        break;
    }

  }

  error(message: string): void {
    this.modalService.error({
      nzTitle: 'Mensaje de error',
      nzContent: message
    });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  //EVENTS OUTPUT

  changeDataTable(data) {
    this.dataInfo = this.getDataInfo(data);

  }

  async getDataBankCashin(){
    let data = {
      service: 'listBanksCashin'
    }
    return await this.api.api(data).toPromise();
  }

  functions(f) {
    this.currentRow = f.data;

    switch (f.function) {
      case 'Pagar':

        this.getDataBankCashin().then((result: any) => {
          this.listBanksCashin = result;
          this.titleModal = 'Forzar Pagos';
          this.contentModal = 'forcePayment';
          this.dataForcePayment = f;
          this.dataEventModal = 'forcePayment';
        }).catch(error => {
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });
        this.showModal();
        this.okText = "Pagar";
        break;

      default:
        break;
    }

  }


  async forcePayment() {
    const metadata = {
      ...this.dataForcePayment.userDetails,
      data: { ...this.currentRow }

    }

    const data = {
      service: 'forcePayment',
      data: {
        bankCode: this.bankCode.value,
        channel: this.channel.value,
        publicId: this.dataForcePayment.data.PublicId,
        bankOperationNumber: this.bankOperationNumber.value,
        metadata: JSON.stringify(metadata),
      }
    };
    return await this.api.api(data).toPromise();
  }


  get bankCode() { return this.formForcePayment.get('bankCode'); }
  get channel() { return this.formForcePayment.get('channel'); }
  get bankOperationNumber() { return this.formForcePayment.get('bankOperationNumber'); }

}
