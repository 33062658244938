export const STATUS_OPERATION_CASH_OUT: StatusOperationCashOut[] = [
  {
    name: 'Expirado',
    value: '0',
    color: 'red',
  },
  {
    name: 'Creado',
    value: '10',
    color: 'orange',
  },
  {
    name: 'Aprobación de cliente',
    value: '11',
    color: 'blue'
  },
  {
    name: 'En proceso (Payroll)',
    value: '12',
    color: 'green'
  },
  {
    name: 'Error en procesar',
    value: '13',
    color: 'red'
  },
  {
    name: 'Procesado',
    value: '14',
    color: 'cyan'
  },
  {
    name: 'Notificado',
    value: '15',
    color: 'cyan'
  },
  {
    name: 'Error al notificar',
    value: '16',
    color: 'red'
  },
  {
    name: 'Extornado',
    value: '18',
    color: 'default'
  },
];

export type StatusOperationCashOut = {
  name: string;
  value: string;
  color: Color;
};

export type Color = 'red' | 'orange' | 'blue' | 'green' | 'cyan' | 'default';


