import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Channel } from "../settings/interfaces/channel";
import { Observable } from "rxjs";

const API_ADMIN_URL = environment.api.urlCore;

@Injectable({
  providedIn: 'root'
})
export class ChannelsService {

  constructor(private http: HttpClient) { }

  getAllChannels(): Observable<Channel[]> {
    const url = `${API_ADMIN_URL}channels`;
    return this.http.get(url).pipe(
        map((res: any) => {
          return res.data as Channel[];
        }));
  }
}
