import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { ApiService } from "../../../../../services/api.service";

const API = environment.api;

@Component({
  selector: 'app-reports-payroll',
  templateUrl: './payroll.component.html',
})
export class ReportsPayrollComponent implements OnInit {
  dataInfo: any = this.getDataInfo();
  filters: any = this.getFilters();

  constructor(private api: ApiService) {}

  ngOnInit() {}

  changeDataTable(data) {
    const {from_date: dateStart, to_date: dateEnd} = data;
    this.dataInfo = this.getDataInfo({dateStart, dateEnd});

  }

  async getFilters() {
    return [
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },
    ];

  }

  async getDataInfo(dataInfo: any = {}) {

    let headers = [
      {
        'name': 'Nombre',
        'key': 'name',
        'opc': true
      },
      {
        'name': 'Fecha',
        'key': 'date',
        'opc': true
      },
      {
        'name': 'Archivo',
        'key': 'file',
        'label': 'Descargar',
        type: 'external-link',
        'opc': true
      },
    ];


    const list: any = await this.api.queryGet(`readFilesS3`, dataInfo, API.urlBase ).toPromise()

    return {
      headers: headers,
      data: await list.data,
    }

  }

}
