import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserData } from "../settings/interfaces/response-login";

export interface DataLoginAccess {
	access_token: string;
	requiredRessetPassword: boolean;
	permissions: Permission[];
	user: User;
}

export interface User {
	email: string;
	fullName: string;
	image: string | null;
	userId: number;
}

export interface Permission {
	userId: string;
	userProfileId: string;
	permissionId: string;
	endpoint: string;
}

const API_ACCES_URL = environment.api.urlAcces;

export interface ResponseValidateUser {
	statusCode: number;
	status: boolean;
	message: string;
	data: boolean;
	metadata: any;
}

export interface PasswordChangeRequest {
    email:           string;
    currentPassword: string;
    newPassword:     string;
    confirmPassword: string;
}

export enum Service {
	CASH_IN = 'cashin',
	CASH_OUT = 'cashout',
	BROKER = 'broker',
	RECHARGE = 'recharge'
}


@Injectable({
    providedIn: 'root'
})
export class AccessService {
    private user: UserData = sessionStorage.getItem('ud') ? JSON.parse(sessionStorage.getItem('ud')) : null;
    private authData = new BehaviorSubject<DataLoginAccess | null>(null);
	public authData$ = this.authData.asObservable();
    public isLoggedIn$ = this.authData$.pipe(map((user) => !!user));
    private temporalUser: DataLoginAccess;

    constructor(private http: HttpClient) {
        this.temporalUser = JSON.parse(localStorage.getItem('authAccess'));
        this.authData.next(this.temporalUser);
    }

    
    get tokenAccess(): string {
        return localStorage.getItem('authAccess') != null ? JSON.parse(localStorage.getItem('authAccess')).data.access_token : '';
    }

    login(data: { email: string, password: string }) {
        const url = `${API_ACCES_URL}login`;
        return this.http.post(url, {...data, service: Service.CASH_IN, console: 'CONSOLE_ADMIN'}).pipe(
            tap((res: any) => {
                this.temporalUser = res.data;
            }));
    }

    isUseTokenGoogle(){
        const url = `${API_ACCES_URL}google/validate/register`;
        return this.http.get(url, {params: {service: Service.CASH_IN}}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    isUseTokenWithUserIdGoogle(userId: number | string ){ 
        const url = `${API_ACCES_URL}google/validate/register/` + userId;
        return this.http.get(url, {params: {service: Service.CASH_IN}}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getQrWithUseridGoogle(userId: number, isReset: boolean){
        const url = `${API_ACCES_URL}google/qr/user`;
        return this.http.post(url, {userId, isReset, service: Service.CASH_IN }).pipe(
            map((res: any) => {
                return res;
            }));
    }

    getQrGoogle(isReset: boolean){
        const url = `${API_ACCES_URL}google/qr`;
        return this.http.post(url, {isReset, service: Service.CASH_IN}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    validateAuthToken(token: string, userId?: number): Observable<ResponseValidateUser> {
		this.user = sessionStorage.getItem('ud') ? JSON.parse(sessionStorage.getItem('ud')) : null;
        const id = userId ? userId : (this.user?.userDetails?.id ?? undefined)
        return this.http
			.post<ResponseValidateUser>(
				`${API_ACCES_URL}google/validate`,
                { userId: id, twoFactorAuthenticationToken: token, service: Service.CASH_IN },
			).pipe(
                tap(() => {                  
                    this.authData.next(this.temporalUser)
                })
            )
	}

    resetPassword(data: PasswordChangeRequest) {
        const url = `${API_ACCES_URL}reset-password`;
        return this.http.post(url, {...data, service: Service.CASH_IN}).pipe(
            map((res: any) => {
                return res;
            }));
    }

    logout() {
        localStorage.removeItem('authAccess');
        localStorage.clear();
        this.authData.next(null);
    }

    
}