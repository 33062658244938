import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { UploadFile, UploadXHRArgs } from "ng-zorro-antd/upload";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MerchantService } from "../../../services/merchant.service";
import { IMerchant, MerchantType } from "../../interfaces/merchant";

@Component({
  selector: 'app-form-data-merchant',
  templateUrl: './form-data-merchant.component.html',
  styleUrls: ['./form-data-merchant.component.scss']
})
export class FormDataMerchantComponent implements OnChanges, OnDestroy {
  @Output() onSubmitForm = new EventEmitter<any>();
  @Output() onChangeCountry = new EventEmitter<any>();
  @Input() merchant: IMerchant;
  @Input() idMerchant: string;
  @Input() listTypeMerchant: MerchantType[] = [];
  @Input() countries: any[] = [];
  @Input() formMerchant: FormGroup;
  @Input() typeDocuments: any[] = [];
  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true
  };
  fileList: any[] = [];
  previewImage: string | undefined = '';
  previewVisible = false;
  isChangeCountry: boolean = false;
  public msgErrors: any[] = [
    { error: 'required', message: 'Campo requerido'},
    { error: 'pattern', message: 'Correo no válido'},
    { error: 'minlength', message: 'El motivo debe tener un mínimo de 6 caracteres'},
  ]
  private destroy$ = new Subject<void>();


  constructor(private merchantService: MerchantService) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.merchant && changes.merchant.currentValue) {
      this.chargeLogo(this.merchant.infoMerchant.urlLogo);
      this.setForm();
    }
  }

  setForm() {
    this.formMerchant.setValue({
      settlementSetting: '0',
      legalName: this.merchant.infoMerchant.legalName,
      countryCode: this.merchant.infoMerchant.countryName,
      bussinesName: this.merchant.infoMerchant.bussinesName,
      legalNumber: this.merchant.infoMerchant.legalNumber,
      urlWeb: this.merchant.infoMerchant.webPage,
      merchantType: Number(this.merchant.infoMerchant.merchantTypeID),
      color1: this.merchant.infoMerchant.color1,
      color2: this.merchant.infoMerchant.color2,
      color3: this.merchant.infoMerchant.color3,
      userFullname: this.merchant.infoMerchant.fullName,
      userEmail: this.merchant.infoMerchant.emailAdmin,
      userMobile: this.merchant.infoMerchant.mobile,
      typeDocumentMerchant: this.merchant.infoMerchant.typeDocumentMerchant,
      typeDocumentLegalRepresentative: this.merchant.infoMerchant.typeDocumentLegalRepresentative,
      
    }); 
    

    if (this.idMerchant) {
      this.formMerchant.controls.userEmail.disable();
    }

    this.formMerchant.controls.countryCode.valueChanges
    .pipe(
      takeUntil(this.destroy$),
    )
    .subscribe((value) => {
      this.formMerchant.controls.typeDocumentMerchant.setValue(null);
      this.formMerchant.controls.typeDocumentLegalRepresentative.setValue(null);
    });
  }

  chargeLogo(logo: string) {
    this.fileList = [];
    if (logo) {
        this.fileList.push(
            {
              uid: -1,
              name: 'logo.png',
              status: 'done',
              url: logo
            }
        );
    }
  }

  submitForm() {
    if (this.formMerchant.invalid) {
        this.formMerchant.markAllAsTouched();
        return;
    }

    const country = this.countries.find((country) => country.name === this.formMerchant.controls.countryCode.value);

    const data = {...this.formMerchant.getRawValue(), countryCode: country.value};

    this.onSubmitForm.emit(data);

  }

  handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  };

  handleUpload = (item: UploadXHRArgs) => {
      this.merchantService.uploadLogo(this.idMerchant, item.file as any).subscribe((res) => {
        item.onSuccess(res, item.file, event);
      });
  };

  changeCountry($event){
    this.isChangeCountry = true;
    const country = this.countries.find((country) => country.name === $event);
    this.onChangeCountry.emit(country);
  }
}
