import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loading = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable();

  constructor() { }

  public updateLoading(value: boolean) {
    this.loading.next(value);
  }
}
