import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[noPaste]'
})
export class NoPasteDirective {

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

}