import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-table-adm-conciliation',
    templateUrl: './table-adm-conciliation.component.html',
    styleUrls: ['./table-adm-conciliation.component.scss']
})
export class TableAdmConciliationComponent implements OnInit {
    @Input() data: any;
    @Output() dataOutput = new EventEmitter<any>();

    loading = false;
    headers: any;
    infoTable: any;
    isVisible: boolean = false;
    toggleSortValue = true;
    categories = [];
    selectedValue = null
    formRows: FormGroup

    constructor(private router: Router, private fb: FormBuilder) {
        this.formBuilder()
    }

    ngOnInit() {
        this.getInfo();
    }

    formBuilder() {
        this.formRows = this.fb.group({
            rows: this.fb.array([])
        })
    }

    get rowsField() {
        return this.formRows.get('rows') as FormArray
    }

    getInfo() {
        this.rowsField.reset()
        this.loading = true;
        this.data.then((data: any) => {
            this.headers = data.headers;
            data.headers.forEach(h => {
                if (h.key === 'standard-list') {
                    this.categories = h.list
                }
            })
            if (this.rowsField.length === 0) {
                data.data.forEach(item => {
                    this.rowsField.push(this.fb.group({category: [this.getCategoryByName(item.AdmConciliationCategory), Validators.required]}))
                })
            }
            this.infoTable = data.data;
            this.loading = false;
        }).catch(error => {
            if (error.status === 401) {
                sessionStorage.setItem('ud', '');
                this.router.navigate(['/']);
                this.loading = false;
            }
        });
    }

    ngOnChanges() {
        this.getInfo();
        this.formBuilder()
    }

    getCategoryByName(name: string) {
        return this.categories.find(category => category.Description === name)
    }

    functionsEvent(value, data) {
        console.log(value)
        console.log(data)
    }

    changeValue(value) {
        console.log(value)
    }

    openModal(data, info) {
        this.dataOutput.emit({data, ...info})
    }

    test(key: any) {
        this.toggleSortValue = !this.toggleSortValue;
        this.infoTable.sort(function (a, b) {
            const x = b.PublicId.toLowerCase();
            const y = a.PublicId.toLowerCase();
            if (x < y) {
                return -1;
            }
            if (x > y) {
                return 1;
            }
            return 0;
        });
    }

}
