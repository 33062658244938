import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { isArray } from "rxjs/internal-compatibility";

@Component({
  selector: 'app-table-partner-conciliation',
  templateUrl: './table-partner-conciliation.component.html',
  styleUrls: ['./table-partner-conciliation.component.scss']
})
export class TablePartnerConciliationComponent implements OnInit {

  @Input() data: any;
  @Output() dataOutput = new EventEmitter<any>();

  loading = false;
  headers: any;
  infoTable: any;
  isVisible: boolean = false;
  titleModal: any;
  modalInfo: any;
  contentModal: any = '';
  toggleSortValue = true;

  expandSet = new Set<number>();

  constructor(private router: Router) { }

  ngOnInit() {
    this.getInfo();
  }

  getInfo() {
    this.loading = true;
    this.data.then((data: any) => {
      this.headers = data.headers;
      this.infoTable = data.data;
      this.loading = false;
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
        this.loading = false;
      }
    });
  }

  ngOnChanges() {
    this.getInfo();
  }

  viewModal(e) {

    this.titleModal = 'Información';
    this.modalInfo = JSON.parse(JSON.parse(e));
    this.contentModal = '';

    Object.keys(this.modalInfo).forEach(e => {
      switch (e) {
        case 'message':
          this.contentModal += `
                              <div class="d-flex">
                                <div class="col-6 font-weight-bold">Mensaje</div>
                                <div><b>:</b> ${this.modalInfo[e]}</div>
                              </div>
                              `;
          break;

        default:
          this.contentModal += `
                              <div class="d-flex">
                                <div class="col-6 font-weight-bold">${e}</div>
                                <div><b>:</b> ${this.modalInfo[e]}</div>
                              </div>
                              `;

          break;
      }
    });

    this.showModal();


  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  mapOfExpandData: { [key: string]: boolean } = {

  };

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  test(key?: any) {
    this.toggleSortValue = !this.toggleSortValue;
    this.infoTable.sort(function(a, b)  {
      const x = b.PublicId.toLowerCase();
      const y = a.PublicId.toLowerCase();
      if (x < y) {return -1;}
      if (x > y) {return 1;}
      return 0;
    });
  }

  functionsEvent(f, data, selectedTransaction?: any){
    const info = {
      function: f,
      data: selectedTransaction ? {...data, selectedTransaction} : data,
      userDetails: JSON.parse(sessionStorage.getItem('ud')).userDetails,
      event: false
    };
    this.dataOutput.emit(info);
  }

  getStatus(key, value) {
    if (key === 'Status') {
      switch (value) {
        case 1:
          return 'Pendiente'
        case 2:
          return 'Pagado'
        case 3:
          return 'Extornado'
      }
    }
    return value
  }
  getPendingTransactions(transactions: any[], statusNumber: number) {
    if (isArray( transactions) && statusNumber === 1) {
      return transactions.filter(x => x.LastStatus < 11);
    }
    return transactions;
  }

  checkPayment(item: any) {    
    return item.Status !== 1;
  }

}
