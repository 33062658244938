import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzModalComponent } from "ng-zorro-antd";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { HeadersModel, IHeadersTable, TypeCell } from "../../../models/headersTable.model";
import { TableModel } from "../../../models/table.model";
import { LoaderService } from "../../../services/loader.service";
import { MerchantService } from "../../../services/merchant.service";
import { Channel, Subchannel } from "../../interfaces/channel";
import { IMerchant } from "../../interfaces/merchant";

@Component({
    selector: 'app-channels-merchant',
    templateUrl: './channels-merchant.component.html',
    styleUrls: ['./channels-merchant.component.scss']
})
export class ChannelsMerchantComponent implements OnInit, OnChanges, OnDestroy {
    @Input() merchant: IMerchant;
    @Input() countries: any[] = [];
    @Input() allChannels: Channel[] = [];
    @Output() updateMerchant = new EventEmitter<any>();
    @ViewChild('inputSwitch', {static: true}) inputSwitch: TemplateRef<any>
    @ViewChild('modalSubchannel', {static: false}) modalSubchannel: NzModalComponent;
    public dataTable: TableModel<any>;
    public isLoading = false;
    public formFilters: FormGroup;
    public channels = [];
    public subChannels: Subchannel[] = [];
    public destroy$ = new Subject();
    public formSubchannel: FormGroup;
    public isEdit = false;
    public hasTypeInMetadata = false;
    public currentMerchantSetupId = '';
    public listTypesInMetadata = []

    constructor(
        private merchantService: MerchantService,
        private fb: FormBuilder,
        private loaderService: LoaderService
    ) {
        this.formFilters = this.fb.group({
            countryCode: [null],
            channelId: [null],
        })

        this.createFormSubchannel();
    }

    ngOnInit() {
        this.formFilters.valueChanges.pipe(
            takeUntil(this.destroy$)
        ).subscribe((value) => {
            this.getChannels(value);
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.merchant) {
            const merchant = changes.merchant.currentValue;
            this.channels = Object.keys(merchant.settings.cashin.channel || {}).map((key) => {
                return {
                    name: key,
                    active: merchant.settings.cashin.channel[key].active,
                    id: merchant.settings.cashin.channel[key].paymentChannelId
                }
            })
            this.getChannels();
        }
    }

    createFormSubchannel() {
        this.formSubchannel = this.fb.group({
            paymentChannelId: [null, Validators.required],
            paymentSubchannelId: [{value: null, disabled: true}, Validators.required],
            countryCode: [null, Validators.required],
            subchannelDescription: ['', Validators.minLength(3)],
            order: [''],
            active: [true],
            metadata: [null]
        })
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getChannels(params?: { countryCode?: string, channelId?: number }) {
        this.isLoading = true;
        this.merchantService.getChannels(this.merchant.infoMerchant.merchantCode, params).subscribe((res) => {
                const data = this.getAllPaymentChannelAndSubchannel(res);
                this.isLoading = false;
                this.dataTable = new TableModel(data, this.headersTable);
            }, () => {
                this.isLoading = false;
            }
        )
    }

    getSubchannels(id: number) {
        this.formSubchannel.get('paymentSubchannelId').setValue(null);
        this.formSubchannel.get('paymentSubchannelId').enable()
        if (id) {
            this.subChannels = this.allChannels.find((item) => item.PaymentChannelID === id).subchannels;
        }
    }

    getAllPaymentChannelAndSubchannel(data) {
        const paymentChannelAndSubchannel = [];
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const element = data[key];
                element.forEach((item) => {
                    if (item.paymentSubchannel) {
                        paymentChannelAndSubchannel.push(item);
                    }
                });
            }
        }
        return paymentChannelAndSubchannel;
    }

    //get data of object by string key (ex: 'paymentChannel.channelName')
    getDataByStringKey(data, key) {
        const keys = key.split('.');
        let value = data;
        keys.forEach((item) => {
            if (value) {
                value = value[item];
            }
        });
        return value;
    }

    get headersTable(): HeadersModel[] {
        const headers: IHeadersTable[] = [
            {
                label: 'Channel ID',
                key: 'paymentChannelId',
                responsive: true
            },
            {
                label: 'Channel Name',
                key: 'paymentChannel.channelName',
            },
            {
                label: 'Setup ID',
                key: 'merchantSetupId',
            },
            {
                label: 'Subchannel Name',
                key: 'paymentSubchannel.subchannelName',
            },
            {
                label: 'Custom Name',
                key: 'subchannelDescription',
            },
            {
                label: 'Country',
                key: 'countryCode',
            },
            {
                label: 'Status',
                key: 'active',
                type: TypeCell.status,
            },
            {
                label: 'Action',
                key: 'edit',
                type: TypeCell.button,
                labelCol: 'Edit'
            }
        ]

        return headers.map((header) => new HeadersModel(header));
    }

    getColorTag(status: boolean) {
        return status ? 'green' : 'red';
    }

    getLabelTag(status: boolean) {
        return status ? 'Active' : 'Inactive';
    }

    tableEvent(event) {
        switch (event.action) {
            case 'edit':
                this.isEdit = true;
                this.currentMerchantSetupId = event.row.merchantSetupId;
                const data = {...event.row, paymentSubchannelId: Number(event.row.paymentSubchannelId)};
                this.formSubchannel.patchValue(data);
                this.modalSubchannel.nzTitle = 'Edit Subchannel';
                this.modalSubchannel.open()
                this.formSubchannel.get('paymentChannelId').disable();
                this.formSubchannel.get('paymentSubchannelId').disable();
                this.formSubchannel.get('countryCode').disable();
        }

    }

    openModalSubchannel() {
        this.isEdit = false;
        this.modalSubchannel.nzTitle = 'Add Subchannel';
        this.formSubchannel.reset({}, {onlySelf:true, emitEvent: false});
        this.formSubchannel.get('paymentChannelId').enable({onlySelf:true, emitEvent: false});
        this.formSubchannel.get('countryCode').enable();
        this.formSubchannel.get('paymentSubchannelId').disable();
        this.modalSubchannel.open();
    }

    toggleStatusSubchannel(value, id) {
        this.isLoading = true;
        this.merchantService.updateSubchannelStatus(id, value).subscribe(() => {
            this.isLoading = false;
            this.getChannels(this.formFilters.value);
        })
    }

    addSubchannel() {
        this.loaderService.updateLoading(true);
        if (!this.isEdit) {
            this.merchantService.addChannel(this.merchant.infoMerchant.merchantCode, this.formSubchannel.value).subscribe(() => {
                this.modalSubchannel.close();
                this.getChannels(this.formFilters.value);
                this.formSubchannel.reset();
            },
                () => this.loaderService.updateLoading(false),
                () => this.loaderService.updateLoading(false)
            )
        } else {
            this.merchantService.updateChannel(this.currentMerchantSetupId, this.formSubchannel.value).subscribe(() => {
                this.modalSubchannel.close();
                this.loaderService.updateLoading(false);
                this.getChannels(this.formFilters.value);
                this.formSubchannel.reset();
            })
        }
    }

    onSelectSubchannel(event) {
        if (event) {
            const refMetadata = this.formSubchannel.get('metadata');
            refMetadata.setValue(null);
            const subchannel = this.subChannels.find((item) => item.PaymentSubchannelID === event);
            if (subchannel && subchannel.Metadata && subchannel.Metadata.type && Array.isArray(subchannel.Metadata.type)) {
                this.listTypesInMetadata = subchannel.Metadata.type;
                this.hasTypeInMetadata = true;
                refMetadata.setValidators([Validators.required]);
                refMetadata.updateValueAndValidity();
            } else {
                this.hasTypeInMetadata = false;
                this.listTypesInMetadata = [];
                refMetadata.clearValidators();
                refMetadata.updateValueAndValidity();
            }
        }
    }

}
