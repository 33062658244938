import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { HeadersModel, IHeadersTable, TypeCell } from "../../../models/headersTable.model";
import { Merchant } from "../../../models/merchant.model";
import { TableModel } from "../../../models/table.model";
import { ApiService } from '../../../services/api.service';
import { CountriesService } from '../../../services/countries.service';
import { MerchantService } from "../../../services/merchant.service";

@Component({
    selector: 'app-merchants',
    templateUrl: './merchants.component.html',
    styleUrls: ['./merchants.component.scss']
})
export class MerchantsComponent implements OnInit, OnDestroy {
    public merchants: Merchant[] = [];
    public isLoading = false;
    public dataTable: TableModel<any> = new TableModel([], this.headersTable, {total: 0, currentPage: 1, pageSize: 10, lastPage: 1});
    public pagination: any
    public pageSizes = [10, 20, 50, 100];
    public pageSize = 10;
    protected currentPage = 1;
    public subMerchants: Subscription
    protected filters: any = this.getFilters();
    public countries: any[] = [];
    protected filterTable = {
        currentPage: 1,
        size: this.pageSize,
        countryCode: null,
        name: null,
        merchantCode: null
    }


    constructor(private merchantService: MerchantService, private router: Router, private api: ApiService, private countriesService: CountriesService) {
        this.countriesService.countries$.subscribe(countries => {
            if (countries.length === 0) {
              this.countriesService.getCountries();
            } else {
              this.countries = countries;
            }
          })
    }

    ngOnInit() {
        this.getMerchants()
    }

    ngOnDestroy() {
        this.subMerchants.unsubscribe();
    }

    get headersTable (): HeadersModel[] {
        const headers: IHeadersTable[] = [
            {
                label: 'ID',
                key: 'merchantID',
            },
            {
                label: 'Name',
                key: 'bussinesName',
                responsive: true
            },
            {
                label: 'Website',
                key: 'webPage',
            },
            {
                label: 'Status',
                key: 'status',
                type: TypeCell.status,
                responsive: true
            },
            {
                label: 'Action',
                key: 'merchantCode',
                labelCol: 'Editar',
                type: TypeCell.linkInternal,
                path: '/settings/merchants/edit/',
            }
        ]

        return headers.map((header) => new HeadersModel(header));
    }



    getMerchants() {
        this.isLoading = true;
        this.subMerchants =  this.merchantService.getMerchants({currentPage: 1, pageSize: this.pageSize }).subscribe((res: any) => {
            this.merchants = res.data as Merchant [];
            this.pagination = res.metadata;
            this.dataTable = new TableModel(this.merchants, this.headersTable, {...res.metadata});
            this.isLoading = false;
        });
    }

    getColorTag(status) {
        switch (status) {
            case 'Active':
                return 'green';
            case 'Inactive':
                return 'red';
            default:
                return 'gray';
        }
    }

    tableEvent(event) {
        console.log(event)
    }

    searchData(index) {
        this.currentPage = index;
        this.filterTable = { ...this.filterTable, currentPage: index};
        this.isLoading = true;
        this.merchantService.getMerchants(this.filterTable).subscribe((res: any) => {
            this.merchants = res.data as Merchant [];
            this.pagination = res.metadata;
            this.dataTable = new TableModel(this.merchants, this.headersTable, {...res.metadata});
            this.isLoading = false;
        });
    }

    redirectMerchantNew() {
        this.router.navigate(['/settings/merchants/new']);
    }

    async getFilters(){

        const data = {
          service: 'merchants'
        };

    
        const dataMerchant = [];
        await this.api.api(data).toPromise().then((result: any) => {
    
          result.forEach(element => {
            dataMerchant.push({
              name: element.BussinesName,
              value: element.MerchantCode,
            });
          });
        });
    
        return [
          {
            'name': 'Comercio',
            'key': 'merchantCode',
            'type': 'select',
            'data': dataMerchant
          },
          {
            'name': 'País',
            'key': 'countryCode',
            'type': 'select',
            'data': this.countries
          },
          {
            'name': 'Nombre',
            'key': 'name',
            'type': 'text'
          }
        ];
    
    }

    changeDataTable(data) {   
        this.filterTable = { ...this.filterTable, ...data, currentPage: 1};
        this.currentPage = 1;
        this.searchData(this.currentPage);
    }

}
