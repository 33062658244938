import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend
} from 'ng-apexcharts';
import { UtilsService } from "../../../../services/utils.service";
import { LoaderService } from "../../../../services/loader.service";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: any; // ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  responsive: any; // ApexResponsive[];
};


@Component({
  selector: 'app-dashboard-cashout',
  templateUrl: './dashboard-cashout.component.html',
  styleUrls: ['./dashboard-cashout.component.scss']
})
export class DashboardCashoutComponent implements OnInit {
  chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  public path = '';
  user: any;
  titlePage: any = 'Cash Out';
  merchants: any[] = [];
  allMerchants: any[] = [];

  LastStatus = new FormControl('14');
  status = this.api.getStatusCashOut();
  typeCashInValue = '1';
  typeCashInName = 'Transacciones';
  selectedCurrency = 'USD';
  currencies = []
  selectedMerchant = null;

  single: any[];
  view: any[] = [500, 226];
  dataChart1: any;
  dataChart2: any[];
  dataChart2Temp: any[];
  // from_date: any = moment().startOf('month').add(1, 'days').format('YYYY-MM-DD');
  // to_date: any = moment().add(1, 'days').format('YYYY-MM-DD');
  from_date: any = moment().subtract(6, 'days').format('YYYY-MM-DD');
  to_date: any = moment().add(1, 'days').format('YYYY-MM-DD');

  // options
  gradient = true;
  showLegend = true;
  showLabels = true;
  isDoughnut = false;

  styleDate = {
    'min-width': '200px'
  };

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  dataChart3: any[];

  constructor(private router: Router, private api: ApiService, private utilsService: UtilsService, private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.path = window.location.pathname.trim();
    this.user = JSON.parse(sessionStorage.getItem('ud'));
    this.getData();
    this.getCurrencies();
    this.getMerchants();
  }

  getCurrencies() {
    this.api.queryGet('currencies').subscribe((result: any) => {
      this.currencies = result;
    })
  }

  getData() {
    this.loaderService.updateLoading(true);
    const data = {
      service: 'getstadistict_cashout',
      data: {
        from_date: moment(this.from_date).format('YYYY-MM-DD'),
        to_date: moment(this.to_date).format('YYYY-MM-DD'),
        currency_code: this.selectedCurrency,
        mcode: this.selectedMerchant,
      }
    };

    this.api.api(data).toPromise().then((result: any) => {
      this.dataChart1 = result.graphic1;
      this.dataChart2 = result.graphic2;
      this.dataChart2Temp = result.graphic2;
      this.merchants = [];
      this.dataChart2.forEach(e => {
        this.merchants.push(e.MerchantName);
      });
      this.getChart3();
    }).catch(error => {
      if (error.status === 401) {
        this.loaderService.updateLoading(false);
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  getChart3() {
    const data = {
      service: 'getstadistict_line_cashout',
      data: {
        from_date: moment(this.from_date).format('YYYY-MM-DD'),
        to_date: moment(this.to_date).format('YYYY-MM-DD'),
        type: this.typeCashInValue,
        currency_code: this.selectedCurrency,
        mcode: this.selectedMerchant,
      }
    };

    this.api.api(data).toPromise().then((result: any) => {
      this.loaderService.updateLoading(false);
      this.chartOptions = {
        series: result.data,
        chart: {
          height: 400,
          width: 1000,
          type: 'line'
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
          dashArray: [0]
        },
        legend: {
          tooltipHoverFormatter: function (val, opts) {
            return (
              val +
              ' - <strong>' +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              '</strong>'
            );
          }
        },
        markers: {
          size: 5,
          hover: {
            sizeOffset: 2
          }
        },
        xaxis: {
          labels: {
            trim: false
          },
          categories: result.categories
        },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1'
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300
              },
              legend: {
                position: 'bottom'
              }
            }
          },
          {
            breakpoint: 767,
            options: {
              chart: {
                width: 400
              },
              legend: {
                position: 'bottom'
              }
            }
          },
          {
            breakpoint: 991,
            options: {
              chart: {
                width: 700
              },
              legend: {
                position: 'bottom'
              }
            }
          },
          {
            breakpoint: 1199,
            options: {
              chart: {
                width: 800
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ]
      };

    }).catch(error => {
      if (error.status === 401) {
        // TO DO
        // sessionStorage.setItem('ud', '');
        // this.router.navigate(['/']);
      }
    });
  }

  onChangeMerchant(value) {
    this.dataChart2 = this.searchByName(this.dataChart2Temp, value);
  }

  selectChangeMerchant(value: any) {
    this.selectedMerchant = value;
    this.getData();
  }

  getMerchants() {
    this.api.queryGet('merchant').subscribe((result: any) => {
      if (result.length > 0) {
        this.allMerchants = result;
      }
    })
  }

  searchByName(array, name) {
    if (name === '0') {
      return this.dataChart2Temp;
    } else {
      let elements = [];

      array.forEach(e => {
        if (e.MerchantName === name) {
          elements.push(e);
        }
      });

      return elements;
    }
  }

  selectChangeHandler(value: any) {
    this.selectedCurrency = value;
    this.getData();
  }

  selectChangeTypeHandler(event: any) {
    this.typeCashInValue = event.target.value;
    this.validateName(this.typeCashInValue);
    this.getChart3();
  }

  validateName(n: string) {
    switch (n) {
      case '1': this.typeCashInName = 'Transacciones'; break;
      case '2': this.typeCashInName = 'Facturación'; break;
      case '3': this.typeCashInName = 'Bruto'; break;
      case '4': this.typeCashInName = 'Neto'; break;
    }
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
