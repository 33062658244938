import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessService } from '../../services/access.services';


@Injectable({
	providedIn: 'root'
})
export class IsLoggedInGuard implements CanActivate {
	constructor(private accessService: AccessService, private router: Router) {}

	canActivate(): Observable<boolean | UrlTree> {
		return this.accessService.isLoggedIn$.pipe(map((isLoggedIn) => isLoggedIn || this.router.createUrlTree(['/login'])));
	}
}


@Injectable({
	providedIn: 'root'
})
export class IsNotLoggedInGuard implements CanActivate {
	constructor(private accessService: AccessService, private router: Router) {}

	canActivate(): Observable<boolean | UrlTree> {
		return this.accessService.isLoggedIn$.pipe(map((isLoggedIn) => !isLoggedIn || this.router.createUrlTree(['/dashboard-cashin'])));
	}
}