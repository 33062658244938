import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { environment } from '../../../../../../environments/environment';
import { UtilsService } from "../../../../../services/utils.service";

export interface Settlement {
  SettlementID:             string;
  TotalRecord:              number;
  TotalAmount:              string;
  SubTotal:                 string;
  TotalFee:                 string;
  TotalTax:                 string;
  TotalOther:               string;
  CurrencyCode:             string;
  created_at:               string;
  Status:                   string;
  ApplySettlementAutomatic: boolean;
  BussinesName:             string;
}


@Component({
  selector: 'app-sttlement',
  templateUrl: './sttlement.component.html',
  styleUrls: ['./sttlement.component.scss']
})
export class SttlementComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();
  public filters: any = this.getFilters();
  public titlePage: string = 'Actividades > Cash In > Liquidaciones';

  titleModal: any = '';
  isVisible = false;
  contentModal: any = '';
  operationNumber: any = '';
  listBanksCashin: any;
  isAlertVisible = false;
  okText = 'Confirmar';
  selectedId = '';
  isLoadingModal = false;
  selectedRow: Settlement | null = null;

  constructor(
    private api: ApiService, 
    private router: Router, 
    private utilsService: UtilsService,
    private messageService: NzMessageService
    ) { }

  ngOnInit() {
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      service: 'settlement/list',
      data: []
    };

    const headers = [
      {
        'name': 'ID',
        'key': 'SettlementID',
        'opc': true,
        type: 'link',
        responsive: true,
      },
      {
        'name': 'Cliente',
        'key': 'BussinesName',
        'opc': true
      },
      {
        'name': 'Monto a depositar',
        'key': 'AmountToDeposit',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'created_at',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'Status',
        type: 'status',
        responsive: true,
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'opc',
        type: 'actions',
        'opc': false,
        'functions': ['Confirmar'],
      },
    ];

    if (dataInfo !== false) {
      data.service = 'settlement/listWithParams';
      data.data = dataInfo;
    }

    return {
      headers: headers,
      data: await this.api.api(data).pipe(
        map((result: any) => {
          result.forEach((element) => {
            element.AmountToDeposit = element.TotalTransfer ? element.TotalAmount : element.SubTotal;
          });
          return result;
        })
      ).toPromise(),
      source: 'activities-cashin-sttlement',
      getUrl: (id) => `/sttlement-detail/${id}`,
    };

  }



  async getFilters() {

    let data = {
      service: 'merchants'
    }
    let dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    let dataStatus = [{name: 'Creado', value: 1}, {name: 'Pagado', value: 2}, {name: 'En proceso', value: 3}];

    return [

      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus
      },
      {
        'name': 'ID Liquidaciones',
        'key': 'publicid',
        'type': 'text'
      },
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },
      {
        'name': 'Descargar',
        'key': 'download',
        'type': 'button'
      },

    ];

  }

  //EVENTS OUTPUT

  changeDataTable(data) {
    if (data.type === 'download') {
      const paramsDownload = {
        MerchantID: data.data.mcode,
        PublicId: data.data.publicid,
        from_date: data.data.from_date,
        to_date: data.data.to_date,
        Status: data.data.status,
      }
      
      this.api.queryDownload('listAllSettlements/download', paramsDownload)
      return
    }
    this.dataInfo = this.getDataInfo(data);
  }

  functions(e) {
    this.selectedRow = e.data;
    this.selectedId = e.data.SettlementID;
    switch (e.function) {
      case 'Confirmar':
        this.titleModal = 'Confirmar Liquidación';
        this.isVisible = true;
        this.okText = 'Confirmar';
        break;
      default:
      break;
    }
  }

  handleConfirm() {
    this.createOrder();
  }

  createOrder() {
    this.isLoadingModal = true;
    const data = this.selectedRow.ApplySettlementAutomatic ? { data: [
      {
        publicId: this.selectedId,
        service: true
      }
    ] } : {
      SettlementId: this.selectedId,
      metadata: JSON.stringify(JSON.parse(sessionStorage.getItem('ud')).userDetails)
    };
    
    const route = this.selectedRow.ApplySettlementAutomatic ? '/v1/payout/create-order' : 'settlement/confirm';
    const api = this.selectedRow.ApplySettlementAutomatic ? environment.api.urlFinance : environment.api.urlCore;

    this.api.queryPost(route, data, api).subscribe((result: any) => {
      if (result.data && result.data[0].error) {        
        this.messageService.error(result.data[0].message, {nzDuration: 5000});
      } 
      if(result.data && result.data.length && !result.data[0].error) {
        this.messageService.success(result.data[0].message, {nzDuration: 5000});
      }
      this.dataInfo = this.getDataInfo();
      this.isLoadingModal = false;
      this.isVisible = false;
      this.selectedRow = null;
      return;
    },(error: any) => {
      this.isLoadingModal = false;
      this.isVisible = false;
      this.messageService.error(error.message, {nzDuration: 5000});
      this.selectedRow = null;
      return;
    }
    )
  }

  handleCancel() {
    this.isVisible = false;
  }

  handleAlertNotification(): void {
    this.dataInfo = this.getDataInfo();
  }

}
