import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, from } from "rxjs";
import { environment } from "../../environments/environment";
import { Alert } from '../settings/interfaces/alerts';
import { PaginatedResponse, PaginatedService } from '../settings/interfaces/paginate-service';
import { objectToQueryParams } from "../shared/utils/object-to-query-params";

const API_ADMIN_URL = environment.api.urlCore;

@Injectable({
  providedIn: 'root'
})
export class AlertsService implements PaginatedService<Alert> {

  constructor(private http: HttpClient) {}

  getPaginatedData(page: number, size: number, filters: { [key: string]: any } = {}): Observable<PaginatedResponse<Alert>> {
    const queryParams = objectToQueryParams(filters);
    const url = `${API_ADMIN_URL}alerts/list?page=${page}&size=${size}&${queryParams}`;
    
    return this.http.get<PaginatedResponse<Alert>>(url);
  }

  updateAlert(alertId: number, status: number): Observable<any> {
    return this.http.put(`${API_ADMIN_URL}alerts/update/${alertId}`, {status});
  }

  getAlerts(page: number, size: number, filters: { [key: string]: any } = {}): Observable<PaginatedResponse<Alert>> {
    return from(this.getPaginatedData(page, size, filters));
  }
}
