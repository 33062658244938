import { TemplateRef } from "@angular/core";

export enum TypeCell {
    text = 'text',
    number = 'number',
    status = 'status',
    hideText = 'hideText',
    date = 'date',
    currency = 'currency',
    image = 'image',
    linkInternal = 'linkInternal',
    linkExternal = 'linkExternal',
    button = 'button',
    icon = 'icon',
    custom = 'custom',
    default = 'default'
}

export enum AlignCell {
    left = 'left',
    center = 'center',
    right = 'right'
}

export interface IHeadersTable {
    key: string;
    label: string;
    labelCol?: string;
    responsive?: boolean;
    sortable?: boolean;
    filterable?: boolean;
    type?: TypeCell;
    options?: any;
    width?: string;
    align?: AlignCell;
    format?: string;
    classes?: string;
    path?: string;
    template?: TemplateRef<any>;
}

export class HeadersModel {
    public key: string;
    public label: string;
    public labelCol: string;
    public responsive: boolean;
    public sortable: boolean;
    public filterable: boolean;
    public type: TypeCell;
    public options: any;
    public width: string;
    public align: AlignCell;
    public format: string;
    public classes: string;
    public path: string;
    public template: TemplateRef<any>;

    constructor(data: IHeadersTable) {
        this.key = data.key;
        this.label = data.label;
        this.labelCol = data.labelCol;
        this.responsive = data.responsive;
        this.sortable = data.sortable != null ? data.sortable : false;
        this.filterable = data.filterable != null ? data.filterable : false;
        this.type = data.type != null ? data.type : TypeCell.text;
        this.options = data.options != null ? data.options : {};
        this.width = data.width;
        this.align = data.align != null ? data.align : AlignCell.left;
        this.format = data.format != null ? data.format : '';
        this.classes = data.classes != null ? data.classes : '';
        this.path = data.path != null ? data.path : '';
        this.template = data.template != null ? data.template : null;
    }
}
