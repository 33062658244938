import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

  toogleMenuResponsive(){
    $('#nav').toggleClass('d-none');
    $('#nav').toggleClass('position-absolute');
  }

}
