import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { TableModel } from "../../../models/table.model";

@Component({
  selector: 'app-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.scss']
})
export class TableSettingsComponent implements OnInit {
  @Input() dataTable: TableModel<any>;
  @Input() isLoading: boolean = false;
  @Input() size: 'small' | 'default' | 'middle' = 'default';
  @Input() showPagination: boolean = true;
  @Output() eventTable = new EventEmitter<any>();
  @Output() pageIndexChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  searchData(index) {
    this.pageIndexChange.emit(index);
  }

  tableEvent(event) {
    this.eventTable.emit(event);
  }

  getWithColumn(value: string): string {
    if (value.includes('px')) {
      const num = value.replace(/px/g, '');
      return `${(parseInt(num) + 20)}px`;
    }
    if (value.includes('%')) {
        return value
    }
    return undefined;
  }

  getColorTag(status: boolean) {
    return status ? 'green' : 'red';
  }

  getLabelTag(status: boolean) {
    return status ? 'Active' : 'Inactive';
  }

}
