import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";


const API = environment.api;

@Injectable({
  providedIn: 'root'
})


export class AuthService {

  constructor(private http: HttpClient, public router: Router) {

  }
  api(datos) {

    switch (datos.service) {
      case 'auth/login':
        return this.http.post(`${API.urlCore + datos.service}`, datos);
      default:
        break;

    }


  }



}
