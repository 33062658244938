import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from 'moment';
import { ApiService } from "src/app/services/api.service";

interface filterDataConciliation{
  paginate: number;
  dateStart: string;
  dateEnd: string;
  merchantId: string;
  page:number;

}


@Component({
    selector: 'app-reports-conciliation-cashout',
    templateUrl: './conciliations-cashout.component.html',
    styleUrls: ['./conciliations-cashout.component.scss']
  })
export class ConsiliationsCashoutComponent implements OnInit {
    public allOperationsConsiliations;
    public filters;
    public totalItems = 0;
    public pagePer = 10;
    public ingresos: string | number = 0;
    public egresos: string | number = 0;
    public FinalBalance: string | number = 0;
    public InicialBalance: string | number = 0;
    protected titlePage: string = 'Conciliaciones';
 
    private filtersresul: filterDataConciliation;

    constructor(private api: ApiService,
                private router: Router) {

    }

    
    ngOnInit(): void {
        this.filters = this.getFilter();
        this.filtersresul =  { paginate: this.pagePer,  
                             dateStart: moment().date(new Date().getUTCDate()).format('YYYY-MM-DD') ,
                             dateEnd: moment().date(new Date().getUTCDate()).format('YYYY-MM-DD'), 
                             merchantId: '1001', page: 1};
        this.allOperationsConsiliations = this.showDataMonitor(this.filtersresul);
        this.getCardData();
    }

    private getCardData(): void{
      const data = {
                    service: 'extract/total',
                    data: {
                              dateStart: this.filtersresul.dateStart,
                              dateEnd: this.filtersresul.dateEnd,
                              merchant: this.filtersresul.merchantId,
                        }
                  };
      
        this.api.api(data).toPromise().then( (resul: any) =>{
            this.ingresos = '0';
            this.egresos = '0';
            this.FinalBalance = '0';
            this.InicialBalance = '0';
            console.log(resul);
            
            if (resul.ingresos){
              this.ingresos = resul.ingresos;
            }
            if (resul.egresos){
              this.egresos = resul.egresos;
            }
            if(resul.FinalBalance){
              this.FinalBalance = resul.FinalBalance;
            }
            if(resul.PreviousBalance){
              this.InicialBalance = resul.PreviousBalance;
            }
        });
    }

    public async downloadFileMonitor(filter: filterDataConciliation){
      let data: object;
      if (!filter){
        data = {
          service: 'admConciliationExcel',
          data: null
        };
      }else{
         data = {
          service: 'admConciliationExcel',
          data: {
                paginate: filter.paginate,
                dateStart: filter.dateStart,
                dateEnd: filter.dateEnd,
                merchantId: filter.merchantId
              }
        };
      }

      this.api.api(data).toPromise().then ((response : any) => {   
          let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
            downloadLink.setAttribute('download', new Date().toDateString());
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }).catch(error => {console.log(error)});
    }

    public async showDataMonitor(filter: filterDataConciliation){
      let data: object;
      if (!filter){
        data = {
          service: 'admConciliation',
          data: null
        };
      }else{
         data = {
          service: 'admConciliation',
          data: {
                    paginate: filter.paginate,
                    dateStart: filter.dateStart,
                    dateEnd: filter.dateEnd,
                    merchantId: filter.merchantId,
                    page: filter.page
              }
        };

        
      }
        

        const headers = [
            {
              'name': 'Fecha y Hora',
              'key': 'date',
              'opc': true,
              'type': 'text'
            },
            {
              'name': 'Descripción',
              'key': 'description',
              'opc': false,
              'responsive': true,
              'type': 'text'
            },
            {
              'name': 'monto',
              'key': 'amount',
              'opc': true,
              'responsive': true,
              'type': 'text'
            },
            {
              'name': 'Moneda',
              'key': 'currencyCode',
              'opc': false,
              'responsive': true,
              'type': 'text'
            },
            {
              'name': 'Saldo Inicial',
              'key': 'previousBalance',
              'opc': false,
              'responsive': true,
              'type': 'text'
            },
            {
              'name': 'Saldo Final',
              'key': 'finalBalance',
              'opc': false,
              'type': 'text',
              position: 'center'
            },
        ];

        let responseConsiliation: any;
        responseConsiliation = await this.api.api(data).toPromise().then((respose: any) =>{
          let index = 0;
          let dollarUSLocale = Intl.NumberFormat('en-US');
          respose.data.forEach(element =>{
            respose.data[index].finalBalance =  dollarUSLocale.format(element.finalBalance);
            respose.data[index].previousBalance = dollarUSLocale.format(element.previousBalance);
            index++;
          });

          return respose;
        });
        
        this.totalItems = responseConsiliation.meta.total;
        return {
            headers: headers,
            data: responseConsiliation.data,
            source: 'admConciliation'
        };
    }
 
    private async getFilter(filterby: string = 'merchants'){
        const data = {
            service: filterby
          };
         
          const dataMerchant = [];
          
          
          await this.api.api(data).toPromise().then((result: any) => {              
            result.forEach(element => {
                dataMerchant.push({
                  name: element.BussinesName,
                  value: element.MerchantCode,
                });
              
            }); 
          }).catch(error => {
            if (error.status === 401) {
              sessionStorage.setItem('ud', '');
              this.router.navigate(['/']);
            }
          });
          
         
        return [
            {
                'name': 'Comercio',
                'key': 'merchantCode',
                'type': 'select',
                'data': dataMerchant,
                'value' : 1001,
                allowEmpty: false
            },
            {
                'name': 'Periodo',
                'key': ['from_date', 'to_date', '1'],
                'type': 'period',
                allowEmpty: false
            },          
          // {
          //   'name': '',
          //   'key': 'download',
            
            
          // }
      
          ];
    }

    async changeDataTable(data) {
      console.log(data);
      
        if (data.type){
            if (!data.data.to_date){
              this.filtersresul.dateStart = moment().date(new Date().getUTCDate()).format('YYYY-MM-DD');
              this.filtersresul.dateEnd = moment().date(new Date().getUTCDate()).format('YYYY-MM-DD');
            }else{
              this.filtersresul.dateEnd = data.data.to_date;
              this.filtersresul.dateStart = data.data.from_date;
            } 
            this.filtersresul.merchantId = data.data.merchantCode;
            this.filtersresul.page = 1;
            this.downloadFileMonitor(this.filtersresul);
            this.allOperationsConsiliations = this.showDataMonitor(this.filtersresul);
        }else{
            
            if (!data.to_date){
              this.filtersresul.dateStart = moment().date(new Date().getUTCDate()).format('YYYY-MM-DD');
              this.filtersresul.dateEnd = moment().date(new Date().getUTCDate()).format('YYYY-MM-DD');
            }else{
              this.filtersresul.dateEnd = data.to_date;
              this.filtersresul.dateStart = data.from_date;
            } 
            this.filtersresul.merchantId = data.merchantCode;
            this.filtersresul.page = 1;
            this.allOperationsConsiliations = this.showDataMonitor(this.filtersresul);
        }  
        
        this.getCardData();
    }

    
    onChangePageIndex(pageIndex){
        this.filtersresul.page =  pageIndex;
        this.allOperationsConsiliations = this.showDataMonitor(this.filtersresul);
    }

    onChageItemPage(totalItems){
        this.pagePer = totalItems;
        this.filtersresul.paginate =  totalItems;
        this.allOperationsConsiliations = this.showDataMonitor(this.filtersresul);
    }

}