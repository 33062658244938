import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ResponseConciliationFile } from '../models/response-conciliation-file';

@Injectable({providedIn: 'root'})
export class DocumentsService {
  private readonly API_URL_ADMIN = environment.api.urlCoreAdmin;
	private readonly API_URL_MERCHANT = environment.api.urlMerchant;
  constructor(private httpClient: HttpClient) {}

  getListDocuments(params: { countryCode: string; isActive: boolean; isRequired: boolean }) {		
    const _params = new HttpParams({ fromObject: params as any });
		return this.httpClient.get(`${this.API_URL_ADMIN}/v1/country/documents`, { params: _params });
	}

  getDocumentsByMerchant(
		merchant: string,
		params: { isActive?: boolean; countryDocumentId?: number; status?: string } = { isActive: true }
	) {
    const _params = new HttpParams({ fromObject: params as any });
		return this.httpClient.get(`${this.API_URL_MERCHANT}documents/list/${merchant}`, { params: _params });
	}

	uploadDocument(file: File, merchant: string, countryDocumentId: number) {
		const formData = new FormData();
		formData.append('merchantFile', file);
		formData.append('merchantId', merchant);
		formData.append('countryDocumentId', countryDocumentId.toString());
		return this.httpClient.post(`${this.API_URL_MERCHANT}documents/upload`, formData);
	}

	updateDocumentStatus(merchantDocumentId: number, status: string, token: string) {
		const body = {
			token,
			status,
		};
		return this.httpClient.put(`${this.API_URL_MERCHANT}documents/update/${merchantDocumentId}`, body);
	}

	reconcileFile(data: FormData): Observable<ResponseConciliationFile> {
		return this.httpClient.post<ResponseConciliationFile>(`${this.API_URL_ADMIN}/v1/utilities/file/conciliation`, data);
	}
  
}