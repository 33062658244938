// nz-pagination-adapter.ts

import { PaginatedResponse, PaginatedService } from "../../settings/interfaces/paginate-service";

export class PaginationAdapter<T> {
  constructor(private dataService: PaginatedService<T>) {}

  async fetchData(page: number, size: number, filters?: { [key: string]: any }): Promise<PaginatedResponse<T>> {
    return this.dataService.getPaginatedData(page, size, filters).toPromise();
  }
}
