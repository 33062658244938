import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: 'app-commisions-modals',
  templateUrl: './commisions-modals.component.html',
  styleUrls: ['./commisions-modals.component.scss']
})
export class CommisionsModalsComponent implements OnInit {
  @Input() isVisible = false;
  @Input() inputs: any[] = [];
  @Input() title: string;
  @Input() isLoading = false;
  @Output() nzOnCancel = new EventEmitter<void>();
  @Output() nzOnOk = new EventEmitter<any>();
  form: FormGroup = new FormGroup({});

  constructor() { }

  ngOnInit( ) {}

  ngOnChanges() {
    this.form = new FormGroup({});
    this.inputs.forEach(input => {
      this.form.addControl(input.formControlName, new FormControl(input.value, input.validations));
      this.form.controls[input.formControlName].setValue(input.value);
    });    
  }

  onCancel() {
    this.form = new FormGroup({});
    this.nzOnCancel.emit();
  }

  onOk() {
    this.nzOnOk.emit(this.form.value);
    this.form = new FormGroup({});
  }

  getDataValue(optionValue: string, data){
    if (optionValue && data) {
      return data[optionValue];
    }
    return data;
  }

}
