import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NzModalService } from "ng-zorro-antd";
import { LoaderService } from "../../../services/loader.service";
import { MerchantService } from "../../../services/merchant.service";
import { UtilsService } from "../../../services/utils.service";
import { MerchantType } from "../../interfaces/merchant";

@Component({
    selector: 'app-merchant-new',
    templateUrl: './merchant-new.component.html',
    styleUrls: ['./merchant-new.component.scss']
})
export class MerchantNewComponent implements OnInit {
    formDataMerchant: FormGroup;
    countries: any[] = [];
    listTypeMerchant: MerchantType[] = [];
    typeDocument: any[] = [];

    constructor(
        private fb: FormBuilder,
        private utils: UtilsService,
        private merchantService: MerchantService,
        private router: Router,
        private modalService: NzModalService,
        private loaderService: LoaderService
    ) {
        this.getMerchantType();
        this.getCountries();
        this.createFormDataMerchant();
        this.getTypeDocument();
    }

    ngOnInit() {
    }

    createFormDataMerchant() {
        const patternEmail = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';
        this.formDataMerchant = this.fb.group({
            settlementSetting: [{value: '0', disabled: true}],
            legalName: ['', Validators.required],
            countryCode: ['', Validators.required],
            bussinesName: ['', Validators.required],
            urlWeb: ['', Validators.required],
            legalNumber: ['', Validators.required],
            merchantType: ['', Validators.required],
            color1: [null],
            color2: [null],
            color3: [null],
            userFullname: ['', Validators.required],
            userEmail: ['', [Validators.required, Validators.pattern(patternEmail)]],
            userMobile: ['', Validators.required],
            typeDocumentLegalRepresentative:['', Validators.required],
            typeDocumentMerchant: ['', Validators.required],
        });
    }

    async getCountries() {
        this.countries = await this.utils.getDataCountry();
    }

    getMerchantType() {
        this.merchantService.getMerchantType().subscribe((res) => {
            this.listTypeMerchant = res;
        });
    }

    createMerchant(data) {
        this.loaderService.updateLoading(true);
        this.merchantService.createMerchant(data).subscribe((res) => {
        this.loaderService.updateLoading(false);
            this.modalService.confirm(
                {
                    nzTitle: 'Merchant created successfully',
                    nzContent: 'Do you want to go to merchant settings?',
                    nzOnOk: () => {
                        this.router.navigate([`/settings/merchants/edit/${res.data.merchantCode}`]);
                    },
                    nzOnCancel: () => {
                        this.router.navigate([`/settings/merchants`]);
                    }
                }
            )
        });
    }

    async getTypeDocument(country = 'PER'){
        await this.merchantService.getTypeDocumentMerchant(country).subscribe((res: any) => {
            this.typeDocument = res.data
        });
    }

    changeCountry(country){
        this.getTypeDocument(country.value);
    }
}
