import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormControl } from '@angular/forms';
@Component({
  selector: 'app-profile-maintenance-detail',
  templateUrl: './profile-maintenance-detail.component.html',
  styleUrls: ['./profile-maintenance-detail.component.css']
})
export class ProfileMaintenanceDetailComponent implements OnInit {

  formUserProfile: FormGroup;
  userType: any;

  profileID: number;
  isloading = false;

  dashboardToggle = false;

  operationsToggle = false;
  operationsCashIn = false;
  operationsCashOut = false;

  activityToggle = false;
  // cashIn
  activityCashInToggle = false;
  activityCashInForcePayment = false;
  activityCashInNotifications = false;
  activityCashInSettlement = false;
  // cashOut
  activityCashOutToggle = false;
  activityCashOutBalance = false;
  activityCashOutPayroll = false;

  reportsToggle = false;
  // cashIn
  reportsCashInToggle = false;
  reportsCashInOperations = false;
  reportsCashInSettlement = false;
  // cashOut
  reportsCashOutToggle = false;
  reportsCashOutOperations = false;

  settingToggle = false;
  settingCommerce = false;
  settingBanks = false;
  settingUserToggle = false;
  settingUserMaintenance = false;
  settingUserMaintenanceProfiles = false;

  constructor(private aR: ActivatedRoute, private api: ApiService, private router: Router) {
    this.formUserProfile = this.createFormGroup();
  }

  ngOnInit() {
    this.userType = this.aR.snapshot.paramMap.get('id') === 'admin' ? '01' : '02';
  }

  createFormGroup() {
    return new FormGroup({
      name: new FormControl(''),
      visible_name: new FormControl('')
    });
  }

  handleCreateNewProfileUser() {
    this.isloading = true;
    this.createNewUser().then((result: any) => {
      this.profileID = result.profileID;
      this.isloading = false;
    }).catch(error => {
      this.isloading = false;
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  async createNewUser() {
    const data = {
      service: 'profile',
      data: {
        name: this.name.value,
        visible_name : this.visible_name.value,
        type: this.userType,
        // metadata: JSON.parse(sessionStorage.getItem('ud')).token
      }
    };
    return await this.api.api(data).toPromise();
  }

  updateToggleButton(type: string) {
    switch (type) {
      case 'dashboard':
        this.dashboardToggle = !this.dashboardToggle;
        break;
      case 'operations':
        this.operationsToggle = !this.operationsToggle;
        if (this.operationsToggle) {
          this.operationsCashIn = true;
          this.operationsCashOut = true;
        } else {
          this.operationsCashIn = false;
          this.operationsCashOut = false;
        }
        break;
      case 'operationsCashin':
        this.operationsCashIn = !this.operationsCashIn;
        this.verifyOperationsSection();
        break;
      case 'operationsCashout':
        this.operationsCashOut = !this.operationsCashOut;
        this.verifyOperationsSection();
        break;
      case 'activity':
        this.activityToggle = !this.activityToggle;
        this.verifyActivitySection();
        break;
      case 'activityCashin':
        this.activityCashInToggle = ! this.activityCashInToggle;
        this.verifyActivityCashInToggleSection();
        break;
      case 'activityCashInForcePayment':
        this.activityCashInForcePayment = !this.activityCashInForcePayment;
        this.verifyActivityCashInSection(this.activityCashInForcePayment);
        break;
      case 'activityCashInNotifications':
        this.activityCashInNotifications = !this.activityCashInNotifications;
        this.verifyActivityCashInSection(this.activityCashInNotifications);
        break;
      case 'activityCashInSettlement':
        this.activityCashInSettlement = !this.activityCashInSettlement;
        this.verifyActivityCashInSection(this.activityCashInSettlement);
        break;
      case 'activityCashout':
        this.activityCashOutToggle = !this.activityCashOutToggle;
        this.verifyActivityCashOutToggleSection();
        break;
      case 'activityCashoutBalance':
        this.activityCashOutBalance = !this.activityCashOutBalance;
        this.verifyActivityCashOutSection(this.activityCashOutBalance);
        break;
      case 'activityCashoutPayroll':
        this.activityCashOutPayroll = !this.activityCashOutPayroll;
        this.verifyActivityCashOutSection(this.activityCashOutPayroll);
        break;
      case 'reports':
        this.reportsToggle = !this.reportsToggle;
        this.verifyReportsSection();
        break;
      case 'reportsCashin':
        this.reportsCashInToggle = !this.reportsCashInToggle;
        this.verifyReportsCashInToggleSection();
        break;
      case 'reportsCashInOperations':
        this.reportsCashInOperations = !this.reportsCashInOperations;
        this.verifyReportsCashInSection(this.reportsCashInOperations);
        break;
      case 'reportsCashInSettlement' :
        this.reportsCashInSettlement = !this.reportsCashInSettlement;
        this.verifyReportsCashInSection(this.reportsCashInSettlement);
        break;
      case 'reportsCashout':
        this.reportsCashOutToggle = !this.reportsCashOutToggle;
        this.verifyReportsCashOutToggleSection();
        break;
      case 'reportsCashoutOperation':
        this.reportsCashOutOperations = !this.reportsCashOutOperations;
        this.verifyReportsCashOutSection(this.reportsCashOutOperations);
        break;
      case 'setting':
        this.settingToggle = !this.settingToggle;
        this.verifySettingSection();
        break;
      case 'settingCommerce':
        this.settingCommerce = !this.settingCommerce;
        this.verifySettingToggleSection(this.settingCommerce);
        break;
      case 'settingBanks':
        this.settingBanks = !this.settingBanks;
        this.verifySettingToggleSection(this.settingBanks);
        break;
      case 'settingUser':
        this.settingUserToggle = !this.settingUserToggle;
        this.verifySettingUserToggleSection();
        break;
      case 'settingUserMaintenance':
        this.settingUserMaintenance = !this.settingUserMaintenance;
        this.verifySettingUserSection(this.settingUserMaintenance);
        break;
      case 'settingUserMaintenanceProfiles':
        this.settingUserMaintenanceProfiles = !this.settingUserMaintenanceProfiles;
        this.verifySettingUserSection(this.settingUserMaintenanceProfiles);
        break;
    }

  }

  verifyOperationsSection() {
    if (this.operationsCashIn && this.operationsCashOut) {
      this.operationsToggle = true;
    } else {
      this.operationsToggle = false;
    }
  }

  verifyActivitySection() {
    if (this.activityToggle) {
      // cashIn
      this.activityCashInToggle = true;
      this.activityCashInForcePayment = true;
      this.activityCashInNotifications = true;
      this.activityCashInSettlement = true;
      // cashOut
      this.activityCashOutToggle = true;
      this.activityCashOutBalance = true;
      this.activityCashOutPayroll = true;
    } else {
      // cashIn
      this.activityCashInToggle = false;
      this.activityCashInForcePayment = false;
      this.activityCashInNotifications = false;
      this.activityCashInSettlement = false;
      // cashOut
      this.activityCashOutToggle = false;
      this.activityCashOutBalance = false;
      this.activityCashOutPayroll = false;
    }
  }

  verifyActivityCashInToggleSection() {
    if (this.activityCashInToggle) {
      this.activityCashInForcePayment = true;
      this.activityCashInNotifications = true;
      this.activityCashInSettlement = true;

      if (this.activityCashOutToggle) {
        this.activityToggle = true;
      }

    } else {
      this.activityToggle = false;
      this.activityCashInForcePayment = false;
      this.activityCashInNotifications = false;
      this.activityCashInSettlement = false;
    }
  }

  verifyActivityCashOutToggleSection() {
    if (this.activityCashOutToggle) {
      this.activityCashOutBalance = true;
      this.activityCashOutPayroll = true;
      if (this.activityCashInToggle) {
        this.activityToggle = true;
      }
    } else {
      this.activityToggle = false;
      this.activityCashOutBalance = false;
      this.activityCashOutPayroll = false;
    }
  }

  verifyActivityCashInSection(item) {
    if (!item) {
      this.activityCashInToggle = false;
      this.activityToggle = false;
    }
    if (this.activityCashInForcePayment && this.activityCashInNotifications && this.activityCashInSettlement) {
      this.activityCashInToggle = true;
      if (this.activityCashOutToggle) {
        this.activityToggle = true;
      }
    }
  }

  verifyActivityCashOutSection(item) {
    if (!item) {
      this.activityCashOutToggle = false;
      this.activityToggle = false;
    }
    if (this.activityCashOutBalance && this.activityCashOutPayroll) {
      this.activityCashOutToggle = true;
      if (this.activityCashInToggle) {
        this.activityToggle = true;
      }
    }
  }

  verifyReportsSection() {
    if (this.reportsToggle) {
      // cashIn
      this.reportsCashInToggle = true;
      this.reportsCashInOperations = true;
      this.reportsCashInSettlement = true;
      // cashOut
      this.reportsCashOutToggle = true;
      this.reportsCashOutOperations = true;
    } else {
      // cashIn
      this.reportsCashInToggle = false;
      this.reportsCashInOperations = false;
      this.reportsCashInSettlement = false;
      // cashOut
      this.reportsCashOutToggle = false;
      this.reportsCashOutOperations = false;
    }
  }

  verifyReportsCashInToggleSection() {
    if (this.reportsCashInToggle) {
      this.reportsCashInOperations = true;
      this.reportsCashInSettlement = true;
      if (this.reportsCashOutToggle) {
        this.reportsToggle = true;
      }
    } else {
      this.reportsToggle = false;
      this.reportsCashInOperations = false;
      this.reportsCashInSettlement = false;
    }
  }

  verifyReportsCashInSection(item) {
    if (!item) {
      this.reportsToggle = false;
      this.reportsCashInToggle = false;
    }
    if (this.reportsCashInOperations && this.reportsCashInSettlement) {
      this.reportsCashInToggle = true;
      if (this.reportsCashOutToggle) {
        this.reportsToggle = true;
      }
    }
  }

  verifyReportsCashOutToggleSection() {
    if (this.reportsCashOutToggle) {
      this.reportsCashOutOperations = true;
      if (this.reportsCashInToggle) {
        this.reportsToggle = true;
      }
    } else {
      this.reportsToggle = false;
      this.reportsCashOutOperations = false;
    }
  }

  verifyReportsCashOutSection(item) {
    if (!item) {
      this.reportsToggle = false;
      this.reportsCashOutToggle = false;
    }
    if (this.reportsCashOutOperations) {
      this.reportsCashOutToggle = true;
      if (this.reportsCashInToggle) {
        this.reportsToggle = true;
      }
    }
  }

  verifySettingSection() {
    if (this.settingToggle) {
      this.settingCommerce = true;
      this.settingBanks = true;
      this.settingUserToggle = true;
      this.settingUserMaintenance = true;
      this.settingUserMaintenanceProfiles = true;
    } else {
      this.settingCommerce = false;
      this.settingBanks = false;
      this.settingUserToggle = false;
      this.settingUserMaintenance = false;
      this.settingUserMaintenanceProfiles = false;
    }
  }

  verifySettingUserToggleSection() {
    if (this.settingUserToggle) {
      this.settingUserMaintenance = true;
      this.settingUserMaintenanceProfiles = true;
      if (this.settingCommerce && this.settingBanks) {
        this.settingToggle = true;
      }
    } else {
      this.settingToggle = false;
      this.settingUserMaintenance = false;
      this.settingUserMaintenanceProfiles = false;
    }
  }

  verifySettingToggleSection(item) {
    if (!item) {
      this.settingToggle = false;
    } else {
      if (this.settingCommerce && this.settingBanks && this.settingUserToggle) {
        this.settingToggle = true;
      }
    }
  }

  verifySettingUserSection(item) {
    if (!item) {
      this.settingUserToggle = false;
      this.settingToggle = false;
    } else {
      if ( this.settingUserMaintenance && this.settingUserMaintenanceProfiles) {
        this.settingUserToggle = true;
        this.settingToggle = true;
      }
    }
  }

  get name() { return this.formUserProfile.get('name'); }
  get visible_name() { return this.formUserProfile.get('visible_name'); }

}
