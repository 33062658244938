import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-banks-detail',
  templateUrl: './banks-detail.component.html',
  styleUrls: ['./banks-detail.component.css']
})
export class BanksDetailComponent implements OnInit {

  formBankDetail: FormGroup;
  formCashInInstruction: FormGroup;
  formCashOutDetailInfo: FormGroup;

  isloading = true;
  isInitialDetailData  = false;
  isDetailVisible = false;

  public data: any = this.getDataDetail();
  public filter: any = this.getDataCountry();
  listCountry: any;
  view: any;

  isDataSelected = true;
  isSettingSelected = false;

  isCheckboxCashInSettingSelected = false;
  isCheckboxCashOutSettingSelected = false;
  isCashInSettingSelected = true;
  isCashOutSettingSelected = false;

  isCashinIntructionModalVisible = false;
  isCashoutBankDetailModalVisible = false;

  constructor(private aR: ActivatedRoute, private api: ApiService, private router: Router) {
    this.formBankDetail = this.createFormGroup();
    this.formCashInInstruction = this.createCashInInstructionFormGroup();
    this.formCashOutDetailInfo = this.createFormCashOutDetailInfoFormGroup();
  }

  createFormGroup() {
    return new FormGroup({
      legal_name: new FormControl(''),
      account_digits: new FormControl(''),
      short_name: new FormControl(''),
      cci_digits: new FormControl(''),
      url_logo: new FormControl(''),
      color: new FormControl(''),
      url_website: new FormControl(''),
      country_code: new FormControl('PER')
    });
  }

  createCashInInstructionFormGroup() {
    return new FormGroup({
      position: new FormControl(''),
      logo: new FormControl(''),
      text: new FormControl(''),
    });
  }

  createFormCashOutDetailInfoFormGroup() {
    return new FormGroup({
      account_number: new FormControl(''),
      aba_number: new FormControl(''),
      code: new FormControl('PEN'),
      account_type: new FormControl('Saving')
    });
  }

  ngOnInit() {
  }

  async getDataCountry() {
    const data = {
      service: 'countries'
    };

    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.CountryName,
          value: element.CountryCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
    this.listCountry = dataMerchant;
    return dataMerchant;
  }

  selectDataOption() {
    this.resetOptions();
    this.isDataSelected = true;
  }

  selectSettingOption() {
    this.resetOptions();
    this.isSettingSelected = true;
  }

  resetOptions() {
    this.isDataSelected = false;
    this.isSettingSelected = false;
  }

  handleCreateData(): void {
    this.createNewSetting().then((result: any) => {
      this.isDetailVisible = true;
      this.selectSettingOption();
      // ver aca el result que retona el crete new item
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  async createNewSetting() {
    const data = {
      service: 'createBank',
      data: {
        legal_name: this.legal_name.value,
        short_name: this.short_name.value,
        logo: this.url_logo.value,
        web: this.url_website.value,
        country_code: this.country_code.value,
        account_digits: this.account_digits.value,
        cci_digits: this.cci_digits.value,
        color: this.color.value,
        metadata: JSON.parse(sessionStorage.getItem('ud')).token
      }
    };
    return await this.api.api(data).toPromise();
  }

  async getDataDetail() {
    const id = this.aR.snapshot.paramMap.get('id');
    this.view = this.aR.snapshot.paramMap.get('view');
    this.isInitialDetailData = true;

    if (id !== null) {
      const data = {
        service: 'bankDetail',
        data: id
      };

      return await this.getDetails(id, 'bankDetail').then((result: any) => {
        this.isloading = false;
        this.isInitialDetailData  = false;
        this.isDetailVisible = true;
        this.data = result;
        // HARDCODED VALUES
        this.data.InfoBank.AccountDigits = 123;
        this.data.InfoBank.CCIDigits = 456;

        // TODO VALIDATE NULL VALUES
        this.isCheckboxCashInSettingSelected = this.data.ServiceActive.ActiveCashin;
        this.isCheckboxCashOutSettingSelected = this.data.ServiceActive.ActiveCashout;

      });

    } else {
      this.isloading = false;
    }

  }

  setCollapsibleSection(selectedSection) {
    this.isCashInSettingSelected = !this.isCashInSettingSelected;
    this.isCashOutSettingSelected = !this.isCashOutSettingSelected;
  }

  async getDetails(id, service) {
    const data = {
      service: service,
      data: id
    };
    return await this.api.api(data).toPromise();
  }

  toogleSettingCashIn() {
    this.isCheckboxCashInSettingSelected = !this.isCheckboxCashInSettingSelected;
  }

  toogleSettingCashOut() {
    this.isCheckboxCashOutSettingSelected = !this.isCheckboxCashOutSettingSelected;
  }

  updateCashInToogleSwicth(currencyType, index) {
    this.data.setting.Cashin.Currencies[index].IsActive = !this.data.setting.Cashin.Currencies[index].IsActive;

    // TODO CALL SERVICE TO UPDATE VALUE
    // this.updateCurrencySwitch(currencyType, 'CI');
  }

  updateCashOutToogleSwicth(currencyType, index) {
    this.data.setting.Cashout.Currencies[index].IsActive = !this.data.setting.Cashout.Currencies[index].IsActive;

    // TODO CALL SERVICE TO UPDATE VALUE
    // this.updateCurrencySwitch(currencyType, 'CI');
  }

  openCashInInstructionModal() {
    this.isCashinIntructionModalVisible = true;
  }

  handleCancelCashInInstruction() {
    this.isCashinIntructionModalVisible = false;
    this.formCashInInstruction = this.createCashInInstructionFormGroup();
  }

  handleSaveCashInInstruction() {
    // TODO - pending integration
    // Call service to add instruction
    this.formCashInInstruction = this.createCashInInstructionFormGroup();
    this.isCashinIntructionModalVisible = false;
  }

  openCashOutBankDetailModal() {
    this.isCashoutBankDetailModalVisible = true;
  }

  handleCancelCashOutBankDetail() {
    this.isCashoutBankDetailModalVisible = false;
    this.formCashOutDetailInfo = this.createFormCashOutDetailInfoFormGroup();
  }

  handleSaveCashOutBankDetail() {
    // TODO - pending integration
    // Call service to add instruction
    this.formCashOutDetailInfo = this.createFormCashOutDetailInfoFormGroup();
    this.isCashoutBankDetailModalVisible = false;
  }

  get legal_name() { return this.formBankDetail.get('legal_name'); }
  get account_digits() { return this.formBankDetail.get('account_digits'); }
  get short_name() { return this.formBankDetail.get('short_name'); }
  get cci_digits() { return this.formBankDetail.get('cci_digits'); }
  get url_logo() { return this.formBankDetail.get('url_logo'); }
  get color() { return this.formBankDetail.get('color'); }
  get url_website() { return this.formBankDetail.get('url_website'); }
  get country_code() { return this.formBankDetail.get('country_code'); }

  get position() { return this.formCashInInstruction.get('position'); }
  get logo() { return this.formCashInInstruction.get('logo'); }
  get text() { return this.formCashInInstruction.get('text'); }

  get account_number() { return this.formCashOutDetailInfo.get('account_number'); }
  get aba_number() { return this.formCashOutDetailInfo.get('aba_number'); }
  get code() { return this.formCashOutDetailInfo.get('code'); }
  get account_type() { return this.formCashOutDetailInfo.get('account_type'); }

}
