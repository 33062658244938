import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../../../../services/api.service";
import {Router} from "@angular/router";
import {NzModalService} from "ng-zorro-antd";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-adm-conciliation',
  templateUrl: './adm-conciliation.component.html',
  styleUrls: ['./adm-conciliation.component.scss']
})
export class ADMConciliationComponent implements OnInit {
  public titlePage: string = 'Actividades > Cash In > ADM conciliation';
  public filters: any;
  public dataInfo: any;
  public categories: any = this.getCategories();
  public isVisible: boolean = false;
  public isVisibleCategoryModal: boolean = false;
  public isOkLoading: boolean = false;
  public category: any;
  public conciliation: any;
  public selectedSubcategory: any;
  public categoriesForm: FormGroup;

  constructor(
      private api: ApiService,
      private router: Router,
      private modal: NzModalService,
      private fb: FormBuilder
  ) {
    this.createFormCategories()
  }

  ngOnInit() {
  }

  changeDataTable(data) {
    this.dataInfo = this.getDataInfo(data);
  }

  createFormCategories() {
    this.categoriesForm = this.fb.group({
      category: this.fb.group({name: ['', [Validators.required]]}),
      subcategory: this.fb.group({
        category: [null, [Validators.required]],
        name: ['', [Validators.required]]
      })
    })
  }

  get categoryField() {
    return this.categoriesForm.get('category')
  }

  get subcategoryField() {
    return this.categoriesForm.get('subcategory')
  }

  functions({data, category}) {
    this.isVisible = true
    this.category = category
    this.conciliation = data
  }

  async categorize() {
    this.isOkLoading = true
    const data = {
      service: 'admConciliations-put',
      id: this.conciliation.AdmConciliationID,
      data: {
        AdmConciliationCategoryID: this.category.AdmConciliationCategoryID,
        AdmConciliationSubcategoryID: this.selectedSubcategory ? this.selectedSubcategory.AdmConciliationSubcategoryID : null,
        AdmConciliationStateID: 2
      }
    };
    try {
      const response: any = await this.api.api(data).toPromise()
      this.dataInfo = this.getDataInfo({AdmConciliationStateID: '1'})
      this.success(response.message)
      this.isVisible = false
      this.isOkLoading = false
    } catch (e) {
      console.log(e)
      this.isOkLoading = false
    }
  }

  handleCancel(){
    this.isVisible = false
  }

  handleOk(){
    console.log('Ok modal')
  }

  async getFilters() {
    const data = {
      service: 'bank',
      data: []
    };

    let banks = [];
    await this.api.api(data).toPromise().then((result: any) => {
      result.forEach(element => {
        banks.push({
          name: element.LegalName,
          value: element.BankCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
    const categories = await this.categories

    return [

      {
        'name': 'Categoria',
        'key': 'AdmConciliationCategoryID',
        'type': 'select',
        data: categories.map(category => ({ name: category.Description, value: category.AdmConciliationCategoryID}))
      },
      {
        'name': 'Fecha',
        'key': 'CreatedAt',
        'type': 'date'
      },
      {
        'name': 'Bancos',
        'key': 'BankCode',
        'type': 'select',
        'data': banks
      },
      {
        'name': 'Estado',
        'key': 'AdmConciliationStateID',
        'type': 'select',
        data: [
          {
            name: 'Todos',
            value: '',
          },
          {
            name: 'Pendiente',
            value: 1,
          },
          {
            name: 'Categorizado',
            value: 2,
          }
        ]
      },
    ];
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      service: 'admConciliations',
      data: []
    };

    let headers = [
      {
        'name': 'Detalle',
        'key': 'OperationDetail',
        responsive: true,
        'opc': true
      },
      {
        'name': 'Banco',
        'key': 'BankName',
        'opc': true
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Fecha',
        'key': 'created_at',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'AdmConciliationStage',
        responsive: true,
        type: 'status',
        'opc': true
      },
      {
        'name': 'Categoría',
        'key': 'standard-list',
        type: 'select',
        'opc': false,
        'list': await this.categories,
      },
      {
        'name': 'Acción',
        'key': 'action',
        type: 'button',
        'opc': false,
        'label': 'Categorizar',
      },
    ];


    if (dataInfo !== false) {
      data.service = 'admConciliations/listWithParams';
      data.data = dataInfo;
    }

    const list: any = await this.api.api(data).toPromise()

    return {
      headers: headers,
      data: await list.data,
    }

  }

  async getCategories() {
    const data = {
      service: 'admConciliationsCategories',
      data: []
    };

    const categories: any = await this.api.api(data).toPromise()
    this.dataInfo = this.getDataInfo({AdmConciliationStateID: '1'})
    this.filters = this.getFilters()
    return categories.data
  }

  showModalCategory() {
    this.isVisibleCategoryModal = true
  }

  handleCancelModalCategory(){
    this.categoriesForm.reset()
    this.isVisibleCategoryModal = false
  }

  async createCategory(type) {
    let data;
    if (type === 'category') {
      data = {
        service: 'admConciliationsCategories-post',
        data: {
          Description: this.categoryField.get('name').value
        }
      };
    }
    if (type === 'subcategory') {
      data = {
        service: 'admConciliationsSubcategories',
        id: this.subcategoryField.get('category').value.AdmConciliationCategoryID,
        data: {
          Description: this.subcategoryField.get('name').value
        }
      };
    }

    try {
      const response: any = await this.api.api(data).toPromise()
      this.success(response.message)
      this.isVisibleCategoryModal = false
      this.categories = this.getCategories()

    } catch (e) {
      console.log(e)
    }
  }

  success(title, message?: string): void {
    this.modal.success({nzTitle: title, nzContent: message});
  }

}
