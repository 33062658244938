import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import { ApiService } from "../../../../services/api.service";

@Component({
  selector: 'app-courier',
  templateUrl: './courier.component.html',
  styleUrls: ['./courier.component.scss']
})
export class CourierComponent implements OnInit {
  public operationsCourier: any = this.getOperationsCourier();
  public filters: any = this.getFilters();
  public titlePage: string = 'Operaciones > Courier';
  public countries: any[] = [];

  constructor(private api: ApiService) { }

  ngOnInit() {
  }

  async getOperationsCourier(dataInfo: any = {}) {
    const {from_date, to_date , ...filters} = dataInfo;
    const route = 'list_transactions';
    const queries = {
      fechaIni: from_date ? from_date : moment().startOf('month').format('YYYY-MM-DD'),
      fechaFin: to_date ? to_date : moment().format('YYYY-MM-DD'),
      ...filters
    }

    const headers = [
      {
        'name': 'OrderID',
        'key': 'OrderID',
        'opc': true,
      },
      {
        'name': 'ProviderCode',
        'key': 'ProviderCode',
        'opc': true
      },
      {
        'name': 'Currency',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Date',
        'key': 'created_at',
        'opc': true
      },
      {
        'name': 'Merchant',
        'key': 'MerchantCode',
        'opc': true,
        responsive: true
      },
      {
        'name': 'Amount',
        'key': 'Amount',
        'opc': true,
        responsive: true
      },
      {
        'name': 'Status',
        'key': 'Status',
        type: 'status',
        'opc': true,
        responsive: true
      },
    ];

    const response: any = await this.api.queryGet(route, queries).toPromise();

    return {
      headers: headers,
      data: response,
      source: 'operations-courier',
    };
  }

  async getFilters(){
    const rets = await Promise.all([
      this.api.queryGet<any[]>('merchant').toPromise(),
      this.api.queryGet<any[]>('countries').toPromise(),
    ]);

    const [merchants, countries] = rets;
    const merchantList = merchants.map(merchant => ({name: merchant.BussinesName, value: merchant.MerchantCode}));
    const countryList = countries.map(country => ({name: country.CountryName, value: country.CountryCode}));


    return [
      {
        'name': 'Provider Code',
        'key': 'ProviderCode',
        'type': 'text'
      },
      {
        'name': 'Periodo',
        'key': ['from_date','to_date', '3'],
        'type': 'period'
      },
      {
        'name': 'Comercio',
        'key': 'MerchantCode',
        'type': 'select',
        'data': merchantList
      },
      {
        'name': 'Country',
        'key': 'CountryCode',
        'type': 'select',
        'data': countryList
      }

    ];

  }

  changeDataTable(data: any) {
    if (data.period === '5') {
      data.from_date = '2020-01-01';
      data.to_date = moment().format('YYYY-MM-DD');
    }
    this.operationsCourier = this.getOperationsCourier(data);
  }
}
