import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-msg-error',
  templateUrl: './msg-error.component.html',
  styleUrls: ['./msg-error.component.scss']
})
export class MsgErrorComponent implements OnInit {

  @Input() messages: any[] = [];
  @Input() control!: AbstractControl;
  @Input() class = '';
  @Input() style = ''

  constructor() { }

  ngOnInit() {
  }

}
