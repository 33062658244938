import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from "jquery";
import { NzModalService } from "ng-zorro-antd";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { ApiService } from 'src/app/services/api.service';
import { AccessService } from '../../../../../services/access.services';
import { LoaderService } from "../../../../../services/loader.service";
import { UtilsService } from "../../../../../services/utils.service";
import { TypeOfOperationEnum } from "../enums/type-of-operation.enum";
import { TypeOfOperationForToken } from "../interfaces/type-of-operation-for-token.interface";

@Component({
  selector: 'app-payroll-detail',
  templateUrl: './payroll-detail.component.html',
  styleUrls: ['./payroll-detail.component.css']
})
export class PayrollDetailComponent implements OnInit {

  public details: any = this.processDetail();
  public historical: any;
  public files: any;
  public titlePage: any;
  public dataDeleteTransactions: any;
  public isVisibleRejectModal = false;
  public rejectReason: string;
  public titleRejectModal = '¿Esta seguro de rechazar esta transacción?';
  public dataTransaction: any;
  selectedNotifications: any[] = [];
  contentModal: any = '';
  isVisible: boolean = false;
  isVisibleDeleteModal: boolean = false;
  typeFunction = ''
  titleModalToken = ''
  showRadioGroup = false;
  showReason = false;
  showQrGoogle = false;
  qrAuthGoogle = '';
  isVisibleModalToken = false;
  tokenAuthGoogle = '';
  isLoading = false;
  reasonCancel = '';
  transactionsProcess = true

  constructor(
      private aR: ActivatedRoute,
      private api: ApiService,
      private router: Router,
      private modalService: NzModalService,
      private utilsService: UtilsService,
      private loaderService: LoaderService,
      private notification: NzNotificationService,
      private accessService: AccessService,
  ) {
  }

  ngOnInit() {
    this.processHistorical()
  }

  async processDetail() {

    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];
    this.titlePage = 'Operation > Cash Out > Nóminas > ' + id;

    return {
      headers: this.getHeadersDetails(),
      data: this.getDetails(id, 'payroll/detail')
    };
  }


  async getDetails(id, service) {
    const data = {
      service: service,
      data: id,
    };
    return await this.api.api(data).toPromise();
  }


  getHeadersDetails() {
  return [
    {
      name: 'ID',
      key: 'Information-PublicID',
    },
    {
      name: 'Templete',
      key: 'Information-Templete'
    },
    {
      name: 'Total Record',
      key: 'Information-TotalRecords'
    },
    {
      name: 'Total Amount',
      key: 'Information-Amount'
    },
    {
      name: 'Currency',
      key: 'Information-CurrencyCode'
    },
    {
      name: 'Date',
      key: 'Information-created_at'
    },
    {
      name: 'Last Status',
      key: 'Information-LastStatus'
    },
  ];
}


  // HISTORICAL

  getHeadersHistorical() {

    return [
      {
        'name': 'Eliminar',
        'key': 'headerActions',
        type: 'checkbox',
        responsive: true,
        'functions': ['Eliminar', 'Aprobar', 'Rechazar'],
      },
      {
        name: 'ID',
        key: 'PublicID',
        notDetail: true,
        responsive: true
      },
      {
        name: 'Reference',
        key: 'MerchantReference'
      },
      {
        name: 'Date',
        key: 'TxCreation'
      },
      {
        name: 'Amount',
        key: 'Amount'
      },
      {
        name: 'Currency',
        key: 'CurrencyCode'
      },
      {
        name: 'Client',
        key: 'Customer.Info.FullName'
      },
      {
        name: 'Status',
        key: 'LastStatus',
        responsive: true,
        type: 'status'
      },
      {
        name: 'Actions',
        key: 'list',
        type: 'actions',
        functions: [
          {
            'label': 'Aprobar',
            'key': 'approve',
            'icon': 'check-circle',
            color: '#52c41a'
          },
          {
            'label': 'Rechazar',
            'key': 'reject',
            'icon': 'close-circle',
            color: 'red'
          },
        ]
      }
    ];
  }

  getHeadersFiles() {

    return [
      {
        name: 'ID',
        key: 'PublicID',
        notDetail: true
      },
      {
        name: 'File',
        key: 'FileName'
      },
      {
        name: 'Date',
        key: 'created_at'
      },
      {
        name: 'Amount',
        key: 'TotalAmount'
      },
      {
        name: 'Currency',
        key: 'CurrencyCode'
      },
      {
        name: 'Template',
        key: 'Templete'
      },
      {
        name: 'Status',
        key: 'StatusDescription',
        type: 'status'
      }
    ];
  }

  async processHistorical() {
    this.loaderService.updateLoading(true);
    const parameters = this.aR.snapshot.paramMap.get("id");
    const id = parameters.split('-')[0];

    await this.getDetails(id, 'payroll/detail').then((data: any) => {

      const activity = [];
      data.listTransactions.forEach(e => {
        activity.push(e);
      });

      const files = [];
      data.ActivityResponse.forEach(e => {
        files.push(e);
      });

      this.historical = new Promise((resolve) => {
        resolve({
          title: 'Transacciones:',
          headers: this.getHeadersHistorical(),
          data: activity,
          source: 'payroll-detail'
        });
      });

      this.files = new Promise((resolve) => {
        resolve({
          headers: this.getHeadersFiles(),
          data: files,
          title: 'Files'
        });
      });

      this.loaderService.updateLoading(false);

    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  transactionEvent(event) {
    this.dataTransaction = event.data;
    if (event.function.key === 'approve') {
      this.modalService.confirm({
        nzTitle: '¿Esta seguro de procesar esta transacción?',
        nzContent: 'Recuerde que una vez que acepte no podrá realizar cambios de estado',
        nzOnOk: () => this.approveTransaction()
      })
    } else if (event.function.key === 'reject') {
      this.isVisibleRejectModal = true;
    } else {
      this.functions(event);
    }
  }

  approveTransaction() {
    this.loaderService.updateLoading(true);
    const url = `cashout/processTransaction/${this.dataTransaction.PublicID}/Transfer Ok/OK`;
    this.api.queryPost(url, {}).subscribe(
      () => {
        this.loaderService.updateLoading(false);
        this.modalService.success({
          nzTitle: 'Transacción aprobada',
          nzContent: 'La transacción ha sido aprobada exitosamente'
        });
        this.dataTransaction = null;
        this.processHistorical();
      },
      error => {
        this.dataTransaction = null;
        this.loaderService.updateLoading(false);
        this.modalService.error({
          nzTitle: 'Error',
          nzContent: error.error.message
        });
      }
    );
  }

  handleCancel() {
    this.isVisibleRejectModal = false;
  }

  rejectTransaction() {
    this.loaderService.updateLoading(true);
    const url = `cashout/processTransaction/${this.dataTransaction.PublicID}/Transfer Error/${this.rejectReason}`;
    this.api.queryPost(url, {}).subscribe(
      () => {
        this.loaderService.updateLoading(false);
        this.modalService.success({
          nzTitle: 'Transacción rechazada',
          nzContent: 'La transacción ha sido rechazada exitosamente'
        });
        this.dataTransaction = null;
        this.rejectReason = null;
        this.isVisibleRejectModal = false;
        this.processHistorical();
      },
      error => {
        this.dataTransaction = null;
        this.rejectReason = null;
        this.isVisibleRejectModal = false;
        this.loaderService.updateLoading(false);
        this.modalService.error({
          nzTitle: 'Error',
          nzContent: error.error.message
        });
      }
    );
  }

  functions(e){
    this.typeFunction = e.function;
    switch (e.function) {
      case 'Eliminar':
        this.selectNotifications(e);
        break;

      case 'checkbox':
        this.selectNotifications(e);
        break;

      case 'selectAllCheckboxes':
        this.selectNotificationsAll(e);
        break;

      case 'AprobarAction':
        this.titleModalToken = 'Aprobar transacciones';
        this.showRadioGroup = false;
        this.showReason = false;
        this.isVisibleDeleteModal = true;
        break;

      case 'EliminarAction':
        this.titleModalToken = 'Eliminar transacciones';
        this.showRadioGroup = true;
        this.showReason = false;
        this.isVisibleDeleteModal = true;
        break;

      case 'RechazarAction':
        this.titleModalToken = 'Rechazar transacciones';
        this.showRadioGroup = false;
        this.showReason = true;
        this.isVisibleDeleteModal = true;
        break;
      default:
        break;
    }
  }

  showTokenModal(type: TypeOfOperationEnum, event: any) {
    this.typeFunction = event.function;
    this.loaderService.updateLoading(true);
    this.generateToken(type).subscribe(
        () => {
          this.loaderService.updateLoading(false);
          this.isVisibleDeleteModal = true;
        }, () => {
          this.loaderService.updateLoading(false);
        }
    );
  }

  selectNotifications(e){
    if(e.event.target.checked === false){
      const search = this.searchByProp(this.selectedNotifications, e.data.PublicID);
      if (search != -1){
        this.selectedNotifications.splice(search, 1);
      }
    }else{
      const search = this.searchByProp(this.selectedNotifications, e.data.PublicID);
      if (search == -1) {
        this.selectedNotifications.push(e.data);
      }
    }
  }

  searchByProp(e, PublicId){
    if(!e || e.length === 0){
      return -1;
    }else{
      for (let i = 0; i < e.length; i++) {
        if (e[i].PublicID === PublicId) {
          return i;
        } else {
          if (i == e.length - 1) {
            return -1;
          }
        }
      }
    }
  }

  selectNotificationsAll(e){

    let ids = $('td input[type="checkbox"]');

    if(e.event.target.checked === false){
      $('td input[type="checkbox"]').prop('checked', false);
      this.selectedNotifications = [];
    }else{
      $('td input[type="checkbox"]').prop('checked', true);
      this.selectedNotifications = [];
      for (let i = 0; i < ids.length; i++) {
        const id = $(ids[i]).prop('id');
        const indexID = this.searchByProp(e.data, id);

        if(indexID != -1){
          this.selectedNotifications.push(e.data[indexID]);
        }

      }
    }
  }

  confirmModalToken() {
    console.log('confirmModalToken', this.typeFunction);
    
    if (this.typeFunction === 'EliminarAction') {   
      this.validateToken(this.deleteTransactions.bind(this));   
    }
    if (this.typeFunction === 'AprobarAction') {
      this.validateToken(this.approveTransactions.bind(this));
    }
    if (this.typeFunction === 'RechazarAction') {
      this.validateToken(this.rejectTransactions.bind(this));
    }
  }

  rejectTransactions() {
    this.loaderService.updateLoading(true);
    const route = 'cashout/processTransactionBatch'
    const data = {
      ids: this.selectedNotifications.map(e => e.PublicID),
      token: this.tokenAuthGoogle,
      messageBank: this.reasonCancel,
      status: 'Transfer Error'
    }
    this.api.queryPost(route, data).subscribe(
        () => {
          this.loaderService.updateLoading(false);
          this.processHistorical()
          this.notification.create('success', 'Operación exitosa', 'Se han rechazado las transacciones seleccionadas');
        },
        () => {
          this.loaderService.updateLoading(false);
        }
    );
  }

  deleteTransactions() {
    this.loaderService.updateLoading(true);
    const route = 'payroll/update/' + this.aR.snapshot.paramMap.get("id")
    const data = {
      type: 2,
      transactionsProcess: this.transactionsProcess,
      transactions: this.selectedNotifications.map(e => e.PublicID),
      operation: TypeOfOperationEnum.EDIT,
      reason: this.reasonCancel
    }
    this.api.queryPut(route, data).subscribe(
      () => {
        this.loaderService.updateLoading(false);
        this.processHistorical()
        this.notification.create('success', 'Operación exitosa', 'Se han cancelado las transacciones');
        this.resetModalToken()
      },
      () => {
        this.loaderService.updateLoading(false);
        this.resetModalToken()
      }
    );
  }

  approveTransactions() {
    this.loaderService.updateLoading(true);
    const route = 'cashout/processTransactionBatch'
    const data = {
      ids: this.selectedNotifications.map(e => e.PublicID),
      token: this.tokenAuthGoogle,
      messageBank: this.reasonCancel,
      status: 'Transfer Ok'
    }
    this.api.queryPost(route, data).subscribe(
      () => {
        this.loaderService.updateLoading(false);
        this.processHistorical()
        this.notification.create('success', 'Operación exitosa', 'Se han aprobado las transacciones');
        this.resetModalToken()
      },
      () => {
        this.loaderService.updateLoading(false);
        this.resetModalToken()
      }
    );
  }

  generateToken(operation: TypeOfOperationEnum ) {
    const route = 'token'
    const data: TypeOfOperationForToken = {operation}
    return this.api.queryPost(route, data)
  }

  resetQr(){
    this.accessService.getQrGoogle(true).subscribe(resulQr => {
      this.showQrGoogle = true;
      this.qrAuthGoogle = resulQr.data;
    });
  }

  onCancelModalToken() {
    this.isVisibleDeleteModal = false;
  }

  resetModalToken() {
    this.isVisibleDeleteModal = false;
    this.reasonCancel = '';
    this.transactionsProcess = true
    this.tokenAuthGoogle = '';
  }

  validateToken(callback: () => void ) {
    this.accessService.validateAuthToken(this.tokenAuthGoogle).subscribe((result: any) => {
      this.resetModalToken();
      callback();
    }, (error) => {
      this.resetModalToken();
      this.modalService.error({
        nzTitle: 'Mensaje de error',
        nzContent: `<ul> ${error.error.message} </ul>`,
        nzWidth: '500px',
      });
    });
  }

  get isDisabledOKBottomModal() {
    if (this.showReason) {
      return !this.tokenAuthGoogle || !this.reasonCancel;
    }
    return !this.tokenAuthGoogle;
  }
}
