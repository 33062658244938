import { Component, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalButtonOptions, NzModalService, NzNotificationService, NzUploadFile } from 'ng-zorro-antd';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { MetadataConciliation, ResponseConciliationFile, RowConciliation } from '../../../../models/response-conciliation-file';
import { ItemListStatus } from '../../../../models/transaction-status';
import { DocumentsService } from '../../../../services/documents.service';
import { ListService } from '../../../../services/list.service';
import { MerchantService } from '../../../../services/merchant.service';
import { ModalConciliationComponent } from '../modal-conciliation/modal-conciliation.component';

@Component({
  selector: 'app-file-reconciliation',
  templateUrl: './file-reconciliation.component.html',
  styleUrls: ['./file-reconciliation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileReconciliationComponent implements OnInit {
  formConciliation: FormGroup;
  file: NzUploadFile = null;
  loading$ = new BehaviorSubject<boolean>(true);
  types$: Observable<ItemListStatus[]> = new Observable<ItemListStatus[]>();
  statusList$: Observable<ItemListStatus[]> = new Observable<ItemListStatus[]>();
  loadingStatus$ = new BehaviorSubject<boolean>(false);
  merchants$: Observable<any> = new Observable<any>();
  loadingMerchants$ = new BehaviorSubject<boolean>(false);
  private searchTerms = new Subject<string>();
  tableHeaders: string[] = [];
  tableData: any[] = [];
  isLoading = false;
  isVisibleTokenModal = false;
  isOkLoading = false;
  otpControl = new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);

  constructor(
    private fb: FormBuilder, 
    private listService: ListService, 
    private merchantService: MerchantService, 
    private notification: NzNotificationService, 
    private documentsService: DocumentsService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef
  ) {
    this.fetchMerchants()
  }

  ngOnInit(): void {
    this.createForm();
    this.fetchTypes();
  }

  createForm() {
    this.formConciliation = this.fb.group({
      range: [[]],
      type: ['', [Validators.required]],
      lastStatus: [{ value: [], disabled: true }],
      merchantId: [null, [Validators.required]],
      amountIndex: [{ value: null, disabled: true }, [Validators.required]],
      documentIndex: [{ value: null, disabled: true }, [Validators.required]],
      file: [null, [Validators.required]],
    });

    this.changeControlType();

  }

  changeControlType() {
    this.formConciliation.get('type').valueChanges.subscribe((value: any) => {
      if (value) this.formConciliation.get('lastStatus').enable();
      else this.formConciliation.get('lastStatus').disable();

      this.formConciliation.get('lastStatus').setValue(null);

      if (value === '1') {
        this.statusList$ = this.listService.getTransactionStatusCashinList().pipe(
          finalize(() => this.loadingStatus$.next(false))
        );

      }
      if (value === '2') {
        this.statusList$ = this.listService.getTransactionStatusCashoutList().pipe(
          finalize(() => this.loadingStatus$.next(false))
        );
      }
    });
  }

  search(value: string) {
    this.searchTerms.next(value);
  }

  fetchTypes(): void {
    this.types$ = this.listService.getTypeServicesList().pipe(
      finalize(() => this.loading$.next(false))
    );
  }

  fetchMerchants(): void {
    this.merchants$ = this.searchTerms.pipe(
      tap(() => this.loadingMerchants$.next(true)),
      debounceTime(500), // Espera 500 ms después de que el usuario deja de escribir
      distinctUntilChanged(), // Evita llamadas repetidas con el mismo valor
      switchMap((term: string) => this.merchantService.getMerchants({ name: term }).pipe(
        map((response: any) => response.data),
        finalize(() => this.loadingMerchants$.next(false))
      )), // Realiza la llamada al servidor
    );
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    // Verificar el formato del archivo
    const isCsvOrXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel' ||
      file.name.endsWith('.csv') ||
      file.name.endsWith('.xlsx');

    if (!isCsvOrXlsx) {
      this.notification.error('Error de formato', 'Solo se permiten archivos .csv o .xlsx.');
      return false;
    }

    this.formConciliation.get('file').setValue(file);
    this.formConciliation.get('amountIndex').enable();
    this.formConciliation.get('documentIndex').enable();
    this.file = file;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Supongamos que deseas leer la primera hoja
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convertir la hoja a formato JSON para obtener los datos
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      this.tableHeaders = jsonData[0] as string[];
      this.tableData = jsonData.slice(1).filter((row: any[]) => {
        // Verifica que al menos una celda de la fila tenga datos
        return row.some(cell => cell !== null && cell !== '');
      }).map((row: any[]) => {
        const rowData: any = {};
        this.tableHeaders.forEach((header, index) => {
          rowData[header] = row[index] || '';
        });
        return rowData;
      });
    };
    reader.readAsArrayBuffer(file as any);

    return false;
  };

  processFile(approve: boolean = false) {
    if (this.formConciliation.invalid) {
      this.notification.error('Error', 'Por favor complete todos los campos requeridos (*).');
      return;
    }
    this.isLoading = true;
    const formValues = this.formConciliation.value;
  
    const fromDate = formValues.range[0]; // Fecha de inicio
    const toDate = formValues.range[1];   // Fecha de fin

    const status = formValues.lastStatus ? `[${formValues.lastStatus.join(",")}]` : undefined;
  
    const formData = new FormData();
    if (formValues.file) {
      formData.append('file', formValues.file);
    }
    if (formValues.merchantId) {
      formData.append('merchantId', formValues.merchantId);
    }
    if (formValues.type) {
      formData.append('type', formValues.type);
    }
    if (status) {
      formData.append('lastStatus', status);
    }
    if (fromDate) {
      formData.append('fromDate', fromDate);
    }
    if (toDate) {
      formData.append('toDate', toDate);
    }
    if (formValues.amountIndex) {
      formData.append('amountIndex', formValues.amountIndex);
    }
    if (formValues.documentIndex) {
      formData.append('documentIndex', formValues.documentIndex);
    }
    if (approve) {
      formData.append('confirmation', 'true');
    }
    if (this.otpControl.valid) {
      formData.append('token', this.otpControl.value);
    }
  
    this.documentsService.reconcileFile(formData).pipe(
      finalize(() => {
        this.isLoading = false;
        this.isOkLoading = false;
        this.otpControl.reset();
      })
    ).subscribe(
      (response: ResponseConciliationFile) => {
        if (!approve) {
          this.createComponentModal(response.data, response.metadata);     
        } else {          
          this.notification.success('Éxito', 'Las transacciones han sido aprobadas correctamente.');
          this.isVisibleTokenModal = false;
          this.deleteFile();
        }
      },
      (error: any) => {
        this.isVisibleTokenModal = false;
      } 
    );
  }

  deleteFile() {
    this.file = null;
    this.formConciliation.get('file').setValue(null);
    this.formConciliation.get('amountIndex').disable();
    this.formConciliation.get('amountIndex').setValue(null);
    this.formConciliation.get('documentIndex').disable();
    this.formConciliation.get('documentIndex').setValue(null);

    this.tableHeaders = [];
    this.tableData = [];
  }

  createComponentModal(data: RowConciliation[], summary: MetadataConciliation): void {
    const modal = this.modal.create({
      nzTitle: `Conciliación de archivo (${this.formConciliation.get('type').value === '1' ? 'Cash In' : 'Cash Out'})`,
      nzContent: ModalConciliationComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzGetContainer: () => document.body,
      nzComponentParams: {
        type: this.formConciliation.get('type').value,
        data: data,
        summary: summary
      },
      nzWidth: 'calc(100% - 60px)',
      nzStyle: { top: '20px'},
      nzFooter: this.getButtonsFooter(summary)
    });

    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    modal.afterClose.subscribe(result => console.log('[afterClose] The result is:', result));
  }

  getButtonsFooter(summary: MetadataConciliation): ModalButtonOptions<ModalConciliationComponent>[] {
    return this.formConciliation.get('type').value === '1' ? [
      {
        label: 'Aceptar',
        onClick: (componentInstance) => componentInstance.destroyModal(),
        type: 'primary',
      },
    ] : [
      {
        label: 'Cerrar',
        onClick: (componentInstance) => componentInstance.destroyModal(),
      },
      {
        label: 'Aprobar',
        onClick: (componentInstance) => this.showOtpModal(componentInstance),
        type: 'primary',
        disabled: summary.totalSuccess === 0,
      },
    ]
  }

  showOtpModal(componentInstance: ModalConciliationComponent) {
    componentInstance.destroyModal();
    this.isVisibleTokenModal = true;
  }

  handleOkTokenModal(): void {
    this.isOkLoading = true;
    this.processFile(true)
  }

}
