import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessService } from 'src/app/services/access.services';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

enum TypeUser {
  ADMIN = 1,
  MERCHANT = 2
}

@Component({
  selector: 'app-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.css']
})
export class UserMaintenanceComponent implements OnInit, OnDestroy {

  formAdministrator: FormGroup;
  formMerchant: FormGroup;

  protected TypeUser = TypeUser;
  protected totalItemsMerchants = 0;
  protected totalItemsAdmin = 0;
  protected pagePer = 10;
  protected page = 1;
  protected filterTable = {
    Type: TypeUser.ADMIN,
    page:  this.page,
    size: this.pagePer,
    email: null,
    name: null
  }
  protected dataAdminInfo: any = this.getDataInfo(TypeUser.ADMIN);
  protected dataMerchantInfo: any = this.getDataInfo(TypeUser.MERCHANT);
  protected filters: any = this.getFilters();

  protected resetPasswordForm: FormGroup;
  protected isLoadingRequest = false;
  protected currentPasswordVisible = false;
  protected confirmPasswordVisible = false;
  protected newPasswordVisible = false;
  private unsubscribe$: Subject<void> = new Subject();
  protected isGoogleAuthModalVisible = false;
  protected otpControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);


  isAdministradorSelected = true;
  isMerchantSelected = false;

  isCreateAdministradorModalVisible = false;
  isCreatMerchantModalVisible = false;

  isEditAdministradorModalVisible = false;
  isEditMerchantModalVisible = false;

  isResetPasswordModalVisible = false;

  qrAuthGoogle = "";
  showQrGoogle = false;
  userEditId = 0;
  userData: any;

  merchants: any[] = [];

  public msgErrors: any[] = [
    { error: 'required', message: 'Campo requerido'},
    { error: 'email', message: 'Email inválido'},
  ]

  constructor(private api: ApiService, private router: Router, private accessService: AccessService, private nzModalService: NzModalService) {
    this.formAdministrator = this.createFormAdministratorFormGroup();
    this.formMerchant = this.createFromMerchantFromGroup();
    this.getMerchants();
   }

  ngOnInit() {
    this.resetPasswordForm = this.createResetPasswordFormGroup();
    this.resetPasswordForm.get('newPassword').valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.resetPasswordForm.get('confirmPassword').updateValueAndValidity();
    });
    this.userData = JSON.parse(sessionStorage.getItem('ud')).userDetails;
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  createFormAdministratorFormGroup() {
    return new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      phone: new FormControl('',),
      profile: new FormControl('1000', [Validators.required]),
      state: new FormControl({value: '1', disabled: true}, [Validators.required])
    });
  }

  createFromMerchantFromGroup() {
    return new FormGroup({
      merchantName: new FormControl('', [Validators.required]),
      merchantEmail: new FormControl('', [Validators.required, Validators.email]),
      merchantPassword: new FormControl('', [Validators.required]),
      merchantPhone: new FormControl(''),
      merchantProfile: new FormControl('1000', [Validators.required]),
      merchantState: new FormControl({value: '1', disabled: true}),
      merchantList: new FormControl([], [Validators.required])
    });
  }

  async getDataInfo(type: TypeUser) {

    const headers = [
      {
        'name': 'Name',
        'key': 'fullname',
        'opc': true
      },
      {
        'name': 'Email',
        'key': 'email',
        'opc': true
      },
      {
        'name': 'Phone',
        'key': 'mobile',
        'opc': true
      },
      {
        'name': 'Profile',
        'key': 'profile',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'standard-list',
        'opc': false,
        'functions': ['Edit', 'Reset Password']
      }
    ];

    const { data: response, ...pagination } = await this.api.queryGet('user/merchant', {...this.filterTable, Type: type}).toPromise() as any;
    if (type === TypeUser.ADMIN) {
      this.totalItemsAdmin = pagination.total;
    }else {
      this.totalItemsMerchants = pagination.total;
    }
    this.pagePer = pagination.per_page;
    

    return {
      headers: headers,
      data: await Promise.resolve(response)
    };
  }

  getMerchants() {
    this.api.queryGet('merchant').subscribe((res: any) => {
      this.merchants = res;
    });
  }

  selectAdministradorOption() {
    this.resetOptions();
    this.isAdministradorSelected = true;
    this.dataAdminInfo = this.getDataInfo(this.TypeUser.ADMIN);
  }

  selectMerchantOption() {
    this.resetOptions();
    this.isMerchantSelected = true;
    this.dataMerchantInfo = this.getDataInfo(this.TypeUser.MERCHANT);
  }

  resetOptions() {
    this.isAdministradorSelected = false;
    this.isMerchantSelected = false;
    this.page = 1;
    this.pagePer = 10;
    this.filterTable = {
      Type: TypeUser.ADMIN,
      page:  this.page,
      size: this.pagePer,
      email: null,
      name: null
    }
  }

  newItem() {
    if (this.isAdministradorSelected) {
      this.isCreateAdministradorModalVisible = true;
    } else {
      this.isCreatMerchantModalVisible = true;
    }
  }

  cancelCreateModal() {
    this.isCreateAdministradorModalVisible = false;
    this.isCreatMerchantModalVisible = false;
    this.formAdministrator = this.createFormAdministratorFormGroup();
    this.formMerchant = this.createFromMerchantFromGroup();
  }

  saveCreateModal() {
    if (this.isAdministradorSelected) {
      if (this.formAdministrator.invalid) {
        this.formAdministrator.markAllAsTouched();
        return;
      }
      this.createUser(1).then((result: any) => {
        // REFRESH DATA
        this.dataAdminInfo = this.getDataInfo(this.TypeUser.ADMIN);
        this.isCreateAdministradorModalVisible = false;
        this.formAdministrator = this.createFormAdministratorFormGroup();
      }).catch(error => {
        this.nzModalService.error({
          nzTitle: `Error ${error.statusCode}`,
          nzContent: error.error?.message || 'Error al crear el usuario',
        });
        if (error.status === 401) {
          sessionStorage.setItem('ud', '');
          this.router.navigate(['/']);
        }
      });
    } else {

      if (this.formMerchant.invalid) {
        this.formMerchant.markAllAsTouched();
        return;
      }

      this.createUser(2).then((result: any) => {
        // REFRESH DATA
        this.dataMerchantInfo = this.getDataInfo(this.TypeUser.MERCHANT);
        this.isCreatMerchantModalVisible = false;
        this.formMerchant = this.createFromMerchantFromGroup();
      }).catch(error => {
        console.log('ERROR', error);
        if (error.status === 401) {
          sessionStorage.setItem('ud', '');
          this.router.navigate(['/']);
        }
      });
    }
  }

  async createUser(type) {
    const data = {
        email: (type === 1) ? this.email.value : this.merchantEmail.value,
        fullname: (type === 1) ? this.name.value : this.merchantName.value,
        mobile : (type === 1) ? this.phone.value : this.merchantPhone.value,
        password: (type === 1) ? this.password.value : this.merchantPassword.value,
        type: type,
        profileId: (type === 1) ? this.profile.value : this.merchantProfile.value,
        listMerchant: (type === 1) ? [] : this.merchantList.value.map((item: number) => item.toString()),
        metadata: JSON.stringify(JSON.parse(sessionStorage.getItem('ud')).userDetails),
        service: 'cashin'
      
    };
    // return await this.api.api(data).toPromise();
    return await this.api.queryPost('user/create', data, environment.api.urlAcces).toPromise();
  }

  cancelEditModal() {
    this.isEditAdministradorModalVisible = false;
    this.isEditMerchantModalVisible = false;
  }

  saveEditModal() {
    this.isEditAdministradorModalVisible = false;
    this.isEditMerchantModalVisible = false;
    // CALL SERVICES
    // RESET VALUES
  }

  handleOpcionEvents(list) {    
    if (list.function === 'Edit') {
      if (this.isAdministradorSelected) {
        //  SHOW EDIT ADMIN MODAL
        this.isEditAdministradorModalVisible = true;
        this.getQrForUser(Number(list.data.id));
        
        this.userEditId = Number(list.data.id);
        //  CALL DETAIL ADMIN USER SERVICE - TODO
        //  RESET FIELDS - TODO
      } else {
        //  SHOW EDIT MERCHANT MODAL
        this.isEditMerchantModalVisible = true;

        this.getQrForUser(Number(list.data.id));
        
        this.userEditId = Number(list.data.id);
        //  CALL DETAIL MERCHANT USER SERVICE - TODO
        //  RESET FIELDS - TODO
      }
    } else {
      this.resetPasswordForm.controls.email.setValue(list.data?.email);
      this.isResetPasswordModalVisible = true;
    }
  }

  closeResetPasswordModal() {
    this.isResetPasswordModalVisible = false;
    this.resetPasswordForm.reset();
  }

  saveResetPasswordModal() {
    if (this.resetPasswordForm.invalid) {      
      this.resetPasswordForm.markAllAsTouched();
      return;
    }

    this.isGoogleAuthModalVisible = true;
  }

  getQrForUser(userId: number):void{
    this.accessService.isUseTokenWithUserIdGoogle(userId).subscribe(resul => {
        if(!resul.data){
          this.showQrGoogle = true;
          this.accessService.getQrWithUseridGoogle(userId, false).subscribe(resulQr => {
            this.qrAuthGoogle = resulQr.data;
          });
        } else {
          this.showQrGoogle = false;
        }
    }, error => {
      console.log(error)
    })
  }

  resetQr(userId?: number){
    this.showQrGoogle = true;    
    this.accessService.getQrWithUseridGoogle(userId ? Number(userId) : this.userEditId, true).subscribe(resulQr => {
      this.qrAuthGoogle = resulQr.data;
    });
  }

  async getFilters() {
    return [
      {
        'name': 'Email',
        'key': 'Email',
        'type': 'text'
      },
      {
        'name': 'Nombre',
        'key': 'Name',
        'type': 'text'
      }
    ];
  }

  changeDataTable(data) {                 
    this.filterTable = { ...this.filterTable, ...data, page: 1};
    this.page = 1;
    if (this.isAdministradorSelected) {
      this.dataAdminInfo = this.getDataInfo(TypeUser.ADMIN);
    } else {
      this.dataMerchantInfo= this.getDataInfo(TypeUser.MERCHANT);
    }
  
  }

  onChangePageIndex(pageIndex) {
    this.page =  pageIndex;
    this.filterTable.page = pageIndex;
    if (this.isAdministradorSelected) {
      this.dataAdminInfo = this.getDataInfo(TypeUser.ADMIN);
    } else {
      this.dataMerchantInfo= this.getDataInfo(TypeUser.MERCHANT);
    }

  }

  onChangeItemPage(totalItems){    
    this.pagePer = totalItems;
    this.filterTable.size = totalItems;
    
    if (this.isAdministradorSelected) {
      this.totalItemsAdmin = totalItems;
      this.dataAdminInfo = this.getDataInfo(TypeUser.ADMIN);
    } else {
      this.totalItemsMerchants = totalItems;
      this.dataMerchantInfo= this.getDataInfo(TypeUser.MERCHANT);
    }
  }

  createResetPasswordFormGroup() {
    return new FormGroup({
      email: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
      newPassword: new FormControl('', [Validators.required, Validators.minLength(12), Validators.pattern(/^[a-zA-Z0-9]*$/)]),
      confirmPassword: new FormControl('', [this.confirmValidator]),
    });
  }

  handleCancelGoogleAuthModal(): void {
    this.isGoogleAuthModalVisible = false;
    this.qrAuthGoogle = "";
    this.showQrGoogle = false;
    this.otpControl.reset();
  }
  handleOkGoogleAuthModal(): void {
    this.isLoadingRequest = true;
    this.accessService.validateAuthToken(this.otpControl.value)
      .subscribe(resul => {
        this.isLoadingRequest = false;
        this.isGoogleAuthModalVisible = false;
        this.submitForm();
        this.otpControl.reset();
        this.qrAuthGoogle = "";
        this.showQrGoogle = false;
        
      }, error => {
        this.isLoadingRequest = false;
        this.otpControl.reset();
        this.isGoogleAuthModalVisible = false;
        this.nzModalService.error({
          nzTitle: `Error ${error.statusCode}`,
          nzContent: error.error?.message || 'Error al validar el token',
        });
      });
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.resetPasswordForm.controls.confirmPassword.updateValueAndValidity());
  }

  confirmValidator: ValidatorFn = (control: AbstractControl) => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.resetPasswordForm.controls.newPassword.value) {
      return { confirmPassword: true, error: true };
    }
    return {};
  };

  submitForm(): void {
    this.isLoadingRequest = true;
    const body = {
      ...this.resetPasswordForm.getRawValue(),
      console: "CONSOLE_ADMIN"
    }
    this.accessService.resetPassword(body)
      .subscribe(resul => {
        this.isResetPasswordModalVisible = false;
        this.resetPasswordForm.reset();
        this.isLoadingRequest = false;
        this.nzModalService.success({
          nzTitle: 'Success',
          nzContent: 'Contraseña cambiada con éxito'
        });
      }, error => {
        this.isLoadingRequest = false;
        this.resetPasswordForm.reset();
        this.isResetPasswordModalVisible = false
        this.nzModalService.error({
          nzTitle: 'Error',
          nzContent: error.error?.message || 'Error al cambiar la contraseña',
        });
      });
  }

  get name() { return this.formAdministrator.get('name'); }
  get email() { return this.formAdministrator.get('email'); }
  get password() { return this.formAdministrator.get('password'); }
  get phone() { return this.formAdministrator.get('phone'); }
  get profile() { return this.formAdministrator.get('profile'); }
  get state() { return this.formAdministrator.get('state'); }

  get merchantName() { return this.formMerchant.get('merchantName'); }
  get merchantEmail() { return this.formMerchant.get('merchantEmail'); }
  get merchantPassword() { return this.formMerchant.get('merchantPassword'); }
  get merchantPhone() { return this.formMerchant.get('merchantPhone'); }
  get merchantProfile() { return this.formMerchant.get('merchantProfile'); }
  get merchantState() { return this.formMerchant.get('merchantState'); }
  get merchantList() { return this.formMerchant.get('merchantList'); }

}
