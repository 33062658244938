import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeadersModel, IHeadersTable } from '../../../models/headersTable.model';
import { TableModel } from '../../../models/table.model';
import { DocumentsService } from '../../../services/documents.service';
import { IMerchant } from '../../interfaces/merchant';
import { TypeCell } from './../../../models/headersTable.model';

@Component({
  selector: 'app-documents-merchant',
  templateUrl: './documents-merchant.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsMerchantComponent implements OnInit {
  @Input() merchant: IMerchant;
  @ViewChild('actionsColumn', { static: true }) actionsColumn!: TemplateRef<unknown>;
  protected dataTable: TableModel<any> = new TableModel([], this.headersTable);
  protected isLoading = false;
  protected uploadedDocuments: any[] = [];
  protected documentList: any[] = [];
  protected documentListWithStatus: any[] = [];
  protected otpControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
  protected documentStatus = new FormControl(null, [Validators.required]);
  protected tplModalButtonLoading = false;
  protected tplModal?: NzModalRef;
  protected currentRow: any;

  constructor(
    private documentsService: DocumentsService, 
    private cd: ChangeDetectorRef,
    private nzModalService: NzModalService
  ) {}

  ngOnInit(): void {    
    this.getDocumentsByCountry(this.merchant.infoMerchant.countryCode);
  }

  get headersTable(): HeadersModel[] {
    const headers: IHeadersTable[] = [
        {
            label: 'Nombre de documento',
            key: 'name',
            type: TypeCell.text,
        },
        {
            label: 'Fecha de envío',
            key: 'updatedAt',
            type: TypeCell.date,
        },
        {
            label: 'Estado',
            key: 'state',
            type: TypeCell.status,
        },
        {
          label: 'Opciones',
          key: 'options',
          type: TypeCell.custom,
          template: this.actionsColumn
        }
    ]

    return headers.map((header) => new HeadersModel(header));
}


getDocumentsByCountry(countryCode: string) {
  forkJoin({
    documents: this.documentsService.getListDocuments({ countryCode, isActive: true, isRequired: true }) as Observable<any>,
    uploadedDocuments: this.getUploadedDocumentsByMerchant()
  }).subscribe(({ documents, uploadedDocuments }) => {
    this.documentList = documents.data;
    this.uploadedDocuments = uploadedDocuments.map((doc: any) => ({
      ...doc,
      countryDocumentId: Number(doc.countryDocumentId)
    }));

    this.documentListWithStatus = this.enrichDocumentsWithStatus(this.documentList, this.uploadedDocuments);
    this.dataTable = new TableModel(this.documentListWithStatus, this.headersTable);
    this.cd.detectChanges();
  });
}

private getUploadedDocumentsByMerchant(): Observable<any[]> {
  return this.documentsService.getDocumentsByMerchant(this.merchant.infoMerchant.merchantCode.toString()).pipe(
    map((response: any) => response.data.map((doc: any) => ({
      ...doc,
      countryDocumentId: Number(doc.countryDocumentId)
    })))
  );
}

private enrichDocumentsWithStatus(documents: any[], uploadedDocuments: any[]): any[] {
  return documents.map((doc) => {
    const uploaded = this.findUploadedDocument(doc.countryDocumentId, uploadedDocuments);
    return this.mapDocumentWithStatus(doc, uploaded);
  });
}

private findUploadedDocument(countryDocumentId: number, uploadedDocuments: any[]): any | undefined {
  return uploadedDocuments.find((d) => d.countryDocumentId === countryDocumentId);
}

private mapDocumentWithStatus(doc: any, uploaded: any): any {
  if (uploaded) {
    const { status: state, updatedAt, urlDocument, merchantDocumentId } = uploaded;
    return { ...doc, state, updatedAt, urlDocument, merchantDocumentId };
  } else {
    return { ...doc, state: '', updatedAt: '', urlDocument: '', merchantDocumentId: '' }; 
  }
}

  getColorByState(state: string): string {
		switch (state) {
			case 'APPROVED':
				return 'green';
			case 'REFUSED':
				return 'red';
			case 'PENDING':
				return 'orange';
			case 'REVIEW':
				return 'purple';
			default:
				return 'default'
		}
	}

  get allDocumentsOk() {
		return this.documentList.every((doc) => {
			const uploaded = this.uploadedDocuments.find((d) => d.countryDocumentId === doc.countryDocumentId);
			return uploaded && uploaded.status === 'APPROVED';
		});
	};

  downloadDocument(url: string) {
    window.open(url, '_blank');
  }

  openModalChangeStatus(tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, row: any) {
    this.currentRow = row;
    this.documentStatus.setValue(row.state);
    this.tplModal = this.nzModalService.create({
      nzTitle: 'Cambiar estado de documento',
      nzContent: tplContent,
      nzFooter: tplFooter,
    });
  }

  destroyTplModal(): void {
    this.tplModalButtonLoading = true;
    this.documentsService.updateDocumentStatus(this.currentRow.merchantDocumentId, this.documentStatus.value, this.otpControl.value).subscribe(() => {
      this.tplModalButtonLoading = false;
      this.tplModal!.destroy();
      this.otpControl.reset();
      this.getDocumentsByCountry(this.merchant.infoMerchant.countryCode);
      this.nzModalService.success({
        nzTitle: 'Éxito',
        nzContent: 'El estado del documento ha sido actualizado correctamente'
      });
    }, 
    ({error}) => {
      this.tplModalButtonLoading = false;      
      if (error.statusCode === 500) {
        this.tplModal!.destroy();
        return this.nzModalService.error({
          nzTitle: 'Error',
          nzContent: 'Ocurrió un error al cambiar el estado del documento, por favor intenta nuevamente o contacta a soporte'
        });
      }
      
      
    }
  );
  }

  handleCancel(): void {
    this.tplModal!.destroy();
    this.otpControl.reset();
  }

  getNameStatus(key: string): string {
    const status = {
      APPROVED: 'Aprobado',
      REFUSED: 'Rechazado',
      PENDING: 'Pendiente',
      REVIEW: 'En revisión'
    }

    return status[key] || key
  }
}
