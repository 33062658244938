export class CurrenciesConst {
    public static readonly currencies = [
        {
            code: 'COP',
            name: 'Peso colombiano',
            symbol: '$',
        },
        {
            code: 'USD',
            name: 'Dólar estadounidense',
            symbol: '$',
        },
        {
            code: 'PEN',
            name: 'Nuevo sol',
            symbol: 'S/',
        },
        {
            code: 'MXN',
            name: 'Peso mexicano',
            symbol: '$',
        },
        {
            code: 'BRL',
            name: 'Real brasileño',
            symbol: 'R$',
        }

    ]
}
