import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.css']
})
export class BanksComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();

  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      service: 'bank',
      data: []
    };

    const headers = [
      {
        'name': 'ID',
        'key': 'BankCode',
        'opc': true
      },
      {
        'name': 'Name',
        'key': 'ShortName',
        'opc': true
      },
      {
        'name': 'Creation Date',
        'key': 'Creation',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'standard-list',
        'opc': false,
        'functions': ['Edit'],
      }
    ];

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'activities-cashin-sttlement'
    };
  }

  handleOpcionEvents(list) {
    if (list.function === 'Edit') {
      this.router.navigate(['/banks-detail/' + list.data.BankCode +  '/edit']);
    }
  }

  newItem() {
    this.router.navigate(['/banks-detail/']);
  }

}
