import { AbstractControl, FormControl } from "@angular/forms";

export class Validations {
    /**
     * Método estático que se utiliza para crear una validación que verifica si un valor es mayor que otro valor
     * @param controlName
     */
    static greaterThan(controlName: string) {
        return (control: AbstractControl) => {
            const greater = control
            const less: FormControl = control.parent ? control.parent.controls[controlName] : null
            if (less && greater.value !== '') {
                if (Number(greater.value) <= Number(less.value)) {
                    return {greaterThan: true};
                }
                less.setErrors(null);
                return null;
            }
        }
    }

    /**
     * Método estático que se utiliza para crear una validación que verifica si un valor es menor que otro valor
     * @param controlName
     */
    static lessThan(controlName: string) {
        return (control: AbstractControl) => {
            const less = control
            const greater: FormControl = control.parent ? control.parent.controls[controlName] : null
            if ((greater && (greater.value !== '' && greater.value !== null)) && less.value !== '') {
                if (Number(less.value) >= Number(greater.value)) {
                    return {lessThan: true};
                }
                greater.setErrors(null);
                return null;
            }
        }
    }
}
