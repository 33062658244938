import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from "moment";
import { ApiService } from 'src/app/services/api.service';
import { CountriesService } from "../../../../services/countries.service";


@Component({
  selector: 'app-cashin',
  templateUrl: './cashin.component.html',
  styleUrls: ['./cashin.component.scss']
})
export class CashinComponent implements OnInit {

  public allOperationsCashInData: any = this.allOperationsCashIn();
  public filters: any = this.getFilters();
  public titlePage: string = 'Operaciones > Cash In';
  public countries: any[] = [];
  protected totalItems = 0;
  protected pagePer = 10;
  protected page = 1;
  private filterTable;
  protected params: {[key: string]: string} = {};

  constructor(private api: ApiService, private router: Router, private countriesService: CountriesService, private routeActive: ActivatedRoute) {
    this.countriesService.countries$.subscribe(countries => {
      if (countries.length === 0) {
        this.countriesService.getCountries();
      } else {
        this.countries = countries;
      }
    })
   }

  ngOnInit() {
    this.params = this.getUrlParams(); 
      this.filterTable = {
        from_date: moment().startOf('month').format('YYYY-MM-DD'), 
        from_to: moment().format('YYYY-MM-DD'), 
        page: this.page, 
        size: this.pagePer,
        // MerchantReference: this.params.reference || null,
      };
      this.allOperationsCashInData = this.allOperationsCashIn();
  }

  async allOperationsCashIn() {
    const headers = [
      {
        'name': 'Operación',
        'key': 'PublicId',
        'opc': true,
        'type': 'link'
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'BussinesName',
        'opc': true,
        responsive: true
      },
      {
        'name': 'Nombre',
        'key': 'FullName',
        'opc': true,
      },
      {
        'name': 'Documento',
        'key': 'DocNumber',
        'opc': true,
      },
      {
        'name': 'Código de pago',
        'key': 'PaymentCode',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'TxCreation',
        'opc': true
      },
      {
        'name': 'Expiración',
        'key': 'TxExpiration',
        'opc': true
      },
      {
        'name': 'Método de pago',
        'key': 'PaymentMethod',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        'opc': true,
        responsive: true,
        type: 'status',
        position: 'right'
      }
    ];

    const { data: response, ...pagination } = await this.api.queryGet('cashin/operations', this.filterTable).toPromise() as any;
    this.totalItems = pagination.total;
    this.pagePer = pagination.per_page;
    

    return {
      headers: headers,
      data: await Promise.resolve(response),
      source: 'operation-cashin',
      getUrl: (id) => `/operation-cashin/${id}`,
    };
  }



  async getFilters(){

    const data = {
      service: 'merchants'
    };

    const dataStatus = this.api.getStatus();

    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [
      {
        'name': 'Código de pago',
        'key': 'paymentCode',
        'type': 'text'
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus
      },
      {
        'name': 'Referencia Comercial',
        'key': 'merchantSalesID',
        'type': 'text'
      },
      {
        'name': 'N° Operación',
        'key': 'PublicId',
        'type': 'text'
      },
      {
        'name': 'Periodo',
        'key': ['from_date','to_date', '3'],
        'type': 'period'
      },
      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': 'País',
        'key': 'CountryCode',
        'type': 'select',
        'data': this.countries
      },
      {
        'name': 'Nombre',
        'key': 'clientName',
        'type': 'text'
      },
      {
        'name': 'Documento',
        'key': 'documentClient',
        'type': 'text'
      }

    ];

  }

  changeDataTable(data) {    
    this.filterTable = {...this.filterTable, ...data, page: 1};
    this.page = 1;
    this.allOperationsCashInData = this.allOperationsCashIn();
  }

  onChangePageIndex(pageIndex) {
    this.page =  pageIndex;
    this.filterTable.page = pageIndex;
    this.allOperationsCashInData = this.allOperationsCashIn();
}

  onChangeItemPage(totalItems){        
      this.pagePer = totalItems;
      this.filterTable.size = totalItems;
      this.allOperationsCashInData = this.allOperationsCashIn();
  }

  getUrlParams() {
    const url = window.location.href;
    const urlSplit = url.split('?');
    const params = {};
    if (urlSplit.length > 1) {
      const paramsSplit = urlSplit[1].split('&');
      paramsSplit.forEach(element => {
        const paramSplit = element.split('=');
        params[paramSplit[0]] = paramSplit[1];
      }
      );
    }
    return params;
  }
}
