import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-modal-token',
  templateUrl: './modal-token.component.html',
  styleUrls: ['./modal-token.component.scss']
})
export class ModalTokenComponent implements OnInit {
  @ViewChild('otpField', {static: false}) otpField : ElementRef;
  @Output() eventOk = new EventEmitter<any>();
  @Output() isVisibleChange = new EventEmitter<any>();
  @Input() isVisible = false;
  @Input() title = '';
  @Input() showRadioGroup = false;
  @Input() id = '';
  @Input() type = '';
  @Input() showReason = false;
  public transactionsProcess = true;
  public form: FormGroup;
  public msgErrors: any[] = [
    { error: 'required', message: 'Campo requerido'},
    { error: 'minlength', message: 'El motivo debe tener un mínimo de 6 caracteres'},
  ]

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm()
  }

  ngOnChanges(changes) {
    if(changes.showReason){
      if(changes.showReason.currentValue){
        this.form.controls.reason.setValidators([Validators.required, Validators.minLength(6)]);
        this.form.controls.reason.updateValueAndValidity();
      } else {
        this.form.controls.reason.setValidators([]);
        this.form.controls.reason.updateValueAndValidity();
      }

    }
  }

   ngAfterViewInit() {
    const inputs = this.otpField.nativeElement.querySelectorAll('.otp-field input');

    inputs.forEach((input, index) => {
      input.dataset.index = index;
      input.addEventListener('paste', (e) => this.handleOnPasteOtp(e, inputs));
      input.addEventListener('keyup', (e) => this.handleOtp(e, inputs));
    })
  }

  handleOnPasteOtp(e, inputs) {
    const data = e.clipboardData.getData('text');
    const value = data.split('');
    inputs.forEach((input, index) => {
      this.otp.controls[`otp${index}`].setValue(value[index])
    });
    if (inputs[value.length -1]){
      inputs[value.length -1].focus();
    }
  }

  handleOtp(e, inputs){
    const input = e.target;
    const value = input.value;
    input.value = '';
    input.value = value ? value[0] : '';
    let fieldIndex = input.dataset.index;
    if (value.length > 0 && fieldIndex < inputs.length - 1) {
      input.nextElementSibling.focus();
    }

    if (e.key === 'Backspace' && fieldIndex > 0) {
      input.previousElementSibling.focus();
    }
  }

  createForm() {
    this.form = this.fb.group({
      transactionsProcess: [true],
      otp: this.fb.group({
        otp0: ['', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]],
        otp1: ['', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]],
        otp2: ['', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]],
        otp3: ['', [Validators.required, Validators.maxLength(1), Validators.minLength(1)]]
      }),
      reason: ['', []]
    });

    this.form.controls.transactionsProcess.valueChanges.subscribe(value => {
      if(!value){
        this.form.controls.reason.setValidators([Validators.required, Validators.minLength(6)]);
        this.form.controls.reason.updateValueAndValidity();
      } else {
        this.form.controls.reason.setValidators([]);
        this.form.controls.reason.updateValueAndValidity();
      }
    })
  }

  handleCancel() {
    this.isVisible = false;
    this.isVisibleChange.emit(this.isVisible);
    this.form.reset({transactionsProcess: true})
  }

  handleOk() {
    const data = {
      transactionsProcess: this.form.controls['transactionsProcess'].value,
      otp: Object.values(this.form.controls['otp'].value).join(''),
      reason: this.form.controls['reason'].value,
      type: this.type,
    }
    this.eventOk.emit(data);
    this.form.reset({transactionsProcess: true})
    this.isVisible = false;
    this.type = '';
    this.isVisibleChange.emit(this.isVisible);
  }

  get otp() {
    return this.form.get('otp') as FormGroup;
  }
}
