import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../../../services/api.service";
import {Router} from "@angular/router";
import {NzModalService} from "ng-zorro-antd";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

enum Options {
    preview = 'Ver datos',
    process = 'Pagar',
    reject = 'Rechazar',
    allow = 'Autorizar',
    update = 'Actualizar datos',
    receipts = 'Enviar a receita',
}

@Component({
    selector: 'app-extorno',
    templateUrl: './extorno.component.html',
    styleUrls: ['./extorno.component.scss']
})
export class ExtornoComponent implements OnInit {
    public destroy$ = new Subject();
    public titlePage: string = 'Actividades > Cash In > Extorno';
    public filters: any;
    public dataInfo: any;
    public isVisibleUpdate: boolean = false;
    public isOkLoading: boolean = false;
    public selectedExtorno: any;
    public listBanks: any[] = [];
    public formExtorno: FormGroup;
    public isPIX: boolean = false;
    documentTypes: any[] = [
        {label: 'CC', value: 'CC'},
        {label: 'CE', value: 'CE'},
        {label: 'NIT', value: 'NIT'},
        {label: 'CPF', value: 'CPF'},
        {label: 'CNPJ', value: 'CNPJ'},
    ];
    public extornoTypes: any[] = [
        {label: 'TRANSFER', value: 'TRANSFER'},
        {label: 'PIX', value: 'PIX'}
    ];
    public types: any[] = [
        {label: 'Total', value: 1},
        {label: 'Parcial', value: 2},
    ];
    accountTypes: any[] = [
        {label: 'Ahorros', value: 'ahorros'},
        {label: 'Corriente', value: 'corriente'},
    ];
    public keyTypes: any[] = [
        {label: 'Correo', value: 'correo'},
        {label: 'Teléfono', value: 'telefono'},
        {label: 'Alter key', value: 'alter key'},
        {label: 'CPF/CNP', value: 'CPF/CNP'},
    ];

    public disabled = false;

    constructor(
        private api: ApiService,
        private router: Router,
        private modal: NzModalService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.filters = this.getFilters()
        this.dataInfo = this.getDataInfo();
        this.createForm()
        this.changeTypeDeposit()
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    createForm() {
        this.formExtorno = this.fb.group({
            FullName: ['', [Validators.required]],
            DocumentType: ['', [Validators.required]],
            DocumentNumber: ['', [Validators.required]],
            Phone: ['', [Validators.required]],
            Email: ['', [Validators.required, Validators.email]],
            TypeDeposit: [this.extornoTypes[0].value, [Validators.required]],
            TypeAccount: [null, [Validators.required]],
            Type: [{value: null, disabled: true}, [Validators.required]],
            BankName: [null, [Validators.required]],
            Amount: [{value: null, disabled: true}, [Validators.required]],
            NumberAccount: [null, [Validators.required]],
            NumberAgence: [null, [Validators.required]],
            KeyType: ['', [Validators.required]],
            KeyValue: ['', [Validators.required]],
        })
    }

    changeDataTable(data) {
        if (data.type === 'download') {
            this.api.queryDownload('listAllExtornos/download', data.data)
            return
        }
        this.dataInfo = this.getDataInfo(data);
    }

    async getDataInfo(dataInfo: any = false) {
        const data = {
            service: 'extornos-get',
            data: null
        };

        let headers = [
            {
                'name': 'Fecha',
                'key': 'created_at',
                'opc': true
            },
            {
                'name': 'PublicID',
                'key': 'PublicID',
                responsive: true,
                type: 'link',
                'opc': true
            },
            {
                'name': 'Nombre',
                'key': 'FullName',
                'opc': true
            },
            {
                'name': 'Documento',
                'key': 'DocumentNumber',
                'opc': true
            },
            {
                'name': 'Banco',
                'key': 'Bank',
                'opc': true
            },
            {
                'name': 'Monto',
                'key': 'Amount',
                'opc': true
            },
            {
                'name': 'Moneda',
                'key': 'CurrencyCode',
                'opc': true
            },
            {
                'name': 'Estado',
                'key': 'Stage',
                type: 'status',
                responsive: true,
                'opc': true
            },
            {
                'name': 'Opciones',
                'key': 'standard-list',
                'opc': false,
                type: 'actions',
                'functions': [
                    Options.preview,
                    Options.receipts,
                    Options.process,
                    Options.reject,
                    Options.allow,
                    Options.update
                ],
            },
        ];


        if (dataInfo !== false) {
            data.data = dataInfo;
        }

        const list: any = await this.api.api(data).toPromise()

        return {
            headers: headers,
            data: await list.data,
            source: 'extorno',
            getUrl: (id, data?: any) => `/extorno/${data.ExtornoID}`
        }
    }

    async getFilters() {
        const data = {
            service: 'bank',
            data: []
        };

        this.listBanks = [];
        await this.api.api(data).toPromise().then((result: any) => {
            result.forEach(element => {
                this.listBanks.push({
                    name: element.LegalName,
                    value: element.BankCode,
                });
            });
        }).catch(error => {
            if (error.status === 401) {
                sessionStorage.setItem('ud', '');
                this.router.navigate(['/']);
            }
        });
        // const categories = await this.categories

        return [
            {
                'name': 'PublicID',
                'key': 'PublicID',
                'type': 'text'
            },
            {
                'name': 'Documento',
                'key': 'DocumentNumber',
                'type': 'text'
            },
            {
                'name': 'Fecha',
                'key': 'CreatedAt',
                'type': 'date'
            },
            {
                'name': 'Monto',
                'key': 'Amount',
                'type': 'text'
            },
            {
                'name': 'Bancos',
                'key': 'BankCode',
                'type': 'select',
                'data': this.listBanks
            },
            {
                'name': 'Estado',
                'key': 'DevolutionStageID',
                'type': 'select',
                data: [
                    {
                        name: 'Todos',
                        value: '',
                    },
                    {
                        name: 'Pendiente',
                        value: 1,
                    },
                    {
                        name: 'Autorizado',
                        value: 2,
                    },
                    {
                        name: 'Rechazado',
                        value: 3,
                    },
                    {
                        name: 'Pagado',
                        value: 4,
                    }
                ]
            },
            {
                'name': 'Descargar',
                'key': 'download',
                'type': 'button'
            },
        ];
    }

    changeTypeDeposit() {
        this.TypeDeposit.valueChanges.pipe(
            takeUntil(this.destroy$)
        ).subscribe(resp => {
            if (resp) {
                this.isPIX = resp.value ? resp.value === 'PIX' : resp === 'PIX';
            }
        })
    }

    update() {
        const optionalPIX = ['KeyValue', 'KeyType']
        const noAccountValues = ['TypeDeposit', 'BankName', 'NumberAgence', 'TypeAccount', 'NumberAccount']

        if(this.isPIX) {
            this.clearValidations(noAccountValues)
            optionalPIX.forEach(key => {
                this.updateValidation(key, Validators.required)
            })
        } else if (!this.isPIX) {
            this.clearValidations(optionalPIX)
            noAccountValues.forEach(key => {
                this.updateValidation(key, Validators.required)
            })
        }

        if (this.formExtorno.invalid) {
            this.formExtorno.markAllAsTouched()
            return;
        }

        let dataService: any = {
            service: 'extornos-put',
            id: this.selectedExtorno.ExtornoID,
            data: {
                Amount: this.Amount.value,
                ExtornoType: this.TypeDeposit.value,
                DevolutionStageID: this.getStageId('Autorizado'),
                Details: {
                    DocumentType: this.DocumentType.value,
                    DocumentNumber: this.DocumentNumber.value,
                    Email: this.Email.value,
                    Phone: this.Phone.value,
                    NameBank: this.BankName.value.name,
                    NumberAgence: this.NumberAgence.value,
                    TypeAccount: this.TypeAccount.value,
                    NumberAccount: this.NumberAccount.value,
                    FullName: this.FullName.value
                },
                NoAccount: false,
                MetaData: {
                    KeyType: this.KeyType.value,
                    KeyValue: this.KeyValue.value
                }
            }
        };

        this.api.api(dataService).toPromise().then((resp: any) => {
            this.isVisibleUpdate = false;
            this.success(resp.message)
            this.dataInfo = this.getDataInfo();
        }).catch(e => {
            this.error(e.error.message || e.message)
        })
    }

    showModalUpdate() {
        this.isVisibleUpdate = true
    }

    handleCancel() {
        this.isVisibleUpdate = false
        this.formExtorno.reset()
        this.isPIX = false
    }

    functions(data) {
        this.selectedExtorno = data.data
        let dataService: any = {
            service: 'extornos-put',
            id: data.data.ExtornoID,
            data: {
                Amount: data.data.Amount,
                NoAccount: false,
                ExtornoType: data.data.details.length > 0 ? data.data.details[0].MetaData.ExtornoType : '',
            }
        };
        switch (data.function) {
            case Options.process: {
                dataService.data.DevolutionStageID = 4
                let title = Options.process
                let message = 'Esta seguro que desea pagar?'
                this.confirmModal(dataService, title, message)
            }
                break;
            case Options.reject: {
                dataService.data.DevolutionStageID = 3
                let title = Options.reject
                let message = 'Esta seguro que desea rechazar?'
                this.confirmModal(dataService, title, message)
            }
                break;
            case Options.allow: {
                dataService.data.DevolutionStageID = 2
                let title = Options.allow
                let message = 'Esta seguro que desea autorizar?'
                this.confirmModal(dataService, title, message)
            }
                break;
            case Options.update: {
                this.disabled = false
                dataService.data.DevolutionStageID = this.getStageId(data.data.Stage)
                this.setDefaultValues(data.data)
                this.showModalUpdate()
            }
                break;
            case Options.preview: {
                this.disabled = true
                this.setDefaultValues(data.data)
                this.showModalUpdate()
            }
                break;
            case Options.receipts: {
                dataService.data.DevolutionStageID = 5
                let title = Options.allow
                let message = '¿Esta seguro que desea enviar a receitas?'
                this.confirmModal(dataService, title, message)
            }
                break;
        }
    }

    getStageId(name) {
        const stages = {
            Pendiente: 1,
            Pagado: 4,
            Rechazado: 3,
            Autorizado: 2
        }

        return stages[name]
    }

    confirmModal(data: any, title: string, message: string) {
        this.modal.confirm({
            nzTitle: title,
            nzContent: message,
            nzOnOk: () => {
                this.api.api(data).toPromise().then((resp: any) => {
                    this.success(resp.message)
                    this.dataInfo = this.getDataInfo();
                }).catch(e => {
                    this.error(e.error.message || e.message)
                })
            }
        })
    }

    success(title, message?: string): void {
        this.modal.success({nzTitle: title, nzContent: message});
    }

    error(error): void {
        this.modal.error({nzTitle: 'Algo salió mal', nzContent: error});
    }

    clearValidations(keys) {
        keys.forEach(key => {
            this.formExtorno.get(key).clearValidators()
            this.formExtorno.get(key).updateValueAndValidity()
        })
    }

    updateValidation(key, validator) {
        this.formExtorno.get(key).setValidators([validator])
        this.formExtorno.get(key).updateValueAndValidity()
    }

    setDefaultValues(extorno) {
        const details = extorno.details[0]
        this.FullName.setValue(extorno.FullName)
        this.DocumentNumber.setValue(extorno.DocumentNumber)
        this.DocumentType.setValue(details.DocumentType)
        this.Phone.setValue(details.Phone)
        this.Email.setValue(details.Email)
        this.Type.setValue(this.getType(extorno.Type))
        this.TypeDeposit.setValue(details.MetaData ? details.MetaData.ExtornoType : null)
        this.BankName.setValue(this.getBank(extorno.BankCode))
        this.Amount.setValue(extorno.Amount)
        this.NumberAccount.setValue(details.NumberAccount)
        this.TypeAccount.setValue( details.TypeAccount )
        this.KeyType.setValue( details.MetaData ? details.MetaData.KeyType : '' )
        this.KeyValue.setValue( details.MetaData ? details.MetaData.KeyValue : '' )
        this.NumberAgence.setValue(details.MetaData ? details.MetaData.NumberAgence : '')
    }

    getBank(BankCode) {return this.listBanks.find(bank => bank.value === BankCode)}

    getType(value) {return this.types.find(type => type.value === value)}

    get isTypeValid() {return this.Type.invalid && this.Type.touched}
    get isAmountValid() {return this.Amount.invalid && this.Amount.touched}
    get isTypeDepositValid() {return this.TypeDeposit.invalid && this.TypeDeposit.touched}
    get isDocumentTypeValid() {return this.DocumentType.invalid && this.DocumentType.touched}
    get isDocumentNumberValid() {return this.DocumentNumber.invalid && this.DocumentNumber.touched}
    get isFullNameValid() {return this.FullName.invalid && this.FullName.touched}
    get isPhoneValid() {return this.Phone.invalid && this.Phone.touched}
    get isEmailValid() {return this.Email.invalid && this.Email.touched}
    get isNameBankValid() {return this.BankName.invalid && this.BankName.touched}
    get isNumberAgenceValid() {return this.NumberAgence.invalid && this.NumberAgence.touched}
    get isTypeAccountValid() {return this.TypeAccount.invalid && this.TypeAccount.touched}
    get isNumberAccountValid() {return this.NumberAccount.invalid && this.NumberAccount.touched}
    get isKeyValueValid() {return this.KeyValue.invalid && this.KeyValue.touched}
    get isKeyTypeValid() {return this.KeyType.invalid && this.KeyType.touched}

    get FullName() {return this.formExtorno.get('FullName')}
    get DocumentNumber() {return this.formExtorno.get('DocumentNumber')}
    get DocumentType() { return this.formExtorno.get('DocumentType') }
    get Phone() { return this.formExtorno.get('Phone') }
    get Email() { return this.formExtorno.get('Email') }
    get TypeDeposit() {return this.formExtorno.get('TypeDeposit')}
    get TypeAccount() {return this.formExtorno.get('TypeAccount')}
    get Type() {return this.formExtorno.get('Type')}
    get BankName() {return this.formExtorno.get('BankName')}
    get Amount() {return this.formExtorno.get('Amount')}
    get NumberAccount() {return this.formExtorno.get('NumberAccount')}
    get NumberAgence() {return this.formExtorno.get('NumberAgence')}
    get KeyType() {return this.formExtorno.get('KeyType')}
    get KeyValue() {return this.formExtorno.get('KeyValue')}
}
