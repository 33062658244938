import { Component, OnInit,OnDestroy } from '@angular/core';
import { ProviderService } from "../../../services/provider.service";
import { TableModel } from "../../../models/table.model";
import { Provider } from "../../interfaces/provider";
import { HeadersModel, IHeadersTable, TypeCell } from "../../../models/headersTable.model";
import { Subject, Subscription } from "rxjs";
import { CountriesService } from 'src/app/services/countries.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit, OnDestroy{
  dataTable: TableModel<Provider> = new TableModel([], this.headersTable, {total: 0, currentPage: 1, pageSize: 10, lastPage: 1});
  providers: Provider[] = [];
  pageSizes = [10, 20, 50, 100];
  pageSize: number = 10;
  isLoading = false;
  subPayments: Subscription
  public filters: any;
  public countries: any[] = [];
  private filterTable : {countryCode? : string, legalName? : string, legalNumber?: string, providerCode?: string};
  private destroy$ = new Subject();
  constructor(private providerService: ProviderService,
              private countriesService: CountriesService) {
    
      this.countriesService.countries$.pipe(
        takeUntil(this.destroy$)
      ).subscribe(countries => {
        if (countries.length === 0) {
          this.countriesService.getCountries();
        } else {
          this.countries = countries;
        }
      })

    this.filters = this.getFilters();
  }

  ngOnInit() {
    this.getPayments();
       
  }
  ngOnDestroy() {
    this.subPayments.unsubscribe();
  }

  getPayments({page = 1} = {}) {
    const params = {pageSize: this.pageSize, currentPage: page, ...this.filterTable};
    this.isLoading = true;

    this.subPayments = this.providerService.getPayments(params).subscribe(
      (response) => {
        this.isLoading = false;
        this.providers = response.data;
        this.dataTable = new TableModel(this.providers, this.headersTable, {...response.metadata});
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  searchData(value) {
    this.getPayments({page: value});
  }

  toggleStatus(event, data) {
    console.log(event);
    console.log(data);
  }

  get headersTable (): HeadersModel[] {
    const headers: IHeadersTable[] = [
      {
        label: 'Provider code',
        key: 'providerCode',
      },
      {
        label: 'Legal name',
        key: 'legalName',
        responsive: true
      },
      {
        label: 'Legal number',
        key: 'legalNumber',
      },
      {
        label: 'Country Code',
        key: 'countryCode',
      },
      {
        label: 'Date',
        key: 'createdAt',
        type: TypeCell.date,
      },
      {
        label: 'Action',
        key: 'providerCode',
        labelCol: 'Editar',
        type: TypeCell.linkInternal,
        path: '/settings/providers/edit/',
      }
    ]

    return headers.map((header) => new HeadersModel(header));
  }

  async getFilters(){
    
    return [
      {
        'name': 'Country',
        'key': 'countryCode',
        'type': 'select',
        'data': this.countries
      },
      {
        'name': 'Legal Name',
        'key': 'legalName',
        'type': 'text'
      },
      {
        'name': 'Legal Number',
        'key': 'legalNumber',
        'type': 'text'
      },
      {
        'name': 'Provider Code',
        'key': 'providerCode',
        'type': 'text'
      }
    ];

  }

  changeDataTable(data) {    
    this.filterTable = {}
    if (data.countryCode != null){
      this.filterTable.countryCode = data.countryCode;
    }
    if (data.legalName != null){
      this.filterTable.legalName = data.legalName;
    }
    if (data.legalNumber != null){
      this.filterTable.legalNumber = data.legalNumber;
    } 
    if (data.providerCode != null){
      this.filterTable.providerCode = data.providerCode;
    }

    this.getPayments({page: 1}) ;
  }
}
