import { Directive, ElementRef, HostListener, Renderer2, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appNumberFormat]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFormatDirective),
      multi: true
    }
  ]
})
export class NumberFormatDirective implements ControlValueAccessor {

  private onChange: (value: any) => void;
  private onTouched: () => void;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    // Obtener el valor actual del input
    let value = this.elementRef.nativeElement.value;

    // Eliminar todos los caracteres que no sean dígitos o punto decimal
    let numericValue = value.replace(/[^0-9.]/g, '');

    // Separar la parte entera y decimal
    const parts = numericValue.split('.');

    // Limitar a dos decimales si es necesario
    if (parts.length > 1) {
      parts[1] = parts[1].substring(0, 2);
    }

    // Reunir el valor numérico sin formato
    numericValue = parts.join('.');

    // Actualizar el modelo con el valor sin formato
    if (this.onChange) {
      this.onChange(numericValue);
    }

    // Formatear la parte entera con separadores de miles
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // Unir las partes nuevamente para el valor formateado
    const formattedValue = parts.join('.');

    // Actualizar el valor mostrado en el input
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', formattedValue);
  }

  @HostListener('blur')
  onBlur() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  // Métodos de ControlValueAccessor

  writeValue(value: any): void {
    if (value !== undefined && value !== null) {
      // Formatear el valor para mostrarlo
      let numericValue = value.toString();
      const parts = numericValue.split('.');

      if (parts.length > 1) {
        parts[1] = parts[1].substring(0, 2);
      }

      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      const formattedValue = parts.join('.');

      this.renderer.setProperty(this.elementRef.nativeElement, 'value', formattedValue);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', '');
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  
  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const regex = new RegExp('[0-9]');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);

    // Permitir solo un punto decimal
    if (event.key === '.' && this.elementRef.nativeElement.value.indexOf('.') === -1) {
      return;
    }

    // Prevenir la entrada si no es un número
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
}
