import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
})
export class CountriesService {
    private countries = new BehaviorSubject([]);
    public countries$: Observable<any[]> = this.countries.asObservable();

    constructor(private api: ApiService,) { }

    public updateCountries(value: any[]) {
        this.countries.next(value);
    }

    public getCountries() {
        this.api.queryGet('countries').pipe(
            map((countries: any[]) => {
                if (countries && countries.length) {
                    return countries.map( country => ({name: country.CountryName, value: country.CountryCode, id: country.CountryID}))
                }
                return []
            }),
            tap((countries: any[]) => {
                if (countries.length) {
                    this.updateCountries(countries);
                }
            })
        ).subscribe((result: any[]) => {
            if (Array.isArray(result) && result.length) {
                this.updateCountries(result);
            }
        })
    }
}
