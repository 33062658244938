import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/services/api.service';
import { CountriesService } from 'src/app/services/countries.service';
import { environment } from 'src/environments/environment';
import { STATUS_OPERATION_CASH_OUT } from '../../../../../constants/status.const';

@Component({
  selector: 'app-operations-cashout',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsCashoutComponent {

  public allOperationsCashOutData: any;
  public filters: any = this.getFilters();
  public titlePage: string = 'Operaciones > Cash Out';
  public countries: any[] = [];

  public pagePer = 10;
  public totalItems = 0;
  private page = 1;
  private filterTable = {
    to_date: moment().date(new Date().getUTCDate()).format('YYYY-MM-DD'),
    from_date: moment().date(new Date().getUTCDate()).format('YYYY-MM-DD'),
    CountryCode: null,
    bankCode: null,
    status: null,
    publicId: null,
    page: 1, 
    size: this.pagePer,
  }

  constructor(private api: ApiService, private router: Router,
              private countriesService: CountriesService,
              private modal: NzModalService) {

                this.countriesService.countries$.subscribe(countries => {
                  if (countries.length === 0) {
                    this.countriesService.getCountries();
                  } else {
                    this.countries = countries;
                  }
                })
                this.allOperationsCashOutData = this.allOperationsCashOut();
   }


  async allOperationsCashOut(): Promise<any> {
    const headers = [
      {
        'name': 'Operación',
        'key': 'PublicID',
        'opc': true,
        responsive: true,
        type: 'link',
      },
      {
        'name': 'Cliente',
        'key': 'Customer.Info.FullName',
        'opc': true
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Banco',
        'key': 'Customer.InfoBank.CustomerBank',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'TxCreation',
        'opc': true
      },
      {
        'name': 'Fecha de depósito',
        'key': 'PaymementDate',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        responsive: true,
        type: 'status',
        'opc': true
      }
    ]    

    const { data: response, ...pagination } = await this.api.queryGet('cashout/operations', this.filterTable).toPromise() as any;
    this.totalItems = pagination.total;
    this.pagePer = pagination.per_page;
    


    return {
      headers: headers,
      data: await Promise.resolve(response),
      source: 'operation-cashout',
      getUrl: (id) => `/operation-cashout/${id}`
    }

  }



  async getFilters() {

    let data = {
      service: 'merchants'
    }

    let dataStatus = STATUS_OPERATION_CASH_OUT


    let dataMerchant = [];
    let dataBanks = [];

    await this.api.api({
      service: 'listBanksCashout'
    }).toPromise().then((result: any) => {

      result.forEach(element => {
        dataBanks.push({
          name: element.ShortName,
          value: element.BankCode,
        });
      });
    }).catch(error => {
      if (error.status == 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });


    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status == 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [
      {
        'name': 'Cliente',
        'key': 'ClientName',
        'type': 'text'
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus
      },
      {
        'name': 'Banco',
        'key': 'bankCode',
        'type': 'select',
        'data': dataBanks
      },
      {
        'name': 'N° Operación',
        'key': 'publicId',
        'type': 'text'
      },
      {
        'name': 'Fecha',
        'key': 'range',
        'type': 'range_date'
      },
      {
        'name': 'País',
        'key': 'CountryCode',
        'type': 'select',
        'data': this.countries
      },
      {
        'name': 'Descargar',
        'key': 'download',
        'type': 'button'
      }

    ];

  }

  changeDataTable(data) {
    let from_date = null;
    let to_date = null;
    let isDownload = data.type && data.type === 'download';
    this.page = 1;
    
    const { range, ...rest } = isDownload ? data.data : data;
  
    if (range && range.length > 0) {
      from_date = this.formatDate(range[0]);
      to_date = this.formatDate(range[1]);
    }
  
    if (isDownload) {
      this.downloadReport({ ...rest, from_date, to_date, type: data.type });
    } else {
      this.filterTable = { ...rest, from_date, to_date };
      this.allOperationsCashOutData = this.allOperationsCashOut();
    }
  }

  downloadReport(dat) {
    let data = {
      service: 'listAllOperationCashOut/download',
      data: dat
    }
    
    const fromDate = moment( dat.from_date + 'T00:00:00');
    const toDate = moment(  dat.to_date + 'T00:00:00');
    var months = fromDate.diff(toDate, 'months');
    if (months < 0) {months =  months * -1;}
    if(months > environment.maxMonthsSelect ){
      this.modal.error({
        nzTitle: 'Rango de fecha no valido',
        nzContent: 'El rango de fecha no debe ser mayor a ' + environment.maxMonthsSelect + ' meses'
      });
      return 
    }
    this.api.api(data);
  }

  private formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  onChangePageIndex(value) {
    this.page = value;
    this.filterTable.page = this.page;
    this.allOperationsCashOutData = this.allOperationsCashOut();
  }

  onChangeItemPage(value: number) {
    this.pagePer = value;
    this.filterTable.size = this.pagePer;
    this.allOperationsCashOutData = this.allOperationsCashOut();
  }

}
