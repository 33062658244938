import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CashoutCurrency, IMerchant, UpdateCurrency, UpdateSettings } from "../../interfaces/merchant";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { NzMessageService, NzModalService } from "ng-zorro-antd";
import { MerchantService } from "../../../services/merchant.service";
import { LoaderService } from "../../../services/loader.service";
import { Currency } from "../../../models/currency.interface";

@Component({
    selector: 'app-settings-merchant',
    templateUrl: './settings-merchant.component.html',
    styleUrls: ['./settings-merchant.component.scss']
})
export class SettingsMerchantComponent implements OnInit, OnChanges {
    @Output() updateMerchant = new EventEmitter<any>();
    @Input() merchant: IMerchant;
    @Input() currencies: Currency[] = [];
    currenciesNotAvailable: string[] = [];
    mapOfExpandData: { [key: string]: boolean } = {};
    listCurrenciesCashIn = [];
    listChannels = [];
    listCurrenciesCashOut: CashoutCurrency[] = [];
    formSettings = new FormGroup({});
    editCache: { [key: string]: { edit: boolean; data: CashoutCurrency } } = {};
    isVisibleModelVtex= false;
    formAppKeyVtex = new FormGroup({});
    vtexLabelButton = 'Create Token Vtex';

    constructor(
        private modalService: NzModalService,
        private merchantService: MerchantService,
        private loaderService: LoaderService,
        private message: NzMessageService
    ) {
    }

    ngOnInit() {
        this.initFormSettings();

        this.formAppKeyVtex =  new FormGroup({
                                    labelVtex: new FormControl('',[Validators.required, Validators.min(5)])
                                });
    }

    ngOnChanges(changes: SimpleChanges) {
        let merchant: IMerchant;

        if (changes.merchant) {
            merchant = changes.merchant.currentValue;
        }

        if (merchant && merchant.settings
            && merchant.settings.cashin.listCurrencies) {
            this.listCurrenciesCashIn = merchant.settings.cashin.listCurrencies;
            this.listChannels = Object.keys(merchant.settings.cashin.channel).map(key => ({channel: key, value: merchant.settings.cashin.channel[key]}));
            this.loadCurrencies('cashin', this.listCurrenciesCashIn);
            this.loadChannels();
        }

        if (merchant && merchant.settings
            && merchant.settings.cashout.listCurrencies
            && merchant.settings.cashout.listCurrencies.length > 0) {
            this.listCurrenciesCashOut = merchant.settings.cashout.listCurrencies;
            this.currenciesNotAvailable = this.listCurrenciesCashOut.map(currency => currency.currencyCode);
            this.loadCurrencies('cashout', this.listCurrenciesCashOut);
            this.updateEditCache();
        }

        if (merchant && merchant.infoMerchant) {
            this.Crossborder.setValue(merchant.infoMerchant.supportCrossBorder, {emitEvent: false, emitViewToModelChange: false});
            this.ActiveCashin.setValue(merchant.serviceActive.activeCashin, {emitEvent: false, emitViewToModelChange: false});
            this.ActiveCashout.setValue(merchant.serviceActive.activeCashout, {emitEvent: false, emitViewToModelChange: false});
            this.GroupCheckout.setValue(merchant.infoMerchant.groupCheckout, {emitEvent: false, emitViewToModelChange: false});
            this.AutomaticTransferCashout.setValue(merchant.infoMerchant.automaticTransferCashout, {emitEvent: false, emitViewToModelChange: false});
            this.AutomaticFeeCashout.setValue(merchant.infoMerchant.automaticFeeCashout, {emitEvent: false, emitViewToModelChange: false});
            this.ActiveEDT.setValue(merchant.infoMerchant.activeEDT, {emitEvent: false, emitViewToModelChange: false});
            this.AprovalCleanNumberAccount.setValue(merchant.infoMerchant.aprovalCleanNumberAccount, {emitEvent: false, emitViewToModelChange: false});
            this.CashoutRequireAproval.setValue(!!merchant.settings.cashout.requireAproval, {emitEvent: false, emitViewToModelChange: false});

            this.changeStatusOptionsCashOut(!!merchant.serviceActive.activeCashout);
            this.changeStatusOptionsCashIn(!!merchant.serviceActive.activeCashin);
        }
    }

    initFormSettings() {
        this.formSettings = new FormGroup({
            crossborder: new FormControl(false),
            cashInCurrencies: new FormGroup({}),
            activeCashin: new FormControl(false),
            activeCashout: new FormControl(false),
            cashOutCurrencies: new FormArray([]),
            channels: new FormGroup({}),
            groupCheckout: new FormControl(false),
            cashoutRequireAproval: new FormControl(false),
            aprovalCleanNumberAccount: new FormControl(false),
            activeEDT: new FormControl(false),
            automaticFeeCashout: new FormControl(false),
            automaticTransferCashout: new FormControl(false),
        });

        this.formSettings.get('activeCashout').valueChanges.subscribe(value => {
            this.changeStatusOptionsCashOut(value);
        })
        this.formSettings.get('activeCashin').valueChanges.subscribe(value => {
            this.changeStatusOptionsCashIn(value);
        })
    }

    changeStatusOptionsCashOut(status: boolean) {
        if (!status) {
            this.formSettings.get('cashoutRequireAproval').disable()
            this.formSettings.get('automaticFeeCashout').disable()
            this.formSettings.get('automaticTransferCashout').disable()
            this.formSettings.get('aprovalCleanNumberAccount').disable()
        } else {
            this.formSettings.get('cashoutRequireAproval').enable()
            this.formSettings.get('automaticFeeCashout').enable()
            this.formSettings.get('automaticTransferCashout').enable()
            this.formSettings.get('aprovalCleanNumberAccount').enable()
        }
    }

    changeStatusOptionsCashIn(status: boolean) {
        if (!status) {
            this.formSettings.get('crossborder').disable()
            this.formSettings.get('activeEDT').disable()
            this.formSettings.get('groupCheckout').disable()
        } else {
            this.formSettings.get('crossborder').enable()
            this.formSettings.get('activeEDT').enable()
            this.formSettings.get('groupCheckout').enable()
        }
    }

    getUserDetail() {
        const user = JSON.parse(sessionStorage.getItem('ud') || '{}');
        return user.userDetails || {};
    }

    toggleCurrencyCashIn(value, currencyCode) {
        const metadata = JSON.stringify(this.getUserDetail());
        const data: UpdateCurrency = { currencyCode, status: value, type: 1, maxAmount: '200', minAmount: '0', metadata };
        this.merchantService.updateCurrency(this.merchant.infoMerchant.merchantCode, data).subscribe(
            () => {
                this.message.create('success', 'Update currency success');
                this.updateMerchant.emit();
            });
    }

    toggleChannel(value: boolean, channel) {
        const data = { paymentChannelId: channel.value.paymentChannelId, active: value };
        this.merchantService.updateChannelStatus(this.merchant.infoMerchant.merchantCode, data).subscribe(
            (res) => {
                console.log(res)
            });
    }

    startEdit(id: string, form: AbstractControl): void {
        this.editCache[id].edit = true;
        form.get('currencyCode').disable({emitEvent: false, onlySelf: false});
    }

    saveEdit(key: string, data: any): void {
        this.editCache[key].edit = false;
        this.updateCurrencyCashOut(data);
    }

    updateCurrencyCashOut(values) {
        const metadata = JSON.stringify(this.getUserDetail());
        const data: UpdateCurrency = { currencyCode: values.currencyCode, status: values.isActive, type: 2, maxAmount: values.maxAmount , minAmount: values.minAmount, metadata };
        this.merchantService.updateCurrency(this.merchant.infoMerchant.merchantCode, data).subscribe(
            () => {
                this.message.create('success', 'Update currency success');
                const index = this.listCurrenciesCashOut.findIndex(item => item.merchantFinanceSettingID === '-1');
                this.listCurrenciesCashOut.splice(index, 1);
                this.updateMerchant.emit();
            });
    }

    cancelEdit(id: string): void {
        if(id === '-1') {
            this.listCurrenciesCashOut = [...this.listCurrenciesCashOut.slice(0, this.listCurrenciesCashOut.length - 1)];
            this.updateEditCache();
            this.FormCashOutCurrencies.removeAt(this.FormCashOutCurrencies.length - 1);
            return;
        }

        const index = this.listCurrenciesCashOut.findIndex(item => item.merchantFinanceSettingID === id);
        this.editCache[id] = {
            data: { ...this.listCurrenciesCashOut[index] },
            edit: false
        };
    }

    updateEditCache(): void {
        this.editCache = {};
        this.listCurrenciesCashOut.forEach(item => {
            this.editCache[item.merchantFinanceSettingID] = {
                edit: false,
                data: { ...item }
            };
        });
    }

    changeCheckbox(value, controlName) {
        this.loaderService.updateLoading(true)
        const data: UpdateSettings = {
            cashoutRequireAproval: this.formSettings.get('cashoutRequireAproval').value,
            aprovalCleanNumberAccount: this.formSettings.get('aprovalCleanNumberAccount').value,
            activeEDT: this.formSettings.get('activeEDT').value,
            groupCheckout: this.formSettings.get('groupCheckout').value,
            automaticFeeCashout: this.formSettings.get('automaticFeeCashout').value,
            automaticTransferCashout: this.formSettings.get('automaticTransferCashout').value,
            activeCashin: this.formSettings.get('activeCashin').value,
            activeCashout: this.formSettings.get('activeCashout').value,
        }

        data[controlName] = value;

        this.merchantService.updateSettings(this.merchant.infoMerchant.merchantCode, data).subscribe(
            (res) => {
                this.loaderService.updateLoading(false)
                this.updateMerchant.emit(res);
            }, () => {
                this.loaderService.updateLoading(false)
            })
    }

    toggleCrossBorder(value) {
        const title = value ? '¿Está seguro de activar el servicio de pagos en otro país?' : '¿Está seguro de desactivar el servicio de pago en otro país?';
        const content = value ? 'Todos las monedas disponibles quedaran activas por defecto, puede desactivar las que no requiera' : 'Si actualmente tiene monedas de otros países activas, se desactivarán automáticamente';
        this.modalService.confirm({
            nzTitle: title,
            nzContent: content,
            nzOnOk: () => {
                this.Crossborder.setValue(value, {emitEvent: true, emitViewToModelChange: false});
                this.loaderService.updateLoading(true)
                this.merchantService.updateCrossborder(this.merchant.infoMerchant.merchantCode, value).subscribe(
                    () => {
                        this.updateMerchant.emit(value);
                    },
                    () => {
                        this.loaderService.updateLoading(false)
                    }
                );
            },
            nzOnCancel: () => {
                this.Crossborder.setValue(!value, {emitEvent: true, emitViewToModelChange: false});
            }
        });
    }

    initFormCurrency(): FormGroup {
        return new FormGroup({
            isActive: new FormControl(true),
            minAmount: new FormControl(0, [Validators.required]),
            maxAmount: new FormControl(0, [Validators.required]),
            currencyCode: new FormControl('',  [Validators.required]),
        });
    }

    upperCase(value, controlName, form) {
        form.get(controlName).setValue(value.toUpperCase(), {emitEvent: false, emitViewToModelChange: false});
    }

    initCurrency(): any {
        return {
            isActive: true,
            minAmount: '',
            maxAmount: '',
            currencyCode: '',
            merchantFinanceSettingID: '-1',
        };
    }

    addRowCurrency() {
        this.listCurrenciesCashOut = [...this.listCurrenciesCashOut, this.initCurrency()];
        this.editCache[this.initCurrency().merchantFinanceSettingID] = {edit: true, data: this.initCurrency()}
        const control = this.formSettings.get('cashOutCurrencies') as FormArray;
        control.push(this.initFormCurrency());
    }

    addCurrency(nameFormGroup, currency): void {
        if (nameFormGroup === 'cashInCurrencies') {
            const refCurrencies = this.formSettings.get(nameFormGroup) as FormGroup;
            refCurrencies.addControl(currency.currencyCode, new FormControl(currency.active));
        }
        if (nameFormGroup === 'cashOutCurrencies') {
            const refCurrencies = this.formSettings.get(nameFormGroup) as FormArray;
            refCurrencies.push(this.initFormCurrency());
        }
    }

    loadCurrencies(type, listCurrencies) {
        if (type === 'cashin') {
            for (const currency of listCurrencies) {
                this.addCurrency('cashInCurrencies', currency);
            }
        }
        if (type === 'cashout') {
            for (const currency of listCurrencies) {
                this.addCurrency('cashOutCurrencies', currency);
            }
            this.formSettings.get('cashOutCurrencies').patchValue(listCurrencies);
        }
    }

    loadChannels() {
        const refChannels = this.formSettings.get('channels') as FormGroup;
        for (const channel of this.listChannels) {
            refChannels.addControl(channel.channel, new FormControl(channel.value.active));
        }
    }

    getValueControlOfFormArray(formArray: FormArray, index: number, controlName: string): any {
        return formArray.at(index).get(controlName).value;
    }

    get isDisabled(): boolean {
        return this.listCurrenciesCashOut.findIndex(item => item.merchantFinanceSettingID === '-1') !== -1;
    }

    get Crossborder(): FormControl {return this.formSettings.get('crossborder') as FormControl}
    get ActiveCashin(): FormControl {return this.formSettings.get('activeCashin') as FormControl}
    get ActiveCashout(): FormControl {return this.formSettings.get('activeCashout') as FormControl}
    get GroupCheckout(): FormControl {return this.formSettings.get('groupCheckout') as FormControl}
    get CashoutRequireAproval(): FormControl {return this.formSettings.get('cashoutRequireAproval') as FormControl}
    get ActiveEDT(): FormControl {return this.formSettings.get('activeEDT') as FormControl}
    get AprovalCleanNumberAccount(): FormControl {return this.formSettings.get('aprovalCleanNumberAccount') as FormControl}
    get AutomaticFeeCashout(): FormControl {return this.formSettings.get('automaticFeeCashout') as FormControl}
    get AutomaticTransferCashout(): FormControl {return this.formSettings.get('automaticTransferCashout') as FormControl}

    get FormCashOutCurrencies(): FormArray {return this.formSettings.get('cashOutCurrencies') as FormArray}

    modalVtexCancel(){
        this.isVisibleModelVtex =false;
        this.formAppKeyVtex.get('labelVtex').setValue('');
    }

    modalVtexOk(){
        if(this.formAppKeyVtex.valid){
            const labelVtex = this.formAppKeyVtex.get('labelVtex').value;
            this.merchantService.createAppToken(labelVtex, this.merchant.infoMerchant.merchantCode.toString()).subscribe(resul =>{
                this.modalService.success({
                    nzTitle: 'Toke and Key Created',
                    nzContent: `<b>the AppToken is:</b></br> <stong>${resul.data.appToken}</stong></br> <b>AppKey is:</b></br> <stong>${resul.data.appKey}</stong>`
                  });
                this.formAppKeyVtex.get('labelVtex').setValue('');
                this.isVisibleModelVtex =false;
            });
        }
    }

    validateMerchantTypeVtex():boolean{
        if(this.merchant.infoMerchant.merchantTypeID == "10" || this.merchant.infoMerchant.merchantTypeID == "11"){
            return true;
        }
        return false;
    }

    validateExistVtexToken():boolean{
        if(this.merchant.infoMerchant.appKeyVtext){
            this.vtexLabelButton = 'Update Token Vtex';
            return true;
        }
        this.vtexLabelButton = 'Create Token Vtex';
        return false;
    }
}
