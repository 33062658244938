import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from 'moment';
import { ApiService } from "src/app/services/api.service";
import { CountriesService } from "src/app/services/countries.service";
import { environment } from 'src/environments/environment';
import { BankService } from "../../../../services/bank.service";

interface filterDataCashout {
  type: string;
  orderBy: string;
  dateStart: string;
  dateEnd: string;
  countryCode: string;
  merchantId: number;
  bankCode?: string;
  page: number;
  paginate: number;
}

@Component({
  selector: 'app-transation-cashout',
  templateUrl: './transation-cashout.component.html',
  styleUrls: ['./transation-cashout.component.scss']
})
export class TransationCashoutComponent implements OnInit, OnDestroy {
  public allOperationsCashoutData;
  public filters;

  private countryEnable;
  private timerRefresh;

  public totalItems = 0;
  public pagePer = 10;
  protected page = 1;
  private filterTable: filterDataCashout

  protected listCurrenciesCashOut: any[] = []
  protected mapOfExpandData: { [key: string]: boolean } = {};
  protected mapOfLoad: { [key: string]: boolean } = {};
  protected mapOfExpandBanks: { [key: string]: any } = {};
  protected editCache: { [key: string]: { edit: boolean; data: any } } = {};
  protected isLoading = false;

  constructor(private api: ApiService,
    private countriesService: CountriesService,
    private router: Router,
    private bankService: BankService
    ) {

  }

  ngOnDestroy(): void {
    clearInterval(this.timerRefresh);
  }

  ngOnInit(): void {
    this.countriesService.countries$.subscribe(countries => {
      if (countries.length === 0) {
        this.countriesService.getCountries();
      } else {
        this.countryEnable = countries;
      }
      this.filters = this.getFilter();
    })
    this.filterTable = { type: 'merchants', orderBy: 'totalAmountTransactions', dateStart: moment().date(new Date().getUTCDate()).format('YYYY-MM-DD'), dateEnd: moment().date(new Date().getUTCDate()).format('YYYY-MM-DD'), countryCode: null, merchantId: null, page: this.page, paginate: this.pagePer };
    this.allOperationsCashoutData = this.showDataMonitor(this.filterTable);
    this.timerRefresh = setInterval(this.refreshMonitorData.bind(this, this.filterTable), environment.refreshMonitorTimer);
  }

  public refreshMonitorData(data = null) {
    this.allOperationsCashoutData = this.showDataMonitor(data);
  }

  public async downloadFileMonitor(filter: filterDataCashout) {
    let data: object;
    if (!filter) {
      data = {
        service: 'monitorCashoutExcel',
        data: {
          type: "merchants",
          orderBy: "totalAmountTransactions"
        }
      };
    } else {
      data = {
        service: 'monitorCashoutExcel',
        data: {
          type: filter.type,
          orderBy: filter.orderBy,
          dateStart: filter.dateStart,
          dateEnd: filter.dateEnd,
          countryCode: filter.countryCode,
          merchantId: filter.merchantId
        }
      };
    }

    this.api.api(data).toPromise().then(
      (response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', new Date().toDateString());
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  public async showDataMonitor(filter: filterDataCashout) {
    this.isLoading = true;
    let data: object;
    if (!filter) {
      data = {
        service: 'monitorCashout',
        data: {
          type: "merchants",
          orderBy: "totalAmountTransactions",
          page: this.page,
          paginate: this.pagePer
        }
      };
    } else {
      data = {
        service: 'monitorCashout',
        data: {
          type: filter.type,
          orderBy: filter.orderBy,
          dateStart: filter.dateStart,
          dateEnd: filter.dateEnd,
          countryCode: filter.countryCode,
          merchantId: filter.merchantId,
          page: this.page,
          paginate: this.pagePer,
          ...filter
        }
      };


    }


    let responseMonitorCashout: any;
    responseMonitorCashout = await this.api.api(data).toPromise();
    this.isLoading = false;

    let index = 0;
    responseMonitorCashout.data.forEach(response => {
      responseMonitorCashout.data[index].totalAmountTransactions = response.totalAmountTransactions + ' ' + response.symbol;
      responseMonitorCashout.data[index].totalCommissions = response.totalCommissions + ' ' + response.symbol;
      index++;
    })

    this.totalItems = responseMonitorCashout.meta.total;
    this.pagePer = responseMonitorCashout.meta.per_page;

    return {
      data: responseMonitorCashout.data,
      source: 'monitorCashout'
    };
  }

  private async getFilter(filterby: string = 'merchants') {
    const data = {
      service: filterby
    };
    const orderBy = [{ name: 'Nun. Transacciones', value: 1 }, { name: 'Monto Transacciones', value: 2 }];
    const typeUser = [{ name: 'Shopper', value: 1 }, { name: 'Merchant', value: 2 }];
    const dataMerchant = [];


    await this.api.api(data).toPromise().then((result: any) => {

      if (filterby === 'merchants') {
        result.forEach(element => {

          dataMerchant.push({
            name: element.BussinesName,
            value: element.MerchantID,
          });

        });
      } else {
        result.data.forEach(element => {
          dataMerchant.push({
            name: element.FullName,
            value: element.CustomerID,
          });
        });
      }
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });


    let nameMerchantShopper = 'Merchant';
    if (filterby === 'shopper') nameMerchantShopper = 'Shopper';

    data.service = 'bank'

    const bankEnable = [];

    await this.api.api(data).toPromise().then((result: any) => {
      result.forEach(element => {

        bankEnable.push({
          name: element.LegalName,
          value: element.BankCode,
        });

      });
    });

    return [
      {
        'name': 'País',
        'key': 'countryCode',
        'type': 'select',
        'data': this.countryEnable
      },
      {
        'name': 'Tipo ',
        'key': 'typeShop',
        'type': 'typeMerchant',
        'data': typeUser,
        'value': 2
      },
      {
        'name': nameMerchantShopper,
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },

      {
        'name': 'Fecha',
        'key': 'range',
        'type': 'range_date'
      },
      {
        'name': 'Banco ',
        'key': 'bankCode',
        'type': 'select',
        'data': bankEnable
      },
      {
        'name': 'Ordenado por ',
        'key': 'order',
        'type': 'select',
        'data': orderBy
      },


      {
        'name': '',
        'key': 'download',


      }

    ];
  }

  async changeDataTable(data) {
    let type = 'merchants';
    let orderBy = 'totalAmountTransactions';
    let dateStart = null;
    let dateEnd = null;
    let countryCode = null;
    let merchantId = null;
    let bankCode = null;

    if (data.type) { //download file
      if (data.data.typeShop) {
        if (data.data.typeShop == 1) {
          type = 'customer';
        } else {
          type = 'merchants';
        }
      }
      if (data.data.order) {
        if (data.data.order === 1) {
          orderBy = 'totalsTransactions'
        } else {
          orderBy = 'totalAmountTransactions'
        }
      }
      if (data.data.range) {
        dateStart = this.formatDate(data.data.range[0]);
        dateEnd = this.formatDate(data.data.range[1]);
      }
      if (data.data.countryCode) {
        countryCode = data.data.countryCode;
      }

      if (data.mcode) {
        merchantId = data.mcode;
      }

      if (data.bankCode) {
        bankCode = data.bankCode;
      }

      this.filterTable = { type, orderBy, dateStart, dateEnd, countryCode, merchantId, bankCode, page: this.page, paginate: this.pagePer };
      this.downloadFileMonitor(this.filterTable);
      this.allOperationsCashoutData = this.showDataMonitor(this.filterTable);
    } else { //show table
      if (data.typeShop) {
        if (data.typeShop == 1) {
          type = 'customer';
        } else {
          type = 'merchants';
        }
      }
      if (data.order) {
        if (data.order === 1) {
          orderBy = 'totalsTransactions'
        } else {
          orderBy = 'totalAmountTransactions'
        }
      }
      if (data.range) {
        dateStart = this.formatDate(data.range[0]);
        dateEnd = this.formatDate(data.range[1]);
      }
      if (data.countryCode) {
        countryCode = data.countryCode;
      }

      if (data.mcode) {
        merchantId = data.mcode;
      }

      if (data.bankCode) {
        bankCode = data.bankCode;
      }
      this.filterTable = { type, orderBy, dateStart, dateEnd, countryCode, merchantId, bankCode, page: this.page, paginate: this.pagePer };
      this.allOperationsCashoutData = this.showDataMonitor(this.filterTable);

      clearInterval(this.timerRefresh);
      this.timerRefresh = setInterval(this.refreshMonitorData.bind(this, this.filterTable), environment.refreshMonitorTimer);
    }

  }

  functions(func) {
    this.router.navigate(['/operations-cashout', { st: func.function, n: func.data.name, c: func.data.country }]);
  }


  onChangeType($event) {
    if ($event == 1) {
      this.filters = this.getFilter('shopper');
    } else {
      this.filters = this.getFilter();
    }
  }

  private formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  onChangePageIndex() {
    this.filterTable.page = this.page;
    this.allOperationsCashoutData = this.showDataMonitor(this.filterTable);
  }

  onChangeItemPage(value: number) {
    this.pagePer = value;
    this.filterTable.paginate = this.pagePer;
    this.allOperationsCashoutData = this.showDataMonitor(this.filterTable);
  }

  openExpandRow(id: string, data: any) {     
    this.mapOfExpandData[id] = !this.mapOfExpandData[id]
    if (!!this.mapOfExpandData[id]) {
      this.mapOfLoad[id] = true
      if (data.bankFilters) {
        this.bankService.getMonitorBanks(data.bankFilters).subscribe((response) => {
          this.mapOfLoad[id] = false
          this.mapOfExpandBanks[id] = response.data
        }, () => {
          this.mapOfLoad[id] = false
        })
      }
    } 
    }

    redirect(row2: any, row: any, type: string) {
      this.router.navigate(['/operations-cashout'], { queryParams: { bankCode: row2.BankCode, countryCode: row2.CountryCode, merchantCode: row.merchantCode, dateStart: row.bankFilters.dateStart, dateEnd: row.bankFilters.dateEnd, status: this.getStatusNumber(type) } });
    }

    getStatusNumber(status: string) {
      switch (status) {
        case 'completedTransactions':
          return 14;
        case 'expiredTransactions':
          return 0;
        case 'notificationError':
          return 16;
        case 'pendingTransactions':
          return 11;
        case 'returnedTransactions':
          return 18;
        default:
          return '';
      }
    }
       
}