import { Validators } from "@angular/forms";
import { FormField } from "../interfaces/form";

export class InfoServiceForm {
    static getForm(values: any = {}): FormField[] {
        return [
            {
                label: 'Provider ID',
                key: 'providerID',
                value: values && values.providerID || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'providerID',
                placeholder: 'Provider ID',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'Service Code',
                key: 'serviceCode',
                value: values && values.serviceCode || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'serviceCode',
                placeholder: 'Service Code',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'Provider service code',
                key: 'providerServiceCode',
                value: values && values.providerServiceCode || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'providerServiceCode',
                placeholder: 'Provider service code',
                type: 'text',
                validations:  [],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'Url logo',
                key: 'url_logo',
                value: values && values.url_logo || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'url_logo',
                placeholder: 'Url logo',
                type: 'text',
                validations:  [],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'Type',
                key: 'type',
                value: values && values.type || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'type',
                placeholder: 'Type',
                type: 'select',
                options:  [],
                validations:  [],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'Status',
                key: 'isActive',
                value: values && values.isActive || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'isActive',
                placeholder: 'Status',
                type: 'switch',
                validations:  [ ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'With confirmation',
                key: 'with_confirmation',
                value: values && values.with_confirmation || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'with_confirmation',
                placeholder: 'With confirmation',
                type: 'switch',
                validations:  [ ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 12,
            },
            {
                label: 'Service description',
                key: 'serviceDescription',
                value: values && values.serviceDescription || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'serviceDescription',
                placeholder: 'Service description',
                type: 'textarea',
                validations:  [ ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 24,
            },
            // {
            //     label: 'Metadata',
            //     key: 'metadata',
            //     value: values && values.metadata || '',
            //     disabled: false,
            //     readonly: false,
            //     visible: true,
            //     formControlName: 'metadata',
            //     placeholder: 'Metadata',
            //     type: 'textarea',
            //     validations:  [ ],
            //     errors: [
            //         { error: 'required', message: 'Campo requerido'},
            //     ],
            //     span: 24,
            //     mdSpan: 24,
            // },
        ]
    }
}
