import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AccessService } from '../../../../services/access.services';
import { CountriesService } from "../../../../services/countries.service";
import { LoaderService } from '../../../../services/loader.service';

@Component({
  selector: 'app-cashout',
  templateUrl: './cashout.component.html',
  styleUrls: ['./cashout.component.scss']
})
export class CashoutComponent implements OnInit, OnDestroy {
  public allOperationsCashOutData: any
  public filters: any = this.getFilters();
  public titlePage: string = 'Operaciones > Cash Out';
  protected titleModal = '';
  protected isVisibleTokenModal = false;
  protected showQrGoogle = false;
  protected qrAuthGoogle = '';
  protected rejectReason = '';
  protected isOkLoading = false;
  public countries: any[] = [];
  protected totalItems = 0;
  protected pagePer = 10;
  protected page = 1;
  private filterTable;
  private destroy$ = new Subject();
  protected showModalToken = false;
  protected dataItem;
  protected isLoadingModal = false;
  protected otpControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);
  protected params: {[key: string]: string} = {};

  constructor(
    private api: ApiService, 
    private countriesService: CountriesService, 
    private router: Router,
    private nzModalService: NzModalService,
    private accessService: AccessService,
    private loaderService: LoaderService,
    ) {
    this.countriesService.countries$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(countries => {
      if (countries.length === 0) {
        this.countriesService.getCountries();
      } else {
        this.countries = countries;
      }
    })
  }

  ngOnInit() {
    this.params = this.getUrlParams();    
      this.filterTable = {
        from_date: this.params.dateStart ? this.params.dateStart : moment().startOf('month').format('YYYY-MM-DD'), 
        to_date: this.params.dateEnd ? this.params.dateEnd : moment().format('YYYY-MM-DD'),
        page: this.page, 
        size: this.pagePer,
        bankCode: this.params.bankCode || null,
        CountryCode: this.params.countryCode || null,
        mcode: this.params.merchantCode || null,
        status: this.params.status || null,
        MerchantReference: this.params.reference || null,

      };
      this.allOperationsCashOutData = this.allOperationsCashOut();

      
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }



  async allOperationsCashOut(): Promise<any> {


    const headers = [
      {
        'name': 'Operación',
        'key': 'PublicID',
        'opc': true,
        'type': 'link'
      },
      {
        'name': 'Referencia',
        'key': 'MerchantReference',
        'opc': true,
        responsive: true,
      },
      {
        'name': 'Cliente',
        'key': 'Customer.Info.FullName',
        'opc': true,
        responsive: true
      },
      {
        'name': 'Documento',
        'key': 'Customer.Info.DocNumber',
        'opc': true,
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Banco',
        'key': 'Customer.InfoBank.CustomerBank',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'TxCreation',
        'opc': true
      },
      {
        'name': 'Fecha de depósito',
        'key': 'PaymementDate',
        'opc': true
      },
      {
        'name': 'Comentario',
        'key': 'ResponseBank',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        'opc': true,
        type: 'status',
        responsive: true
      },
      {
        'name': 'Opciones',
        'key': 'list',
        'opc': true,
        'functions': [
            'Extornar',
            'Aprobar',
            'Rechazar',
            'Anular'
        ],
    },
    ];

    const { data: response, ...pagination } = await this.api.queryGet('cashout/operations', this.filterTable).toPromise() as any;
    this.totalItems = pagination.total;
    this.pagePer = pagination.per_page;
        
    return {
      headers: headers,
      data: await Promise.resolve(response),
      source: 'operation-cashout',
      getUrl: (id) => `/operation-cashout/${id}`
    };

  }



  async getFilters() {

    let data = {
      service: 'merchants'
    }

    let dataStatus = this.api.getStatusCashOut();


    let dataMerchant = [];
    let dataBanks = [];

    await this.api.api({
      service: 'listBanksCashout'
    }).toPromise().then((result: any) => {

      result.forEach(element => {
        dataBanks.push({
          name: element.ShortName,
          value: element.BankCode,
        });
      });
    }).catch(error => {
      if(error.status == 401){
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });


    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [
      {
        'name': 'Documento',
        'key': 'DocNumber',
        'type': 'text'
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus,
        value: this.params['status'] ? this.params['status'] : ''
      },
      {
        'name': 'Banco',
        'key': 'bankCode',
        'type': 'select',
        'data': dataBanks,
        value: this.params['bankCode'] ? Number(this.params['bankCode']) : ''
      },
      {
        'name': 'N° Operación',
        'key': 'publicId',
        'type': 'text'
      },
      {
        'name': 'Fecha',
        'key': 'range',
        'type': 'range_date',
        'value': this.params['dateStart'] ? [ moment(this.params['dateStart'] ).toISOString(), moment(this.params['dateEnd'] ).toISOString()] : [moment().startOf('month').toISOString(), moment().toISOString()]
      },
      {
        'name': 'País',
        'key': 'CountryCode',
        'type': 'select',
        'data': this.countries,
        value: this.params['countryCode'] ? this.params['countryCode'] : ''
      },
      {
        'name': 'Referencia del comercio',
        'key': 'MerchantReference',
        'type': 'text',
        value: this.params['reference'] ? this.params['reference'] : ''
      },
      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant,
        value: this.params['merchantCode'] ? Number(this.params['merchantCode']) : ''
      },
      {
        'name': 'Nombre',
        'key': 'ClientName',
        'type': 'text'
      },
    ];
  }

  changeDataTable(data) {
    const { range, ...rest} = data;
    
    if (range) {
      this.filterTable.from_date = this.formatDate(range[0]);
      this.filterTable.to_date = this.formatDate(range[1]);
    }
    this.filterTable = {...this.filterTable, ...rest, page: 1};
    this.page = 1;
    this.allOperationsCashOutData = this.allOperationsCashOut();
  }

  onChangePageIndex(pageIndex) {
    this.page =  pageIndex;
    this.filterTable.page = pageIndex;
    this.allOperationsCashOutData = this.allOperationsCashOut();
  }

  onChangeItemPage(totalItems){    
    this.pagePer = totalItems;
    this.filterTable.size = totalItems;
    this.allOperationsCashOutData = this.allOperationsCashOut();
  }

  functions(data) {    
    this.dataItem = data.data;
    this.loaderService.updateLoading(true);
    this.validateTokenGoogle();
    if (data.function == 'Extornar') {
      this.titleModal = 'Extornar';
    }
    if (data.function == 'Aprobar') {
      this.titleModal = 'Aprobar';
    }
    if (data.function == 'Rechazar') {
      this.titleModal = 'Rechazar';
    }
    if (data.function == 'Anular') {
      this.titleModal = 'Anular';
    }
  }

  validateTokenGoogle() {
    this.accessService.isUseTokenGoogle().subscribe(resul => {
      if(!resul.data){
        this.showQrGoogle = true;
        this.accessService.getQrGoogle(false).subscribe(resulQr => {
          this.qrAuthGoogle = resulQr.data;
          this.isVisibleTokenModal = true;
          this.loaderService.updateLoading(false);
        });
      }else{
        this.isVisibleTokenModal = true;
        this.showQrGoogle = false;
        this.loaderService.updateLoading(false);
      }
    }, (error) => {
      this.isVisibleTokenModal = false;
      this.loaderService.updateLoading(false);
      if (error.status === 401) {
        this.error('Sesión expirada')
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }       
    })
  }

  error(error): void {
    this.nzModalService.error({nzTitle: 'Mensaje de error', nzContent: error});
  }

  resetQr(){
    this.showQrGoogle = true;
    this.accessService.getQrGoogle(true).subscribe(resulQr => {
      this.qrAuthGoogle = resulQr.data;
    });
  }

  handleCancelTokenModal() {
    this.isVisibleTokenModal = false;
    this.otpControl.reset();
    this.rejectReason = '';
  }

  handleOkTokenModal() {
    this.isOkLoading = true;
    this.accessService.validateAuthToken(this.otpControl.value).subscribe(resul => {
      this.otpControl.reset();
      this.isOkLoading = false;
      this.isVisibleTokenModal = false;
      if (this.titleModal == 'Rechazar') {
        this.rejectTransaction();
      } 
      if(this.titleModal == 'Aprobar') {
        if (this.dataItem.LastStatus === 12) {
          this.approveTransaction();
        }
        if (this.dataItem.LastStatus === 10) {
          this.updateStatusTransaction(11);
        }
      }
      if(this.titleModal == 'Extornar') {
        this.extornar();
      }
      if(this.titleModal == 'Anular') {
        this.updateStatusTransaction(0);
      }
   
    }, () => {
      this.otpControl.reset();
      this.isOkLoading = false
      this.isVisibleTokenModal = false;
      this.rejectReason = '';
      this.error('Token incorrecto');
    });
  }


  closeAuthModal() {
    this.showModalToken = false;
    this.otpControl.reset();
  }

  extornar() {
    this.isLoadingModal = true;
    const data = {
      publicId: this.dataItem.PublicID,
    }    
    this.api.queryPost(`extornoTransaction`, data).subscribe((result: any) => {
      this.showModalToken = false;
      this.isLoadingModal = false;
      this.allOperationsCashOutData = this.allOperationsCashOut();
      this.nzModalService.success({
        nzTitle: 'Extorno',
        nzContent: 'Extorno realizado con éxito',
        nzOkText: 'Aceptar',
      });
    }, error => {
      this.showModalToken = false;
      this.isLoadingModal = false;
      this.nzModalService.error({
        nzTitle: 'Extorno',
        nzContent: error.message,
        nzOkText: 'Aceptar',
      });
    }
    );

  }

  approveTransaction() {
    this.loaderService.updateLoading(true);
    const url = `cashout/processTransaction/${this.dataItem.PublicID}/Transfer Ok/OK`;
    this.api.queryPost(url, {}).subscribe(
      () => {
        this.loaderService.updateLoading(false);
        this.nzModalService.success({
          nzTitle: 'Transacción aprobada',
          nzContent: 'La transacción ha sido aprobada exitosamente'
        });
        this.dataItem = null;
        this.allOperationsCashOutData = this.allOperationsCashOut();
      },
      error => {
        this.dataItem = null;
        this.loaderService.updateLoading(false);
        this.nzModalService.error({
          nzTitle: 'Error',
          nzContent: error.error.message
        });
      }
    );
  }

  rejectTransaction() {
    this.loaderService.updateLoading(true);    
    const url = `cashout/processTransaction/${this.dataItem.PublicID}/Transfer Error/${this.rejectReason}`;
    this.api.queryPost(url, {}).subscribe(
      () => {
        this.loaderService.updateLoading(false);
        this.allOperationsCashOutData = this.allOperationsCashOut();
        this.nzModalService.success({
          nzTitle: 'Transacción rechazada',
          nzContent: 'La transacción ha sido rechazada exitosamente'
        });
        this.dataItem = null;
        this.rejectReason = '';
        this.isVisibleTokenModal = false;
      },
      error => {
        this.dataItem = null;
        this.rejectReason = '';
        this.isVisibleTokenModal = false;
        this.isVisibleTokenModal = false;
        this.loaderService.updateLoading(false);
        this.nzModalService.error({
          nzTitle: 'Error',
          nzContent: error.error.message
        });
      }
    );
  }

  updateStatusTransaction(id: number) {
    this.loaderService.updateLoading(true);
    const url = `cashout/operations/update-transaction`;
    this.api.queryPost(url, {
      publicId: this.dataItem.PublicID,
      status: id
    }).subscribe(
      () => {
        this.loaderService.updateLoading(false);
        this.allOperationsCashOutData = this.allOperationsCashOut();
        this.nzModalService.success({
          nzTitle: `Transacción ${id === 0 ? 'anulada' : 'aprobada'}`,
          nzContent: `La transacción ha sido ${id === 0 ? 'anulada' : 'aprobada'} exitosamente`
        });
        this.dataItem = null;
        this.rejectReason = '';
        this.isVisibleTokenModal = false;
      },
      error => {
        this.dataItem = null;
        this.rejectReason = '';
        this.isVisibleTokenModal = false;
        this.isVisibleTokenModal = false;
        this.loaderService.updateLoading(false);
      }
    );
  }


  getUrlParams() {
    const url = window.location.href;
    const urlSplit = url.split('?');
    const params = {};
    if (urlSplit.length > 1) {
      const paramsSplit = urlSplit[1].split('&');
      paramsSplit.forEach(element => {
        const paramSplit = element.split('=');
        params[paramSplit[0]] = paramSplit[1];
      }
      );
    }
    return params;
  }

  private formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
 

}
