import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.scss']
})
export class SettlementsComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();
  public filters: any = this.getFilters();
  public titlePage: string = 'Reportes > Cash In > Liquidaciones';

  titleModal: any = '';
  isVisible: boolean = false;
  contentModal: any = '';
  operationNumber: any = '';
  listBanksCashin: any;


  constructor(private api: ApiService, private router: Router) { }

  ngOnInit() {
  }

  async getDataInfo(dataInfo: any = false) {
    let data = {
      service: 'settlement/list',
      data: []
    }

    let headers = [
      {
        'name': 'ID',
        'key': 'SettlementID',
        'opc': true,
        'type': 'link',
        responsive: true
      },
      {
        'name': 'Monto',
        'key': 'TotalAmount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'created_at',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'Status',
        'opc': true,
        'type': 'status',
        responsive: true
      }
    ]

    if (dataInfo !== false) {
      data.service = 'settlement/listWithParams';
      data.data = dataInfo;
    }

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'activities-cashin-sttlement',
      getUrl: (id) => `/sttlement-detail/${id}`
    }

  }



  async getFilters() {

    let data = {
      service: 'merchants'
    }
    let dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status == 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    let dataStatus = [{name: 'Creado', value: 1}, {name: 'Pagado', value: 2}];

    return [

      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus
      },
      {
        'name': 'ID Liquidaciones',
        'key': 'publicid',
        'type': 'text'
      },
      {
        'name': 'Periodo',
        'key': ['from_date', 'to_date'],
        'type': 'period'
      },
      {
        'name': 'Descargar',
        'key': 'download',
        'type': 'button'
      }

    ];

  }

  //EVENTS OUTPUT

  changeDataTable(data) {
    if (data.type && data.type === 'download') {
      this.api.queryDownload('settlement/download', data.data)
    } else {
      this.dataInfo = this.getDataInfo(data);
    }

  }

  functions(e) {
    console.log(e);
  }

  downloadReport(dat) {
    let data = {
      service: 'settlement/download',
      data: dat.data
    }
    this.api.api(data);
  }

}
