import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../../../../services/api.service";
import {Router} from "@angular/router";
import {NzModalService} from "ng-zorro-antd";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import { CountriesService } from "../../../../../services/countries.service";

enum Options {
  preview = 'Ver datos',
  process = 'Procesar',
  reject = 'Rechazar',
  allow = 'Autorizar',
  update = 'Actualizar datos',
  pay = 'Pagar',
}

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss']
})
export class RefundComponent implements OnInit {
  public destroy$ = new Subject();
  public titlePage: string = 'Actividades > Cash In > Refund';
  public filters: any;
  public dataInfo: any;
  public isVisibleUpdate: boolean = false;
  public isOkLoading: boolean = false;
  public selectedRefund: any;
  public listBanks: any[] = [];
  public countries: any[] = [];
  public formRefund: FormGroup;
  public isPIX: boolean = false;
  public refundTypes: any[] = [
    {label: 'TRANSFER', value: 'TRANSFER'},
    {label: 'PIX', value: 'PIX'}
  ];
  public types: any[] = [
    {label: 'Total', value: 1},
    {label: 'Parcial', value: 2},
  ];
  public keyTypes: any[] = [
    {label: 'Correo', value: 'correo'},
    {label: 'Teléfono', value: 'telefono'},
    {label: 'Alter key', value: 'alter key'},
    {label: 'CPF/CNP', value: 'CPF/CNP'},
  ];
  accountTypes: any[] = [
    {label: 'Ahorros', value: 'ahorros'},
    {label: 'Corriente', value: 'corriente'},
  ];
  public disabled = false;

  constructor(
      private api: ApiService,
      private router: Router,
      private modal: NzModalService,
      private fb: FormBuilder,
      private countriesService: CountriesService
  ) {
    this.countriesService.countries$.subscribe(countries => {
      if (countries.length === 0) {
        this.countriesService.getCountries();
      } else {
        this.countries = countries;
      }
    })
  }

  ngOnInit() {
    this.filters = this.getFilters()
    this.dataInfo = this.getDataInfo();
    this.createForm()
  }

  createForm() {
    this.formRefund = this.fb.group({
      FullName: ['', [Validators.required]],
      DocumentNumber: ['', [Validators.required]],
      DocumentType: ['', [Validators.required]],
      Phone: ['', [Validators.required]],
      Email: ['', [Validators.required, Validators.email]],
      TypeDeposit: [null, [Validators.required]],
      Type: [null, [Validators.required]],
      BankName: [null, [Validators.required]],
      Amount: [null, [Validators.required]],
      NumberAccount: [null, [Validators.required]],
      NumberAgence: [null, [Validators.required]],
      TypeAccount: [null, [Validators.required]],
      KeyType: ['', [Validators.required]],
      KeyValue: ['', [Validators.required]],
    })
  }

  changeDataTable(data) {
    if (data.type === 'download') {
      this.api.queryDownload('listAllRefunds/download', data.data)
      return
    }
    this.dataInfo = this.getDataInfo(data);
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      service: 'refunds',
      data: null
    };

    let headers = [
      {
        'name': 'ID',
        'key': 'RefundPublicID',
        'opc': true
      },
      {
        'name': 'Beneficiario',
        'key': 'FullName',
        'opc': true
      },
      {
        'name': 'Documento',
        'key': 'DocumentNumber',
        'opc': true
      },
      {
        'name': 'Banco',
        'key': 'Bank',
        'opc': true
      },
      {
        'name': 'Transacción',
        'key': 'TransactionPublicID',
        'opc': true
      },
      {
        'name': 'MerchantSalesID',
        'key': 'MerchantSalesID',
        'opc': true,
        responsive: true
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Fecha',
        'key': 'created_at',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'Stage',
        responsive: true,
        type: 'status',
        'opc': true
      },
      {
        'name': 'Opciones',
        'key': 'standard-list',
        type: 'actions',
        'opc': false,
        'functions': [
          Options.preview,
          Options.process,
          Options.reject,
          Options.allow,
          Options.update,
          Options.pay,
        ],
      },
    ];


    if (dataInfo !== false) {
      data.data = dataInfo;
    }

    const list: any = await this.api.api(data).toPromise()

    return {
      headers: headers,
      data: await list.data,
      source: 'refunds'
    }
  }

  async getFilters() {
    const data = {
      service: 'bank',
      data: []
    };

    let banks = [];
    await this.api.api(data).toPromise().then((result: any) => {
      result.forEach(element => {
        banks.push({
          name: element.LegalName,
          value: element.BankCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [
      {
        'name': 'Refund ID',
        'key': 'RefundPublicID',
        'type': 'text'
      },
      {
        'name': 'Transacción',
        'key': 'TransactionID',
        'type': 'text'
      },
      {
        'name': 'Merchant ID',
        'key': 'MerchantSalesID',
        'type': 'text'
      },
      {
        'name': 'Documento',
        'key': 'DocumentNumber',
        'type': 'text'
      },
      {
        'name': 'Fecha',
        'key': 'CreatedAt',
        'type': 'date'
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'type': 'text'
      },
      {
        'name': 'Bancos',
        'key': 'BankCode',
        'type': 'select',
        'data': banks
      },
      {
        'name': 'Estado',
        'key': 'RefundStateID',
        'type': 'select',
        data: [
          {
            name: 'Todos',
            value: '',
          },
          {
            name: 'Pendiente',
            value: 1,
          },
          {
            name: 'Autorizado',
            value: 2,
          },
          {
            name: 'Rechazado',
            value: 3,
          },
          {
            name: 'Pagado',
            value: 4,
          }
        ]
      },
      {
        'name': 'País',
        'key': 'CountryCode',
        'type': 'select',
        'data': this.countries
      },
      {
        'name': 'Descargar',
        'key': 'download',
        'type': 'button'
      },
    ];
  }

  changeTypeDeposit() {
    this.TypeDeposit.valueChanges.pipe(
        takeUntil(this.destroy$)
    ).subscribe(resp => {
      this.isPIX = resp.value ? resp.value === 'PIX' : resp === 'PIX';
    })
  }

  update() {
    console.log('update')
  }

  showModalUpdate() {
    this.isVisibleUpdate = true
  }

  handleCancel() {
    this.isVisibleUpdate = false
  }

  functions(data) {
    this.selectedRefund =  data.data
    let dataService: any = {
      service: 'refunds-put',
      id: data.data.RefundID,
      data: {
        Amount: data.data.Amount,
        NoAccount: false,
        RefundType: data.data.RefundType,
        Details: {
          DocumentType: data.data.DocumentType,
          DocumentNumber: data.data.DocumentNumber,
          Email: data.data.Email,
          Phone: data.data.Phone,
          NameBank: data.data.Bank,
          NumberAgence: data.data.NumberAgence,
          TypeAccount: data.data.TypeAccount,
          NumberAccount: data.data.NumberAccount,
          FullName: data.data.FullName,
        },
      }
    };

    switch (data.function) {
      case Options.allow: {
        dataService.data.DevolutionStageID = 2
        let title = 'Autorizar'
        let message = 'Esta seguro que desea autorizar?'
        this.confirmModal(dataService, title, message)
      }
        break;
      case Options.pay: {
        dataService.data.DevolutionStageID = 4
        let title = 'Pagar'
        let message = 'Esta seguro que desea pagar?'
        this.confirmModal(dataService, title, message)
      }
        break;
      case Options.reject: {
        dataService.data.DevolutionStageID = 3
        let title = 'Rechazar'
        let message = 'Esta seguro que desea rechazar?'
        this.confirmModal(dataService, title, message)
      }
        // this.isVisibleProcess = true
        break;
      case Options.update:
        this.disabled = false;
        this.setDefaultValues(data.data)
        this.showModalUpdate()
        break;
      case Options.preview:
        this.disabled = true;
        this.setDefaultValues(data.data)
        this.showModalUpdate()
        break;
    }
  }

  confirmModal(data, title: string, message: string){
    this.modal.confirm({
      nzTitle: title,
      nzContent: message,
      nzOnOk: () => {
        this.api.api(data).toPromise().then((resp: any) => {
          this.success(resp.Message || resp.message)
          this.dataInfo = this.getDataInfo();
        }).catch(e => {
          console.log(e)
          // this.error(e.message || e.error.message)
        })
      }
    })
  }

  success(title, message?: string): void {
    this.modal.success({nzTitle: title, nzContent: message});
  }

  error(error): void {
    this.modal.error({nzTitle: 'Algo salió mal', nzContent: error});
  }

  setDefaultValues(refund) {
    this.isPIX = refund.RefundType === 'PIX'
    this.FullName.setValue(refund.FullName)
    this.DocumentNumber.setValue(refund.DocumentNumber)
    this.DocumentType.setValue(refund.DocumentType)
    this.Phone.setValue(refund.Phone)
    this.Email.setValue(refund.Email)
    this.Type.setValue(this.getType(refund.Type))
    this.TypeDeposit.setValue(refund.RefundType)
    this.BankName.setValue(this.getBank(refund.Bank) || refund.Bank)
    this.TypeAccount.setValue(refund.TypeAccount)
    this.NumberAgence.setValue(refund.NumberAgence)
    this.Amount.setValue(refund.Amount)
    this.NumberAccount.setValue(refund.NumberAccount)
  }

  getBank(bankName) {
    return this.listBanks.find(bank => bank.ShortName === bankName)
  }

  getType(value) {
    return this.types.find(type => type.value === value)
  }

  get FullName() {return this.formRefund.get('FullName')}
  get DocumentNumber() {return this.formRefund.get('DocumentNumber')}
  get DocumentType() {return this.formRefund.get('DocumentType')}
  get Phone() {return this.formRefund.get('Phone')}
  get Email() {return this.formRefund.get('Email')}
  get TypeDeposit() {return this.formRefund.get('TypeDeposit')}
  get Type() {return this.formRefund.get('Type')}
  get BankName() {return this.formRefund.get('BankName')}
  get Amount() {return this.formRefund.get('Amount')}
  get NumberAccount() {return this.formRefund.get('NumberAccount')}
  get NumberAgence() {return this.formRefund.get('NumberAgence')}
  get TypeAccount() {return this.formRefund.get('TypeAccount')}
  get KeyType() {return this.formRefund.get('KeyType')}
  get KeyValue() {return this.formRefund.get('KeyValue')}

}
