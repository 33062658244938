import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { FormField } from "../../interfaces/form";

@Component({
    selector: 'app-form-data-bank',
    templateUrl: './form-data-bank.component.html',
    styleUrls: ['./form-data-bank.component.scss']
})
export class FormDataBankComponent implements OnInit, OnChanges {
    @Output() onSubmitForm = new EventEmitter<any>();
    @Input() bankCode: number | string;
    @Input() settingsForm: FormField[] = [];
    @Input() countries: any[] = [];
    form: FormGroup = new FormGroup({});


    constructor() {
    }

    ngOnInit() {

    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.settingsForm) {
            this.createForm();
        }
    }

    submitForm() {
        if (this.form.invalid) {
            for (const i in this.form.controls) {
                this.form.controls[i].markAsDirty();
                this.form.controls[i].updateValueAndValidity();
            }
            return;
        }
        this.onSubmitForm.emit(this.form.value);
    }

    createForm() {
        this.form = new FormGroup({});
        this.settingsForm.forEach(field => {
            this.form.addControl(field.formControlName, new FormControl({value: field.value, disabled: field.disabled}, field.validations));
        })
    }

    getErrors(c: AbstractControl) {
        const formGroup = c.parent.controls;
        const control = Object.keys(formGroup).find(name => c === formGroup[name]) || null;
        return this.settingsForm.find(field => field.formControlName === control).errors || [];
    }

    getDataValue(optionValue: string, data) {
        if (optionValue && data) {
            return data[optionValue];
        }
        if (data.value) {
            return data.value;
        }
        return data;
    }

    getLabelValue(optionValue: string, data) {
        if (optionValue && data) {
            return data[optionValue];
        }
        if (data.name) {
            return data.name;
        }
        return data;
    }

}
