import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Alert } from '../../../../settings/interfaces/alerts';
import { PaginationData } from '../../../../settings/interfaces/paginate-service';
import { HEADERS_ALERTS } from './config-alert-table';

@Component({
  selector: 'app-table-alerts',
  templateUrl: './table-alerts.component.html',
  styleUrls: ['./table-alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableAlertsComponent implements OnInit {
  @Input() paginationData: PaginationData;
  @Input() alerts: Alert[] = [];
  @Input() loading = false;
  @Output() onPageChange = new EventEmitter<number>();
  @Output() onPageSizeChange = new EventEmitter<number>();
  @Output() onOptionsClick = new EventEmitter<{ alert: Alert, option: string }>();
  protected headers = HEADERS_ALERTS
  protected mapOfExpandData: { [key: string]: boolean } = {};
  protected expandSet = new Set<number>();

  constructor() { }

  ngOnInit() {
  }

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  pageIndexChange(newIndex: number): void {
    this.onPageChange.emit(newIndex);
  }
  
  pageSizeChange(newSize: number): void {
    this.onPageSizeChange.emit(newSize);
  }

  optionsClick(alert: Alert, option: string) {
    this.onOptionsClick.emit({ alert, option });
  }

}
