import { Component, OnInit } from '@angular/core';
import { InfoBankForm } from "../../components/form-data-bank/info-bank.form";
import { UtilsService } from "../../../services/utils.service";
import { ActivatedRoute } from "@angular/router";
import { FormField } from "../../interfaces/form";

@Component({
  selector: 'app-bank-new',
  templateUrl: './bank-new.component.html',
  styleUrls: ['./bank-new.component.scss']
})
export class BankNewComponent implements OnInit {
  bankFormSettings = InfoBankForm.getForm();
  countries: any[] = [];
  bankCode = this.aR.snapshot.paramMap.get('id') ? this.aR.snapshot.paramMap.get('id') : null;

  constructor(private utilsService: UtilsService, private aR: ActivatedRoute,) { }

  ngOnInit() {
    this.getCountries();
  }

  async getCountries() {
    this.countries = await this.utilsService.getDataCountry();
    this.setOptionsCountries();
  }

  setOptionsCountries() {
    this.bankFormSettings.map((item: FormField) => {
        if (item.key === 'country' && item.options) {
            item.options = this.countries;
        }
    })
  }

  createBank(data) {
    console.log(data);
  }

}
