import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NzModalService } from "ng-zorro-antd";
import { map } from "rxjs/operators";
import { Currency } from "../../../models/currency.interface";
import { ApiService } from "../../../services/api.service";
import { BankService } from "../../../services/bank.service";
import { ChannelsService } from '../../../services/channels.service';
import { LoaderService } from "../../../services/loader.service";
import { MerchantService } from "../../../services/merchant.service";
import { ProviderService } from "../../../services/provider.service";
import { UtilsService } from "../../../services/utils.service";
import { Bank } from "../../interfaces/bank";
import { Channel } from '../../interfaces/channel';
import { IMerchant, MerchantType, Settlement } from "../../interfaces/merchant";

@Component({
    selector: 'app-merchant-edit',
    templateUrl: './merchant-edit.component.html',
    styleUrls: ['./merchant-edit.component.scss']
})
export class MerchantEditComponent implements OnInit {
    merchant: IMerchant;
    formDataMerchant: FormGroup;
    idMerchant = this.aR.snapshot.paramMap.get('id') ? this.aR.snapshot.paramMap.get('id') : null;
    listTypeMerchant: MerchantType[] = [];
    countries: any[] = [];
    banks: Bank[] = [];
    providers: any[] = []
    allChannels: Channel[] = [];
    currencies: Currency[] = [];
    typeDocument: any[] = [];
    settlements: Settlement[] = [];

    constructor(
        private loaderService: LoaderService,
        private bankService: BankService,
        private modalService: NzModalService,
        private merchantService: MerchantService,
        private channelsService: ChannelsService,
        private aR: ActivatedRoute,
        private fb: FormBuilder,
        private utils: UtilsService,
        private providerService: ProviderService,
        private apiService: ApiService
    ) {
        this.getMerchant();
        this.getMerchantType();
        this.getAllChannels();
        this.getCountries();
        this.getCurrencies();
        this.getBankList();
        this.getPayments();
        this.createFormDataMerchant();
        this.getTypeDocument()
    }

    ngOnInit() {
    }

    createFormDataMerchant() {
        const patternEmail = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';
        
        this.formDataMerchant = this.fb.group({
            settlementSetting: [{value: '0', disabled: true}],
            legalName: ['', Validators.required],
            countryCode: ['', Validators.required],
            bussinesName: ['', Validators.required],
            urlWeb: ['', Validators.required],
            typeDocumentLegalRepresentative:['', Validators.required],
            typeDocumentMerchant: ['', Validators.required],
            legalNumber: ['', Validators.required],
            merchantType: ['', Validators.required],
            color1: ['#072146'],
            color2: ['#ff9100'],
            color3: ['#f2f2f2'],
            userFullname: ['', Validators.required],
            userEmail: ['', [Validators.required, Validators.pattern(patternEmail)]],
            userMobile: ['', Validators.required],
        });
    }

    getMerchant() {
        this.loaderService.updateLoading(true)
        this.merchantService.getMerchant(this.idMerchant).subscribe((res) => {
                this.loaderService.updateLoading(false)                
                this.settlements = res.settlements || [];
                this.merchant = res;
            }, () => {
                this.loaderService.updateLoading(false)
            }
        );
    }

    getMerchantType() {
        this.merchantService.getMerchantType().subscribe((res) => {
            this.listTypeMerchant = res;
        });
    }

    getBankList() {
        this.bankService.getBanks({page: 1, size: 100}).pipe(
            map((res) => {
                return res.data.map((item) => ({...item, id: item.bankCode, type: 'bank', bussinesName: item.shortName})) as Bank[]
            })
        ).subscribe(
            (data) => {
                this.banks = data;
            })
    }

    getCurrencies() {
        this.apiService.queryGet<Currency[]>('currencies').subscribe((result) => {
            this.currencies = result;
        })
    }

    getPayments() {
        this.providerService.getPayments({type: 'PAYMENT'}).pipe(
            map((res) => {
                return res.data.map((item) => ({...item, id: item.providerID, shortName: item.legalName, type: 'provider'}));
            })
        ).subscribe((res) => {
            this.providers = res;
        });
    }

    updateMerchant(data) {
        this.loaderService.updateLoading(true)
        this.merchantService.updateMerchant(this.idMerchant, data).subscribe(() => {
            this.loaderService.updateLoading(false)
            this.modalService.success({
                nzTitle: 'Actualización exitosa',
                nzContent: 'Los datos del comercio se han actualizado correctamente'
            });
        }, () => {
            this.loaderService.updateLoading(false)
        })
    }

    getAllChannels() {
        this.channelsService.getAllChannels().subscribe((res) => {
            this.allChannels = res;
        })
    }

    get banksAndProviders() {
        return [...this.banks, ...this.providers];
    }

    async getCountries() {
        this.countries = await this.utils.getDataCountry();
    }

    async getTypeDocument(country = 'PER'){
        await this.merchantService.getTypeDocumentMerchant(country).subscribe((res: any) => {
            this.typeDocument = res.data
        });
    }

    changeCountry(country){
        if (country) {
            this.getTypeDocument(country.value);
        }
        
    }
}
