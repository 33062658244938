import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { FormField } from "../../interfaces/form";

@Component({
  selector: 'app-form-base',
  templateUrl: './form-base.component.html',
  styleUrls: ['./form-base.component.scss']
})
export class FormBaseComponent implements OnInit {
  @Output() onSubmitForm = new EventEmitter<any>();
  @Input() form: FormGroup;
  @Input() settingsForm:  FormField[] = [];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.settingsForm && simpleChanges.settingsForm.currentValue) {
      this.createForm();
    }
  }

  createForm() {
    // this.form = new FormGroup({});
    this.settingsForm.forEach(field => {
      this.form.addControl(field.formControlName, new FormControl({value: field.value, disabled: field.disabled}, field.validations));
    })
  }

  getErrors(c: AbstractControl) {
    const formGroup = c.parent.controls;
    const control = Object.keys(formGroup).find(name => c === formGroup[name]) || null;
    return this.settingsForm.find(field => field.formControlName === control).errors || [];
  }

  getDataValue(optionValue: string, data) {
    if (optionValue && data) {
      return data[optionValue];
    }
    if (data.value) {
      return data.value;
    }
    return data;
  }

  getLabelValue(optionValue: string, data) {
    if (optionValue && data) {
      return data[optionValue];
    }
    if (data.name) {
      return data.name;
    }
    return data;
  }

  submitForm() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsDirty();
        this.form.controls[i].updateValueAndValidity();
      }
      return;
    }
    this.onSubmitForm.emit(this.form.value);
  }

  isArray(value) {
    return Array.isArray(value) ;
  }

}
