import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { IMerchant } from "../../interfaces/merchant";
import { TableModel } from "../../../models/table.model";
import { HeadersModel, IHeadersTable, TypeCell } from "../../../models/headersTable.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CurrenciesConst } from "../../../constants/currencies.const";
import { NzModalService } from "ng-zorro-antd";
import { MerchantService } from "../../../services/merchant.service";
import { BankService } from "../../../services/bank.service";
import { Bank } from "../../interfaces/bank";

enum TypeModal {
    new = 'new',
    edit = 'edit'
}

@Component({
    selector: 'app-bank-account-merchant',
    templateUrl: './bank-account-merchant.component.html',
    styleUrls: ['./bank-account-merchant.component.scss']
})
export class BankAccountMerchantComponent implements OnInit, OnChanges {
    @Input() merchant: IMerchant;
    @Input() banks: Bank[] = [];
    @Output() updateMerchant = new EventEmitter<any>();
    dataTable: TableModel<any>;
    public typeModalEnum = TypeModal;
    public isLoading = false;
    public canEditBankAccount = false;
    public isLoadingButtonBankAccount = false;
    public isOpenBankAccountModal = false;
    public titleBankModal: string;
    public id: string;
    public merchantBankAccountId: string | number;
    public formBankSection: FormGroup;
    public currencies = CurrenciesConst.currencies; // TODO: obtener las monedas de la api
    public accountTypes = [
        {
            label: 'Cuenta Ahorro',
            value: 1
        },
        {
            label: 'Cuenta Corriente',
            value: 2
        },
    ]

    constructor(private merchantService: MerchantService, private bankService: BankService, private modalService: NzModalService, private fb: FormBuilder,) {
        this.createFormBankSectionGroup();
    }

    ngOnInit() {
        this.getDataDetail();
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    get headersTable(): HeadersModel[] {
        const headers: IHeadersTable[] = [
            {
                label: 'ID',
                key: 'merchantBankAccountId',
            },
            {
                label: 'Type',
                key: 'Type',
                responsive: true
            },
            {
                label: 'Account Number',
                key: 'numberAccount',
            },
            {
                label: 'Currency',
                key: 'currencyCode',
            },
            {
                label: 'Default',
                key: 'isDefault',
                type: TypeCell.default,
                responsive: true
            },
            {
                label: 'Status',
                key: 'isActive',
                type: TypeCell.status,
                responsive: true
            },
            {
                label: 'Action',
                key: 'merchantBankAccountId',
                labelCol: 'Editar',
                type: TypeCell.button,
                path: '',
            }
        ]

        return headers.map((header) => new HeadersModel(header));
    }

    tableEvent(event) {
        this.openModalBankAccount(TypeModal.edit, event);
    }

    openModalBankAccount(type: TypeModal, account?: any) {
        this.isOpenBankAccountModal = true;
        if (type === TypeModal.new) {
            this.canEditBankAccount = false;
            this.titleBankModal = 'Crear bank account';
        }
        if (type === TypeModal.edit) {
            this.canEditBankAccount = true;
            this.titleBankModal = 'Editar bank account';
            this.merchantBankAccountId = account.row.merchantBankAccountId;
            const bank: any = this.banks.find((bank) => bank.bankId === account.row.bankID) || {}
            console.log(account.row)
            this.formBankSection.patchValue({...account.row, type: Number(account.row.type), bankCode: bank.bankCode || null});
        }
    }

    getDataDetail() {
        this.isLoading = true;
        this.merchantService.getBankList(this.merchant.infoMerchant.merchantCode).subscribe(
            (data: any) => {
                this.dataTable = new TableModel(data, this.headersTable);
                this.isLoading = false;
            })
    }

    createFormBankSectionGroup() {
        this.formBankSection = this.fb.group({
            type: [null, [Validators.required]],
            bankCode: [null, [Validators.required]],
            numberAccount: ['', [Validators.required]],
            currencyCode: [null, [Validators.required]],
            numberABA: [''],
            isActive: [true],
            isDefault: [null]
        });
    }

    handleCancelBankSectionItem() {
        this.isOpenBankAccountModal = false;
        this.formBankSection.reset();
    }

    handleCreateBankSectionItem() {
        this.isLoadingButtonBankAccount = true;
        if (this.canEditBankAccount) {
            this.updateBankAccount();
        } else {
            this.createBankAccount();
        }
        this.formBankSection.reset();
    }

    updateBankAccount() {
        this.merchantService.updateBankAccount(this.merchantBankAccountId, this.formBankSection.value).subscribe(
            () => {
                this.isOpenBankAccountModal = false;
                this.isLoadingButtonBankAccount = false;
                this.getDataDetail();
                this.modalService.success({
                    nzTitle: 'Success',
                    nzContent: 'Bank account updated successfully'
                });
            },
            (error) =>{this.isLoadingButtonBankAccount = false; }
        )
    }

    createBankAccount() {
        this.merchantService.createBankAccount(this.merchant.infoMerchant.merchantCode, this.formBankSection.value).subscribe(
            () => {
                this.isOpenBankAccountModal = false;
                this.isLoadingButtonBankAccount = false;
                this.getDataDetail();
                this.modalService.success({
                    nzTitle: 'Success',
                    nzContent: 'Bank account created successfully'
                });
            },
            (error) =>{this.isLoadingButtonBankAccount = false; }
        )
    }

    getColorTag(status) {

        if (status) {
            return 'green';
        } else {
            return 'red';
        }
    }

    get state() {
        return this.formBankSection.get('isActive');
    }

}
