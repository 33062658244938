import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { UploadFile, UploadXHRArgs } from "ng-zorro-antd/upload";
import { FormField } from "../../interfaces/form";

@Component({
  selector: 'app-form-dynamic-settings',
  templateUrl: './form-dynamic-settings.component.html',
  styleUrls: ['./form-dynamic-settings.component.scss']
})
export class FormDynamicSettingsComponent implements OnInit {
  @Output() onSubmitForm = new EventEmitter<any>();
  @Output() onHandleUpload = new EventEmitter<any>();
  @Input() hasImageUpload = false
  @Input() isCreating = false
  @Input() title = ''
  @Input() textOkButton = 'Crear';
  @Input() settingsForm: FormField[] = [];
  form: FormGroup = new FormGroup({});
  fileList: any[] = [];
  previewImage: string | undefined = '';
  previewVisible = false;
  showUploadList = {
    showPreviewIcon: true,
    showRemoveIcon: true,
    hidePreviewIconInNonImage: true
  };

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges.settingsForm) {
      this.createForm();
    }
  }

  createForm() {
    this.form = new FormGroup({});
    this.settingsForm.forEach(field => {
      this.form.addControl(field.formControlName, new FormControl({value: field.value, disabled: field.disabled}, field.validations));
    })
  }

  getErrors(c: AbstractControl) {
    const formGroup = c.parent.controls;
    const control = Object.keys(formGroup).find(name => c === formGroup[name]) || null;
    return this.settingsForm.find(field => field.formControlName === control).errors || [];
  }

  getDataValue(optionValue: string, data) {
    if (optionValue && data) {
      return data[optionValue];
    }
    if (data.value) {
      return data.value;
    }
    return data;
  }

  getLabelValue(optionValue: string, data) {
    if (optionValue && data) {
      return data[optionValue];
    }
    if (data.name) {
      return data.name;
    }
    return data;
  }

  submitForm() {
    if (this.form.invalid) {
      for (const i in this.form.controls) {
        this.form.controls[i].markAsDirty();
        this.form.controls[i].updateValueAndValidity();
      }
      return;
    }
    this.onSubmitForm.emit(this.form.value);
  }

  handlePreview = (file: UploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    this.previewVisible = true;
  };

  handleUpload = (item: UploadXHRArgs) => {
    this.onHandleUpload.emit(item.file);
  };

  isArray(value) {
    return Array.isArray(value) ;
  }

}
