import { Component } from '@angular/core';
import { InfoProviderForm } from "../../form-settings/info-new-provider.form";
import { FormField } from "../../interfaces/form";
import { UtilsService } from "../../../services/utils.service";
import { from } from "rxjs";
import { ProviderService } from "../../../services/provider.service";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { LoaderService } from "../../../services/loader.service";

@Component({
    selector: 'app-provider-new',
    templateUrl: './provider-new.component.html',
    styleUrls: ['./provider-new.component.scss']
})
export class ProviderNewComponent {
    settingsForm = InfoProviderForm.getForm();

    constructor(
        private utilsService: UtilsService,
        private providerService: ProviderService,
        private loaderService: LoaderService,
        private router: Router
    ) {
        this.setOptionsProviderTypes()
        this.setOptionsCountries();
    }

    setOptionsCountries() {
        this.settingsForm.map((item: FormField) => {
            if (item.key === 'countryCode' && item.options) {
                item.options = from(this.utilsService.getDataCountry())
            }
        })
    }

    setOptionsProviderTypes() {
        this.settingsForm.map((item: FormField) => {
            if (item.key === 'providerType' && item.options) {
                item.options = this.providerService.getProviderTypes()
                    .pipe(map((res) => res.map((type) => ({value: type, name: type}))))
            }
        })
    }

    onSubmitForm(value) {
        this.loaderService.updateLoading(true);
        this.providerService.createProvider(value).subscribe((res) => {
            this.loaderService.updateLoading(false);
            this.router.navigate([`/settings/providers/edit/${res.data.providerCode}`])
        })
    }

    handleUploadFile(event) {
        console.log(event);
    }

}
