import { HeadersModel } from "./headersTable.model";
import { Pagination } from "./pagination";

export class TableModel<T> {
    public data: T[];
    public headers: HeadersModel[];
    public pagination: Pagination;

    constructor(data: Partial<T[]>, headers: HeadersModel[], pagination?: Pagination) {
        this.data = data;
        this.headers = headers;
        this.pagination = pagination
    }

}
