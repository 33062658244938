import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MerchantsComponent } from "./pages/merchants/merchants.component";
import { MerchantEditComponent } from "./pages/merchant-edit/merchant-edit.component";
import { MerchantNewComponent } from "./pages/merchant-new/merchant-new.component";
import { BanksComponent } from "./pages/banks/banks.component";
import { BankNewComponent } from "./pages/bank-new/bank-new.component";
import { BankEditComponent } from "./pages/bank-edit/bank-edit.component";
import { ProvidersComponent } from "./pages/providers/providers.component";
import { ProviderNewComponent } from "./pages/provider-new/provider-new.component";
import { ProviderEditComponent } from "./pages/provider-edit/provider-edit.component";


const routes: Routes = [

    {
        path: '',
        redirectTo: 'merchants',
        pathMatch: 'full'
    },
    {
        path: 'merchants',
        component: MerchantsComponent,
        data: {
            breadcrumb: 'Comercios'
        },
    },
    {
        path: 'merchants/edit/:id',
        component: MerchantEditComponent,
        data: {
            breadcrumb: 'Editar comercio'
        }
    },
    {
        path: 'merchants/new',
        component: MerchantNewComponent,
        data: {
            breadcrumb: 'Nuevo comercio'
        }
    },
    {
        path: 'banks',
        component: BanksComponent,
        data: {
            breadcrumb: 'Bancos'
        },
    },
    {
        path: 'banks/new',
        component: BankNewComponent,
        data: {
            breadcrumb: 'Nuevo banco'
        }
    },
    {
        path: 'banks/edit/:id',
        component: BankEditComponent,
        data: {
            breadcrumb: 'Editar banco'
        }
    },
    {
        path: 'providers',
        component: ProvidersComponent,
        data: {
            breadcrumb: 'Proveedores'
        },
    },
    {
        path: 'providers/new',
        component: ProviderNewComponent,
        data: {
            breadcrumb: 'Nuevo proveedor'
        }
    },
    {
        path: 'providers/edit/:id',
        component: ProviderEditComponent,
        data: {
            breadcrumb: 'Editar proveedor'
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SettingsRoutingModule {
}
