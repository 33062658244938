import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StatusService {

    constructor() {
    }

    public getPayrollStatus(): any {
        return [
            {name: 'Anulado', value: 0},
            {name: 'En creación', value: 1},
            {name: 'Creado', value: 2},
            {name: 'Aprobado', value: 3},
            {name: 'Observaciones', value: 4},
            {name: 'Procesado', value: 5},
            {name: 'Rechazado', value: 6},
            {name: 'Error al crear', value: 7},
            {name: 'Error al enviar', value: 8}
        ];
    }

    public getPayrollStatusColor(): any {
        return [
            {statusId: 0, color: 'red'},
            {statusId: 1, color: 'orange'},
            {statusId: 2, color: 'lime'},
            {statusId: 3, color: 'blue'},
            {statusId: 4, color: 'green'},
            {statusId: 5, color: 'cyan'},
            {statusId: 6, color: 'volcano'},
            {statusId: 7, color: 'red'},
            {statusId: 8, color: 'volcano'}
        ];
    }
}
