import { Validators } from "@angular/forms";
import { Settlement } from "../../interfaces/merchant";

const patternDecimals = '^[0-9]+(\.[0-9]{1,3})?$';

export  class InfoCardsMerchantSettlements {
    static getInputs(values?: Settlement) {        
        return [
          {
            label: 'Canal de pago*',
            value:  values && values.paymentChannelId || null,
            disabled: false,
            readonly: false,
            formControlName: 'channel',
            placeholder: '',
            type: 'select',
            options: [],
            model: 'default',
            bindLabel: 'ChannelName',
            bindValue: 'PaymentChannelID',
            validations:  [ Validators.required ],
            errors: [
                { error: 'required', message: 'Campo requerido'},
              ],
          },
          {
            label: 'Tipo*',
            value:  values && values.type || null,
            disabled: false,
            readonly: false,
            formControlName: 'type',
            placeholder: '',
            type: 'select',
            options: [
              {id: 1, value: 'Día vencido'},
              {id: 2, value: 'Días de semana'},
              {id: 3, value: 'Días de mes'},
            ],
            model: 'default',
            bindLabel: 'value',
            bindValue: 'id',
            validations:  [ Validators.required ],
            errors: [
                { error: 'required', message: 'Campo requerido'},
              ],
            },
          {
            label: 'Frecuencia*',
            value: values && values.frequency.days ? Array.isArray(values.frequency.days) ? values.frequency.days.map(x => String(x)) : values.frequency.days : null,
            disabled: false,
            readonly: false,
            formControlName: 'frequency',
            placeholder: '',
            type: 'text',
            model: 'multiple',
            bindLabel: 'value',
            bindValue: 'id',
            validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
            errors: [
                { error: 'required', message: 'Campo requerido'},
                { error: 'pattern', message: 'Número inválido'},
              ],
            },
            {
                label: 'Mínimo a liquidar*',
                value: values && values.minimumSettlement || '',
                disabled: false,
                readonly: false,
                formControlName: 'minimumSettlement',
                placeholder: '0',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
              label: 'Total transfer*',
              value: values && values.totalTransfer || false,
              disabled: false,
              readonly: false,
              formControlName: 'totalTransfer',
              placeholder: '',
              type: 'checkbox',
              col: 'col-md-6',
              validations:  [ Validators.required ],
              errors: [
                  { error: 'required', message: 'Campo requerido'},
              ],
            },
            {
              label: 'Activo*',
              value: values && values.isActive || false,
              disabled: true,
              readonly: false,
              formControlName: 'isActive',
              placeholder: '',
              type: 'checkbox',
              col: 'col-md-6',
              validations:  [ Validators.required ],
              errors: [
                  { error: 'required', message: 'Campo requerido'},
              ],
          },
        ]
    }

    static getFormCard(channelName: string, id: number, data: Settlement) {
      return {title: channelName, id, inputs: this.getInputs(data)}
    }
}


export enum TypeSettlement {
  Day = 1,
  Week = 2,
  Month = 3
}

export const WEEKDAYS = [
  {id: '1', value: 'Lunes'},
  {id: '2', value: 'Martes'},
  {id: '3', value: 'Miércoles'},
  {id: '4', value: 'Jueves'},
  {id: '5', value: 'Viernes'},
  {id: '6', value: 'Sábado'},
  {id: '7', value: 'Domingo'}
];

export const DAYS_OF_MONTH = Array.from(Array(31).keys()).map((item) => {
  return {id: (item + 1).toString(), value: (item + 1).toString()};
});

