import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/services/api.service';
import { AccessService } from '../../../../services/access.services';
import { AlertsService } from '../../../../services/alerts.service';
import { Alert } from '../../../../settings/interfaces/alerts';
import { PaginationData } from '../../../../settings/interfaces/paginate-service';
import { PaginationAdapter } from '../../../../shared/adapters/pagination-adapter';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  protected paginationAdapter: PaginationAdapter<Alert>;
  protected titlePage: string = 'Monitor > Alertas';
  protected filters: any = this.getFilters();
  protected totalItems = 0;
  protected paginationData = new PaginationData();
  protected loading = false;
  protected page = 1;
  protected size = 10;
  private filterTable = {
    startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    endDate: moment().date(new Date().getUTCDate()).format('YYYY-MM-DD'),
    priority: null,
    status: 1,
    service: null,
    merchant: null
  }
  protected alertsData: Alert[] = [];
  protected currentAlert: Alert;
  protected titleModal = '';
  protected isVisibleTokenModal = false;
  protected showQrGoogle = false;
  protected qrAuthGoogle = '';
  protected isOkLoading = false;
  protected otpControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);

  constructor(private api: ApiService, 
    private router: Router, 
    private alertsService: AlertsService, 
    private accessService: AccessService, 
    private nzModalService: NzModalService
    ) {
    this.paginationAdapter = new PaginationAdapter(this.alertsService);
  }

  ngOnInit() {
    this.getAlerts();
  }

  async getFilters() {

    let data = {
      service: 'merchants'
    }

    let dataMerchant = [];


    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status == 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [
      {
        'name': 'Servicio',
        'key': 'service',
        'type': 'select',
        'data': [
          {
            'name': 'Cash In',
            'value': 1
          },
          {
            'name': 'Cash Out',
            'value': 2
          }
        ]
      },
      {
        'name': 'Merchant',
        'key': 'merchant',
        'type': 'select',
        data: dataMerchant || []
      },
      {
        'name': 'Prioridad',
        'key': 'priority',
        'type': 'select',
        'data': [
          {
            'name': 'Alta',
            'value': 3
          },
          {
            'name': 'Media',
            'value': 2
          },
          {
            'name': 'Baja',
            'value': 1
          }
        ]
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': [
          {
            'name': 'Pendiente',
            'value': 1
          },
          {
            'name': 'En proceso',
            'value': 2
          },
          {
            'name': 'Cerrado',
            'value': 3
          }
        ],
        value: 1
      },
      {
        'name': 'Fecha',
        'key': 'range',
        'type': 'range_date',
        value: [moment().subtract(30, 'days').toISOString(), moment().toISOString()]
      },
    ];

  }

  changeDataTable(data) {
    let startDate = null;
    let endDate = null;
    this.page = 1;
    
    const { range, ...rest } = data;
  
    if (range && range.length > 0) {
      startDate = this.formatDate(range[0]);
      endDate = this.formatDate(range[1]);
    }
  
    this.filterTable = { ...rest, startDate, endDate };
    this.getAlerts();
    
  }

  private formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  async getAlerts() {
    this.loading = true;
    const {data, ...rest} = await this.paginationAdapter.fetchData(this.page, this.size, this.filterTable);
    this.paginationData = rest;
    this.alertsData = data;
    this.loading = false;
  }

  async changePage(page: number) {
    this.page = page;
    await this.getAlerts();
  }

  async changeSize(size: number) {
    this.size = size;
    this.page = 1
    await this.getAlerts();
  }

  onOptionsClick({ alert, option }: { alert: Alert, option: string }) {
    this.currentAlert = alert;
    switch (option) {
      case 'Revisar':
        const path = alert.ServiceID == 1 ? 'operations-cashin' : 'operations-cashout';
        const dateStart = alert.details.length ? alert.details[0].transaction.created_at.split(' ')[0] : new Date().toISOString().split('T')[0];
        this.router.navigate([`${path}`], { queryParams: { reference: alert.details[0].transaction.MerchantReference, dateStart } });
        break;
      case 'Cerrar':
        this.titleModal = 'Cerrar alerta';
        this.isVisibleTokenModal = true;
        break;
    }
  }

  updateAlert(alertId: number, status) {
    this.alertsService.updateAlert(alertId, status).subscribe((res: any) => {
      this.isOkLoading = false;
      this.isVisibleTokenModal = false;     
      this.getAlerts();
    }, error => {
      this.isOkLoading = false;
      this.isVisibleTokenModal = false;
    });
  }

  handleCancelTokenModal() {
    this.isVisibleTokenModal = false;
    this.otpControl.reset();
  }

  handleOkTokenModal() {
    this.isOkLoading = true;
    this.accessService.validateAuthToken(this.otpControl.value).subscribe((res: any) => {
      this.isOkLoading = false;
      this.isVisibleTokenModal = false;
      this.updateAlert(this.currentAlert.AlertID , 3);
    }, () => {
      this.isOkLoading = false;
      this.isVisibleTokenModal = false;
      this.otpControl.reset();
      this.error('Token incorrecto');
    });
  }

  error(error): void {
    this.nzModalService.error({nzTitle: 'Mensaje de error', nzContent: error});
  }

  resetQr(){
    this.showQrGoogle = true;
    this.accessService.getQrGoogle(true).subscribe(resulQr => {
      this.qrAuthGoogle = resulQr.data;
    });
  }

}
