import { Validators } from "@angular/forms";
import { ProviderCommission } from "../../interfaces/provider";

const patternDecimals = '^[0-9]+(\.[0-9]{1,3})?$';

export  class InfoCardsProviderCommissions {
    static getInputs(values?: ProviderCommission) {        
        return [
          {
            label: 'Canal de pago*',
            value:  null,
            disabled: false,
            readonly: false,
            formControlName: 'channel',
            placeholder: '',
            type: 'select',
            options: [],
            bindLabel: 'ChannelName',
            bindValue: 'PaymentChannelID',
            validations:  [ Validators.required ],
            errors: [
                { error: 'required', message: 'Campo requerido'},
              ],
            },
          {
            label: 'Subcanal de pago*',
            value: values && values.paymentSubChannelId || null,
            disabled: true,
            readonly: false,
            formControlName: 'subchannel',
            placeholder: '',
            type: 'select',
            options: [],
            bindLabel: 'SubchannelName',
            bindValue: 'PaymentSubchannelID',
            validations:  [ Validators.required ],
            errors: [
                { error: 'required', message: 'Campo requerido'},
              ],
            },
            {
                label: 'Fee*',
                value:  {
                    value: values && values.fee || '0',
                    isPercentage: values && values.isPercentageFee || false
                },
                disabled: false,
                readonly: false,
                formControlName: 'fee',
                placeholder: '',
                type: 'percentage',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Other Fee*',
                value:  {
                    value: values && values.otherFee || '0',
                    isPercentage: values && values.isPercentageOtherFee || false
                },
                disabled: false,
                readonly: false,
                formControlName: 'otherFee',
                placeholder: '',
                type: 'percentage',
                validations:  [ Validators.required],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Minimum*',
                value: values && values.minimum || '0',
                disabled: false,
                readonly: false,
                formControlName: 'minimum',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Maximum*',
                value: values && values.maximum || '0',
                disabled: false,
                readonly: false,
                formControlName: 'maximum',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'IVA*',
                value: values && values.tax || '0',
                disabled: false,
                readonly: false,
                formControlName: 'tax',
                placeholder: '',
                type: 'tax',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Tiempo de respuesta (seg)*',
                value: values && values.timeAverageResponse || '0',
                disabled: false,
                readonly: false,
                formControlName: 'timeAverageResponse',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
        ]
    }

    static getFormCard(channelName: string, id: number, data: ProviderCommission) {
      return {title: channelName, id, providerConfigurationId: data.providerConfigurationId, inputs: this.getInputs(data)}
    }
}
