import { Observable } from "rxjs";

export interface PaginatedService<T> {
  getPaginatedData(page: number, size: number, filters?: { [key: string]: any }): Observable<PaginatedResponse<T>>;
}

export interface PaginatedResponse<T> {
  current_page: number;
  data: T[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}

export class PaginationData {
  current_page: number;
  first_page_url?: string;
  from?: number;
  last_page?: number;
  last_page_url?: string;
  next_page_url?: string;
  path?: string;
  per_page?: number;
  prev_page_url?: string;
  to?: number;
  total?: number;

  constructor(data: Partial<Omit<PaginatedResponse<unknown>, 'data'>> = {}) {
    this.current_page = data.current_page || 1;
    this.first_page_url = data.first_page_url || '';
    this.from = data.from || 0;
    this.last_page = data.last_page || 0;
    this.last_page_url = data.last_page_url || '';
    this.next_page_url = data.next_page_url || '';
    this.path = data.path || '';
    this.per_page = data.per_page || 0;
    this.prev_page_url = data.prev_page_url || '';
    this.to = data.to || 0;
    this.total = data.total || 0;
  }
}
