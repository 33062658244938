import { Validators } from "@angular/forms";
import { FormField } from "../interfaces/form";

// const prueba = {
//     legalName: 'legal name',
//     legalNumber: '123456',
//     businessName: 'bussines name',
//     currentBalance: '1000',
//     providerCode: '123456',
//     urlLogo: 'https://us.123rf.com/450wm/boxfile123/boxfile1232009/boxfile123200900181/155620061-mm-moderno-logotipo-dise%C3%B1o-vector-icono-s%C3%ADmbolo.jpg?ver=6',
//     countryCode: 'COL',
//     providerType: 'PAYMENT',
//     webPage: 'https://www.google.com',
// }

export class InfoProviderForm {
    static getForm(values: any = {}): FormField[] {
        return [
            {
                label: 'Legal name*',
                key: 'legalName',
                value: values && values.legalName || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'legalName',
                placeholder: 'Legal name',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Legal number*',
                key: 'legalNumber',
                value: values && values.legalNumber || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'legalNumber',
                placeholder: 'Legal number',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Business name*',
                key: 'businessName',
                value: values && values.businessName || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'businessName',
                placeholder: 'Business name',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Current balance*',
                key: 'currentBalance',
                value: values && values.currentBalance || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'currentBalance',
                placeholder: 'Provider code',
                type: 'text',
                validations:  [ Validators.required, Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$') ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Solo se permiten números'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Provider code*',
                key: 'providerCode',
                value: values && values.providerCode || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'providerCode',
                placeholder: 'Provider code',
                type: 'text',
                validations:  [ Validators.required, Validators.pattern('^[0-9]*$') ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Solo se permiten números'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'URL logo*',
                key: 'urlLogo',
                value: values && values.urlLogo || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'urlLogo',
                placeholder: 'URL logo',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Country*',
                key: 'countryCode',
                value: values && values.countryCode || null,
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'countryCode',
                placeholder: 'Country code',
                type: 'select',
                options: [],
                optionLabel: '',
                optionValue: '',
                showSearch: true,
                allowClear: false,
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Provider type*',
                key: 'providerType',
                value: values && values.providerType || null,
                disabled: false,
                readonly: false,
                formControlName: 'providerType',
                placeholder: 'Provider type',
                type: 'select',
                options: [],
                visible: true,
                optionLabel: '',
                optionValue: '',
                showSearch: true,
                allowClear: false,
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Status*',
                key: 'active',
                value: values && values.active || true,
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'active',
                placeholder: '',
                type: 'switch',
                validations:  [],
                errors: [],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Web site',
                key: 'webPage',
                value: values && values.webPage || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'webPage',
                placeholder: 'Web site',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 24,
                mdSpan: 24,
            },
            {
                label: 'Metadata',
                key: 'metadata',
                value: values && values.metadata || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'metadata',
                placeholder: 'Metadata',
                type: 'textarea',
                validations:  [],
                errors: [],
                span: 24,
                mdSpan: 24,
            },
        ]
    }
}
