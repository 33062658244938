import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(private api: ApiService, private router: Router) { }

  async getDataCountry() {
    const data = {
      service: 'countries'
    };

    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.CountryName,
          value: element.CountryCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
    return dataMerchant;
  }

  async getBanks() {
    const data = {
      service: 'bank'
    };

    let banks = [];
    await this.api.api(data).toPromise().then((result: any) => {
      banks = result;
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
    return banks;
  }

  public getParams(object){
    if (object) {
      const parameters = [];
      for (const property in object) {
        if (object.hasOwnProperty(property)) {
          if(object[property] && property != 'period'){
            parameters.push(encodeURI(property + '=' + (object[property] ? object[property] : '')));
          }
        }
      }
      return '?'+parameters.join('&');
    }
    return ''
  }
}
