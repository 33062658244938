import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/services/api.service';
import { CountriesService } from 'src/app/services/countries.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss']
})
export class OperationsComponent {

  public allOperationsCashInData: Promise<any> = new Promise((resolve, reject) => { resolve([]) });
  public filters: any = this.getFilters();
  public titlePage: string = 'Reportes > Cash In > Operaciones';
  public countries: any[] = [];

  public pagePer = 10;
  public totalItems = 0;
  private page = 1;
  private filterTable = {
    to_date: moment().format('YYYY-MM-DD'),
    from_date: moment().format('YYYY-MM-DD'),
    CountryCode: null,
    bankCode: null,
    status: null,
    publicId: null,
    page: 1, 
    size: this.pagePer,
  }

  constructor(private api: ApiService, private router: Router,
              private modal: NzModalService,
              private countriesService: CountriesService) {
                this.countriesService.countries$.subscribe(countries => {
                  if (countries.length === 0) {
                    this.countriesService.getCountries();
                  } else {
                    this.countries = countries;
                  }
                })
                this.allOperationsCashInData = this.allOperationsCashIn();
  }


  async allOperationsCashIn(): Promise<any> {

    const headers = [
      {
        'name': 'Operación',
        'key': 'PublicId',
        type: 'link',
        responsive: true,
        'opc': true
      },
      {
        'name': 'Monto',
        'key': 'Amount',
        'opc': true
      },
      {
        'name': 'Moneda',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Cliente',
        'key': 'BussinesName',
        'opc': true
      },
      {
        'name': 'Código de pago',
        'key': 'PaymentCode',
        'opc': true
      },
      {
        'name': 'Creación',
        'key': 'TxCreation',
        'opc': true
      },
      {
        'name': 'Expiración',
        'key': 'TxExpiration',
        'opc': true
      },
      {
        'name': 'Estado',
        'key': 'LastStatus',
        responsive: true,
        type: 'status',
        'opc': true
      }
    ];

    const { data: response, ...pagination } = await this.api.queryGet('cashin/operations', this.filterTable).toPromise() as any;
    this.totalItems = pagination.total;
    this.pagePer = pagination.per_page;

    return {
      headers: headers,
      data: await Promise.resolve(response),
      source: 'reports-cashin-operations',
      getUrl: (id) => `/operation-cashin/${id}`
    };

  }



  async getFilters() {
    

    const data = {
      service: 'merchants'
    };

    const dataStatus = this.api.getStatus();
    const [, ...status] = dataStatus
    status.unshift({name: 'Expirado', value: '0'})

    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [
      {
        'name': 'Código de pago',
        'key': 'paymentCode',
        'type': 'text'
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': status
      },
      {
        'name': 'Referencia Comercial',
        'key': 'merchantSalesID',
        'type': 'text'
      },
      {
        'name': 'N° Operación',
        'key': 'PublicId',
        'type': 'text'
      },
      {
        'name': 'Fecha',
        'key': 'range',
        'type': 'range_date'
      },
      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': 'País',
        'key': 'CountryCode',
        'type': 'select',
        'data': this.countries
      },
      {
        'key': 'actions',
        'type': 'actions',
        'data': [
          /*{
            'name': 'Descargar',
            'key': 'report',
            'type': 'button'
          },*/
          {
            'name': 'Descargar reporte',
            'key': 'report-brasil',
            'type': 'button'
          },
        ]
      }


    ];

  }

  changeDataTable(data) {
    let from_date = null;
    let to_date = null;
    let isDownload = data.type && data.type === 'report-brasil';
    this.page = 1;

    const { range, ...rest } = isDownload ? data.data : data;
    
  
    if (range && range.length > 0) {
      from_date = this.formatDate(range[0]);
      to_date = this.formatDate(range[1]);
    }

    if(isDownload) {
      const fromDate = moment( from_date + 'T00:00:00');
      const toDate = moment(  to_date + 'T00:00:00');
      var months = fromDate.diff(toDate, 'months');
      if (months < 0) {months =  months * -1;}
      if(months > environment.maxMonthsSelect ){
        this.modal.error({
          nzTitle: 'Rango de fecha no valido',
          nzContent: 'El rango de fecha no debe ser mayor a ' + environment.maxMonthsSelect + ' meses'
        });
        return 
      }
      this.api.queryDownload('listAllTransactions/download', { ...rest, from_date, to_date })
    } else {   
      this.filterTable = { ...rest, from_date, to_date };   
      this.allOperationsCashInData = this.allOperationsCashIn();
    }
  }

  private formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  onChangePageIndex(value) {
    this.page = value;
    this.filterTable.page = this.page;
    
    this.allOperationsCashInData = this.allOperationsCashIn();
  }

  onChangeItemPage(value: number) {
    this.pagePer = value;
    this.filterTable.size = this.pagePer;
    
    this.allOperationsCashInData = this.allOperationsCashIn();
  }

}
