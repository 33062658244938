import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from 'moment';
import { ApiService } from "src/app/services/api.service";
import { CountriesService } from "src/app/services/countries.service";
import { environment } from 'src/environments/environment';

interface filterDataCashIn {
  type: string;
  orderBy: string;
  dateStart: string;
  dateEnd: string;
  CountryCode: string;
  merchantId: number;
  page: number;
  paginate: number;
}

@Component({
  selector: 'app-transation-cashin',
  templateUrl: './transation-cashin.component.html',
  styleUrls: ['./transation-cashin.component.scss']
})
export class TransationCashinComponent implements OnInit, OnDestroy {
  public allOperationsCashInData;
  public filters;

  private countryEnable;
  private timerRefresh;
  public totalItems = 0;
  public pagePer = 10;
  private page = 1;
  private filterTable: filterDataCashIn


  constructor(private api: ApiService,
    private countriesService: CountriesService,
    private router: Router) {

  }
  ngOnDestroy(): void {
    clearInterval(this.timerRefresh);
  }

  ngOnInit(): void {
    this.countriesService.countries$.subscribe(countries => {
      if (countries.length === 0) {
        this.countriesService.getCountries();
      } else {
        this.countryEnable = countries;
      }
      this.filters = this.getFilter();
    })
    this.filterTable = {
      type: 'merchants',
      orderBy: 'totalAmountTransactions',
      dateStart: moment().date(new Date().getUTCDate()).format('YYYY-MM-DD'),
      dateEnd: moment().date(new Date().getUTCDate()).format('YYYY-MM-DD'),
      CountryCode: null,
      merchantId: null, page: 1, paginate: this.pagePer
    };
    this.allOperationsCashInData = this.showDataMonitor(this.filterTable);
    this.timerRefresh = setInterval(this.refreshMonitorData.bind(this, this.filterTable), environment.refreshMonitorTimer);
  }

  public refreshMonitorData(data = null) {
    this.allOperationsCashInData = this.showDataMonitor(data);
  }

  public async downloadFileMonitor(filter: filterDataCashIn) {
    let data: object;
    if (!filter) {
      data = {
        service: 'monitorCashinExcel',
        data: {
          type: "merchants",
          orderBy: "totalAmountTransactions"
        }
      };
    } else {
      data = {
        service: 'monitorCashinExcel',
        data: {
          type: filter.type,
          orderBy: filter.orderBy,
          dateStart: filter.dateStart,
          dateEnd: filter.dateEnd,
          CountryCode: filter.CountryCode,
          merchantId: filter.merchantId
        }
      };
    }

    this.api.api(data).toPromise().then(
      (response: any) => {
        //console.log(response);
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', new Date().toDateString());
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  public async showDataMonitor(filter: filterDataCashIn) {
    let data: object;
    if (!filter) {
      data = {
        service: 'monitorCashin',
        data: {
          ...filter,
          type: "merchants",
          orderBy: "totalAmountTransactions",
          page: this.page,
          paginate: this.pagePer,
        }
      };
    } else {
      data = {
        service: 'monitorCashin',
        data: {
          type: filter.type,
          orderBy: filter.orderBy,
          dateStart: filter.dateStart,
          dateEnd: filter.dateEnd,
          countryCode: filter.CountryCode,
          merchantId: filter.merchantId,
          page: this.page,
          paginate: this.pagePer,
          ...filter,
        }
      };
    }


    const headers = [
      {
        'name': 'ID',
        'key': 'id',
        'opc': true,
        'type': 'text'
      },
      {
        'name': 'Nombre',
        'key': 'name',
        'opc': true,
        'responsive': true,
        'type': 'text'
      },
      {
        'name': 'País',
        'key': 'country',
        'opc': true,
        'responsive': true,
        'type': 'text'
      },
      {
        'name': 'Moneda',
        'key': 'currency',
        'opc': true,
        'responsive': true,
        'type': 'text'
      },
      {
        'name': 'Transacciones Completadas',
        'key': 'completedTransactions',
        'opc': true,
        'type': 'text',
        position: 'center'
      },
      {
        'name': 'Transacciones Pendientes',
        'key': 'pendingTransactions',
        'opc': true,
        'type': 'text',
        position: 'center'
      },
      {
        'name': 'Transacciones Expiradas',
        'key': 'expiredTransactions',
        'opc': true,
        'type': 'text',
        position: 'center'
      },
      {
        'name': 'Transacciones Extornadas',
        'key': 'returnedTransactions',
        'opc': true,
        'type': 'text',
        position: 'center'
      },
      {
        'name': 'Notificación Error',
        'key': 'notificationError',
        'opc': false,
        'type': 'text',
        position: 'center'
      },
      {
        'name': 'Total de Transacciones',
        'key': 'totalsTransactions',
        'opc': true,
        'type': 'text',
        position: 'center'
      },
      {
        'name': 'Monto Total Transacciones',
        'key': 'totalAmountTransactions',
        'opc': true,
        'type': 'text',
        position: 'center'

      },
      {
        'name': 'Total Comisiones Pagadas',
        'key': 'totalcommissions',
        'opc': true,
        'type': 'text'
      },
      {
        'name': 'Opciones',
        'key': 'opc',
        'translationKey': 'table.Options',
        'opc': true,
        'functions': ['Ver detalle']
      },
    ];

    let responseMonitorCashin: any;
    responseMonitorCashin = await this.api.api(data).toPromise();

    let index = 0;
    responseMonitorCashin.data.data.forEach(response => {
      responseMonitorCashin.data.data[index].totalCommissions = response.totalCommissions + ' ' + response.symbol;
      if (responseMonitorCashin.data.data[index].completedTransactions == null) { responseMonitorCashin.data.data[index].completedTransactions = '0' }
      if (responseMonitorCashin.data.data[index].pendingTransactions == null) { responseMonitorCashin.data.data[index].pendingTransactions = '0' }
      if (responseMonitorCashin.data.data[index].expiredTransactions == null) { responseMonitorCashin.data.data[index].expiredTransactions = '0' }
      if (responseMonitorCashin.data.data[index].returnedTransactions == null) { responseMonitorCashin.data.data[index].returnedTransactions = '0' }
      if (responseMonitorCashin.data.data[index].totalAmountTransactions == null) { responseMonitorCashin.data.data[index].totalAmountTransactions = '0 ' }
      if (responseMonitorCashin.data.data[index].notificationError == null) { responseMonitorCashin.data.data[index].notificationError = '-' }
      responseMonitorCashin.data.data[index].totalAmountTransactions = response.totalAmountTransactions + ' ' + response.symbol;
      index++;
    })

    this.totalItems = responseMonitorCashin.data.total;
    this.pagePer = responseMonitorCashin.data.per_page;
    return {
      headers: headers,
      data: responseMonitorCashin.data.data,
      source: 'monitorCashin'
    };
  }

  private async getFilter(filterby: string = 'merchants') {
    const data = {
      service: filterby
    };
    const orderBy = [{ name: 'Nun. Transacciones', value: 1 }, { name: 'Monto Transacciones', value: 2 }];
    const typeUser = [{ name: 'Shopper', value: 1 }, { name: 'Merchant', value: 2 }];
    const dataMerchant = [];

    await this.api.api(data).toPromise().then((result: any) => {
      // console.log(result)

      if (filterby === 'merchants') {
        result.forEach(element => {

          dataMerchant.push({
            name: element.BussinesName,
            value: element.MerchantID,
          });

        });
      } else {
        result.data.forEach(element => {
          dataMerchant.push({
            name: element.FullName,
            value: element.CustomerID,
          });
        });
      }
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
    let nameMerchantShopper = 'Merchant';
    if (filterby === 'shopper') nameMerchantShopper = 'Shopper';

    return [
      {
        'name': 'Tipo ',
        'key': 'typeShop',
        'type': 'typeMerchant',
        'data': typeUser,
        'value': 2
      },
      {
        'name': 'País',
        'key': 'CountryCode',
        'type': 'select',
        'data': this.countryEnable
      },

      {
        'name': 'Ordenado por',
        'key': 'order',
        'type': 'select',
        'data': orderBy
      },
      {
        'name': 'Fecha',
        'key': 'range',
        'type': 'range_date'
      },
      {
        'name': nameMerchantShopper,
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': '',
        'key': 'download',


      }

    ];
  }

  async changeDataTable(data) {
    console.log(data);
    

    let type = 'merchants';
    let orderBy = 'totalAmountTransactions';
    let dateStart = null;
    let dateEnd = null;
    let CountryCode = null;
    let merchantId = null;

    if (data.type) { //download file
      if (data.data.typeShop) {
        if (data.data.typeShop == 1) {
          type = 'customer';
        } else {
          type = 'merchants';
        }
      }
      if (data.data.order) {
        if (data.data.order === 1) {
          orderBy = 'totalsTransactions'
        } else {
          orderBy = 'totalAmountTransactions'
        }
      }
      if (data.data.range) {
        dateStart = this.formatDate(data.data.range[0]);
        dateEnd = this.formatDate(data.data.range[1]);
      }
      if (data.data.CountryCode) {
        CountryCode = data.data.CountryCode;
      }

      if (data.mcode) {
        merchantId = data.mcode;
      }

      this.filterTable = { type, orderBy, dateStart, dateEnd, CountryCode, merchantId, page: this.page, paginate: this.pagePer };
      this.downloadFileMonitor(this.filterTable);
      this.allOperationsCashInData = this.showDataMonitor(this.filterTable);
    } else { //show table
      if (data.typeShop) {
        if (data.typeShop == 1) {
          type = 'customer';
        } else {
          type = 'merchants';
        }
      }
      if (data.order) {
        if (data.order === 1) {
          orderBy = 'totalsTransactions'
        } else {
          orderBy = 'totalAmountTransactions'
        }
      }
      if (data.range) {
        dateStart = this.formatDate(data.range[0]);
        dateEnd = this.formatDate(data.range[1]);
      }
      if (data.CountryCode) {
        CountryCode = data.CountryCode;
      }

      if (data.mcode) {
        merchantId = data.mcode;
      }

      this.filterTable = { type, orderBy, dateStart, dateEnd, CountryCode, merchantId, page: this.page, paginate: this.pagePer };
      this.allOperationsCashInData = this.showDataMonitor(this.filterTable);

      clearInterval(this.timerRefresh);
      this.timerRefresh = setInterval(this.refreshMonitorData.bind(this, this.filterTable), environment.refreshMonitorTimer);
    }

  }

  functions(func) {
    //console.log(func)
    this.router.navigate(['/operations-cashin', { st: func.function, n: func.data.merchantCode, c: func.data.country }]);
  }


  onChangeType($event) {
    if ($event == 1) {
      this.filters = this.getFilter('shopper');
    } else {
      this.filters = this.getFilter();
    }
  }

  private formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  onChangePageIndex(pageIndex) {
    console.log(pageIndex);

    this.page = pageIndex;
    this.filterTable.page = pageIndex;
    this.allOperationsCashInData = this.showDataMonitor(this.filterTable);
  }

  onChangeItemPage(totalItems) {
    this.pagePer = totalItems;
    this.filterTable.paginate = totalItems;
    this.allOperationsCashInData = this.showDataMonitor(this.filterTable);
  }
}