import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { ChannelsService } from '../../../services/channels.service';
import { ProviderService } from '../../../services/provider.service';
import { Channel } from '../../interfaces/channel';
import { DataProviderCommission, RequestCommission } from '../../interfaces/provider';
import { InfoCardsProviderCommissions } from './provider-settings';

type NewType = ChannelsService;

@Component({
  selector: 'app-commissions-providers',
  templateUrl: './commissions-providers.component.html',
  styleUrls: ['./commissions-providers.component.scss']
})
export class CommissionsProvidersComponent implements OnInit {
  protected id: string;
  protected filterTypeControl = new FormControl('cashin', [Validators.required]);
  protected selectedCommissionCode = new FormControl('CI_001', [Validators.required]);
  protected formCards: FormGroup;
  protected configInputsCards: any[] = [];
  protected baseInputsCard = InfoCardsProviderCommissions.getInputs();
  protected listPaymentMethodsCashIn: DataProviderCommission[] = [];
  protected listPaymentMethodsCashOut: any[] = [];
  protected channels: Channel[] = [];
  protected commissions = [
    {
      CommissionId: 1,
      CommissionCode: "CI_001",
      Description: "STANDARD",
      ServiceId: 1,
    }
  ];

  constructor(private providerService: ProviderService, 
    private fb: FormBuilder, 
    private channelsService: ChannelsService, 
    private modalService: NzModalService,
    private route: ActivatedRoute,
    private message: NzMessageService
    ) {
    this.createForm();
  }

  ngOnInit() {
    const fullUrl = this.route.snapshot['_routerState'].url;    
    const segments = fullUrl.split('/');    
    this.id = segments[segments.length - 1];

    this.getProviderConfigurationById();
  }

  createForm(){
    const formGroup = new FormGroup({});
    this.baseInputsCard.forEach(element => {
      formGroup.addControl(element.formControlName, new FormControl({value: element.value, disabled: element.disabled}, element.validations));
    });
    this.formCards = this.fb.group({
      newCommission: formGroup,
      CI_001: this.fb.array([])
    });
  }

  changeFilterType(event) {
    this.createForm();
    this.getCards();
    this.createFormCards();
    this.loadData('channel', this.baseInputsCard, this.channels);
      this.configInputsCards.forEach((element) => {        
        this.loadData('channel', element.inputs, this.channels);
        const subchannels = this.channels.find(channel => channel.PaymentChannelID === element.id).subchannels;        
        this.loadData('subchannel', element.inputs, subchannels);
      });
  }

  createFormCards() {
    const channelArray = this.formCards.get('CI_001') as FormArray;
    channelArray.clear();
    this.configInputsCards.forEach((data, index) => {
      channelArray.push(this.createFormCard(data));
    });
    this.setChannelControl();
  }


  createFormCard(card) {
    const formGroup = new FormGroup({});
    if (!card.inputs) return formGroup;
      card.inputs.forEach(element => {
        let value = element.value
        if (!isNaN(element.value)) {
            if (typeof element.value == "boolean") {
                value = element.value
            }else{
                value = Number(element.value)
            }    
        }          
        formGroup.addControl(element.formControlName, new FormControl({value, disabled: element.disabled}, element.validations));
    });
    return formGroup;
  }

  changeCommission(event) {
    console.log(event);
  }

  changeSelectFormBase(key) {
    if (key === 'channel') {
      if (this.formGroupNewCommission.get('channel').value !== null) {
        this.loadData('subchannel', this.baseInputsCard, this.channels.find(channel => channel.PaymentChannelID === this.formGroupNewCommission.get('channel').value).subchannels);
        this.formGroupNewCommission.get('subchannel').enable();
      } else {
        this.formGroupNewCommission.get('subchannel').disable();
        this.formGroupNewCommission.get('subchannel').setValue(null);
      }
      
    }
  }

  getProviderConfigurationById() {
    this.providerService.getProviderConfigurationById(this.id).subscribe((res) => {
      this.listPaymentMethodsCashIn = res.data.cashin ? res.data.cashin : [];
      this.listPaymentMethodsCashOut = res.data.cashout ? res.data.cashout : [];
      this.getPaymentChannels();
      this.getCards();
      this.createFormCards();
    });
  }

  getCards() {
    this.configInputsCards = [];
    if(this.filterTypeControl.value === 'cashin') {
      this.listPaymentMethodsCashIn.forEach(element => {
        element.data.forEach(data => {
          this.configInputsCards.push(InfoCardsProviderCommissions.getFormCard(element.channelName, element.paymentChannelId, data)); 
        });
      });    
    }
    if(this.filterTypeControl.value === 'cashout') {
      this.listPaymentMethodsCashOut.forEach(element => {
        element.data.forEach(data => {
          this.configInputsCards.push(InfoCardsProviderCommissions.getFormCard(element.channelName, element.paymentChannelId, data)); 
        });
      });
    }
  }

  get formArrayCI001(): FormArray {
    return this.formCards.get('CI_001') as FormArray;
  }

  get formGroupNewCommission(): FormGroup {
    return this.formCards.get('newCommission') as FormGroup;
  }

  updateCommission(index: number, card: any) {
    const formCommission: FormGroup = this.formArrayCI001.at(index) as FormGroup;
    const dataCommission = formCommission.getRawValue();
    
    const commission = {
      providerCode: Number(this.id), // 40042
      providerConfigurationId: card.providerConfigurationId,
      paymentChannelId: dataCommission.channel,
      paymentSubChannelId: dataCommission.subchannel,
      serviceId: this.filterTypeControl.value === 'cashin' ? '1' : '2',
      fee: dataCommission.fee.value,
      otherFee: dataCommission.otherFee.value,
      minimum: dataCommission.minimum,
      maximum: dataCommission.maximum,
      tax: dataCommission.tax,
      timeAverageResponse: dataCommission.timeAverageResponse,
      isPercentageFee: dataCommission.fee.isPercentage,
      isPercentageOtherFee: dataCommission.otherFee.isPercentage,
    }

    this.modalService.confirm({
      nzTitle: '¿Estás seguro de actualizar esta comisión?',
      nzContent: 'Esta acción no se puede deshacer',
      nzOkText: 'Si',
      nzOnOk: () => {
        this.providerService.updateCommission(commission).subscribe((res) => {
          this.createMessage('success', 'Comisión actualizada correctamente')    
          this.getProviderConfigurationById();
        });
      }
    })
  }

  getPaymentChannels() {
    this.channelsService.getAllChannels().subscribe((res) => {
      this.channels = res
      this.loadData('channel', this.baseInputsCard, this.channels);
      this.configInputsCards.forEach((element) => {        
        this.loadData('channel', element.inputs, this.channels);
        const subchannels = this.channels.find(channel => channel.PaymentChannelID === element.id).subchannels;        
        this.loadData('subchannel', element.inputs, subchannels);
      });
    });
  }

  saveCommission(){
    const commission: RequestCommission = {
      providerCode: Number(this.id), // 40042
      paymentChannelId: this.formGroupNewCommission.get('channel').value,
      paymentSubChannelId: this.formGroupNewCommission.get('subchannel').value,
      serviceId: this.filterTypeControl.value === 'cashin' ? '1' : '2',
      fee: this.formGroupNewCommission.get('fee').value.value,
      otherFee: this.formGroupNewCommission.get('otherFee').value.value,
      minimum: this.formGroupNewCommission.get('minimum').value,
      maximum: this.formGroupNewCommission.get('maximum').value,
      tax: this.formGroupNewCommission.get('tax').value,
      timeAverageResponse: this.formGroupNewCommission.get('timeAverageResponse').value,
      isPercentageFee: this.formGroupNewCommission.get('fee').value.isPercentage,
      isPercentageOtherFee: this.formGroupNewCommission.get('otherFee').value.isPercentage,
    }
    this.providerService.createCommission(commission).subscribe((res) => {   
      this.createMessage('success', 'Comisión creada correctamente')   
      this.formGroupNewCommission.patchValue({
        channel: null,
        subchannel: null,
        fee: {
          value: '0',
          isPercentage: false
        },
        otherFee: {
          value: '0',
          isPercentage: false
        },
        minimum: '0',
        maximum: '0',
        tax: '0',
        timeAverageResponse: '0',
      });
      this.formGroupNewCommission.markAsUntouched();
      this.formGroupNewCommission.markAsPristine();
      this.getProviderConfigurationById();
    });
    
  }

  removeCommission(index: number) {
    this.modalService.confirm({
      nzTitle: '¿Estás seguro de eliminar esta comisión?',
      nzContent: 'Esta acción no se puede deshacer',
      nzOkText: 'Si',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.providerService.removeCommission(this.configInputsCards[index].providerConfigurationId).subscribe((res) => {
          this.createMessage('success', 'Comisión eliminada correctamente')
          this.configInputsCards.splice(index, 1);
          this.formArrayCI001.removeAt(index);
          this.getProviderConfigurationById();
        })
      },
    })
  }

  loadData(key: string, inputs: any[], data: any[]) {    
      inputs.forEach(input => {
        if(input.formControlName === key) {
          input.options = data;          
        }
      });      
  }

  setChannelControl() {
    this.configInputsCards.forEach((element, index) => {     
      element.inputs.forEach(input => {
        if (input.formControlName === 'channel') {          
          this.formArrayCI001.at(index).get('channel').setValue(element.id);
          this.formArrayCI001.at(index).get('channel').disable();
        }
        if (input.formControlName === 'subchannel') {
          this.formArrayCI001.at(index).get('subchannel').enable();
        }
      });
       
    });
    
  }

  createMessage(type: string, message: string): void {
    this.message.create(type, message);
  }
  
}
