import { Component, OnInit } from '@angular/core';
import { InfoBankForm } from "../../components/form-data-bank/info-bank.form";
import { UtilsService } from "../../../services/utils.service";
import { Bank } from "../../interfaces/bank";
import { BankService } from "../../../services/bank.service";
import { ActivatedRoute } from "@angular/router";
import { FormField } from "../../interfaces/form";

@Component({
  selector: 'app-bank-edit',
  templateUrl: './bank-edit.component.html',
  styleUrls: ['./bank-edit.component.scss']
})
export class BankEditComponent implements OnInit {
  bank: Bank;
  bankFormSettings: FormField[] = []
  countries: any[] = [];
  bankCode: string | number = this.aR.snapshot.paramMap.get('id') ? this.aR.snapshot.paramMap.get('id') : null;

  constructor(private utilsService: UtilsService, private bankService: BankService, private aR: ActivatedRoute, ) { }

  ngOnInit() {
    this.getBankDataById()
    this.getCountries()
  }

  async getCountries() {
    this.countries = await this.utilsService.getDataCountry();
    this.setOptionsCountries();
  }

  setOptionsCountries() {
    this.bankFormSettings.map((item: FormField) => {
      if (item.key === 'country' && item.options) {
        item.options = this.countries;
      }
    })
  }

  getBankDataById() {
    this.bankService.getBanks({ page: 1, size: 1, bankCode: Number(this.bankCode)}).subscribe( (response) =>{
      this.bank = response.data[0];
      this.bankFormSettings = InfoBankForm.getForm(this.bank);
    })
  }



  updateBank(data) {
    console.log(data);
  }

}
