import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/services/api.service';
import { AccessService } from '../../../../../services/access.services';
import { LoaderService } from '../../../../../services/loader.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})

export class BalanceComponent implements OnInit {

  public dataInfo: any = this.getDataInfo();
  public filters: any = this.getFilters();
  public titlePage: string = 'Actividades > Cash Out > Balance';

  titleModal: any = '';
  isVisible: boolean = false;
  isVisibleRefundModal: boolean = false;
  operationNumber: any = '';
  listBanksCashin: any;
  formNewBalance: FormGroup;
  formRefund: FormGroup;
  okText: any = '';
  messageModal: string;
  dataEventModal: any;
  dataNewBalance: any;
  isAlertVisible = false;
  isAlertErrorVisible = false;
  dataMerchant: any;
  errorMessage = '';
  currencies = [];
  banks = [];
  isLoading = false;
  currentFilters: any;
  isVisibleModalCode = false;
  showQrGoogle = false;
  qrAuthGoogle = '';
  tokenPayroll = '';
  private patternDecimal = /^(0|[1-9][0-9]*)(\.[0-9]+)?$/;


  constructor(
    private api: ApiService, 
    private modalService: NzModalService,
    private router: Router, 
    private fb: FormBuilder,
    private loaderService: LoaderService,
    private accessService: AccessService,
    ) {
    this.formNewBalance = this.createFormGroup();
    this.createFormRefund();
  }

  ngOnInit() {
    this.getCurrencies();
  }

  createFormGroup() {
    return new FormGroup({
      MerchantCode: new FormControl('', [Validators.required]),
      BankCode: new FormControl('', [Validators.required]),
      Reference: new FormControl('', [Validators.required]),
      Amount: new FormControl('', [Validators.required]),
      CurrencyId: new FormControl('USD', [Validators.required]),
    });
  }

  createFormRefund() {
    this.formRefund = this.fb.group({
      description: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
      is_partial: [false, [Validators.required]],
      amount: [''],
      reference: ['', [Validators.required]],
      currency_code: [null, [Validators.required]],
      bank_id: [{value: null, disabled: true}, [Validators.required]],
      merchantCode: [null, [Validators.required]],
    });

    this.MerchantCodeRefund.valueChanges.subscribe(value => {
      if (value && this.CurrencyCode.value) {
        this.BankId.setValue(null);
        this.getBanks();
        this.BankId.enable();
      }
    })

    this.CurrencyCode.valueChanges.subscribe(value => {
      if (value && this.MerchantCodeRefund.value) {
        this.BankId.setValue(null);
        this.getBanks();
        this.BankId.enable();
      }
    })
  }

  getCurrencies() {
    this.api.queryGet('currencies').subscribe((result: any) => {
      this.currencies = result;
    })
  }

  getBanks(){
    const params = {
      merchantCode: this.MerchantCodeRefund.value.value,
      currencyCode: this.CurrencyCode.value
    };
    this.api.queryGet('banks/balance', params).subscribe((result: any) => {
      this.banks = result.data;
    })
  }

  async getDataInfo(dataInfo: any = false) {
    const data = {
      service: 'balance/listBalance',
      data: []
    };

    const headers = [
      {
        'name': 'Number Account',
        'key': 'NumberAccount',
        'opc': true,
        responsive: true,
        type: 'link',
      },
      {
        'name': 'Cliente',
        'key': 'BussinesName',
        'opc': true,
        responsive: true,
      },
      {
        'name': 'Total Amount',
        'key': 'TotalAmount',
        type: 'number',
        'opc': true
      },
      {
        'name': 'Amount Available',
        'key': 'AmountAvailable',
        type: 'number',
        'opc': true
      },
      {
        'name': 'Max Amount',
        'key': 'MaxAmount',
        type: 'number',
        'opc': true
      },
      {
        'name': 'MinAmount',
        'key': 'MinAmount',
        type: 'number',
        'opc': true
      },
      {
        'name': 'Currency',
        'key': 'CurrencyCode',
        'opc': true
      },
      {
        'name': 'Active',
        'key': 'IsActive',
        'opc': true
      }
    ];

    if (dataInfo !== false) {
      data.service = 'balance/listBalanceWithParams';
      data.data = dataInfo;
    }

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'activities-cashout-balance',
      getUrl: (id) => `/balance-detail/${id}`
    };

  }


  async getFilters() {

    const data = {
      service: 'merchants'
    };
    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
          id: element.MerchantID
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    // const dataStatus = this.api.getStatus();
    this.dataMerchant = dataMerchant;

    return [

      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      },
      {
        'name': 'Devolución',
        'key': 'refund',
        'type': 'button',
      },
      {
        'name': 'Nuevo balance',
        'key': 'new-balance',
        'type': 'button',
      }
    ];

  }

  //EVENTS OUTPUT

  changeDataTable(data) {
    switch (data) {
      case 'new-balance':
        this.getDataBankCashin().then((result: any) => {
          this.listBanksCashin = result;
          this.titleModal = 'Nuevo Balance';
          this.dataEventModal = 'newBalance';
        }).catch(error => {
          alert(error.message);
          if (error.status === 401) {
            sessionStorage.setItem('ud', '');
            this.router.navigate(['/']);
          }
        });
        this.showModal();
        this.okText = "Crear";
        break;
      case 'refund':
        this.isVisibleRefundModal = true;
        break;

      default:
        this.currentFilters = data.mcode ? data : null;
        this.dataInfo = this.getDataInfo(data);
        break;
    }

  }

  functions(e) {
    console.log(e);
  }

  async getDataBankCashin() {
    let data = {
      service: 'listBanksCashin'
    }
    return await this.api.api(data).toPromise();
  }


  async newBalance() {
    const data = {
      service: 'balance/create',
      data: {
        MerchantCode: this.MerchantCode.value,
        BankCode: this.BankCode.value,
        Reference: this.Reference.value,
        Amount: this.Amount.value,
        CurrencyId: this.CurrencyId.value,
        Metadata: JSON.stringify(JSON.parse(sessionStorage.getItem('ud')).userDetails),
        Type: '1',
        Description: 'Ingreso de saldo'
      }
    };
    return await this.api.api(data).toPromise();

  }


  //MODAL

  showModal(): void {
    this.isVisible = true;
  }

  handleAlertNotification(): void {
    this.isAlertVisible = true;
    this.dataInfo = this.getDataInfo();
    this.formNewBalance = this.createFormGroup();
    var that = this;
    setTimeout(function () {
      that.isAlertVisible = false;
    }, 3000);
  }

  handleAlertErrorNotification(): void {
    this.isAlertErrorVisible = true;
    this.dataInfo = this.getDataInfo();
    this.formNewBalance = this.createFormGroup();
    var that = this;
    setTimeout(function () {
      that.isAlertErrorVisible = false;
      that.errorMessage = '';
    }, 3000);
  }

  handleOk(): void {
    this.isVisibleModalCode = true;
  }

  showModalSuccess(message): void {
    this.modalService.success({
      nzTitle: '¡Éxito!',
      nzContent: message,
      nzOkText: 'Aceptar',
      nzOnOk: () => console.log('OK')
    });
  }

  showModalError(message): void {
    this.modalService.error({
      nzTitle: '¡Error!',
      nzContent: message ? message : 'Ha ocurrido un error',
      nzOkText: 'Aceptar',
      nzOnOk: () => console.log('OK')
    });
  }

  handleCancel(): void {
    this.formNewBalance.reset();
    this.isVisible = false;
  }

  handleCancelRefund(): void {
    this.createFormRefund();
    this.isVisibleRefundModal = false;
  }

  onChangeIsPartial(){
    if (this.IsPartial.value){
      this.AmountRefund.setValidators([Validators.required]);
    } else {
      this.AmountRefund.setValidators([]);
    }
    this.AmountRefund.updateValueAndValidity();
  }

  cancelCode() {
    this.isVisibleModalCode = false;
    this.tokenPayroll = '';
  }

  onOkModalToken() {
    this.validateToken();
  }

  validateToken() {
    this.accessService.validateAuthToken(this.tokenPayroll).subscribe((result: any) => {
      this.isVisibleModalCode = false;
      this.tokenPayroll = '';
      this.newBalance().then((result: any) => {
        if (result.success) {
          const message = result.message ? result.message : 'Se ha creado el balance correctamente';
          this.dataInfo = this.getDataInfo(this.currentFilters);
          this.showModalSuccess(message);
        } else {
          this.showModalError(result.message);
        }
        this.isVisible = false;
        this.okText = "Aceptar";
      }).catch(error => {
        this.showModalError(error.message);
      }).finally(() => {
        this.formNewBalance.reset();
      });
    }, (error) => {
      this.tokenPayroll = '';
      this.isVisibleModalCode = false;
      this.modalService.error({
        nzTitle: 'Mensaje de error',
        nzContent: `<ul> ${error.error.message} </ul>`,
        nzWidth: '500px',
      });
    });
  }

  resetQr(){
    this.accessService.getQrGoogle(true).subscribe(resulQr => {
      this.showQrGoogle = true;
      this.qrAuthGoogle = resulQr.data;
    });
  }

  createRefund() {
    if (this.formRefund.invalid) {
      this.formRefund.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    const url = `cashout/operations/${this.MerchantCodeRefund.value.id}/return-balance`;
    const {merchantCode, ...values} = this.formRefund.value;
    const data = {
      ...values,
      metadata: JSON.parse(sessionStorage.getItem('ud')).userDetails
    }

      this.api.queryPost(url, data).subscribe(() => {
        this.isLoading = false;
        this.isVisibleRefundModal = false;
        this.dataInfo = this.getDataInfo()
        this.modalService.success({
          nzTitle: 'Devolución',
          nzContent: 'La devolución se realizó con éxito',
        });
        this.formRefund.reset()
        }, () => {
        this.isLoading = false;
      }
    );
  }

  get MerchantCode() { return this.formNewBalance.get('MerchantCode'); }
  get BankCode() { return this.formNewBalance.get('BankCode'); }
  get Reference() { return this.formNewBalance.get('Reference'); }
  get Amount() { return this.formNewBalance.get('Amount'); }
  get CurrencyId() { return this.formNewBalance.get('CurrencyId'); }

  get IsPartial() { return this.formRefund.get('is_partial'); }
  get Description() { return this.formRefund.get('description'); }
  get AmountRefund() { return this.formRefund.get('amount'); }
  get ReferenceRefund() { return this.formRefund.get('reference'); }
  get MerchantCodeRefund() { return this.formRefund.get('merchantCode'); }
  get CurrencyCode() { return this.formRefund.get('currency_code'); }
  get BankId() { return this.formRefund.get('bank_id'); }


  // Validations
  get MerchantCodeRefundValid() { return this.MerchantCodeRefund.invalid && this.MerchantCodeRefund.touched; }
  get ReferenceRefundValid() { return this.ReferenceRefund.invalid && this.ReferenceRefund.touched; }
  get IsPartialValid() { return this.IsPartial.invalid && this.IsPartial.touched; }
  get AmountRefundValid() { return this.AmountRefund.invalid && this.AmountRefund.touched; }
  get CurrencyCodeValid() { return this.CurrencyCode.invalid && this.CurrencyCode.touched; }
  get DescriptionValid() { return this.Description.invalid && this.Description.touched; }

}
