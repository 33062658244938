import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd';
import { AccessService } from 'src/app/services/access.services';
import { AuthService } from 'src/app/services/auth.service';
import { AlertsService } from '../../../services/alerts.service';
import { BankService } from "../../../services/bank.service";
import { MerchantService } from "../../../services/merchant.service";

export interface ResponseDataQR {
	statusCode: number;
	status: boolean;
	message: string;
	data: string;
	metadata: any;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  response:string = '';
  public isLogged: boolean = false;
  formLogin: FormGroup;
  protected ud: any;
  iconVisiblePassword = 'eye-invisible';

  protected isVisibleTokenModal = false;
  protected showQrGoogle = false;
  protected qrAuthGoogle = '';
  protected isOkLoading = false;
  protected otpControl = new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]);

  constructor(
    private router: Router,
    private authService: AuthService, 
    private merchantService: MerchantService, 
    private bankService: BankService, 
    private accessService: AccessService,
    private alertsService: AlertsService,
    private nzModalService: NzModalService
    ) {
    this.formLogin = this.createFormGroup();
   }

  createFormGroup() {
    return new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  onLogin(): void {    
    if (this.formLogin.invalid) {
      this.formLogin.markAllAsTouched();
      return;
    }

    this.isOkLoading = true;
      const data = {
        email: this.formLogin.get('email').value,
        password: this.formLogin.get('password').value,
      }


      this.authService.api({...data, service: 'auth/login'}).subscribe((result: any) => {    
        if (result.p !== 'Error en credenciales') {
          this.ud = result;
          this.loginAccess(data)
        } else   {
          this.response = JSON.stringify('Error en credenciales');
          this.isOkLoading = false;
        }
      },
        error => {
          this.isOkLoading = false;
          if (error.status === 401) {
            this.response = 'Error en el correo o contraseña';
          }
        });
  }


  ngOnInit() {
  }

  loginMerchant(data) {
    this.merchantService.login(data).subscribe((res: any) => {
      localStorage.setItem('authMerchant', JSON.stringify(res));
    })
  }

  loginBank(data) {
    this.bankService.login(data).subscribe((res: any) => {
      localStorage.setItem('authBank', JSON.stringify(res));
    })
  }

  loginAccess(data){
    this.accessService.login(data).subscribe((res: any) => {      
      localStorage.setItem('authAccess', JSON.stringify(res));
      this.isOkLoading = false;
      this.validateRegisterUserGoogle(this.ud.userDetails.profileid);
    })
  }

  validateRegisterUserGoogle(userId: number) {
    this.accessService.isUseTokenWithUserIdGoogle(userId).subscribe((res: ResponseDataQR) => {
      this.isOkLoading = false;
      if(!res.data){
        this.showQrGoogle = true;
        this.isOkLoading = true;
        this.accessService.getQrWithUseridGoogle(userId, false).subscribe(resulQr => {
          this.isOkLoading = false;
          this.qrAuthGoogle = resulQr.data;
          this.isVisibleTokenModal = true;
        });
      } else {
        this.showQrGoogle = false;
        this.qrAuthGoogle = '';
        this.isVisibleTokenModal = true;
      }
    });
  }

  handleCancelTokenModal() {
    this.isVisibleTokenModal = false;
    this.otpControl.reset();
  }

  handleOkTokenModal() {
    this.isOkLoading = true;
    const data = {
      email: this.formLogin.get('email').value,
      password: this.formLogin.get('password').value,
    }
    this.accessService.validateAuthToken(this.otpControl.value, this.ud.userDetails.profileid).subscribe((res: any) => {
      this.isOkLoading = false;
      this.isVisibleTokenModal = false;
      this.loginMerchant(data);
      sessionStorage.setItem('ud', JSON.stringify(this.ud));
      this.router.navigate(['/']);
    }, () => {
      this.isOkLoading = false;
      this.isVisibleTokenModal = false;
      this.otpControl.reset();
      this.error('Token incorrecto');
    });
  }

  updateAlert(alertId: number, status) {
    this.alertsService.updateAlert(alertId, status).subscribe((res: any) => {
      this.isOkLoading = false;
      this.isVisibleTokenModal = false;
    }, error => {
      this.isOkLoading = false;
      this.isVisibleTokenModal = false;
    });
  }

  error(error): void {
    this.nzModalService.error({nzTitle: 'Mensaje de error', nzContent: error});
  }

  onResetForm() {
    this.formLogin.reset();
  }

  toggleVisiblePassword() {
    let inputPassword = document.getElementById('password');
    if (inputPassword.getAttribute('type') == 'password') {
      inputPassword.setAttribute('type', 'text');
      this.iconVisiblePassword = 'eye';
    } else {
      inputPassword.setAttribute('type', 'password');
      this.iconVisiblePassword = 'eye-invisible';
    }
  }

  get email() { return this.formLogin.get('email'); }
  get password() { return this.formLogin.get('password'); }


}
