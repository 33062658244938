import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-bots',
  templateUrl: './bots.component.html',
  styleUrls: ['./bots.component.scss']
})
export class BotsComponent implements OnInit {

  public allOperationsCashInData: any = this.allOperationsCashIn();
  public filters: any = this.getFilters();
  public titlePage: string = 'Monitor > Bots';

  constructor(private api: ApiService, private router: Router) {

  }

  private updateData:  Subscription;

  ngOnInit() {
    this.updateData = interval(5000).subscribe(
      () => {this.allOperationsCashInData =  this.allOperationsCashIn();}
    );
  }

  async allOperationsCashIn(dataInfo: any = false) {
    const data = {
      service: 'bots',
      data: []
    };

    const headers = [
      {
        'name': 'BotID',
        'key': 'RobotID',
        'opc': true,
        'type': 'link',
      },
      {
        'name': 'Description',
        'key': 'Description',
        'opc': true,
        'responsive': true,
      },
      {
        'name': 'Status',
        'key': 'status',
        'opc': true,
        'type': 'status',
        'responsive': true,
        position: 'right'
      },
      {
        'name': 'CountErrors',
        'key': 'CountErrors',
        'opc': true
      },
      {
        'name': 'Ultima ejecucion',
        'key': 'updated_at',
        'opc': true
      },
      {
        'name': 'Acciones',
        'key': 'buttons',
        'module': 'bots',
        'opc': false,
        'type': 'buttons',
        'buttons': [
          {
            'label': 'Prender',
            'key': 'turnOn',
            'type': 'primary',
          },
          {
            'label': 'Apagar',
            'key': 'off',
            'type': 'danger',
          },
          {
            'label': 'Reiniciar',
            'key': 'restart',
            'type': 'secondary',
          }
        ]
      },
    ];

    if (dataInfo !== false) {
      data.service = 'listAllOperationCashinWithParams';
      data.data = dataInfo;
    }

    return {
      headers: headers,
      data: await this.api.api(data).toPromise(),
      source: 'bots',
      getUrl: (id) => `/bots-details/${id}-bots`,
    };

  }

  async getFilters(){

    const data = {
      service: 'merchants'
    };

    const dataStatus = this.api.getStatus();

    const dataMerchant = [];
    await this.api.api(data).toPromise().then((result: any) => {

      result.forEach(element => {
        dataMerchant.push({
          name: element.BussinesName,
          value: element.MerchantCode,
        });
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });

    return [
      {
        'name': 'Código de pago',
        'key': 'paymentCode',
        'type': 'text'
      },
      {
        'name': 'Estado',
        'key': 'status',
        'type': 'select',
        'data': dataStatus
      },
      {
        'name': 'Referencia Comercial',
        'key': 'merchantSalesID',
        'type': 'text'
      },
      {
        'name': 'N° Operación',
        'key': 'PublicId',
        'type': 'text'
      },
      {
        'name': 'Periodo',
        'key': ['from_date','to_date'],
        'type': 'period'
      },
      {
        'name': 'Comercio',
        'key': 'mcode',
        'type': 'select',
        'data': dataMerchant
      }

    ];

  }

  functions(func) {
    const route = `bots/${func.robotId}`;
    const status = func.action === 'turnOn' || func.action === 'restart' ? 1 : 3;
    this.api.queryPut(route, {Status: status, CountErrors: 0}).subscribe(
      () => {
        this.allOperationsCashInData = this.allOperationsCashIn();
      }
    );
  }

  changeDataTable(data) {
    this.allOperationsCashInData = this.allOperationsCashIn(data);
  }

}
