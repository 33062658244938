import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';
import { MetadataConciliation, RowConciliation } from '../../../../models/response-conciliation-file';

@Component({
  selector: 'app-modal-conciliation',
  templateUrl: './modal-conciliation.component.html',
  styleUrls: ['./modal-conciliation.component.scss']
})
export class ModalConciliationComponent {
  @Input() type: string;
  @Input() data: RowConciliation[];
  @Input() summary: MetadataConciliation;

  tableHeaders: string[] = ['No. Fila', 'Mensaje', 'Transacciones'];

  constructor(private modal: NzModalRef) { }

  destroyModal(): void {
    this.modal.destroy();
  }

}
