import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-detail1',
  templateUrl: './detail1.component.html',
  styleUrls: ['./detail1.component.scss']
})
export class Detail1Component implements OnInit {

  @Input() data: any;

  headers: any;
  dataDetails: any;
  payer: any[] = []

  constructor(private router: Router) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.data.then((result: any) => {
      this.headers = result.headers;
      if (result.payer) {
        this.payer = result.payer;
      }
      result.data.then((result: any) => {
        this.dataDetails = result;
      });
    }).catch(error => {
      if (error.status === 401) {
        sessionStorage.setItem('ud', '');
        this.router.navigate(['/']);
      }
    });
  }

  getDataKeys(e, h) {
    if (e) {
      if (h.key.indexOf('/') > -1) {
        const keys = h.key.split('/');
        return e[keys[0].split('-')[0]][keys[0].split('-')[1]] + ' ' + e[keys[1].split('-')[0]][keys[1].split('-')[1]];
      } else {
        return this.getDataObject(e, h.key);
      }

    }
  }

  getDataObject(data, key) {
    const keys = key.split('-');
    let value = data;
    for (let i = 0; i < keys.length; i++) {
      value = value[keys[i]];
    }
    return value;
  }

  getStatusPayroll(value) {
    const status = {
      0: {color: 'red', label: 'Anulado'},
      1: {color: 'orange', label: 'En creacion'},
      2: {color: 'lime', label: 'Creado'},
      3: {color: 'blue', label: 'Aprobado'},
      4: {color: 'green', label: 'Observaciones'},
      5: {color: 'cyan', label: 'Procesado'},
      6 : {color: 'volcano', label: 'Rechazado'},
      7 : {color: 'red', label: 'Error al crear'},
      8 : {color: 'volcano', label: 'Error al enviar'},
    }
    return status[value] ? status[value] : {color: 'grey', label: value};
  }

}
