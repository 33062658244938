import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AccessService } from './services/access.services';
import { ApiService } from './services/api.service';
import { IdleService } from './services/idle.service';
import { LoaderService } from "./services/loader.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Wepay4U';
  loading = false;
  private timeoutIdle$!: Subscription;
	private timeoutIdleWarning$!: Subscription;
	private interruptIdle$!: Subscription;
  protected countdown$ = new BehaviorSubject<number>(0);
  protected dialogIsOpen = false;
  protected isModalVisible = false;

  constructor(
    private loaderService: LoaderService, 
    private idleService: IdleService, 
    private apiService: ApiService, 
    private accessService: AccessService) {
  }

  ngOnInit() {
    this.loaderService.loading$.subscribe(loading => this.loading = loading);

    this.interruptIdle$ = this.idleService.onInterrupt.subscribe(() => {
      this.isModalVisible = false;


		});

		this.timeoutIdleWarning$ = this.idleService.onTimeoutWarning.subscribe((countdown: number) => {
			this.showWarningInactivity();
      this.countdown$.next(countdown);
      
		});

		this.timeoutIdle$ = this.idleService.onTimeout.subscribe(() => {
      this.isModalVisible = false;
			this.apiService.logout();

		});

    this.accessService.authData$.subscribe((data) => {
			if (data) {        
				this.idleService.startWatching();
			} else {
        this.idleService.stopWatching();
      }
		});
  }

  ngOnDestroy(): void {
		this.idleService.stopWatching();
		if (this.timeoutIdle$) {
			this.timeoutIdle$.unsubscribe();
		}

		if (this.timeoutIdleWarning$) {
			this.timeoutIdleWarning$.unsubscribe();
		}

		if (this.interruptIdle$) {
			this.interruptIdle$.unsubscribe();
		}

    this.countdown$.unsubscribe();


    this.isModalVisible = false;
	}

  showWarningInactivity() {
    this.isModalVisible = true;
  }

  handleOk(): void {
    this.isModalVisible = false;
    this.idleService.resetTimer();
  }

  handleCancel(): void {
    this.isModalVisible = false;
    this.idleService.resetTimer();
  }
}
