import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from "@angular/forms";

@Component({
  selector: 'app-filter-inputs',
  templateUrl: './filter-inputs.component.html',
  styleUrls: ['./filter-inputs.component.scss']
})
export class FilterInputsComponent implements OnInit {
  @Input() dataFields: any;
  @Input() form: FormGroup;
  @Output() eventChangePeriod: EventEmitter<any> = new EventEmitter();
  @Output() eventChangeType: EventEmitter<any> = new EventEmitter();

  public defultDate = [];
  constructor() { 
    this.defultDate.push(new Date());
    this.defultDate.push(new Date());
  }

  ngOnInit() {
    this.dataFields.forEach((e: any) => {
      if (e.value) {
        if (e.key === 'range') {
          this.defultDate = e.value;
        }
        this.form.get(e.key).setValue(e.value);
      }
    });
    
  }

  onChangePeriod(event) {
    this.eventChangePeriod.emit(event);
  }

  onChangeType(event) {
    this.eventChangeType.emit(event);
  }

}
