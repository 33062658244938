import { Validators } from "@angular/forms";
import { Commission, RequestCommission } from "../../../../../settings/interfaces/merchant";
import { Validations } from "../../../../../validations/validations";

const patternDecimals = '^[0-9]+(\.[0-9]{1,3})?$';

export  class InfoCardsCommissions {
    static getInputs(values?: Commission) {        
        return [
            {
                label: 'Fee*',
                value:  {
                    value: values && values.fee || '0',
                    isPercentage: values && values.isPercentageFee || false
                },
                disabled: false,
                readonly: false,
                formControlName: 'fee',
                placeholder: '',
                type: 'percentage',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Other Fee*',
                value:  {
                    value: values && values.otherFee || '0',
                    isPercentage: values && values.isPercentageOtherFee || false
                },
                disabled: false,
                readonly: false,
                formControlName: 'otherFee',
                placeholder: '',
                type: 'percentage',
                validations:  [ Validators.required],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Minimum*',
                value: values && values.minimum || '0',
                disabled: false,
                readonly: false,
                formControlName: 'minimum',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Maximum*',
                value: values && values.maximum || '0',
                disabled: false,
                readonly: false,
                formControlName: 'maximum',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'IVA*',
                value: values && values.tax || '0',
                disabled: false,
                readonly: false,
                formControlName: 'tax',
                placeholder: '',
                type: 'tax',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
        ]
    }

    static getInputsChannel(values?: Commission) {
        return [
            {
                label: 'Fee*',
                value:  {
                    value: values && values.fee || '0',
                    isPercentage: values && values.isPercentageFee || false
                },
                disabled: false,
                readonly: false,
                formControlName: 'fee',
                placeholder: '',
                type: 'percentage',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Other Fee*',
                value:  {
                    value: values && values.otherFee || '0',
                    isPercentage: values && values.isPercentageOtherFee || false
                },
                disabled: false,
                readonly: false,
                formControlName: 'otherFee',
                placeholder: '',
                type: 'percentage',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Minimum*',
                value: values && values.minimum || '0',
                disabled: false,
                readonly: false,
                formControlName: 'minimum',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Maximum*',
                value: values && values.maximum || '0',
                disabled: false,
                readonly: false,
                formControlName: 'maximum',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'IVA*',
                value: values && values.tax || '0',
                disabled: false,
                readonly: false,
                formControlName: 'tax',
                placeholder: '',
                type: 'tax',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Commission default',
                value: values && values.commissionDefault || false,
                disabled: false,
                readonly: false,
                formControlName: 'commissionDefault',
                placeholder: '',
                type: 'checkbox',
                col: 'col-md-6',
                validations:  [  ],
                errors: [],
            },
        ]
    }

    static getInputsStaggered(values?: Commission) {
        return [
            {
                label: 'From*',
                value: values && values.conditional.split('-')[0] || null,
                disabled: false,
                readonly: false,
                formControlName: 'from',
                placeholder: '1',
                type: 'text',
                validations:  [ Validators.required, Validators.pattern(patternDecimals), Validations.lessThan('until') ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                    { error: 'lessThan', message: 'Debe ser menor que el campo Until'},
                ],
            },
            {
                label: 'Other Fee*',
                value:  {
                    value: values && values.otherFee || '0',
                    isPercentage: values && values.isPercentageOtherFee || false
                },
                disabled: false,
                readonly: false,
                formControlName: 'otherFee',
                placeholder: '',
                type: 'percentage',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Until*',
                value: values && values.conditional.split('-')[1] || null,
                disabled: false,
                readonly: false,
                formControlName: 'until',
                placeholder: '100',
                type: 'text',
                validations:  [ Validators.required, Validators.pattern(patternDecimals), Validations.greaterThan('from') ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                    { error: 'greaterThan', message: 'Debe ser mayor que el campo From'},
                ],
            },
            {
                label: 'Fee*',
                value:  {
                    value: values && values.fee || '0',
                    isPercentage: values && values.isPercentageFee || false
                },
                disabled: false,
                readonly: false,
                formControlName: 'fee',
                placeholder: '',
                type: 'percentage',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Minimum*',
                value: values && values.minimum || '0',
                disabled: false,
                readonly: false,
                formControlName: 'minimum',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Maximum*',
                value: values && values.maximum || '0',
                disabled: false,
                readonly: false,
                formControlName: 'maximum',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'IVA*',
                value: values && values.tax || '0',
                disabled: false,
                readonly: false,
                formControlName: 'tax',
                placeholder: '',
                type: 'tax',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Commission default',
                value: values && values.commissionDefault || false,
                disabled: false,
                readonly: false,
                formControlName: 'commissionDefault',
                placeholder: '',
                type: 'checkbox',
                col: 'col-md-6',
                validations:  [  ],
                errors: [],
            },
        ]
    }
    static getInputsBank(banks, values?: any) {

        const bank = values ?  banks.find(b => Number(b.id) === Number(values.conditional)) : null;

        return [
            {
                label: 'Bank*',
                value: bank,
                disabled: false,
                readonly: false,
                formControlName: 'bank',
                placeholder: 'Choose a bank',
                optionLabel: 'bussinesName',
                optionValue: '',
                options: banks,
                type: 'select',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
            },
            {
                label: 'Fee*',
                value:  {
                    value: values && values.fee || '0',
                    isPercentage: values && values.isPercentageFee || false
                },
                disabled: false,
                readonly: false,
                formControlName: 'fee',
                placeholder: '',
                type: 'percentage',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Other Fee*',
                value:  {
                    value: values && values.otherFee || '0',
                    isPercentage: values && values.isPercentageOtherFee || false
                },
                disabled: false,
                readonly: false,
                formControlName: 'otherFee',
                placeholder: '',
                type: 'percentage',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Minimum*',
                value: values && values.minimum || 0,
                disabled: false,
                readonly: false,
                formControlName: 'minimum',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Maximum*',
                value: values && values.maximum || 0,
                disabled: false,
                readonly: false,
                formControlName: 'maximum',
                placeholder: '',
                type: 'text',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'IVA*',
                value: values && values.tax || 0,
                disabled: false,
                readonly: false,
                formControlName: 'tax',
                placeholder: '',
                type: 'tax',
                col: 'col-md-6',
                validations:  [ Validators.required, Validators.pattern(patternDecimals) ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                    { error: 'pattern', message: 'Número inválido'},
                ],
            },
            {
                label: 'Commission default',
                value: values && values.commissionDefault || false,
                disabled: false,
                readonly: false,
                formControlName: 'commissionDefault',
                placeholder: '',
                type: 'checkbox',
                col: 'col-md-6',
                validations:  [  ],
                errors: [],
            },
        ]
    }
    static getStandard(commission: RequestCommission, data?: Commission[]) {
        if (data && data.length) {
            const itemStandard = data.find((item) => item.commissionCode === commission.CommissionCode)
            if (itemStandard) {
                return [{
                    commissionId: itemStandard.merchantCommissionSetupId,
                    title: commission.Description,
                    inputs: this.getInputs(itemStandard),
                }]
            }
        }
        return [{title: commission.Description, inputs: this.getInputs()}]
    }
    static getChannels(channels, data?: any){
        return channels.map((channel) => {
            if(data && data.length){
                const card: Commission = data.find((item) => item.conditional === channel.channelName)
                if(card){
                    return {
                        commissionId: card.merchantCommissionSetupId,
                        title: channel.channelName,
                        inputs: this.getInputsChannel(card),
                    }
                }
            }
            return {
                title: channel.channelName,
                inputs: this.getInputsChannel(),
            }
        })
    }
    static getCurrencies(currencies, data?: any) {
        return currencies.map((currency) => {
            if(data && data.length){
                const card: Commission = data.find((item) => item.conditional === currency.CurrencyCode)
                if(card){
                    return {
                        commissionId: card.merchantCommissionSetupId,
                        title: currency.CurrencyCode,
                        inputs: this.getInputs(card),
                    }
                }
            }
            return {
                title: currency.CurrencyCode,
                inputs: this.getInputs(),
            }
        })
    }
    static getStaggered(commission, data?: any) {
        if (data && data.length) {
            return data.filter((item) => item.commissionCode === commission.CommissionCode)
        }
        return []
    }

    static getFormCards(commission: RequestCommission, options?: any[], data?: any) {
        switch (commission.CommissionCode) {
            case 'CI_001':
                return this.getStandard(commission, data.data)
            case 'CI_002':
                return this.getChannels(options, data.data)
            case 'CI_003':
                return this.getCurrencies(options, data.data)
            case 'CI_004':
                return this.getStaggered(commission, data.data)
            case 'CI_005':
                return this.getStaggered(commission, data.data)
            case 'CI_006':
                return this.getStaggered(commission, data.data)
            case 'CO_001':
                return this.getStandard(commission, data.data)
            case 'CO_002':
                return this.getStaggered(commission, data.data)
            case 'CO_003':
                return this.getStaggered(commission, data.data)
        }
    }

}
