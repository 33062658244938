import { Injectable, OnDestroy } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class IdleService implements OnDestroy {
	onTimeout = new Subject<boolean>();
	onTimeoutWarning = new Subject<number>();
	onInterrupt = new Subject<boolean>();

	private subscriptions = new Subscription();

	constructor(private idle: Idle) {
		// sets an idle timeout (15 minutes)
		idle.setIdle(840);
		// sets a timeout period of 60 seconds after idle
		idle.setTimeout(60);
		// sets the default interrupts, like mousemove, keydown, touchstart, etc
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

		this.attachEventListeners();
	}

	private attachEventListeners(): void {
		this.subscriptions.add(
			this.idle.onInterrupt.subscribe(() => {
				this.onInterrupt.next(true);
				this.resetTimer();
			})
		);

		this.subscriptions.add(
			this.idle.onTimeoutWarning.subscribe((countdown: number) => {
				this.onTimeoutWarning.next(countdown);
			})
		);

		this.subscriptions.add(
			this.idle.onTimeout.subscribe(() => {
				this.onTimeout.next(true);
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	startWatching() {
		console.log('startWatching');
		
		this.idle.watch();
	}

	stopWatching() {
		this.idle.stop();
	}

	resetTimer() {
		this.idle.watch();
	}
}
