import { Validators } from "@angular/forms";
import { FormField } from "../../interfaces/form";


export class InfoBankForm {
    static getForm(values: any = {}): FormField[] {
        return [
            {
                label: 'Legal name*',
                key: 'legalName',
                value: values && values.legalName || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'legalName',
                placeholder: 'Legal name',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Short name*',
                key: 'shortName',
                value: values && values.shortName || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'shortName',
                placeholder: 'Short name',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Account digits*',
                key: 'accountDigits',
                value: values && values.accountDigits || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'accountDigits',
                placeholder: 'Account digits',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'CCI digits*',
                key: 'cciDigits',
                value: values && values.cciDigits || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'cciDigits',
                placeholder: 'CCI digits',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'URL logo*',
                key: 'urlLogo',
                value: values && values.urlLogo || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'urlLogo',
                placeholder: 'URL logo',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'URL bank*',
                key: 'urlBank',
                value: values && values.urlBank || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'urlBank',
                placeholder: 'URL bank',
                type: 'text',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Country',
                key: 'country',
                value: values && values.countryCode || null,
                disabled: false,
                readonly: false,
                visible: true,
                optionLabel: '',
                optionValue: '',
                showSearch: true,
                formControlName: 'countryCode',
                placeholder: 'Country',
                type: 'select',
                options: [],
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            },
            {
                label: 'Color',
                key: 'color',
                value: values && values.color || '',
                disabled: false,
                readonly: false,
                visible: true,
                formControlName: 'color',
                placeholder: 'Color',
                type: 'color',
                validations:  [ Validators.required ],
                errors: [
                    { error: 'required', message: 'Campo requerido'},
                ],
                span: 8,
                mdSpan: 8,
            }
        ]
    }
}
