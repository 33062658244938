import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../../../../services/api.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-activities-details',
  templateUrl: './activities-details.component.html',
  styleUrls: ['./activities-details.component.scss']
})
export class ActivitiesDetailsComponent implements OnInit {

  public details: any = this.processDetail();
  // public historical: any = this.processHistorical();
  // public activity: any = this.processActivity();
  public titlePage: any;

  constructor(private aR: ActivatedRoute, private api:ApiService, private router: Router) { }

  ngOnInit() {
  }

  async getDetails(id, service) {
    const data = {
      service: service,
      id: id
    };

    return await this.api.api(data).pipe(map((resp: any) => {
      return {data: {
        ...resp.data,
          DevolutionStageID: this.getStageName(resp.data.DevolutionStageID),
          Type: resp.data.Type === 1 ? 'Total' : 'Parcial'
      }}
    })).toPromise();
  }

  getStageName(id) {
    const stages = {
      1: 'Pendiente',
      4: 'Pagado',
      3: 'Rechazado',
      2: 'Autorizado'
    }

    return stages[id]
  }

  async processDetail() {
    const id = this.aR.snapshot.paramMap.get('id');
    const source = this.aR.snapshot.url[0].path;

    switch (source) {
      case 'extorno':
        this.titlePage = 'Extorno > Cash In > ' + id;
        return {
          headers: this.getHeadersDetails(source),
          data: this.getDetails(id, 'extornos-detail')
        };
      case 'operation-cashout':
        this.titlePage = 'Operation > Cash Out > ' + id;

        return {
          headers: this.getHeadersDetails(source),
          data: this.getDetails(id, 'cashout/operations/detail')
        };
      default:
        break;
    }
  }

  getHeadersDetails(source) {

    switch (source) {
      case 'extorno':
        return [
          {
            name: 'PublicID',
            key: 'data-PublicID'
          },
          {
            name: 'Monto',
            key: 'data-Amount'
          },
          {
            name: 'ExtornoID',
            key: 'data-ExtornoID'
          },
          {
            name: 'Estado',
            key: 'data-DevolutionStageID'
          },
          {
            name: 'TramaBankID',
            key: 'data-TramaBankID'
          },
          {
            name: 'Tipo',
            key: 'data-Type'
          },
        ];
      default:
        break;
    }

  }

}
